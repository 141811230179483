import React from 'react';
import { Stack } from '@mui/material';
import { Place, Tag } from '@jaunt/api';
import styled from '@emotion/styled';
import { PlaceGalleryCard } from './PlaceGalleryCard';
import { device } from '../../settings/jaunt-theme';

type PlacesTileGalleryProps = {
  places: Place[];
  placesTags: Record<string, Tag[]>;
};

export const PlacesTileGallery: React.FC<PlacesTileGalleryProps> = ({
  places,
  placesTags,
}): JSX.Element | null => {
  return (
    <PlacesWrapper spacing={2}>
      {places?.map((place) => (
        <PlaceGalleryCard
          key={place.placeId}
          place={place}
          tags={placesTags[place.placeId]}
        />
      ))}
    </PlacesWrapper>
  );
};

const PlacesWrapper = styled(Stack)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto !important;
  max-width: 400px;
  gap: 7px;

  ${device.tablet} {
    max-width: 640px;
    gap: 20px;
    justify-content: flex-start;
  }

  ${device.laptopN} {
    gap: 20px;
    max-width: none;
    justify-content: flex-start;
    align-items: center;
    overflow-y: hidden;
    overflow-x: auto;
    flex-wrap: nowrap;
  }
`;

// const Container = styled(Stack)`
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   align-items: center;
//   margin: 0 auto;
//   justify-content: flex-start;
//   max-width: 400px;
//   gap: 7px;
//
//   ${device.tablet} {
//     max-width: 580px;
//     gap: 20px;
//   }
//
//   ${device.laptopN} {
//     gap: 20px;
//     max-width: none;
//     justify-content: flex-start;
//     align-items: flex-start;
//     overflow-y: hidden;
//     overflow-x: auto;
//     flex-wrap: nowrap;
//   }
// `;
