import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import { device } from '../../settings/jaunt-theme';
import { ExploreImageCard } from '../../components/ExploreImageCard';
import {
  useCreateFavPlace,
  useDeleteFavPlace,
  useFavoritePlaces,
} from '../../hooks/use-favorite-places';
import { usePlaces } from '../../hooks/use-places';
import {
  getPlacesWithImageUrl,
  PlaceWithImageUrl,
} from '../../services/place.util';

export function FavoritePlaces(): JSX.Element {
  const { data: favoritePlaces } = useFavoritePlaces();
  const { data: places } = usePlaces(
    favoritePlaces?.map((item) => item.placeId) ?? [],
  );

  const [placesWithImageUrl, setPlacesWithImageUrl] =
    useState<PlaceWithImageUrl[]>();
  const { createFavPlace } = useCreateFavPlace();
  const { deleteFavPlace } = useDeleteFavPlace();

  useEffect(() => {
    load();

    async function load() {
      if (places?.length) {
        const res = await getPlacesWithImageUrl(places);
        setPlacesWithImageUrl(res);
      } else if (placesWithImageUrl?.length) {
        setPlacesWithImageUrl([]);
      }
    }
  }, [places]);

  async function handleToggleFavPlaces(placeId: string, isNewFav: boolean) {
    if (isNewFav) {
      await createFavPlace({ placeId });
    } else {
      await deleteFavPlace({ placeId });
    }
  }

  return (
    <CitiesWrapper>
      {placesWithImageUrl?.map((placeWithImageUrl) => (
        <ExploreImageCard
          key={placeWithImageUrl.place.placeId}
          id={placeWithImageUrl.place.placeId}
          title={placeWithImageUrl.place.name}
          description={placeWithImageUrl.place.description}
          imageUrl={placeWithImageUrl.imageUrl}
          routePath={`/places/${placeWithImageUrl.place.placePath}`}
          isFavorite={true}
          onToggleFavorite={handleToggleFavPlaces}
        />
      ))}
    </CitiesWrapper>
  );
}

const CitiesWrapper = styled(Stack)`
  margin: 32px auto 0 !important;
  width: 100%;
  align-items: center;
  gap: 8px;

  ${device.tablet} {
    width: 674px;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 22px;
  }

  ${device.laptopN} {
    margin: 36px auto 0 !important;
    padding: 0 16px;
    flex-direction: row;
    overflow-x: auto;
    width: 100%;
    flex-wrap: nowrap;
    gap: 16px;
  }

  ${device.desktop} {
    gap: 21px;
  }
`;
