import { NeighborhoodId, Place } from '@jaunt/api';
import { useQuery, UseQueryResult } from 'react-query';
import QueryKeys from './constants/query-keys';
import { getNeighborhoodPlaces } from '../services/neighborhood.api';

export function usePlacesByNeighborhood(
  neighborhoodId: NeighborhoodId | null | undefined,
): UseQueryResult<Place[], unknown> {
  return useQuery<Place[]>(
    [QueryKeys.placesByNeighborhood, neighborhoodId],
    async () => {
      return neighborhoodId ? await getNeighborhoodPlaces(neighborhoodId) : [];
    },
    {
      placeholderData() {
        return [];
      },
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading PlacesByNeighborhood: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!neighborhoodId,
    },
  );
}
