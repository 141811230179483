import { PlaceId, PlaceImage, PlaceImageData } from '@jaunt/api';
import { getPlaceImages } from '../services/place.api';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import QueryKeys from './constants/query-keys';
import { replacePlaceImages } from '../services/place-image.api';

export function usePlaceImages(
  placeId: PlaceId | null | undefined,
): UseQueryResult<PlaceImage[], unknown> {
  return useQuery<PlaceImage[]>(
    [QueryKeys.placeImages, placeId],
    async () => {
      if (placeId) {
        return await getPlaceImages(placeId);
      }

      return [];
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading PlaceImages: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!placeId,
    },
  );
}

export const useReplacePlaceImages = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({ placeId, data }: { placeId: PlaceId; data: PlaceImageData[] }) => {
      await replacePlaceImages(placeId, data);
    },
    {
      onSuccess: (placeImages, { placeId }) => {
        client.setQueryData([QueryKeys.places, placeId], placeImages);
        client.invalidateQueries([QueryKeys.placeImages, placeId]);
      },
    },
  );
  return { replacePlaceImages: mutateAsync, isLoading, isSuccess, isError };
};
