import { User } from '@jaunt/api';
import { createContext, ReactNode, useContext, useState } from 'react';

interface AuthState {
  me?: User;
}

interface PageState {
  authState: AuthState;
  setAuthState(data: AuthState): void;
}

const initialState: PageState = {
  authState: {
    me: undefined,
  },
  setAuthState() {
    // do nothing (this will be overridden later)
  },
};

const Context = createContext<PageState>(initialState);

interface Props {
  children: ReactNode;
}

export function PageContext(props: Props): JSX.Element {
  const { children } = props;
  const [authState, setAuthState] = useState<AuthState>(initialState.authState);

  return (
    <Context.Provider value={{ authState, setAuthState }}>
      {children}
    </Context.Provider>
  );
}

export function usePageContext(): PageState {
  return useContext(Context);
}
