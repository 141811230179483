import { CityId, Trip, TripId } from '@jaunt/api';
import { FormSelectOption } from '../components/shared/FormSelect';
import { getTripImages, getUserTrips, listTrips } from './trip.api';
import { getFileDownloadUrl } from './file.api';
import { getCityCuratedTrips } from './city.api';

export interface TripWithImageUrl {
  trip: Trip;
  imageUrl: string;
}

export async function getCuratedTripsWithImageUrl(): Promise<
  TripWithImageUrl[]
> {
  const trips = await listTrips(true);
  const imageUrls = await Promise.all(
    trips.map(async (trip) => await getFirstTripImageUrl(trip.tripId)),
  );
  return trips.map((trip, index) => ({ trip, imageUrl: imageUrls[index] }));
}

export async function getUserTripsWithImageUrl(): Promise<TripWithImageUrl[]> {
  const trips = await getUserTrips();
  const imageUrls = await Promise.all(
    trips.map(async (trip) => await getFirstTripImageUrl(trip.tripId)),
  );
  return trips.map((trip, index) => ({ trip, imageUrl: imageUrls[index] }));
}

export async function getCityCuratedTripsWithImageUrl(
  cityId: CityId,
): Promise<TripWithImageUrl[]> {
  const trips = await getCityCuratedTrips(cityId);
  const imageUrls = await Promise.all(
    trips.map(async (trip) => await getFirstTripImageUrl(trip.tripId)),
  );
  return trips.map((trip, index) => ({ trip, imageUrl: imageUrls[index] }));
}

export async function getFirstTripImageUrl(tripId: TripId): Promise<string> {
  const tripImages = (await getTripImages(tripId)) ?? [];
  return await getFileDownloadUrl(tripImages[0]?.fileId);
}

export function getTripOptions(trips: Trip[]): FormSelectOption[] {
  return trips.map((trip) => ({
    value: trip.tripId,
    label: trip.name,
  }));
}
