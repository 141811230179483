import styled from '@emotion/styled';
import { Box, Button, Stack } from '@mui/material';
import { useState } from 'react';
import { device } from 'src/settings/jaunt-theme';
import { Tex } from '../shared/Tex';

const options = ['Family', 'Friends', 'Partner', 'Coworkers', 'Kids', 'No One'];

interface Props {
  onChange(): void;
}

export function PlanTripMembersStep(props: Props): JSX.Element {
  const { onChange } = props;
  const [members, setMembers] = useState<string[]>([]);

  function toggleMembers(member: string): void {
    setMembers((previous) =>
      previous.includes(member)
        ? previous.filter((x) => x !== member)
        : [...members, member],
    );
  }

  return (
    <Stack spacing={6}>
      <Tex color="primary" textAlign="center" fontSize={20}>
        <b>Who&lsquo;s joining you?</b>
      </Tex>
      <Stack spacing={1} alignItems="center">
        <Tex variant="caption">Select as many as you like</Tex>
        <Box sx={{ width: '325px' }}>
          {options.map((option) => (
            <Button
              key={option}
              variant={members.includes(option) ? 'contained' : 'outlined'}
              onClick={() => toggleMembers(option)}
              sx={{
                m: 0.5,
                width: 100,
                textTransform: 'capitalize',
                borderRadius: 2,
              }}
            >
              {option}
            </Button>
          ))}
        </Box>
      </Stack>
      <Stack alignItems="center">
        <NextButton
          variant="contained"
          onClick={() => onChange()}
          disabled={members.length === 0}
        >
          NEXT
        </NextButton>
      </Stack>
    </Stack>
  );
}

const NextButton = styled(Button)`
  padding: 8px 48px;
  border-radius: 9999px;

  ${device.tablet} {
    width: 245px;
  }
`;
