import {
  City,
  CityDetailsGPT,
  Trip,
  TripHotelType,
  TripVibe,
  UserId,
} from '@jaunt/api';
import { Dialog, Stack, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CityWithImageUrl } from '../../services/city.util';
import { v4 as uuid } from 'uuid';
import { PlanTripCityStep } from './PlanTripCityStep';
import { PlanTripMembersStep } from './PlanTripMembersStep';
import { PlanTripNameStep } from './PlanTripNameStep';
import { PlanTripVibeStep } from './PlanTripVibesStep';
import { SignUpStep } from './SignUpStep';
import {
  removeLocalStorageItem,
  setLocalStorageItem,
} from '../../services/local-storage.util';
import {
  BuildTripMode,
  NEW_TRIP,
  NEW_TRIP_CITY_ID,
  NEW_TRIP_PLACE_IDS,
} from '../../constants';
import { useMe } from '../../hooks/use-me';
import { useCreateTrip } from '../../hooks/use-trip';
import { useReplaceTripCity } from '../../hooks/use-trip-city';
import { useReplaceTripUsers } from '../../hooks/use-trip-users';
import styled from '@emotion/styled';
import { device } from 'src/settings/jaunt-theme';
import { PlanTripModeStep } from './PlanTripModeStep';
import { PlanTripHotelTypeStep } from './PlanTripHotelTypeStep';
import { PlanTripDaysStep } from './PlanTripDaysStep';
import { getCuratedTripByCityAndDays } from '../../services/trip.api';
import { useSaveGeneratedQuiz } from 'src/hooks/use-generated-trips';

interface Props {
  isOpen: boolean;
  citiesWithImageUrl: CityWithImageUrl[];
  onClose(): void;
  initialCityGpt?: CityDetailsGPT;
  initialCity?: City;
  initialStep?: number;
}

export function PlanTripDialog(props: Props): JSX.Element {
  const {
    isOpen,
    citiesWithImageUrl,
    onClose,
    initialCityGpt = null,
    initialCity = null,
    initialStep = 0,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { data: userId } = useMe(true);
  const [step, setStep] = useState(initialStep);
  const [history, setHistory] = useState([initialStep]);
  const [chosenCity, setChosenCity] = useState<City | null>(initialCity);
  const [chosenCityGpt, setChosenCityGpt] = useState<CityDetailsGPT | null>(
    initialCityGpt,
  );
  const [createdBy] = useState<UserId>('');
  const [vibe, setVibe] = useState<TripVibe>();
  const [budget, setBudget] = useState(0);
  const [existingTrip, setExistingTrip] = useState<Trip | null>();
  const [days, setDays] = useState(0);
  const [isAutoGenerate, setIsAutoGenerate] = useState<boolean>(false);
  const [isGptCity, setIsGptCity] = useState<boolean>(false);
  const [newTripPath, setNewTripPath] = useState<string>('');
  const [isMobile, setIsMobile] = useState(false);
  const { createTrip } = useCreateTrip();
  const { replaceTripCity } = useReplaceTripCity();
  const { replaceTripUsers } = useReplaceTripUsers();
  const { createGeneratedTrip } = useSaveGeneratedQuiz();

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 745);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setStep(initialStep);
    setHistory([initialStep]);
  }, [initialStep]);

  useEffect(() => {
    if (initialCity) {
      setChosenCity(initialCity);
    } else if (initialCityGpt) {
      setChosenCityGpt(initialCityGpt);
      setIsGptCity(true);
    }
  }, [initialCity, initialCityGpt]);

  function handleCityChange(city: City): void {
    setChosenCity(city);
    nextStep(1);
  }

  async function handleCityGptChange(city: CityDetailsGPT): Promise<void> {
    setChosenCityGpt(city);
    setIsGptCity(true);
    nextStep(6);
  }

  function handleModeChange(mode: BuildTripMode): void {
    if (mode === BuildTripMode.auto) {
      nextStep(6);
      setIsAutoGenerate(true);
    } else {
      nextStep(2);
      setIsAutoGenerate(false);
    }
  }

  function handleMembersChange(): void {
    nextStep(3);
  }

  async function handleVibesChange(vibe: TripVibe): Promise<void> {
    setVibe(vibe);
    if (isGptCity || isAutoGenerate) {
      nextStep(5);
    } else {
      nextStep(4);
    }
  }

  async function handleHotelTypeChange(
    newHotelType: TripHotelType,
  ): Promise<void> {
    if (isGptCity && chosenCityGpt) {
      const tripPath = uuid();
      const name = chosenCityGpt?.name?.toLowerCase().trim() ?? tripPath;
      await createGeneratedTrip({
        name,
        tripPath: tripPath,
        isGptCity: true,
        gptCity: chosenCityGpt,
        vibe: vibe as TripVibe,
        days,
        hotelType: newHotelType,
      });
      if (userId) {
        navigate(`/generated-trips/${tripPath}`);
        handleClose();
        return;
      }
      setNewTripPath(tripPath);
      nextStep(7);
    }

    if (chosenCity) {
      const name = chosenCity.name?.toLowerCase().trim();
      const tripPath = uuid();
      await createGeneratedTrip({
        name,
        tripPath: tripPath,
        city: chosenCity,
        vibe: vibe as TripVibe,
        days,
        hotelType: newHotelType,
        isAutoGenerate: true,
      });

      if (userId) {
        navigate(`/generated-trips/${tripPath}`);
        handleClose();
        return;
      }
      setNewTripPath(tripPath);
      nextStep(7);
    }
  }

  async function handleDaysChange(daysNumber: number): Promise<void> {
    setDays(daysNumber);

    if (chosenCity?.cityId) {
      const { trip } = await getCuratedTripByCityAndDays(
        chosenCity?.cityId,
        daysNumber,
      );

      if (trip) {
        if (userId) {
          navigate(`/trips/${trip.tripPath}?isCurated=true`);
          handleClose();
          return;
        }
        setExistingTrip(trip);
        nextStep(7);
      }
    }

    nextStep(3);
  }

  async function handleNameChange(name: string): Promise<void> {
    if (chosenCity) {
      const { cityId } = chosenCity;
      const newTrip = {
        name,
        tripId: uuid(),
        tripPath: uuid(),
        isCurated: false,
        detail: {
          createdBy,
          days: {},
          isWithDates: false,
          vibes: vibe ? [vibe] : [],
          budget,
        },
        description: '',
        creatorLink: '',
      };
      if (userId) {
        const createdTrip = await createTrip(newTrip);
        const { tripId } = createdTrip;

        await replaceTripCity({
          tripId,
          data: { cityId },
        });
        await replaceTripUsers({
          tripId,
          data: [{ tripId, userId }],
        });
        navigate(`/trips/${createdTrip.tripPath}`);
      } else {
        newTrip.tripId = NEW_TRIP;
        removeLocalStorageItem(NEW_TRIP);
        removeLocalStorageItem(NEW_TRIP_CITY_ID);
        setLocalStorageItem(NEW_TRIP, newTrip);
        setLocalStorageItem(NEW_TRIP_CITY_ID, cityId);
        removeLocalStorageItem(NEW_TRIP_PLACE_IDS);
        nextStep(7);
      }
    }
  }

  function handleSignIn(): void {
    if (existingTrip) {
      navigate(`/trips/${existingTrip.tripPath}?isCurated=true`);
      handleClose();
      return;
    }

    if (isGptCity || isAutoGenerate) {
      if (location.pathname?.includes(`/generated-trips/${newTripPath}`)) {
        navigate(0);
      } else {
        navigate(`/generated-trips/${newTripPath}?firstly=true`);
      }
    } else {
      if (location.pathname === `/trips/${NEW_TRIP}`) {
        navigate(0);
      } else {
        navigate(`/trips/${NEW_TRIP}`);
      }
    }
  }

  function nextStep(nextStep: number): void {
    setHistory((history) => history.concat(nextStep));
    setStep(nextStep);
  }

  function goPreviousStep(): void {
    const newHistory = [...history];
    newHistory.length -= 1;

    if (history.length > 1) {
      setHistory(newHistory);
      setStep(newHistory[newHistory.length - 1]);
    }
  }

  function handleClose(): void {
    setStep(0);
    setChosenCity(null);
    setIsGptCity(false);
    setIsAutoGenerate(false);
    setNewTripPath('');
    setVibe(undefined);
    setBudget(0);
    setExistingTrip(null);
    onClose();
  }

  return (
    <DialogWrapper
      open={isOpen}
      onClose={handleClose}
      maxWidth={false}
      fullScreen={isMobile}
    >
      <DialogContainer spacing={6}>
        <Stack
          height={'30px'}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          gap={'2px'}
          sx={{ position: 'relative', top: '4px' }}
        >
          {history.length > 1 ? (
            <IconButton
              aria-label="back"
              onClick={goPreviousStep}
              sx={{
                position: 'absolute',
                left: 4,
                margin: 0,
                padding: 0,
                color: (theme) => theme.palette.primary.main,
              }}
            >
              <KeyboardBackspaceIcon fontSize="large" />
            </IconButton>
          ) : null}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 4,
              margin: 0,
              padding: 0,
              color: (theme) => theme.palette.primary.main,
            }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Stack>

        {step === 0 && (
          <PlanTripCityStep
            citiesWithImageUrl={citiesWithImageUrl}
            onCityChange={handleCityChange}
            onGptCityChange={handleCityGptChange}
          />
        )}
        {step === 1 && <PlanTripModeStep onChange={handleModeChange} />}
        {step === 2 && <PlanTripMembersStep onChange={handleMembersChange} />}
        {step === 3 && <PlanTripVibeStep onChange={handleVibesChange} />}
        {step === 4 && <PlanTripNameStep onChange={handleNameChange} />}
        {step === 5 && (
          <PlanTripHotelTypeStep onChange={handleHotelTypeChange} />
        )}
        {step === 6 && <PlanTripDaysStep onChange={handleDaysChange} />}
        {step === 7 && <SignUpStep onChange={handleSignIn} />}
      </DialogContainer>
    </DialogWrapper>
  );
}

const DialogWrapper = styled(Dialog)`
  margin: 16px;

  & .MuiPaper-root {
    border-radius: 20px;
  }

  ${device.tablet} {
    & .MuiPaper-root {
      border-radius: 20px;
    }
  }
`;

const DialogContainer = styled(Stack)`
  padding: 16px;

  ${device.tablet} {
    padding: 28px 30px 20px;
    width: 616px;
    height: 665px;
  }

  ${device.laptopS} {
    width: 753px;
    height: 575px;
  }
`;
