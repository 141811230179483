import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Stack } from '@mui/material';
import { common } from '@mui/material/colors';
import { Tex } from './shared/Tex';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import styled from '@emotion/styled';

interface Props {
  id: string;
  title: string;
  description: string;
  tip?: string;
  imageUrl: string;
  routePath: string;
  isFavorite: boolean;
  onToggleFavorite: (id: string, isNewFav: boolean) => void;
}

export function ExploreImageCard({
  id,
  title,
  tip,
  description,
  imageUrl,
  routePath,
  isFavorite,
  onToggleFavorite,
}: Props): JSX.Element {
  async function handleToggleFavorite(event: React.MouseEvent) {
    event.preventDefault();
    onToggleFavorite(id, !isFavorite);
  }

  return (
    <Link
      component={RouterLink}
      to={routePath}
      underline="none"
      sx={{ margin: '0 !important' }}
    >
      <Stack
        justifyContent="end"
        sx={{
          width: 350,
          height: 400,
          flexShrink: 0,
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: 'cover',
          backgroundColor: '#F5C6AB',
          backgroundPosition: 'center',
          borderRadius: 4,
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        {tip ? (
          <Stack
            sx={{
              position: 'absolute',
              top: '16px',
              right: '16px',
              py: 1,
              px: 1.5,
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              borderRadius: '20px',
            }}
          >
            <Tex
              fontSize={12}
              fontWeight={700}
              lineHeight="16px"
              fontFamily="CircularStd-Book"
            >
              {tip}
            </Tex>
          </Stack>
        ) : null}
        <Stack
          sx={{
            position: 'absolute',
            top: '8px',
            left: '8px',
            py: 1,
            px: 1.5,
          }}
          onClick={handleToggleFavorite}
        >
          {isFavorite ? (
            <FavoriteOutlinedIcon fontSize="large" htmlColor="#FE0000" />
          ) : (
            <FavoriteBorderOutlinedIcon fontSize="large" htmlColor="#CCD2E3" />
          )}
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            px: 1,
            py: 2,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            color: common.white,
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Tex fontSize={22} fontWeight={700}>
              {title}
            </Tex>
            <DescriptionTex>{description}</DescriptionTex>
          </Box>
        </Stack>
      </Stack>
    </Link>
  );
}

const DescriptionTex = styled(Tex)`
  font-weight: 300;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
