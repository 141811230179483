import { PlaceId } from '@jaunt/api';
import { getFileDownloadUrl } from '../services/file.api';
import { getPlaceImages } from '../services/place.api';
import { useQuery, UseQueryResult } from 'react-query';
import QueryKeys from './constants/query-keys';

export function usePlaceFirstImage(
  placeId: PlaceId | null,
): UseQueryResult<string | undefined, unknown> {
  return useQuery<string | undefined>(
    [QueryKeys.placeFirstImage, placeId],
    async () => {
      if (placeId) {
        const [placeImage] = await getPlaceImages(placeId);
        return placeImage ? await getFileDownloadUrl(placeImage.fileId) : '';
      }

      return undefined;
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading PlaceFirstImage: ', error); // eslint-disable-line no-console
      },
      // TODO add client.setQueryData([QueryKeys.fileDownloadUrl, placeImage.fileId], imageUrl); to onSuccess
      retry: 3,
      retryDelay: 2000,
      enabled: !!placeId,
    },
  );
}
