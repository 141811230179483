import axios, {
  AxiosError,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { getAuth } from 'firebase/auth';
import { env } from '../settings/env';
import { memoryCache } from '../settings/memory-cache';
import { createSession } from './auth.api';
import { hydrateWithDate } from './base.util';

export interface ErrorResponse {
  error: string;
  message: string;
  statusCode: number;
}

// a non-retry-able axios instance
const noRetryApi = axios.create();
noRetryApi.interceptors.request.use(requestInterceptor);
noRetryApi.interceptors.response.use(responseInterceptor);

// a retry-able axios instance
const retryApi = axios.create();
retryApi.interceptors.request.use(requestInterceptor);
retryApi.interceptors.response.use(
  responseInterceptor,
  errorResponseInterceptor,
);

export async function updateInterceptorMiddleware() {
  noRetryApi.interceptors.request.use(requestInterceptor);
  noRetryApi.interceptors.response.use(responseInterceptor);
  retryApi.interceptors.request.use(requestInterceptor);
  retryApi.interceptors.response.use(
    responseInterceptor,
    errorResponseInterceptor,
  );
}

async function requestInterceptor(
  config: AxiosRequestConfig,
): Promise<AxiosRequestConfig> {
  const { idToken, isFirebaseInitialized } = memoryCache;
  if (isFirebaseInitialized) {
    return {
      ...config,
      baseURL: env.JAUNT_API_URL,
      withCredentials: true,
      headers: {
        ...(idToken && {
          Authorization: `Bearer ${idToken}`, // auth by access token
        }),
        'Content-Type': 'application/json', // overwrites text/plain on 401 error
      },
    };
  }
  // delay 100 ms and retry
  return new Promise((resolve) =>
    setTimeout(() => resolve(requestInterceptor(config)), 100),
  );
}

function responseInterceptor(response: AxiosResponse): AxiosResponse {
  // hydrate date from string => Date, as they were serialized in AJAX response
  if (response.data) {
    response.data = hydrateWithDate(response.data);
  }
  return response;
}

async function errorResponseInterceptor(
  error: AxiosError,
): Promise<AxiosPromise> {
  if (error.response?.status === 401) {
    const { currentUser } = getAuth();
    if (currentUser) {
      // retry API after refreshing the expired Firebase session
      await createSession();
      return noRetryApi(error.config);
    } else {
      // window.location.href = '/login';
    }
  }
  throw error;
}

export async function get<R = void, Q = Record<string, never>>(
  url: string,
  params: Q,
  retry = true,
): Promise<R> {
  const api = retry ? retryApi : noRetryApi;
  const response = await api.get(url, { params });
  return response.data;
}

export async function post<
  R = void,
  D = Record<string, never>,
  Q = Record<string, never>,
>(url: string, params: Q, data: D, retry = true): Promise<R> {
  const api = retry ? retryApi : noRetryApi;
  const response = await api.post(url, data, { params });
  return response.data;
}

export async function put<
  R = void,
  D = Record<string, never>,
  Q = Record<string, never>,
>(url: string, params: Q, data: D, retry = true): Promise<R> {
  const api = retry ? retryApi : noRetryApi;
  const response = await api.put(url, data, { params });
  return response.data;
}

export async function patch<
  R = void,
  D = Record<string, never>,
  Q = Record<string, never>,
>(url: string, params: Q, data: D, retry = true): Promise<R> {
  const api = retry ? retryApi : noRetryApi;
  const response = await api.patch(url, data, { params });
  return response.data;
}

export async function del<R = void, Q = Record<string, never>>(
  url: string,
  params: Q,
  retry = true,
): Promise<R> {
  const api = retry ? retryApi : noRetryApi;
  const response = await api.delete(url, { params });
  return response.data;
}
