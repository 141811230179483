import { Place, PlaceId } from '@jaunt/api';
import { batchGetPlaces, listPlaces } from '../services/place.api';
import { useQuery, UseQueryResult } from 'react-query';
import QueryKeys from './constants/query-keys';

export function usePlaces(
  placeIds: PlaceId[],
): UseQueryResult<Place[], unknown> {
  return useQuery<Place[]>(
    [QueryKeys.places, ...placeIds],
    async () => {
      if (placeIds.length > 0) {
        return await batchGetPlaces(placeIds);
      }
      return [];
    },
    {
      placeholderData() {
        return [];
      },
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading Places: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
    },
  );
}

export function useListPlaces(): UseQueryResult<Place[], unknown> {
  return useQuery<Place[]>(QueryKeys.places, listPlaces, {
    placeholderData() {
      return [];
    },
    onError(error) {
      // tslint:disable-next-line:no-console
      console.log('Error loading Places: ', error); // eslint-disable-line no-console
    },
    retry: 3,
    retryDelay: 2000,
  });
}
