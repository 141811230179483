import { Dialog, List, Stack } from '@mui/material';
import styled from '@emotion/styled';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: JSX.Element;
}

export function SchedulePlaceDialog({
  isOpen,
  onClose,
  children,
}: Props): JSX.Element {
  return (
    <DialogWrapper open={isOpen} onClose={onClose}>
      <Stack spacing={4} direction="column" alignItems="center">
        <List sx={{ pt: 0 }}>{children}</List>
      </Stack>
    </DialogWrapper>
  );
}

const DialogWrapper = styled(Dialog)`
  background-color: rgba(0, 0, 0, 0.1);

  .MuiPaper-root {
    background-color: #fff;
    width: 80%;
    border-radius: 10px;
  }

  .MuiList-root {
    width: 100%;
    padding-bottom: 0;
  }
`;
