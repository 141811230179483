import { TripData, TripId, TripUser, UserId } from '@jaunt/api';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import QueryKeys from './constants/query-keys';
import {
  createTripByUser,
  getTripsByUser,
  saveCuratedTripToUser,
} from '../services/trip.api';

export function useUserTrips(
  userId?: UserId | null,
): UseQueryResult<TripUser[] | undefined, unknown> {
  return useQuery<TripUser[] | undefined>(
    QueryKeys.userTrips,
    async () => {
      return userId ? await getTripsByUser(userId) : undefined;
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading City: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!userId,
    },
  );
}

export const useCreateTripByUser = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async (data: TripData) => {
      return await createTripByUser(data);
    },
    {
      onSuccess: (trip) => {
        client.setQueryData(QueryKeys.userTrips, trip);
      },
    },
  );
  return { createTripByUser: mutateAsync, isLoading, isSuccess, isError };
};

export const useSaveCuratedTripByUser = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async (tripId: TripId) => {
      return await saveCuratedTripToUser(tripId);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(QueryKeys.userTrips);
      },
    },
  );
  return { saveTrip: mutateAsync, isLoading, isSuccess, isError };
};
