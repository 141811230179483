import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { NotFoundPage } from '../pages/NotFoundPage';
import { LandingPage } from '../pages/LandingPage';
import { LogInPage } from '../pages/LogInPage';
import { RegisterPage } from '../pages/RegisterPage';
import { PlannerPage } from '../pages/PlannerPage';
import { ExplorePage } from '../pages/ExplorePage';
import { AdminPage } from '../pages/AdminPage';
import { CityPage } from '../pages/CityPage';
import { PlacesPage } from '../pages/PlacesPage';
import { TripPage } from '../pages/TripPage';
import { PlacePage } from '../pages/PlacePage';
import { PrivacyPage } from '../pages/PrivacyPage';
import { TermsofServicePage } from '../pages/TermsofServicePage';
import { MyTripsPage } from 'src/pages/MyTripsPage';
import { TripTimingPage } from '../pages/TripTimingPage';
import { GeneratedTripsPage } from 'src/pages/GeneratedTripsPage';
import { SchedulePlace } from '../pages/SchedulePlace';
import { AddTripPlace } from '../pages/AddTripPlace';
import { NeighborhoodPage } from '../pages/NeighborhoodPage';
import { EditPlaceSummary } from '../pages/EditPlaceSummary';
import { useCities } from 'src/hooks/use-cities';
import { useTrips } from 'src/hooks/use-trip';
import { LoadingContainer } from 'src/components/loading/LoadingContainer';
import { PublicGeneratedTripPage } from '../pages/PublicGeneratedTripPage';
import { PublicTripPage } from '../pages/public-trip-page/PublicTripPage';
import { FavoritesPage } from '../pages/favorites-page/FavoritesPage';
import { AccountPage } from '../pages/AccountPage';

const OurStoryPage = React.lazy(() => import('../pages/OurStoryPage'));

function JauntRoutes(): JSX.Element {
  useCities();
  useTrips();

  return (
    <BrowserRouter>
      <Routes>
        {/*<Route path="/" element={<EmailCollectionPage />} />*/}
        <Route path="/" element={<LandingPage />} />
        <Route path="/admin/:pageKey" element={<AdminPage />} />
        <Route path="/cities/:cityPath" element={<CityPage />} />
        <Route path="/explore/:pageKey" element={<ExplorePage />} />
        <Route path="/account" element={<AccountPage />} />
        <Route path="/login" element={<LogInPage />} />
        <Route path="/places" element={<PlacesPage />} />
        <Route path="/places/:placePath" element={<PlacePage />} />
        <Route
          path="/neighborhoods/:neighborhoodPath"
          element={<NeighborhoodPage />}
        />
        <Route path="/planner" element={<PlannerPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/favorites/:type" element={<FavoritesPage />} />
        <Route path="/mytrips/:tripsType" element={<MyTripsPage />} />
        <Route path="/trips/:tripPath" element={<TripPage />} />
        <Route
          path="/generated-trips/:tripPath"
          element={<GeneratedTripsPage />}
        />
        <Route path="/trips/:tripPath/register" element={<RegisterPage />} />
        <Route path="/trips/:tripPath/timing" element={<TripTimingPage />} />
        <Route
          path="/trips/:tripPath/places/:placePath/edit-summary"
          element={<EditPlaceSummary />}
        />
        <Route
          path="/trips/:tripPath/places/:placePath/schedule"
          element={<SchedulePlace />}
        />
        <Route path="/trips/:tripPath/add-place" element={<AddTripPlace />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/public/trips/:tripPath" element={<PublicTripPage />} />
        <Route
          path="/public/generated-trips/:tripPath"
          element={<PublicGeneratedTripPage />}
        />
        <Route
          path="our-story"
          element={
            <Suspense fallback={<LoadingContainer />}>
              <OurStoryPage />
            </Suspense>
          }
        />
        <Route path="/terms" element={<TermsofServicePage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default JauntRoutes;
