import { del, get, post, put } from './base.api';
import {
  Place,
  PlaceId,
  PlaceData,
  SinglePlaceResponse,
  MultiplePlacesResponse,
  BatchGetPlacesData,
  MultipleTagsResponse,
  Tag,
  PlaceImage,
  MultiplePlaceImagesResponse,
  TagId,
  ReplacePlaceTagsData,
  MultipleReviewsResponse,
  Review,
  ReviewId,
  ReplacePlaceReviewsData,
  TripPlace,
  MultiplePlaceTripsResponse,
  TripId,
  BatchAddPlaceToTrips,
  MultipleFavoritePlaceResponse,
  SingleFavoritePlaceResponse,
  FavoritePlace,
} from '@jaunt/api';

export async function listPlaces(): Promise<Place[]> {
  const { places } = await get<MultiplePlacesResponse>('/places', {});
  return places;
}

export async function createPlace(data: PlaceData): Promise<Place> {
  const { place } = await post<SinglePlaceResponse, PlaceData>(
    '/places',
    {},
    data,
  );
  return place;
}

export async function getPlace(placeId: PlaceId): Promise<Place> {
  const { place } = await get<SinglePlaceResponse>(`/places/${placeId}`, {});
  return place;
}

export async function getPlaceByPath(placePath: string): Promise<Place> {
  const { place } = await get<SinglePlaceResponse>(
    `/places/path/${placePath}`,
    {},
  );
  return place;
}

export async function batchGetPlaces(placeIds: PlaceId[]): Promise<Place[]> {
  const { places } = await post<MultiplePlacesResponse, BatchGetPlacesData>(
    '/places/batch-get',
    {},
    { placeIds },
  );
  return places;
}

export async function getPlaceImages(placeId: PlaceId): Promise<PlaceImage[]> {
  const { placeImages } = await get<MultiplePlaceImagesResponse>(
    `/places/${placeId}/images`,
    {},
  );
  return placeImages;
}

export async function getPlaceTags(placeId: PlaceId): Promise<Tag[]> {
  const { tags } = await get<MultipleTagsResponse>(
    `/places/${placeId}/tags`,
    {},
  );
  return tags;
}

// export async function getPlaceReviews(placeId: PlaceId): Promise<Review[]> {
//   const { reviews } = await get<MultipleReviewsResponse>(
//     `/places/${placeId}/reviews`,
//     {},
//   );
//   return reviews;
// }

export async function replacePlaceToTrips(
  placeId: PlaceId,
  tripIds: TripId[],
): Promise<TripPlace[]> {
  const { placeTrips } = await put<
    MultiplePlaceTripsResponse,
    BatchAddPlaceToTrips
  >(`/places/${placeId}/trips`, {}, { tripIds });
  return placeTrips;
}

export async function replacePlaceTags(
  placeId: PlaceId,
  tagIds: TagId[],
): Promise<Tag[]> {
  const { tags } = await put<MultipleTagsResponse, ReplacePlaceTagsData>(
    `/places/${placeId}/tags`,
    {},
    { tagIds },
  );
  return tags;
}

export async function replacePlaceReviews(
  placeId: PlaceId,
  reviewIds: ReviewId[],
): Promise<Review[]> {
  const { reviews } = await put<
    MultipleReviewsResponse,
    ReplacePlaceReviewsData
  >(`/places/${placeId}/reviews`, {}, { reviewIds });
  return reviews;
}

export async function updatePlace(
  placeId: PlaceId,
  data: PlaceData,
): Promise<Place> {
  const { place } = await put<SinglePlaceResponse, PlaceData>(
    `/places/${placeId}`,
    {},
    data,
  );
  return place;
}

export async function deletePlace(placeId: PlaceId): Promise<Place> {
  const { place } = await del<SinglePlaceResponse>(`/places/${placeId}`, {});
  return place;
}

export async function listFavoritePlaces(): Promise<FavoritePlace[]> {
  const { favoritePlaces } = await get<MultipleFavoritePlaceResponse>(
    '/place-favorite',
    {},
  );
  return favoritePlaces;
}

export async function getFavoritePlace(
  placeId: PlaceId,
): Promise<FavoritePlace> {
  const { favoritePlace } = await get<SingleFavoritePlaceResponse>(
    `/place-favorite/${placeId}`,
    {},
  );
  return favoritePlace;
}

export async function createFavoritePlace(data: {
  placeId: PlaceId;
}): Promise<FavoritePlace> {
  const { favoritePlace } = await post<
    SingleFavoritePlaceResponse,
    {
      placeId: PlaceId;
    }
  >('/place-favorite', {}, data);
  return favoritePlace;
}

export async function deleteFavoritePlace(
  placeId: PlaceId,
): Promise<FavoritePlace> {
  const { favoritePlace } = await del<SingleFavoritePlaceResponse>(
    `/place-favorite/${placeId}`,
    {},
  );
  return favoritePlace;
}
