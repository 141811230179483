import { TripHotelType } from '@jaunt/api';
import { Box, Button, Stack } from '@mui/material';
import { Tex } from '../shared/Tex';

interface Props {
  onChange(hotelType: TripHotelType): void;
}

export function PlanTripHotelTypeStep({ onChange }: Props): JSX.Element {
  const options = Object.values(TripHotelType);

  function onSelectHotelType(type: TripHotelType): void {
    onChange(type);
  }

  return (
    <Stack spacing={6}>
      <Tex color="primary" textAlign="center" fontSize={20}>
        <b>What type of hotel do you want?</b>
      </Tex>
      <Stack spacing={1} alignItems="center">
        <Box
          sx={{
            width: '325px',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            textAlign: 'center',
          }}
        >
          {options.map((option) => (
            <Button
              key={option}
              variant="outlined"
              onClick={() => onSelectHotelType(option)}
              sx={{
                m: 0.5,
                width: 100,
                textTransform: 'capitalize',
                borderRadius: 2,
              }}
            >
              {option}
            </Button>
          ))}
        </Box>
      </Stack>
    </Stack>
  );
}
