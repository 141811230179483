import { useEffect, useMemo, useState } from 'react';
import { Box, Chip, IconButton, Stack } from '@mui/material';
import { LogoHeader } from '../components/LogoHeader';
import { Tex } from '../components/shared/Tex';
import { useParams } from 'react-router-dom';
import { usePlacePath } from '../hooks/use-place';
import { common } from '@mui/material/colors';
import { mdiLightbulbOnOutline, mdiMagnify, mdiBookOpenVariant } from '@mdi/js';
import { mdiCameraOutline } from '@mdi/js';
import { MdiIcon } from '../components/shared/MdiIcon';
import { usePlaceTags } from '../hooks/use-place-tags';
import { getSummaryText } from '../services/tag.util';
import { useNeighborhood } from '../hooks/use-neighborhood';
import { PlaceId, ReviewObj, Tag, TagType, TripId } from '@jaunt/api';
import { PlaceExternalLink } from '../components/PlaceExternalLink';
import { usePlaceImages } from '../hooks/use-place-images';
import { Footer } from '../components/Footer';
import { PlaceReviewCard } from 'src/components/PlaceReviewCard';
import { usePlaceReviews } from 'src/hooks/use-place-reviews';
import { device, SECONDARY_COLOR } from '../settings/jaunt-theme';
import { PlaceToTripDialog } from '../components/PlaceToTripDialog';
import { useImageUrls } from '../hooks/use-image-urls';
import { useMe } from '../hooks/use-me';
import { useCustomUserTripsByCity } from '../hooks/use-trip';
import { useLoadPlacesTrips } from '../hooks/use-trip-places';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { NotFoundContainer } from 'src/components/NotFoundContainer';
import { LoadingContainer } from 'src/components/loading/LoadingContainer';
import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import {
  useCreateFavPlace,
  useDeleteFavPlace,
  useFavoritePlaces,
} from '../hooks/use-favorite-places';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';

export function PlacePage(): JSX.Element {
  const params = useParams();
  const placePath = params.placePath || null;
  const { data: place, isLoading: isPlaceLoading } = usePlacePath(placePath);
  const cityId = place?.cityId;
  const { data: neighborhood } = useNeighborhood(place?.neighborhoodId || null);
  const { data: placeImages } = usePlaceImages(place?.placeId);
  const { data: imageUrls } = useImageUrls(placeImages);
  const { data: tags } = usePlaceTags(place?.placeId);
  const { data: reviews } = usePlaceReviews(place?.placeId);
  const { data: favoritePlaces } = useFavoritePlaces();
  const { createFavPlace } = useCreateFavPlace();
  const { deleteFavPlace } = useDeleteFavPlace();
  const secondaryTags = tags?.filter(
    (x: Tag) => x.type === TagType.SECONDARY_ACTIVITY,
  );
  const summaryText = getSummaryText(tags);
  const { data: userId } = useMe(true);
  const { data: trips } = useCustomUserTripsByCity(userId, cityId, false);
  const { loadPlacesTrips } = useLoadPlacesTrips();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [placesTrips, setPlacesTrips] = useState<Record<PlaceId, TripId[]>>();
  const [shouldUpdateTrips, setShouldUpdateTrips] = useState<boolean>(true);
  const [isDesktop, setIsDesktop] = useState(false);

  const isNewFav = useMemo(() => {
    if (favoritePlaces?.length && place?.placeId) {
      const favPlacesIds = favoritePlaces.reduce(
        (acc: Record<string, boolean>, item) => {
          acc[item.placeId] = true;
          return acc;
        },
        {},
      );
      return favPlacesIds[place?.placeId];
    }
    return false;
  }, [favoritePlaces]);

  async function handleToggleFavPlaces() {
    if (place?.placeId) {
      if (!isNewFav) {
        await createFavPlace({ placeId: place.placeId });
      } else {
        await deleteFavPlace({ placeId: place.placeId });
      }
    }
  }

  useEffect(() => {
    function handleResize() {
      setIsDesktop(window.innerWidth >= 1024);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!shouldUpdateTrips) return;
    let mounted = true;

    async function loadTrips() {
      if (trips?.length) {
        const tripIds = trips.map((trip) => trip.tripId);
        const placesTrips = await loadPlacesTrips(tripIds);

        if (mounted) {
          setPlacesTrips(placesTrips);
          setShouldUpdateTrips(false);
        }
      }
    }

    loadTrips();

    return () => {
      mounted = false;
    };
  }, [trips, shouldUpdateTrips]);

  function handleCloseDialog() {
    setIsOpenDialog(false);
    setShouldUpdateTrips(true);
  }

  if (isPlaceLoading) {
    return <LoadingContainer />;
  }

  return place ? (
    <>
      <LogoHeader showAuthLinks={true} />
      <Stack className="main">
        <Container>
          {place && (
            <ContentWrapper>
              <Stack spacing={4}>
                <ImageContainer imageurl={imageUrls?.[0]}>
                  {/*TODO uncomment when mytrips be ready*/}
                  {trips?.length ? (
                    <>
                      <Stack
                        direction="row-reverse"
                        justifyContent="space-between"
                        sx={{
                          position: 'absolute',
                          top: '16px',
                          right: '16px',
                        }}
                      >
                        <IconButton
                          aria-label="add"
                          onClick={() => setIsOpenDialog(true)}
                          sx={{
                            margin: 0,
                            backgroundColor:
                              placesTrips && placesTrips[place.placeId]
                                ? SECONDARY_COLOR
                                : 'rgba(255, 255, 255, 0.52)',
                            color: '#fff',
                          }}
                        >
                          {placesTrips && placesTrips[place.placeId] ? (
                            <CheckIcon
                              fontSize={isDesktop ? 'small' : 'large'}
                              htmlColor="#fff"
                            />
                          ) : (
                            <AddIcon
                              fontSize={isDesktop ? 'small' : 'large'}
                              htmlColor="#fff"
                            />
                          )}
                        </IconButton>
                      </Stack>
                      {isDesktop ? (
                        <Stack
                          sx={{
                            position: 'absolute',
                            top: '50px',
                            right: '0',
                            py: 1,
                            px: 1.5,
                            fontSize: '40px',
                          }}
                          onClick={handleToggleFavPlaces}
                        >
                          {isNewFav ? (
                            <FavoriteOutlinedIcon
                              fontSize="inherit"
                              htmlColor="#FE0000"
                            />
                          ) : (
                            <FavoriteBorderOutlinedIcon
                              fontSize="inherit"
                              htmlColor="#CCD2E3"
                            />
                          )}
                        </Stack>
                      ) : null}
                    </>
                  ) : null}
                  {!isDesktop ? (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      align-items="center"
                      sx={{
                        px: 1,
                        py: 1,
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        color: common.white,
                        height: '68px',
                        position: 'relative',
                      }}
                    >
                      <Box>
                        <Tex
                          fontSize={20}
                          fontWeight={500}
                          fontFamily="CircularStd-Book"
                        >
                          {place.name}
                        </Tex>
                        {neighborhood && (
                          <Tex
                            fontSize={16}
                            fontWeight={300}
                            fontFamily="CircularStd-Book"
                          >
                            {neighborhood.name}
                          </Tex>
                        )}
                      </Box>
                      <Stack
                        sx={{
                          position: 'absolute',
                          top: '4px',
                          right: 0,
                          py: 1,
                          px: 1.5,
                        }}
                        onClick={handleToggleFavPlaces}
                      >
                        {isNewFav ? (
                          <FavoriteOutlinedIcon
                            fontSize="large"
                            htmlColor="#FE0000"
                          />
                        ) : (
                          <FavoriteBorderOutlinedIcon
                            fontSize="large"
                            htmlColor="#CCD2E3"
                          />
                        )}
                      </Stack>
                    </Stack>
                  ) : null}
                </ImageContainer>
              </Stack>
              <OverviewContainer>
                <ContentBlock>
                  {isDesktop ? (
                    <Box>
                      <Tex
                        textAlign="left"
                        fontSize={33}
                        fontFamily="canela-bold"
                        fontWeight={700}
                        color="primary"
                        sx={{ px: 1.5 }}
                      >
                        {place.name}
                      </Tex>
                      {neighborhood && (
                        <Tex
                          textAlign="left"
                          fontSize={20}
                          fontFamily="circular-std"
                          fontWeight={700}
                          color="primary"
                          sx={{ px: 2, mt: -1 }}
                        >
                          {neighborhood.name}
                        </Tex>
                      )}
                    </Box>
                  ) : null}
                  <LinksWrapper spacing={2}>
                    {isDesktop ? (
                      <>
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{ paddingBottom: '15px', mt: -1 }}
                        >
                          {secondaryTags?.map((tag: Tag) => (
                            <Chip
                              key={tag.tagId}
                              label={tag.name}
                              size="small"
                              sx={{ backgroundColor: common.white }}
                            />
                          ))}
                        </Stack>
                        <Tex fontWeight={300}>
                          {summaryText && (
                            <Tex component="span" fontWeight={700}>
                              {summaryText}.&nbsp;
                            </Tex>
                          )}
                          {place.description}
                        </Tex>
                      </>
                    ) : null}

                    <LinksContainer spacing={1}>
                      {place.website ? (
                        <PlaceExternalLink
                          icon={mdiMagnify}
                          text="WEBSITE"
                          url={place.website}
                        />
                      ) : null}
                      {place.book ? (
                        <PlaceExternalLink
                          icon={mdiBookOpenVariant}
                          text="BOOK"
                          url={place.book}
                        />
                      ) : null}
                      {place.instagram ? (
                        <PlaceExternalLink
                          icon={mdiCameraOutline}
                          text="INSTAGRAM"
                          url={place.instagram}
                        />
                      ) : null}
                      {/* <PlaceExternalLink
                       icon={mdiCardAccountDetailsOutline}
                       text="RESERVE"
                       url="#"
                     />
                     <PlaceExternalLink icon={mdiCameraOutline} text="SHARE" url="#" /> */}
                    </LinksContainer>
                    {!isDesktop ? (
                      <>
                        <Tex fontWeight={300}>
                          {summaryText && (
                            <Tex component="span" fontWeight={700}>
                              {summaryText}.&nbsp;
                            </Tex>
                          )}
                          {place.description}
                        </Tex>
                        {secondaryTags?.length ? (
                          <Stack
                            direction="row"
                            spacing={1}
                            sx={{ paddingBottom: '15px' }}
                          >
                            {secondaryTags.map((tag: Tag) => (
                              <Chip
                                key={tag.tagId}
                                label={tag.name}
                                size="small"
                                sx={{ backgroundColor: common.white }}
                              />
                            ))}
                          </Stack>
                        ) : null}
                      </>
                    ) : null}
                  </LinksWrapper>
                  {!isDesktop && imageUrls?.[1] ? (
                    <LazyLoadImage
                      key={imageUrls[1]}
                      alt={place.name}
                      src={imageUrls[1]}
                      width="100%"
                      height="300px"
                      style={{
                        objectFit: 'cover',
                      }}
                      placeholder={
                        <Stack
                          sx={{
                            width: '100%',
                            height: '300px',
                            backgroundColor: SECONDARY_COLOR,
                          }}
                        />
                      }
                    />
                  ) : null}
                  <TipWrapper direction="row" spacing={1}>
                    <MdiIcon icon={mdiLightbulbOnOutline} fontSize="large" />
                    <Tex fontWeight={300}>
                      <Tex
                        component="span"
                        fontWeight={700}
                        color="common.black"
                      >
                        Jaunt Tip:{' '}
                      </Tex>
                      {place.tip}
                    </Tex>
                  </TipWrapper>
                  {!isDesktop && imageUrls?.[2] ? (
                    <LazyLoadImage
                      key={imageUrls[2]}
                      alt={place.name}
                      src={imageUrls[2]}
                      width="100%"
                      height="300px"
                      style={{
                        objectFit: 'cover',
                      }}
                      placeholder={
                        <Stack
                          sx={{
                            width: '100%',
                            height: '300px',
                            backgroundColor: SECONDARY_COLOR,
                          }}
                        />
                      }
                    />
                  ) : null}
                  {reviews?.length ? (
                    <ReviewWrapper>
                      <Tex
                        textAlign="center"
                        fontSize={20}
                        fontFamily="CircularStd-Book"
                        fontWeight={500}
                        color="primary"
                      >
                        Trusted Reviews
                      </Tex>
                      <ReviewContainer>
                        {reviews.map((review: ReviewObj) => (
                          <PlaceReviewCard
                            key={review.reviewId}
                            review={review}
                          />
                        ))}
                      </ReviewContainer>
                    </ReviewWrapper>
                  ) : null}
                </ContentBlock>
              </OverviewContainer>
            </ContentWrapper>
          )}
          {trips?.length ? (
            <PlaceToTripDialog
              isOpen={isOpenDialog}
              place={place}
              trips={trips}
              placeTripIds={
                placesTrips && place ? placesTrips[place.placeId] : []
              }
              cityId={cityId}
              userId={userId}
              onClose={handleCloseDialog}
            />
          ) : null}
        </Container>
      </Stack>
      <Footer />
    </>
  ) : (
    <NotFoundContainer />
  );
}

const Container = styled(Stack)`
  margin-bottom: 16px;
  width: 100%;
  flex-grow: 1;

  ${device.laptopN} {
    margin-bottom: 0;
  }
`;

const ContentWrapper = styled(Stack)`
  flex-direction: column;

  ${device.laptopN} {
    flex-direction: row;
    flex: 1 1;
    gap: 16px;
  }
`;

const ImageContainer = styled(Stack)<{ imageurl?: string }>`
  width: 100%;
  height: 540px;
  flex-shrink: 0;
  justify-content: flex-end;
  background-image: ${({ imageurl }) => `url(${imageurl})`};
  background-color: #d3c0ac;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  position: relative;

  ${device.laptopN} {
    width: 520px;
    height: 100%;
  }
`;

const OverviewContainer = styled(Stack)`
  ${device.laptopN} {
    padding-top: 20px;
  }
`;

const ContentBlock = styled(Stack)`
  margin: 0 auto !important;
  width: 100%;
  max-width: 1440px;
  padding-top: 17px;
`;

const LinksWrapper = styled(Stack)`
  padding: 8px 12px;

  ${device.tablet} {
    padding: 16px 12px;
  }
`;

const LinksContainer = styled(Stack)`
  flex-direction: row;
  gap: 8px;
  padding: 0 4px;
  overflow: auto;

  ${device.laptopN} {
    padding-top: 27px;
  }
`;

const TipWrapper = styled(Stack)`
  padding: 18px 12px;
  align-items: center;

  ${device.laptopN} {
    padding: 50px 12px;
  }
`;

const ReviewWrapper = styled(Stack)`
  padding: 30px 8px;
  gap: 16px;

  ${device.tablet} {
    padding-bottom: 193px;
    align-items: center;
  }

  ${device.laptopN} {
    align-items: flex-start;
    padding: 0 16px;
    margin-bottom: 16px;
  }
`;

const ReviewContainer = styled(Stack)`
  flex-direction: row;
  overflow: auto;
  gap: 10px;
`;
