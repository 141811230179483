import { Timestamp, TagId } from './shared';

export enum TagType {
  SUMMARY = 'summary', // short names for a city
  ACTIVITY = 'activity', // e.g. "eat", "drink", ...
  SECONDARY_ACTIVITY = 'secondary activity', // child of activity tag
}

export interface TagData {
  readonly name: string;
  readonly type: TagType;
  readonly parentTagId: TagId | null;
}

export interface Tag extends TagData, Timestamp {
  readonly tagId: TagId;
}

export interface SingleTagResponse {
  readonly tag: Tag;
}

export interface MultipleTagsResponse {
  readonly tags: Tag[];
}
