import { useEffect, useState } from 'react';
import { Dialog, IconButton, Stack } from '@mui/material';
import { PlaceToTripSelectTrip } from './PlaceToTripSelectTrip';
import { PlaceToTripNewTrip } from './PlaceToTripNewTrip';
import { CityId, Place, Trip, TripId, UserId } from '@jaunt/api';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  isOpen: boolean;
  userId?: UserId;
  cityId?: CityId | null;
  onClose(): void;
  trips: Trip[];
  placeTripIds?: TripId[];
  place?: Place | null;
};

export function PlaceToTripDialog(props: Props): JSX.Element {
  const { userId, cityId, isOpen, placeTripIds, trips, place, onClose } = props;

  const [isSaveToNewTrip, setSaveToNewTrip] = useState(false);

  useEffect(() => {
    setSaveToNewTrip(!trips.length);
  }, [trips]);

  function handleToggleNewTrip() {
    setSaveToNewTrip(!isSaveToNewTrip);
  }

  function handleClose(): void {
    onClose();
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullScreen
      sx={{
        '& .MuiPaper-root': {
          p: 4,
          borderRadius: '20px',
          overflowY: 'scroll',
        },
        m: 2,
        mt: 18,
        overflowY: 'scroll',
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 4,
          top: 4,
          margin: 0,
          color: (theme) => theme.palette.primary.main,
        }}
      >
        <CloseIcon fontSize="large" />
      </IconButton>
      <Stack height="100%" spacing={6}>
        <Stack
          height="100%"
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          gap="2px"
        >
          {isSaveToNewTrip ? (
            <PlaceToTripNewTrip
              place={place}
              isTripsExist={!!trips.length}
              userId={userId}
              cityId={cityId}
              onClose={handleClose}
              onToggleNewTrip={handleToggleNewTrip}
            />
          ) : (
            <PlaceToTripSelectTrip
              trips={trips}
              place={place}
              onClose={handleClose}
              onToggleNewTrip={handleToggleNewTrip}
              selectedTripIds={placeTripIds}
            />
          )}
        </Stack>
      </Stack>
    </Dialog>
  );
}
