import { CityId, Place } from '@jaunt/api';
import { getCityCuratedPlaces, getCityPlaces } from '../services/city.api';
import { useQuery, UseQueryResult } from 'react-query';
import QueryKeys from './constants/query-keys';

export function usePlacesByCity(
  cityId: CityId | null | undefined,
): UseQueryResult<Place[], unknown> {
  return useQuery<Place[]>(
    [QueryKeys.placesByCities, cityId],
    async () => {
      return cityId ? await getCityPlaces(cityId) : [];
    },
    {
      placeholderData() {
        return [];
      },
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading PlacesByCity: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!cityId,
    },
  );
}

export function useCuratedPlacesByCity(
  cityId: CityId | undefined,
): UseQueryResult<Place[] | undefined, unknown> {
  return useQuery<Place[] | undefined>(
    [QueryKeys.curatedPlacesByCities, cityId],
    async () => {
      return cityId ? await getCityCuratedPlaces(cityId) : undefined;
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading City: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!cityId,
    },
  );
}
