import { Link, Stack } from '@mui/material';
import { Tex } from './shared/Tex';
import { CityId, Place, TagData, TripData, UserId } from '@jaunt/api';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { v4 as uuid } from 'uuid';
import { FormTextField } from './shared/FormTextField';
import { useReplaceTripPlaces } from '../hooks/use-trip-places';
import { useReplaceTripCity } from '../hooks/use-trip-city';
import { useCreateTripByUser } from '../hooks/use-user-trips';

const resolver = yupResolver(
  yup.object({
    name: yup.string().required('Required'),
  }),
);

const defaultTripData: TripData = {
  name: '',
  tripPath: uuid(),
  isCurated: false,
  detail: {
    days: {},
    isWithDates: false,
    createdBy: '',
    vibes: [],
    budget: 0,
  },
  description: '',
  creatorLink: '',
};

type Props = {
  userId?: UserId;
  cityId?: CityId | null;
  isTripsExist?: boolean;
  onClose(): void;
  onToggleNewTrip(): void;
  place?: Place | null;
};

export function PlaceToTripNewTrip(props: Props): JSX.Element {
  const { place, userId, cityId, isTripsExist, onToggleNewTrip, onClose } =
    props;
  const { register, handleSubmit, formState } = useForm<TagData>({
    defaultValues: { name: '' },
    resolver,
  });
  const [isSaving, setIsSaving] = useState(false);
  const { replaceTripPlaces } = useReplaceTripPlaces();
  const { replaceTripCity } = useReplaceTripCity();
  const { createTripByUser } = useCreateTripByUser();

  async function onSubmit(newTrip: { name: string }): Promise<void> {
    if (place && userId && cityId) {
      setIsSaving(true);
      const newTripData = {
        ...defaultTripData,
        name: newTrip.name,
        detail: {
          ...defaultTripData.detail,
          createdBy: userId,
        },
      };
      const createdTrip = await createTripByUser(newTripData);
      await replaceTripCity({ tripId: createdTrip.tripId, data: { cityId } });
      const newTripPlace = {
        tripId: createdTrip.tripId,
        placeId: place.placeId,
        day: null,
        order: null,
      };
      await replaceTripPlaces({
        tripId: createdTrip.tripId,
        data: [newTripPlace],
      });
      setIsSaving(false);
    }
    onClose();
  }

  return (
    <Stack
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      gap={5}
    >
      <Stack width="100%">
        <Stack spacing={4} width="100%">
          {!!place?.name && (
            <Tex
              fontSize={22}
              lineHeight="26px"
              fontWeight={500}
              sx={{
                mt: 8,
                mb: 3,
              }}
            >
              Save to new trip
            </Tex>
          )}
        </Stack>
        <Stack>
          <FormTextField
            register={register}
            formState={formState}
            fieldName="name"
            label="Name your trip"
            required
            sx={{
              width: '100%',
            }}
          />
        </Stack>
      </Stack>
      <Stack sx={{ width: '100%' }}>
        <Stack alignItems="center" gap={3}>
          <LoadingButton
            type="submit"
            variant="contained"
            size="large"
            loading={isSaving}
            disabled={isSaving}
            disableRipple
            sx={{
              px: 6,
              py: 1,
              borderRadius: '9999px',
              height: '46px',
              width: '100%',
              fontSize: '14px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            SAVE
          </LoadingButton>
          {isTripsExist && (
            <Link component="button" variant="body1" onClick={onToggleNewTrip}>
              SAVE TO AN EXISTING TRIP
            </Link>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
