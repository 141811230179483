import { PlaceImage } from '@jaunt/api';
import { getFileDownloadUrl } from '../services/file.api';
import { useQuery, UseQueryResult } from 'react-query';
import QueryKeys from './constants/query-keys';

export function useImageUrls(
  placeImages: PlaceImage[] | undefined,
): UseQueryResult<string[], unknown> {
  return useQuery<string[]>(
    QueryKeys.imageUrls,
    async () => {
      if (placeImages?.length) {
        return await Promise.all(
          placeImages.map(async (image) => {
            return await getFileDownloadUrl(image.fileId);
          }),
        );
      }

      return [];
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading ImageUrls: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!placeImages?.length,
    },
  );
}
