import { put } from './base.api';
import {
  FileId,
  NeighborhoodId,
  NeighborhoodImage,
  MultipleNeighborhoodImagesResponse,
  ReplaceNeighborhoodImagesData,
} from '@jaunt/api';

export async function replaceNeighborhoodImages(
  neighborhoodId: NeighborhoodId,
  imageFileIds: FileId[],
): Promise<NeighborhoodImage[]> {
  const { neighborhoodImages } = await put<
    MultipleNeighborhoodImagesResponse,
    ReplaceNeighborhoodImagesData
  >('/neighborhood-images', {}, { neighborhoodId, imageFileIds });
  return neighborhoodImages;
}
