import { TripVibe } from '@jaunt/api';
import { Box, Button, Stack } from '@mui/material';
import { Tex } from '../shared/Tex';

interface Props {
  onChange(vibe: TripVibe): void;
}

export function PlanTripVibeStep({ onChange }: Props): JSX.Element {
  const options = Object.values(TripVibe);

  function onSelectVibe(newVibe: TripVibe): void {
    onChange(newVibe);
  }

  return (
    <Stack spacing={6}>
      <Tex color="primary" textAlign="center" fontSize={20}>
        <b>What&lsquo;s your trip vibe?</b>
      </Tex>
      <Stack spacing={1} alignItems="center">
        <Box
          sx={{
            width: '325px',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {options.map((option) => (
            <Button
              key={option}
              variant="outlined"
              onClick={() => onSelectVibe(option)}
              sx={{
                m: 0.5,
                width: 100,
                textTransform: 'capitalize',
                borderRadius: 2,
              }}
            >
              {option}
            </Button>
          ))}
        </Box>
      </Stack>
    </Stack>
  );
}
