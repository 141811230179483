import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { AppId, TripEmailApp, TripId } from '@jaunt/api';
import {
  deactivateTripEmailApps,
  getTripEmailAppById,
  getTripEmailAppList,
  saveTripEmailApp,
} from '../services/save-email.api';
import QueryKeys from './constants/query-keys';

export function useTripEmailAppList(): UseQueryResult<
  TripEmailApp[] | null,
  unknown
> {
  return useQuery<TripEmailApp[] | null>(
    QueryKeys.tripEmailApps,
    async () => await getTripEmailAppList(),
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading TripEmailApp list: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
    },
  );
}

export function useTripEmailApp(
  appId: AppId | null | undefined,
): UseQueryResult<TripEmailApp | null | undefined, unknown> {
  return useQuery<TripEmailApp | null | undefined>(
    [QueryKeys.tripEmailApps, appId],
    async () => {
      if (appId) {
        return getTripEmailAppById(appId);
      }

      return undefined;
    },
    {
      placeholderData() {
        return undefined;
      },
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading TripEmailApp: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!appId,
    },
  );
}
export const useSaveTripEmailApp = () => {
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async (data: { tripId: TripId; isGeneratedTrip: boolean }) => {
      return await saveTripEmailApp(data);
    },
    {
      onSuccess: (app) => {
        console.log('TripEmailApp_app: ', app); // eslint-disable-line no-console
      },
    },
  );
  return { saveTripEmailApp: mutateAsync, isLoading, isSuccess, isError };
};

export const useDeactivateTripEmailApps = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async (data: AppId[]) => {
      return await deactivateTripEmailApps(data);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(QueryKeys.tripEmailApps);
      },
    },
  );
  return {
    deactivateTripEmailApps: mutateAsync,
    isLoading,
    isSuccess,
    isError,
  };
};
