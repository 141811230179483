import { del, get, post, put } from './base.api';
import {
  Neighborhood,
  NeighborhoodId,
  NeighborhoodData,
  SingleNeighborhoodResponse,
  MultipleNeighborhoodsResponse,
  Place,
  MultiplePlacesResponse,
  NeighborhoodImage,
  MultipleNeighborhoodImagesResponse,
} from '@jaunt/api';

export async function listNeighborhoods(): Promise<Neighborhood[]> {
  const { neighborhoods } = await get<MultipleNeighborhoodsResponse>(
    '/neighborhoods',
    {},
  );
  return neighborhoods;
}

export async function createNeighborhood(
  data: NeighborhoodData,
): Promise<Neighborhood> {
  const { neighborhood } = await post<
    SingleNeighborhoodResponse,
    NeighborhoodData
  >('/neighborhoods', {}, data);
  return neighborhood;
}

export async function getNeighborhood(
  neighborhoodId: NeighborhoodId,
): Promise<Neighborhood> {
  const { neighborhood } = await get<SingleNeighborhoodResponse>(
    `/neighborhoods/${neighborhoodId}`,
    {},
  );
  return neighborhood;
}

export async function getNeighborhoodByPath(
  neighborhoodPath: string,
): Promise<Neighborhood> {
  const { neighborhood } = await get<SingleNeighborhoodResponse>(
    `/neighborhoods/path/${neighborhoodPath}`,
    {},
  );
  return neighborhood;
}

export async function getNeighborhoodPlaces(
  neighborhoodId: NeighborhoodId,
): Promise<Place[]> {
  const { places } = await get<MultiplePlacesResponse>(
    `/neighborhoods/${neighborhoodId}/places`,
    {},
  );
  return places;
}

export async function getNeighborhoodImages(
  neighborhoodId: NeighborhoodId,
): Promise<NeighborhoodImage[]> {
  const { neighborhoodImages } = await get<MultipleNeighborhoodImagesResponse>(
    `/neighborhoods/${neighborhoodId}/images`,
    {},
  );
  return neighborhoodImages;
}

export async function updateNeighborhood(
  neighborhoodId: NeighborhoodId,
  data: NeighborhoodData,
): Promise<Neighborhood> {
  const { neighborhood } = await put<
    SingleNeighborhoodResponse,
    NeighborhoodData
  >(`/neighborhoods/${neighborhoodId}`, {}, data);
  return neighborhood;
}

export async function deleteNeighborhood(
  neighborhoodId: NeighborhoodId,
): Promise<Neighborhood> {
  const { neighborhood } = await del<SingleNeighborhoodResponse>(
    `/neighborhoods/${neighborhoodId}`,
    {},
  );
  return neighborhood;
}
