import { Button, Chip, CircularProgress, Collapse, Stack } from '@mui/material';
import { common } from '@mui/material/colors';
import { Tex } from 'src/components/shared/Tex';
import { mdiChevronUp, mdiChevronDown } from '@mdi/js';
import { useEffect, useMemo, useState } from 'react';
import { MdiIcon } from 'src/components/shared/MdiIcon';
import {
  device,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
} from 'src/settings/jaunt-theme';
import {
  City,
  Place,
  PlaceId,
  Tag,
  TagType,
  Trip,
  TripPlace,
} from '@jaunt/api';
import { FilteredMyTripPlaceCard } from '../../components/FilteredMyTripPlaceCard';
import styled from '@emotion/styled';
import { format } from 'date-fns';
import { PublicActivitySummary } from './PublicActivitySummary';

enum ChipType {
  activity,
  days,
}

const activityTags = ['Eat', 'Drink', 'Do', 'Stay'];

const getCityDetails = (curatedTripCity?: City) => {
  if (curatedTripCity) {
    return [
      {
        title: 'When To Go.',
        text: curatedTripCity.detail.weather,
      },
      {
        title: 'Where To Stay.',
        text: curatedTripCity.detail.lodging,
      },
      {
        title: 'Getting Around.',
        text: curatedTripCity.detail.traffic,
      },
      {
        title: 'Tips And Tricks.',
        text: curatedTripCity.detail.notes,
      },
    ];
  }
  return [];
};

interface Props {
  trip: Trip;
  city: City;
  places: Place[] | undefined;
  tripPlaces?: TripPlace[];
  placesTags: Record<string, Tag[]>;
  cityImageUrl?: string | null;
}

export function PublicTripContent({
  trip,
  city,
  places,
  tripPlaces,
  placesTags,
  cityImageUrl,
}: Props): JSX.Element {
  const [isExpanded, setIsExpanded] = useState(false);
  const [activePlace, setActivePlace] = useState<Place | null>(null);
  const [chipType, setChipType] = useState(ChipType.activity);
  const [chosenActivity, setChosenActivity] = useState<string | number | null>(
    'Eat',
  );
  const [isShowUnscheduled, setIsShowUnscheduled] = useState(true);
  const [placesActivityTagsObj, setPlacesActivityTagsObj] = useState<
    Record<string, string[]>
  >({});
  const [showPlaces, setShowPlaces] = useState<Place[] | null>();
  const [isMobile, setIsMobile] = useState(false);

  const details = useMemo(() => getCityDetails(city), [city]);

  const tripDays = useMemo(
    () =>
      trip?.detail?.days
        ? Object.keys(trip.detail.days).map((day) => parseInt(day))
        : [],
    [trip],
  );

  function onSelectTripPlace(placeId: PlaceId) {
    if (isMobile) return;
    const selectedPlace = showPlaces?.find(
      (place) => place.placeId === placeId,
    );
    if (selectedPlace) {
      setActivePlace(selectedPlace);
    }
  }

  const tripDaysTags = useMemo(() => {
    if (tripDays.length) {
      return tripDays.reduce((acc: Record<number, string>, day) => {
        if (trip?.detail?.days[day]) {
          acc[day] = `${format(
            new Date(trip.detail.days[day] as string),
            'EEE, MM/d',
          )}`;
        } else {
          acc[day] = `Day ${day + 1}`;
        }
        return acc;
      }, {});
    }
    return {};
  }, [tripDays]);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 745);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (tripDays.length) {
      setChipType(ChipType.days);
    } else {
      setChipType(ChipType.activity);
    }
  }, [tripDays]);

  useEffect(() => {
    if (tripPlaces?.length && Object.keys(placesTags).length) {
      const data = tripPlaces?.reduce(
        (acc: Record<string, string[]>, tripPlace) => {
          const tags = placesTags[tripPlace.placeId];
          const activityTags =
            tags?.filter((x) => x.type === TagType.ACTIVITY) ?? [];
          acc[tripPlace.placeId] = activityTags.map((tag) => tag.name);
          return acc;
        },
        {},
      );

      setPlacesActivityTagsObj(data);
    }
  }, [tripPlaces, placesTags]);

  useEffect(() => {
    if (chosenActivity !== null && Object.keys(placesActivityTagsObj)?.length) {
      if (chipType === ChipType.activity) {
        const newShowPlaces = places?.filter((place) => {
          return placesActivityTagsObj[place.placeId]?.includes(
            `${chosenActivity}`,
          );
        });
        setShowPlaces(newShowPlaces);
        if (newShowPlaces) {
          setActivePlace(newShowPlaces[0]);
        }
      } else {
        const showPlacesObj = tripPlaces
          ?.filter((tripPlace) => tripPlace.day === +chosenActivity)
          .reduce(
            (acc: Record<string, number | undefined | null>, tripPlace) => {
              if (tripPlace.placeId) {
                acc[tripPlace.placeId] = tripPlace.time
                  ? Number(tripPlace.time)
                  : null;
              }
              return acc;
            },
            {},
          );

        if (showPlacesObj && Object.keys(showPlacesObj).length) {
          const newShowPlaces = places
            ?.filter((place) => {
              return (
                showPlacesObj[place.placeId] !== undefined &&
                showPlacesObj[place.placeId] !== null
              );
            })
            .sort((first, second) => {
              return (
                (showPlacesObj[first.placeId] as number) -
                (showPlacesObj[second.placeId] as number)
              );
            });
          setShowPlaces(newShowPlaces);
          if (newShowPlaces) {
            setActivePlace(newShowPlaces[0]);
          }
        } else {
          setShowPlaces(null);
          setActivePlace(null);
        }

        const unscheduledPlaceIds =
          tripPlaces
            ?.filter((tripPlace) => typeof tripPlace.day !== 'number')
            .map((tripPlace) => tripPlace.placeId) ?? [];

        const unscheduledPlaces = places?.filter((place) =>
          unscheduledPlaceIds.includes(place.placeId),
        );

        if (unscheduledPlaces?.length) {
          setIsShowUnscheduled(true);
        } else {
          setIsShowUnscheduled(false);
        }
      }
    } else if (tripPlaces?.length && places?.length) {
      const unscheduledPlaceIds =
        tripPlaces
          ?.filter((tripPlace) => typeof tripPlace.day !== 'number')
          .map((tripPlace) => tripPlace.placeId) ?? [];

      const unscheduledPlaces = places.filter((place) =>
        unscheduledPlaceIds.includes(place.placeId),
      );

      if (unscheduledPlaces.length) {
        const newShowPlaces = places.filter((place) =>
          unscheduledPlaceIds.includes(place.placeId),
        );

        setShowPlaces(newShowPlaces);
        setActivePlace(newShowPlaces[0]);
        setIsShowUnscheduled(true);
      } else {
        setIsShowUnscheduled(false);
        setShowPlaces(null);
        setActivePlace(null);
        setChosenActivity(0);
      }
    }
  }, [chosenActivity, placesActivityTagsObj, places, tripPlaces]);

  return (
    <>
      {trip && city ? (
        <>
          <Stack>
            <Stack spacing={6}>
              <Stack>
                <BackgroundImage
                  justifyContent="end"
                  alignItems="center"
                  cityimageurl={cityImageUrl}
                />
                <OverviewContainer>
                  <Tex
                    align="center"
                    fontSize={28}
                    fontWeight={700}
                    color={common.white}
                  >
                    {trip.name}
                  </Tex>
                  <Tex
                    align="center"
                    fontSize={16}
                    fontWeight={300}
                    color={common.white}
                  >
                    {city.name}
                  </Tex>
                  <Tex align="center" fontWeight={300} color={common.white}>
                    {city.description}
                  </Tex>
                  <Stack spacing={1}>
                    <Collapse in={isExpanded}>
                      <Stack spacing={1.5}>
                        {details.map((detail) => (
                          <Stack
                            key={detail.title}
                            sx={{
                              p: 1.5,
                              backgroundColor: common.white,
                              borderRadius: 4,
                            }}
                          >
                            <Tex>
                              <Tex component="span" fontWeight={700}>
                                {detail.title}&nbsp;
                              </Tex>
                              <Tex component="span" fontWeight={300}>
                                {detail.text}
                              </Tex>
                            </Tex>
                          </Stack>
                        ))}
                      </Stack>
                    </Collapse>
                    <Button
                      onClick={() => setIsExpanded((previous) => !previous)}
                    >
                      <Tex sx={{ color: common.white }}>
                        {isExpanded ? 'See Less' : 'See More'}
                      </Tex>
                      <MdiIcon
                        icon={isExpanded ? mdiChevronUp : mdiChevronDown}
                        sx={{ ml: 1, color: common.white }}
                      />
                    </Button>
                  </Stack>
                </OverviewContainer>
              </Stack>
            </Stack>
          </Stack>
          <MainContentWrapper>
            <TripConstructorWrapper iscompressed={activePlace ? 1 : 0}>
              <Stack spacing={2} sx={{ width: '100%' }}>
                <ChipWrapper
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                >
                  {chipType === ChipType.activity ? (
                    <>
                      <Stack direction="row" spacing={1}>
                        {activityTags.map((activity) => (
                          <Chip
                            key={activity}
                            label={activity}
                            color={
                              chosenActivity === activity
                                ? 'primary'
                                : 'default'
                            }
                            onClick={() => setChosenActivity(activity)}
                            sx={
                              chosenActivity === activity
                                ? {}
                                : { backgroundColor: common.white }
                            }
                          />
                        ))}
                      </Stack>
                    </>
                  ) : (
                    <>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{ overflowX: 'scroll' }}
                      >
                        {isShowUnscheduled ? (
                          <Chip
                            key="unscheduled"
                            label="Unscheduled"
                            color={
                              chosenActivity === null ? 'primary' : 'default'
                            }
                            onClick={() => setChosenActivity(null)}
                            sx={{
                              backgroundColor:
                                chosenActivity === null ? null : common.white,
                            }}
                          />
                        ) : null}
                        {tripDays.map((day) => (
                          <Chip
                            key={day}
                            label={tripDaysTags[day]}
                            color={
                              chosenActivity === day ? 'primary' : 'default'
                            }
                            onClick={() => setChosenActivity(day)}
                            sx={
                              chosenActivity === day
                                ? {}
                                : { backgroundColor: common.white }
                            }
                          />
                        ))}
                      </Stack>
                    </>
                  )}
                </ChipWrapper>
                <PlacesContainer>
                  {showPlaces?.length ? (
                    showPlaces.map((placeItem) => (
                      <FilteredMyTripPlaceCard
                        key={placeItem.placeId}
                        tripPlace={tripPlaces?.find(
                          (tripPlace) =>
                            tripPlace.placeId === placeItem.placeId,
                        )}
                        onClick={onSelectTripPlace}
                        place={placeItem}
                        city={city}
                        trip={trip}
                        userId={'userId'}
                        isActive={
                          !isMobile &&
                          placeItem.placeId === activePlace?.placeId
                        }
                      />
                    ))
                  ) : chipType === ChipType.activity ? (
                    <Stack sx={{ mt: 4, mb: 1 }}>
                      <Tex fontSize={12} lineHeight={'18px'} textAlign="center">
                        Your trip is empty. Use the Jaunt Builder to browse
                        recommendations and add activities to your trip.
                      </Tex>
                    </Stack>
                  ) : (
                    <Stack sx={{ mt: 5, mb: 4 }}>
                      <Tex fontWeight={500} fontSize={14} textAlign="center">
                        Nothing is scheduled yet!
                      </Tex>
                    </Stack>
                  )}
                </PlacesContainer>
              </Stack>
            </TripConstructorWrapper>
            {!isMobile && activePlace ? (
              <ActivitySummaryWrapper>
                <PublicActivitySummary
                  place={activePlace}
                  tripPath={trip.tripPath}
                  tripPlace={tripPlaces?.find(
                    (tripPlace) => tripPlace.placeId === activePlace.placeId,
                  )}
                />
              </ActivitySummaryWrapper>
            ) : null}
          </MainContentWrapper>
        </>
      ) : (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ p: 2, flex: 1, height: '200px' }}
        >
          <CircularProgress
            variant="indeterminate"
            color="primary"
            size="40px"
            value={40}
          />
        </Stack>
      )}
    </>
  );
}

const BackgroundImage = styled(Stack)<{
  cityimageurl: string | null | undefined;
}>`
  height: 215px;
  background-image: ${({ cityimageurl }) =>
    cityimageurl ? `url(${cityimageurl})` : null};
  background-size: cover;
  background-position: center;
  background-color: ${SECONDARY_COLOR};

  ${device.tablet} {
    height: 280px;
  }

  ${device.laptopN} {
    height: 402px;
  }
`;

const OverviewContainer = styled(Stack)`
  width: 100%;
  align-items: center;
  gap: 8px;
  margin: -60px auto 0;
  padding: 12px;
  background-color: ${PRIMARY_COLOR};
  border-radius: 20px 20px;
  max-width: 380px;

  ${device.tablet} {
    max-width: 544px;
    margin: -80px auto 0;
  }

  ${device.laptopN} {
    max-width: 734px;
    margin: -135px auto 0;
  }
`;

const MainContentWrapper = styled(Stack)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 auto;
  width: 100%;

  ${device.laptopN} {
    max-width: 1024px;
    padding: 0 80px;
  }
`;

const ChipWrapper = styled(Stack)`
  width: 100%;
  padding: 8px 16px 0;
  max-width: 410px;
  margin: 0 auto;

  ${device.tablet} {
    max-width: 544px;
    padding: 0;
  }

  ${device.laptopN} {
    max-width: 544px;
    padding: 0;
  }
`;

const PlacesContainer = styled(Stack)`
  padding: 0 8px;
  margin-bottom: 8px !important;
  gap: 16px;

  ${device.tablet} {
    padding: 0;
    margin-top: 26px !important;
  }
`;

const TripConstructorWrapper = styled(Stack)<{
  iscompressed: number;
}>`
  width: 100%;
  padding: 22px 12px 8px;
  max-width: 410px;
  margin: 0 auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  ${device.tablet} {
    width: 100%;
    max-width: ${({ iscompressed }) => (iscompressed ? '374px' : '544px')};
    padding: 20px 12px 8px;
    align-items: flex-start;
  }

  ${device.laptopN} {
    max-width: ${({ iscompressed }) => (iscompressed ? '380px' : '734px')};
    padding: 20px 0 8px;
  }
`;

const ActivitySummaryWrapper = styled(Stack)`
  display: none;

  ${device.tablet} {
    border-left: 1px solid #dadada;
    display: block;
    width: 100%;
    padding: 8px 0;
    margin-left: 12px;
  }

  ${device.laptopN} {
    padding: 16px 0 0;
  }
`;
