import { put } from './base.api';
import {
  PlaceId,
  MultiplePlaceImagesResponse,
  PlaceImage,
  ReplacePlaceImagesData,
  PlaceImageData,
} from '@jaunt/api';

export async function replacePlaceImages(
  placeId: PlaceId,
  placeImageDatas: PlaceImageData[],
): Promise<PlaceImage[]> {
  const { placeImages } = await put<
    MultiplePlaceImagesResponse,
    ReplacePlaceImagesData
  >('/place-images', {}, { placeId, placeImageDatas });
  return placeImages;
}
