import { useMemo } from 'react';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { CityId, Trip, TripData, TripId, UserId } from '@jaunt/api';
import {
  createTrip,
  deleteTrip,
  getCustomUserTripsByCity,
  getPublicTripByPath,
  getTrip,
  getTripByPath,
  listTrips,
  toggleTripHidden,
  updateTrip,
} from '../services/trip.api';
import { NEW_TRIP } from '../constants';
import { getLocalStorageItem } from '../services/local-storage.util';
import QueryKeys from './constants/query-keys';

export function useTrips(
  isCurated = true,
): UseQueryResult<Trip[] | null, unknown> {
  return useQuery<Trip[] | null>(
    QueryKeys.trips,
    async () => listTrips(isCurated),
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading Trips: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
    },
  );
}

export function useTrip(
  tripId: TripId | null | undefined,
): UseQueryResult<Trip | null | undefined, unknown> {
  return useQuery<Trip | null | undefined>(
    [QueryKeys.trips, tripId],
    async () => {
      if (tripId) {
        if (tripId === NEW_TRIP) {
          return getLocalStorageItem(NEW_TRIP);
        } else {
          return await getTrip(tripId);
        }
      }

      return undefined;
    },
    {
      placeholderData() {
        return undefined;
      },
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading Trip: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!tripId,
    },
  );
}

export function useTripPath(tripPath?: string | undefined | null) {
  const client = useQueryClient();
  const result = useQuery<Trip | null | undefined>(
    [QueryKeys.trips, 'path', tripPath],
    async () => {
      if (tripPath) {
        if (tripPath === NEW_TRIP) {
          return getLocalStorageItem(NEW_TRIP);
        } else {
          return await getTripByPath(tripPath);
        }
      }
      return undefined;
    },
    {
      placeholderData() {
        return undefined;
      },
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading Trip: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!tripPath,
    },
  );
  return useMemo(() => {
    const trips: Trip[] = client.getQueryData(QueryKeys.trips) ?? [];
    const trip =
      tripPath && trips.length > 0
        ? trips.find((trip) => trip?.tripPath === tripPath)
        : null;
    if (trip) {
      return {
        isSuccess: result.isSuccess,
        isError: result.isError,
        isLoading: result.isLoading,
        isIdle: result.isIdle,
        data: trip,
      };
    } else {
      if (result.isSuccess && result.data) {
        client.setQueryData(QueryKeys.trips, [...trips, result.data]);
        client.setQueryData(
          [QueryKeys.trips, result.data?.tripId],
          result.data,
        );
      }

      return {
        isSuccess: result.isSuccess,
        isError: result.isError,
        isLoading: result.isLoading,
        isIdle: result.isIdle,
        data: result.data,
      };
    }
  }, [result.isSuccess, result.isError, result.isLoading, result.data]);
}

export const useCreateTrip = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async (data: TripData) => {
      return await createTrip(data);
    },
    {
      onSuccess: (trip) => {
        client.setQueryData([QueryKeys.trips, trip.tripId], trip);
        client.invalidateQueries(QueryKeys.trips);
      },
    },
  );
  return { createTrip: mutateAsync, isLoading, isSuccess, isError };
};

export const useUpdateTrip = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({ tripId, data }: { tripId: TripId; data: TripData }) => {
      return updateTrip(tripId, data);
    },
    {
      onSuccess: (trip) => {
        client.setQueryData([QueryKeys.trips, trip.tripId], trip);
        client.invalidateQueries(QueryKeys.trips);
      },
    },
  );
  return { updateTrip: mutateAsync, isLoading, isSuccess, isError };
};

export const useDeleteTrip = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async (tripId: TripId) => {
      return deleteTrip(tripId);
    },
    {
      onSuccess: (_, tripId) => {
        client.invalidateQueries(QueryKeys.trips);
        client.invalidateQueries([QueryKeys.trips, tripId]);
      },
    },
  );
  return { deleteTrip: mutateAsync, isLoading, isSuccess, isError };
};

export function useCustomUserTripsByCity(
  userId: UserId | null | undefined,
  cityId: CityId | null | undefined,
  isCurated: boolean | undefined,
): UseQueryResult<Trip[] | undefined, unknown> {
  return useQuery<Trip[] | undefined>(
    [QueryKeys.customUserTripsByCity, userId, cityId, isCurated],
    async () => {
      return userId && cityId
        ? await getCustomUserTripsByCity(userId, cityId, isCurated)
        : [];
    },
    {
      placeholderData() {
        return [];
      },
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading Trip: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!userId || !!cityId,
    },
  );
}

export const useToggleTripHidden = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({
      tripId,
      data,
    }: {
      tripId: TripId;
      data: { isHidden: boolean };
    }) => {
      return toggleTripHidden(tripId, data);
    },
    {
      onSuccess: (trip) => {
        client.setQueryData([QueryKeys.trips, trip.tripId], trip);
        client.invalidateQueries(QueryKeys.trips);
        client.invalidateQueries(QueryKeys.curatedTripsWithImageUrl);
      },
    },
  );
  return { toggleTripHidden: mutateAsync, isLoading, isSuccess, isError };
};

export function usePublicTripPath(tripPath?: string | undefined | null) {
  return useQuery<Trip | null | undefined>(
    [QueryKeys.publicTripsPath, tripPath],
    async () => {
      return tripPath ? getPublicTripByPath(tripPath) : undefined;
    },
    {
      onError(error) {
        console.log('Error loading Trip: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!tripPath,
    },
  );
}
