import { Button, Collapse, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { LogoHeader } from '../components/LogoHeader';
import { useEffect, useMemo, useState } from 'react';
import { Tex } from '../components/shared/Tex';
import {
  device,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
} from '../settings/jaunt-theme';
import { common } from '@mui/material/colors';
import { MdiIcon } from '../components/shared/MdiIcon';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import { useCityPath } from '../hooks/use-city';
import { useFirstCityImageUrl } from '../hooks/use-first-city-image-url';
import { useCityTags } from '../hooks/use-city-tags';
import { getSummaryText } from '../services/tag.util';
import { Footer } from '../components/Footer';
import { CityDetail } from '@jaunt/api';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styled from '@emotion/styled';
import { CityDetailNeighborhoods } from '../components/city-detail/CityDetailNeighborhoods';
import { CityDetailCuratedTrips } from '../components/city-detail/CityDetailCuratedTrips';
import { CityDetailActivities } from '../components/city-detail/CityDetailActivities';
import { CityDetailTopHotels } from '../components/city-detail/CityDetailTopHotels';
import { useCities } from '../hooks/use-cities';
import { useCitiesWithImageUrl } from '../hooks/use-cities-with-image-url';
import { PlanTripDialog } from '../components/plan-trip-dialog/PlanTripDialog';
import { LoadingContainer } from 'src/components/loading/LoadingContainer';
import { NotFoundContainer } from 'src/components/NotFoundContainer';
import {
  useCreateFavCity,
  useDeleteFavCity,
  useFavoriteCities,
} from '../hooks/use-favorite-cities';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';

const getCityDetails = (cityDetail: CityDetail) => [
  {
    title: 'When To Go.',
    text: cityDetail.weather,
  },
  {
    title: 'Where To Stay.',
    text: cityDetail.lodging,
  },
  {
    title: 'Getting Around.',
    text: cityDetail.traffic,
  },
  {
    title: 'Tips And Tricks.',
    text: cityDetail.notes,
  },
];

export function CityPage(): JSX.Element {
  const params = useParams();
  const cityPath = params.cityPath || null;
  const { data: city, isLoading: isCityLoading } = useCityPath(cityPath);
  const { data: favoriteCities } = useFavoriteCities();
  const { data: cityImageUrl, isLoading: isCityImageLoading } =
    useFirstCityImageUrl(city?.cityId);
  const { data: tags } = useCityTags(city?.cityId);
  const [isExpanded, setIsExpanded] = useState(false);
  const details = useMemo(
    () => (city ? getCityDetails(city.detail) : []),
    [city],
  );
  const summaryText = getSummaryText(tags);
  const { data: cities } = useCities();
  const { data: citiesWithImageUrl } = useCitiesWithImageUrl(cities);
  const { createFavCity } = useCreateFavCity();
  const { deleteFavCity } = useDeleteFavCity();
  const [isPlanTripDialogOpen, setIsPlanTripDialogOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const isFav = useMemo(() => {
    if (city && favoriteCities) {
      return favoriteCities.find((item) => item.cityId === city.cityId);
    }
    return false;
  }, [favoriteCities, city]);

  async function handleToggleFavCities() {
    if (city) {
      if (!isFav) {
        await createFavCity({ cityId: city.cityId });
      } else {
        await deleteFavCity({ cityId: city.cityId });
      }
    }
  }

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 745);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isCityLoading || isCityImageLoading) {
    return <LoadingContainer />;
  }

  return city ? (
    <>
      <LogoHeader showAuthLinks={true} />
      <Stack className="main">
        <Stack sx={{ mb: 2, width: '100%' }}>
          {city && (
            <Stack spacing={6}>
              <Stack>
                <HeaderImage
                  key={city.cityId}
                  alt={city.name}
                  src={cityImageUrl ? cityImageUrl : ''}
                  placeholder={<Stack className="placeholder-block" />}
                />
                <DescriptionContainer spacing={3}>
                  <CityName
                    align="center"
                    fontFamily="Canela"
                    fontWeight={700}
                    color={common.white}
                    sx={{ mt: 2 }}
                  >
                    {city.name}
                  </CityName>
                  <Tex
                    align="center"
                    fontWeight={300}
                    color={common.white}
                    sx={{ marginTop: '8px !important' }}
                  >
                    {summaryText && (
                      <Tex component="span" fontWeight={700}>
                        {summaryText}.{' '}
                      </Tex>
                    )}
                    {city.description}
                  </Tex>
                  <Stack spacing={1}>
                    <Collapse in={isExpanded}>
                      <Stack spacing={1.5}>
                        {details.map((detail) => (
                          <Stack
                            key={detail.title}
                            sx={{
                              p: 1.5,
                              backgroundColor: common.white,
                              borderRadius: 4,
                            }}
                          >
                            <Tex>
                              <Tex component="span" fontWeight={700}>
                                {detail.title}{' '}
                              </Tex>
                              <Tex component="span" fontWeight={300}>
                                {detail.text}
                              </Tex>
                            </Tex>
                          </Stack>
                        ))}
                      </Stack>
                    </Collapse>
                    <Stack
                      sx={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Stack
                        sx={{
                          position: 'absolute',
                          top: '0px',
                          left: '0px',
                          py: 0,
                          px: 0,
                        }}
                        onClick={handleToggleFavCities}
                      >
                        {isFav ? (
                          <FavoriteOutlinedIcon
                            fontSize="large"
                            htmlColor="#FE0000"
                          />
                        ) : (
                          <FavoriteBorderOutlinedIcon
                            fontSize="large"
                            htmlColor="#CCD2E3"
                          />
                        )}
                      </Stack>
                      <Button
                        onClick={() => setIsExpanded((previous) => !previous)}
                      >
                        <Tex sx={{ color: common.white }}>
                          {isExpanded ? 'See Less' : 'See More'}
                        </Tex>
                        <MdiIcon
                          icon={isExpanded ? mdiChevronUp : mdiChevronDown}
                          sx={{ ml: 1, color: common.white }}
                        />
                      </Button>
                    </Stack>
                  </Stack>
                </DescriptionContainer>
              </Stack>
              <ContentBlock>
                {isMobile ? (
                  <CityDetailNeighborhoods cityId={city?.cityId} />
                ) : null}
                <CityDetailCuratedTrips cityId={city?.cityId} />
                <CityDetailActivities cityPath={cityPath} />
                <CityDetailTopHotels
                  cityId={city?.cityId}
                  cityPath={cityPath}
                />
                {/*//TODO uncomment when mytrips be ready */}
                <Stack
                  alignItems="center"
                  width="100%"
                  sx={{ margin: '12px 0 38px !important' }}
                >
                  <CreateYourTripButton
                    variant="contained"
                    onClick={() => setIsPlanTripDialogOpen(true)}
                  >
                    Create Your Trip
                  </CreateYourTripButton>
                </Stack>
              </ContentBlock>
            </Stack>
          )}
        </Stack>
      </Stack>
      <Footer />
      {citiesWithImageUrl ? (
        <PlanTripDialog
          isOpen={isPlanTripDialogOpen}
          citiesWithImageUrl={citiesWithImageUrl}
          onClose={() => setIsPlanTripDialogOpen(false)}
        />
      ) : null}
    </>
  ) : (
    <NotFoundContainer />
  );
}

const HeaderImage = styled(LazyLoadImage)`
  background-size: cover;
  background-position: center;
  object-fit: cover;
  height: 500px;
  width: 100%;
  background-color: ${SECONDARY_COLOR};

  ${device.tablet} {
    height: 570px;
  }

  ${device.laptopN} {
    height: 700px;
  }

  & .placeholder-block {
    height: 500px;
    background-color: ${SECONDARY_COLOR};

    ${device.tablet} {
      height: 500px;
    }

    ${device.laptopN} {
      height: 700px;
    }
  }
`;

const ContentBlock = styled(Stack)`
  margin: 0 auto !important;
  width: 100%;
  max-width: 1440px;
`;

const DescriptionContainer = styled(Stack)`
  margin: -50px auto 0;
  padding: 8px 16px;
  background-color: ${PRIMARY_COLOR};
  border-radius: 20px;
  max-width: 390px;
  width: 94%;

  ${device.tablet} {
    margin-top: -100px;
    padding: 8px 24px 16px;
    max-width: 730px;
  }

  ${device.laptopN} {
    margin-top: -100px;
    padding: 8px 38px 20px;
    max-width: 730px;
  }

  ${device.desktop} {
    margin-top: -160px;
    padding: 16px 58px 20px;
    max-width: 860px;
  }
`;

const CityName = styled(Tex)`
  font-size: 28px;

  ${device.laptopN} {
    font-size: 34px;
  }
`;
//TODO uncomment when mytrips be ready
const CreateYourTripButton = styled(Button)`
  padding: 8px 16px;
  border-radius: 9999px;
  background-color: ${PRIMARY_COLOR};
  height: 46px;
  width: 80%;
  max-width: 260px;
  font-size: 20px;
  line-height: 24px;
`;
