import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material';
import { FieldError, FormState, Path, UseFormRegister } from 'react-hook-form';
import { Tex } from './Tex';

export interface FormSelectOption {
  value: string;
  label: string;
}

type Props<T> = SelectProps & {
  register: UseFormRegister<T>;
  formState: FormState<T>;
  fieldName: Path<T>;
  label?: string;
  options: FormSelectOption[];
  required?: boolean;
  onChange?: (event?: SelectChangeEvent<unknown>) => void;
};

export function FormSelect<T>(props: Props<T>): JSX.Element {
  const {
    register,
    formState,
    fieldName,
    label,
    options,
    required,
    onChange,
    ...otherProps
  } = props;
  const { onChange: onChangeValue, onBlur, name, ref } = register(fieldName);
  const errors = formState.errors as Record<Path<T>, FieldError>;
  const error = errors[fieldName]?.message;

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    onChangeValue(event);

    if (onChange) {
      onChange(event);
    }
  };

  return (
    <Box>
      {label && (
        <InputLabel
          htmlFor={fieldName}
          required={required}
          shrink
          sx={{ fontWeight: 700 }}
        >
          {label}
        </InputLabel>
      )}
      <Select
        onChange={handleChange}
        onBlur={onBlur}
        name={name}
        ref={ref}
        variant="outlined"
        size="small"
        fullWidth
        {...otherProps}
      >
        {options.map((option) => (
          <MenuItem key={option.label} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && (
        <Tex
          color="error"
          sx={{ margin: '4px 14px 0 14px', fontSize: '0.75rem' }}
        >
          {error}
        </Tex>
      )}
    </Box>
  );
}
