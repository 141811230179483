import { useEffect, useState } from 'react';
import { Place, Trip, TripId } from '@jaunt/api';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Link,
  Stack,
} from '@mui/material';
import { Tex } from './shared/Tex';
import { useReplacePlaceToTrips } from '../hooks/use-place-trips';

type Props = {
  trips: Trip[];
  place?: Place | null;
  selectedTripIds?: TripId[];
  onClose(): void;
  onToggleNewTrip(): void;
};

export function PlaceToTripSelectTrip(props: Props): JSX.Element {
  const {
    trips,
    place,
    selectedTripIds = [],
    onToggleNewTrip,
    onClose,
  } = props;
  const [tripIdsObj, setTripIdsObj] = useState<Record<TripId, boolean>>({});
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const { replacePlaceToTrips } = useReplacePlaceToTrips();

  useEffect(() => {
    const data1 = trips.reduce((acc: Record<TripId, boolean>, trip) => {
      acc[trip.tripId] = selectedTripIds.includes(trip.tripId);
      return acc;
    }, {});
    setTripIdsObj(data1);
  }, []);

  async function onSave(): Promise<void> {
    const tripIds = Object.keys(tripIdsObj).filter(
      (tripId) => tripIdsObj[tripId],
    );
    if (place) {
      await replacePlaceToTrips({
        placeId: place.placeId,
        data: tripIds,
      });
      onClose();
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTripIdsObj({
      ...tripIdsObj,
      [event.target.value]: event.target.checked,
    });
    setIsDirty(true);
  };

  return (
    <Stack
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      gap={5}
    >
      <Stack>
        <Stack spacing={4}>
          {!!place?.name && (
            <Tex
              fontSize={22}
              lineHeight="26px"
              sx={{
                mt: 8,
                mb: 3,
                mx: 1,
                textAlign: 'center',
              }}
            >
              Save&nbsp;
              <Tex component="span" fontSize={22} fontWeight={500}>
                {place.name}
              </Tex>
              &nbsp;to an existing trip:
            </Tex>
          )}
        </Stack>
        <FormControl component="fieldset" variant="standard">
          <FormGroup
            sx={{
              ml: 3,
            }}
          >
            {trips.map((trip) => (
              <FormControlLabel
                key={trip.tripId}
                label={trip.name}
                value={trip.tripId}
                control={
                  <Checkbox
                    value={trip.tripId}
                    onChange={handleChange}
                    checked={tripIdsObj[trip.tripId] ?? false}
                  />
                }
                sx={{
                  border: '1px solid #E8EAED',
                  my: 0.5,
                }}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Stack>
      <Stack sx={{ width: '100%' }}>
        <Stack alignItems="center" gap={3}>
          <Button
            variant="contained"
            onClick={onSave}
            disabled={!isDirty}
            sx={{
              px: 6,
              py: 1,
              borderRadius: '9999px',
              height: '46px',
              width: '100%',
              fontSize: '14px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            SAVE
          </Button>
          <Link component="button" variant="body1" onClick={onToggleNewTrip}>
            SAVE TO A NEW TRIP
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
}
