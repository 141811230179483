import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useQuery } from '../hooks/use-query';
import { Link, Stack } from '@mui/material';
import { useState } from 'react';
import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  WHITE_TEXT_COLOR,
} from '../settings/jaunt-theme';
import { FormTextField } from '../components/shared/FormTextField';
import { Tex } from '../components/shared/Tex';
import { LoadingButton } from '@mui/lab';
import { FirebaseError } from 'firebase/app';
import { translateFirebaseError } from '../services/firebase.util';
import { useLogIn, useRefetchUser } from '../hooks/use-me';
import styled from '@emotion/styled';
import { ReactComponent as GoogleIcon } from 'src/assets/svg/google-icon.svg';
import { common } from '@mui/material/colors';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { createSession } from '../services/auth.api';

const resolver = yupResolver(
  yup.object({
    email: yup.string().email('Enter a valid email').required('Required'),
    password: yup.string().min(8, 'Minimum 8 characters').required('Required'),
  }),
);

interface FormValues {
  email: string;
  password: string;
}

export function LogInPage(): JSX.Element {
  const navigate = useNavigate();
  const query = useQuery();
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  const { register, handleSubmit, formState } = useForm<FormValues>({
    resolver,
    mode: 'onChange',
  });
  const { refetchUser } = useRefetchUser();
  const { logIn } = useLogIn();
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  async function onSubmit(formValues: FormValues): Promise<void> {
    const { email, password } = formValues;
    setErrorMessage('');
    setIsSaving(true);
    try {
      await logIn({ email, password });
      const redirectTo = query.get('redirectTo');
      if (redirectTo === 'back') {
        navigate(-1);
      } else {
        navigate('/explore/cities');
      }
    } catch (e) {
      const message = translateFirebaseError(e as FirebaseError);
      setErrorMessage(message);
      setIsSaving(false);
    }
  }

  async function onGoogleSubmit(): Promise<void> {
    signInWithPopup(auth, provider)
      .then(async () => {
        await createSession();
        await refetchUser();
        const redirectTo = query.get('redirectTo');
        if (redirectTo === 'back') {
          navigate(-1);
        } else {
          navigate('/explore/cities');
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData?.email;
        console.error('google signIn error: ', errorCode, errorMessage, email); // eslint-disable-line no-console
      });
  }

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={2}
      sx={{
        width: '100vw',
        height: '100vh',
        color: WHITE_TEXT_COLOR,
        backgroundColor: PRIMARY_COLOR,
      }}
    >
      <Stack
        component="form"
        alignItems="center"
        onSubmit={handleSubmit(onSubmit)}
        spacing={2}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Link component={RouterLink} to="/">
            <LogoImg src="/logo.png" alt="Jaunt more!" />
          </Link>
          <Tex component="h1" variant="h6">
            Log in
          </Tex>
        </Stack>
        <Stack sx={{ margin: '24px 0 8px !important' }}>
          <LoadingButton
            variant="outlined"
            onClick={onGoogleSubmit}
            sx={{
              px: 2,
              py: 1,
              borderRadius: '9999px',
              height: '45px',
              width: '268px',
              background: '#fff',
              '&:hover': {
                borderColor: '#000',
              },
            }}
            disableElevation
          >
            <Stack spacing={1} direction="row" alignItems="center">
              <StyledGoogleIcon />
              <Tex
                fontSize="14px"
                color={common.black}
                sx={{ fontWeight: 500 }}
              >
                continue with Google
              </Tex>
            </Stack>
          </LoadingButton>
        </Stack>
        <FormTextField
          register={register}
          formState={formState}
          fieldName="email"
          label="Email"
          color="secondary"
          required
          sx={{ width: '268px' }}
        />
        <FormTextField
          register={register}
          formState={formState}
          fieldName="password"
          label="Password"
          type="password"
          color="secondary"
          required
          sx={{ width: '268px' }}
        />
        <Tex variant="caption" color="error">
          {errorMessage}
        </Tex>
        <LoadingButton
          type="submit"
          variant="contained"
          color="secondary"
          size="small"
          loading={isSaving}
          disabled={isSaving || !formState.isValid}
          disableRipple
          sx={{
            '.MuiLoadingButton-loadingIndicator': { color: SECONDARY_COLOR },
            minWidth: '268px',
          }}
        >
          Log in
        </LoadingButton>
        <Tex>
          or{' '}
          <Link component={RouterLink} to="/register">
            <Tex component="span" color="secondary">
              Register
            </Tex>
          </Link>
        </Tex>
      </Stack>
    </Stack>
  );
}

const LogoImg = styled.img`
  width: 40px;
  height: auto;
`;

const StyledGoogleIcon = styled(GoogleIcon)`
  height: 32px;
  width: 32px;
`;
