import { CityId } from '@jaunt/api';
import { getFirstCityImageUrl } from '../services/city.util';
import { useQuery, UseQueryResult } from 'react-query';
import QueryKeys from './constants/query-keys';

export function useFirstCityImageUrl(
  cityId: CityId | undefined | null,
): UseQueryResult<string | null | undefined, unknown> {
  return useQuery<string | null | undefined>(
    [QueryKeys.firstCityImageUrl, cityId],
    async () => {
      return cityId ? await getFirstCityImageUrl(cityId) : undefined;
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading FirstCityImageUrl: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!cityId,
    },
  );
}
