import { CuratedTripPlaceCard } from './CuratedTripPlaceCard';
import { TripPlace, TripStay } from '@jaunt/api';
import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import { common } from '@mui/material/colors';
import { Tex } from '../shared/Tex';
import { device } from '../../settings/jaunt-theme';
import { useMemo } from 'react';
import {
  useCreateFavPlace,
  useDeleteFavPlace,
  useFavoritePlaces,
} from '../../hooks/use-favorite-places';

type CuratedTripDetailsProps = {
  chosenDay: number | null;
  tripStays?: TripStay[];
  tripPlaces?: TripPlace[];
};

export const CuratedTripDetails = ({
  chosenDay,
  tripStays,
  tripPlaces,
}: CuratedTripDetailsProps): JSX.Element | null => {
  const { data: favoritePlaces } = useFavoritePlaces();
  const { createFavPlace } = useCreateFavPlace();
  const { deleteFavPlace } = useDeleteFavPlace();
  const tripStayIds = tripStays?.map((x) => x.placeId) ?? [];
  const visiblePlaceIds = tripPlaces
    ?.filter((x) => x.day === chosenDay)
    .map((x) => x.placeId);

  const favPlacesIds = useMemo(() => {
    if (favoritePlaces?.length) {
      return favoritePlaces.reduce((acc: Record<string, boolean>, item) => {
        acc[item.placeId] = true;
        return acc;
      }, {});
    }
    return {};
  }, [favoritePlaces]);

  async function handleToggleFavPlaces(placeId: string, isNewFav: boolean) {
    if (isNewFav) {
      await createFavPlace({ placeId });
    } else {
      await deleteFavPlace({ placeId });
    }
  }

  return (
    <Container>
      {tripStays?.length || visiblePlaceIds?.length ? (
        <Tex
          align="center"
          fontFamily="Canela, serif"
          fontSize={24}
          lineHeight="26px"
          fontWeight={700}
          color={common.black}
          sx={{ margin: '24px !important' }}
        >
          The Details
        </Tex>
      ) : null}
      <DetailsWrapper>
        {chosenDay === 9999 ? (
          tripStays?.length ? (
            tripStays.map((stay) => (
              <CuratedTripPlaceCard
                key={stay.placeId}
                placeId={stay.placeId}
                placeIds={tripStayIds}
                emptyInfo="no stays selected"
                isFavorite={favPlacesIds[stay.placeId]}
                onToggleFavorite={handleToggleFavPlaces}
              />
            ))
          ) : (
            <Stack>
              <Tex textAlign="center" sx={{ opacity: 0.5, width: '100%' }}>
                No stays selected
              </Tex>
            </Stack>
          )
        ) : visiblePlaceIds?.length ? (
          visiblePlaceIds.map((placeId) => (
            <CuratedTripPlaceCard
              key={placeId}
              placeId={placeId}
              placeIds={visiblePlaceIds}
              emptyInfo="no plan for the day"
              isFavorite={favPlacesIds[placeId]}
              onToggleFavorite={handleToggleFavPlaces}
            />
          ))
        ) : (
          <Stack>
            <Tex textAlign="center" sx={{ opacity: 0.5, width: '100%' }}>
              No plan for the day
            </Tex>
          </Stack>
        )}
      </DetailsWrapper>
    </Container>
  );
};

const Container = styled(Stack)`
  padding: 16px 20px;

  ${device.tablet} {
    padding: 16px 32px;
  }

  ${device.laptopN} {
    padding: 16px 52px;
  }

  ${device.desktop} {
    padding: 16px 70px;
  }
`;

const DetailsWrapper = styled(Stack)`
  margin: 0 auto;
  width: 100%;

  ${device.tablet} {
    width: 674px;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 22px;
  }

  ${device.laptopN} {
    width: 920px;
    gap: 10px;
  }

  ${device.desktop} {
    width: 1300px;
    gap: 21px;
  }
`;
