import { Tex } from '../shared/Tex';
import { Stack } from '@mui/material';
import styled from '@emotion/styled';
import { device, PRIMARY_COLOR } from '../../settings/jaunt-theme';
import { BuildTripMode } from '../../constants';

interface Props {
  onChange(mode: BuildTripMode): void;
}

export function PlanTripModeStep({ onChange }: Props): JSX.Element {
  function onSelectMode(tripMode: BuildTripMode) {
    onChange(tripMode);
  }

  return (
    <Stack spacing={6}>
      <Tex color="primary" textAlign="center" fontSize={20}>
        <b>How do you want to create your trip?</b>
      </Tex>
      <ModesContainer>
        <ModeWrapper onClick={() => onSelectMode(BuildTripMode.manual)}>
          <Tex fontSize={16} fontFamily="CircularStd-Book" fontWeight={700}>
            I’ll build it myself
          </Tex>
          <Tex
            fontSize={16}
            fontFamily="CircularStd-Light"
            sx={{ textAlign: 'center' }}
          >
            browse our best recs and create your own itinerary
          </Tex>
        </ModeWrapper>
        <ModeWrapper onClick={() => onSelectMode(BuildTripMode.auto)}>
          <Tex fontSize={16} fontFamily="CircularStd-Book" fontWeight={700}>
            Please build it for me
          </Tex>
          <Tex
            fontSize={16}
            fontFamily="CircularStd-Light"
            sx={{ textAlign: 'center' }}
          >
            beta test our new AI Trip Builder to autogenerate your itinerary
          </Tex>
        </ModeWrapper>
      </ModesContainer>
    </Stack>
  );
}

const ModesContainer = styled(Stack)`
  gap: 25px;
  margin: 38px auto 20px !important;

  ${device.laptopN} {
    flex-direction: row;
    margin: 60px auto 0 !important;
  }
`;
const ModeWrapper = styled(Stack)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0;
  padding: 12px 22px;
  box-sizing: border-box;
  border: 1px solid ${PRIMARY_COLOR};
  border-radius: 10px;
  max-width: 285px;
  font-size: 16px;
  cursor: pointer;
`;
