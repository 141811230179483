import { CityId, FavoriteCity } from '@jaunt/api';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import QueryKeys from './constants/query-keys';
import {
  listFavoriteCities,
  getFavoriteCity,
  createFavoriteCity,
  deleteFavoriteCity,
} from '../services/city.api';

export function useFavoriteCities(): UseQueryResult<FavoriteCity[], unknown> {
  return useQuery<FavoriteCity[]>(
    QueryKeys.favoriteCities,
    listFavoriteCities,
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading FavoriteCities: ', error); // eslint-disable-line no-console
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: 3,
      retryDelay: 2000,
    },
  );
}

export function useFavoriteCity(
  cityId?: CityId | null,
): UseQueryResult<FavoriteCity | undefined, unknown> {
  return useQuery<FavoriteCity | undefined>(
    [QueryKeys.favoriteCities, cityId],
    async () => {
      return cityId ? await getFavoriteCity(cityId) : undefined;
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading FavoriteCity: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!cityId,
    },
  );
}

export const useCreateFavCity = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({ cityId }: { cityId: CityId }) => {
      return await createFavoriteCity({ cityId });
    },
    {
      onSuccess: () => {
        client.invalidateQueries(QueryKeys.favoriteCities);
      },
    },
  );
  return { createFavCity: mutateAsync, isLoading, isSuccess, isError };
};

export const useDeleteFavCity = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({ cityId }: { cityId: CityId }) => {
      await deleteFavoriteCity(cityId);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(QueryKeys.favoriteCities);
      },
    },
  );
  return { deleteFavCity: mutateAsync, isLoading, isSuccess, isError };
};
