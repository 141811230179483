import { useMutation, useQueryClient } from 'react-query';
import { TripId } from '@jaunt/api';
import { publicTrip } from '../services/trip.api';
import { publicGeneratedTrip } from '../services/trip-generated.api';
import QueryKeys from './constants/query-keys';

export const usePublicMyTrip = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async (data: { tripId: TripId; isPublic: boolean }) => {
      return await publicTrip(data.tripId, { isPublic: data.isPublic });
    },
    {
      onSuccess: (trip) => {
        client.invalidateQueries([QueryKeys.trips, trip.tripId]);
        console.log('Public trip: ', trip); // eslint-disable-line no-console
      },
    },
  );
  return { publicMyTrip: mutateAsync, isLoading, isSuccess, isError };
};

export const usePublicMyGeneratedTrip = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async (data: { tripId: TripId; isPublic: boolean }) => {
      return await publicGeneratedTrip(data.tripId, {
        isPublic: data.isPublic,
      });
    },
    {
      onSuccess: (tripGenerated) => {
        client.invalidateQueries([
          QueryKeys.userGeneratedTrips,
          tripGenerated.tripId,
        ]);
        console.log('Public generated trip: ', tripGenerated); // eslint-disable-line no-console
      },
    },
  );
  return { publicMyGeneratedTrip: mutateAsync, isLoading, isSuccess, isError };
};
