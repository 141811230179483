import { useQuery, UseQueryResult } from 'react-query';
import { CityWithImageUrl, getCitiesWithImageUrl } from '../services/city.util';
import QueryKeys from './constants/query-keys';
import { City } from '@jaunt/api';

export function useCitiesWithImageUrl(
  cities?: City[],
): UseQueryResult<CityWithImageUrl[] | null | undefined, unknown> {
  return useQuery<CityWithImageUrl[] | null | undefined>(
    QueryKeys.citiesWithImageUrl,
    async () => {
      return cities && cities.length > 0
        ? await getCitiesWithImageUrl(cities)
        : [];
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading CitiesWithImageUrl: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!cities,
    },
  );
}
