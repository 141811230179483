import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Stack } from '@mui/material';
import { useState } from 'react';
import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  WHITE_TEXT_COLOR,
} from '../settings/jaunt-theme';
import { useNavigate } from 'react-router-dom';
import { FormTextField } from '../components/shared/FormTextField';
import { Tex } from '../components/shared/Tex';
import { LoadingButton } from '@mui/lab';
import { translateFirebaseError } from '../services/firebase.util';
import { FirebaseError } from 'firebase/app';
import { useQuery } from '../hooks/use-query';
import { useRefetchUser, useSignUp } from '../hooks/use-me';
import { useReplaceTripUsers } from '../hooks/use-trip-users';
import { useRefetchTripCity } from '../hooks/use-trip-city';
import styled from '@emotion/styled';
import { useTripPath } from '../hooks/use-trip';

const resolver = yupResolver(
  yup.object({
    email: yup.string().email('Enter a valid email').required('Required'),
    password: yup.string().min(8, 'Minimum 8 characters').required('Required'),
  }),
);

interface FormValues {
  email: string;
  password: string;
}

export function RegisterPage(): JSX.Element {
  const navigate = useNavigate();
  const query = useQuery();
  const email = query.get('email');
  const tripPath = query.get('tripPath');
  const { data: trip } = useTripPath(tripPath);
  const { register, handleSubmit, formState } = useForm<FormValues>({
    resolver,
  });
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { refetchUser } = useRefetchUser();
  const { refetchTripCity } = useRefetchTripCity();
  const { replaceTripUsers } = useReplaceTripUsers();
  const { signUp } = useSignUp();

  async function onSubmit(formValues: FormValues): Promise<void> {
    const { email, password } = formValues;
    setErrorMessage('');
    setIsSaving(true);
    try {
      await signUp({ email, password });
      const userId = await refetchUser();
      // if the user creates a trip in guest mode, assign the trip to the user
      if (trip?.tripId) {
        await replaceTripUsers({
          tripId: trip.tripId,
          data: [{ tripId: trip.tripId, userId }],
        });
        const cityTrip = await refetchTripCity(trip.tripId);
        if (cityTrip) {
          navigate(`/trips/${trip.tripPath}`);
        }
      } else {
        navigate('/explore/cities');
      }
    } catch (e) {
      const message = translateFirebaseError(e as FirebaseError);
      setErrorMessage(message);
      setIsSaving(false);
    }
  }

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        width: '100vw',
        height: '100vh',
        color: WHITE_TEXT_COLOR,
        backgroundColor: PRIMARY_COLOR,
      }}
    >
      <Stack component="form" onSubmit={handleSubmit(onSubmit)} spacing={2}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Link component={RouterLink} to="/">
            <LogoImg src="/logo.png" alt="Jaunt logo" />
          </Link>
          <Tex component="h1" variant="h6">
            Register
          </Tex>
        </Stack>
        <FormTextField
          defaultValue={email}
          register={register}
          formState={formState}
          fieldName="email"
          label="Email"
          color="secondary"
          required
        />
        <FormTextField
          register={register}
          formState={formState}
          fieldName="password"
          label="Password"
          type="password"
          color="secondary"
          required
        />
        <Tex variant="caption" color="error">
          {errorMessage}
        </Tex>
        <LoadingButton
          type="submit"
          variant="contained"
          color="secondary"
          size="small"
          loading={isSaving}
          disabled={isSaving}
          disableRipple
          sx={{
            '.MuiLoadingButton-loadingIndicator': { color: SECONDARY_COLOR },
          }}
        >
          Register
        </LoadingButton>
        <Tex>
          or{' '}
          <Link component={RouterLink} to="/login">
            <Tex component="span" color="secondary">
              Log in
            </Tex>
          </Link>
        </Tex>
      </Stack>
    </Stack>
  );
}

const LogoImg = styled.img`
  width: 40px;
  height: auto;
`;
