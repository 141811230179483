import { City, CityId } from '@jaunt/api';
import { FormSelectOption } from '../components/shared/FormSelect';
import { getCityImages } from './city.api';
import { getFileDownloadUrl } from './file.api';

export interface CityWithImageUrl {
  city: City;
  imageUrl: string;
}

export async function getCitiesWithImageUrl(
  cities: City[],
): Promise<CityWithImageUrl[]> {
  const imageUrls = await Promise.all(
    cities.map((city) => getFirstCityImageUrl(city.cityId)),
  );
  return cities.map((city, index) => ({ city, imageUrl: imageUrls[index] }));
}

export async function getFirstCityImageUrl(cityId: CityId): Promise<string> {
  const [cityImage] = await getCityImages(cityId);
  return cityImage ? getFileDownloadUrl(cityImage.fileId) : '';
}

export function getCityOptions(cities: City[]): FormSelectOption[] {
  return cities.map((city) => ({
    value: city.cityId,
    label: city.name,
  }));
}
