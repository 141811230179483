import {
  FileId,
  Neighborhood,
  NeighborhoodData,
  NeighborhoodId,
} from '@jaunt/api';
import {
  createNeighborhood,
  deleteNeighborhood,
  getNeighborhood,
  getNeighborhoodByPath,
  updateNeighborhood,
} from '../services/neighborhood.api';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import QueryKeys from './constants/query-keys';
import { useMemo } from 'react';
import { replaceNeighborhoodImages } from '../services/neighborhood-image.api';

export function useNeighborhood(
  neighborhoodId: NeighborhoodId | null | undefined,
): UseQueryResult<Neighborhood | undefined, unknown> {
  return useQuery<Neighborhood | undefined>(
    [QueryKeys.neighborhoods, neighborhoodId],
    async () => {
      return neighborhoodId ? await getNeighborhood(neighborhoodId) : undefined;
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading Neighborhood: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!neighborhoodId,
    },
  );
}

export function useNeighborhoodPath(
  neighborhoodPath?: string | undefined | null,
) {
  const client = useQueryClient();
  const result = useQuery<Neighborhood | undefined>(
    [QueryKeys.neighborhoods, 'path', neighborhoodPath],
    async () => {
      return neighborhoodPath
        ? await getNeighborhoodByPath(neighborhoodPath)
        : undefined;
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading NeighborhoodPath: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!neighborhoodPath,
    },
  );
  return useMemo(() => {
    const neighborhoods: Neighborhood[] =
      client.getQueryData(QueryKeys.neighborhoods) ?? [];
    const neighborhood =
      neighborhoodPath && neighborhoods.length > 0
        ? neighborhoods.find(
            (neighborhood) =>
              neighborhood?.neighborhoodPath === neighborhoodPath,
          )
        : null;
    if (neighborhood) {
      return {
        isSuccess: result.isSuccess,
        isError: result.isError,
        isLoading: result.isLoading,
        data: neighborhood,
      };
    } else {
      if (result.isSuccess && result.data) {
        client.setQueryData(QueryKeys.neighborhoods, [
          ...neighborhoods,
          result.data,
        ]);
        client.setQueryData(
          [QueryKeys.neighborhoods, result.data?.neighborhoodId],
          result.data,
        );
      }

      return {
        isSuccess: result.isSuccess,
        isError: result.isError,
        isLoading: result.isLoading,
        data: result.data,
      };
    }
  }, [result.isSuccess, result.isError, result.isLoading, result.data]);
}

export const useCreateNeighborhood = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({
      neighborhoodData,
      imageFileIds,
    }: {
      neighborhoodData: NeighborhoodData;
      imageFileIds: FileId[];
    }) => {
      const createdNeighborhood = await createNeighborhood(neighborhoodData);
      const { neighborhoodId } = createdNeighborhood;
      await Promise.all([
        replaceNeighborhoodImages(neighborhoodId, imageFileIds),
      ]);

      return createdNeighborhood;
    },
    {
      onSuccess: (neighborhood: Neighborhood) => {
        client.invalidateQueries(QueryKeys.neighborhoods);
        client.invalidateQueries([
          QueryKeys.neighborhoodsWithImageUrl,
          neighborhood.neighborhoodId,
        ]);
      },
    },
  );
  return { createNeighborhood: mutateAsync, isLoading, isSuccess, isError };
};

export const useUpdateNeighborhood = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({
      neighborhoodId,
      imageFileIds,
      neighborhoodData,
    }: {
      neighborhoodId: NeighborhoodId;
      imageFileIds: FileId[];
      neighborhoodData: NeighborhoodData;
    }) => {
      const [updatedNeighborhood] = await Promise.all([
        updateNeighborhood(neighborhoodId, neighborhoodData),
        replaceNeighborhoodImages(neighborhoodId, imageFileIds),
      ]);

      return updatedNeighborhood;
    },
    {
      onMutate: ({ neighborhoodId, neighborhoodData }) => {
        const oldNeighborhood = client.getQueryData([
          QueryKeys.neighborhoods,
          neighborhoodId,
        ]);

        client.setQueryData(
          [QueryKeys.neighborhoods, neighborhoodId],
          (prevData) => {
            if (prevData && prevData instanceof Object) {
              return {
                ...prevData,
                ...neighborhoodData,
              };
            }
            return null;
          },
        );

        return oldNeighborhood;
      },
      onSuccess: (neighborhood: Neighborhood) => {
        client.invalidateQueries(QueryKeys.neighborhoods);
        client.invalidateQueries([
          QueryKeys.neighborhoodsWithImageUrl,
          neighborhood.neighborhoodId,
        ]);
      },
      onError: (_, { neighborhoodId }, oldNeighborhood) => {
        client.setQueryData(
          [QueryKeys.neighborhoods, neighborhoodId],
          oldNeighborhood,
        );
      },
    },
  );
  return { updateNeighborhood: mutateAsync, isLoading, isSuccess, isError };
};

export const useDeleteNeighborhood = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({ neighborhoodId }: { neighborhoodId: NeighborhoodId }) => {
      await deleteNeighborhood(neighborhoodId);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(QueryKeys.neighborhoods);
      },
    },
  );
  return { deleteNeighborhood: mutateAsync, isLoading, isSuccess, isError };
};
