import {
  useCreateFavCity,
  useDeleteFavCity,
  useFavoriteCities,
} from '../../hooks/use-favorite-cities';
import { useCities } from '../../hooks/use-cities';
import { useCitiesWithImageUrl } from '../../hooks/use-cities-with-image-url';
import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import { device } from '../../settings/jaunt-theme';
import { ExploreImageCard } from '../../components/ExploreImageCard';
import { useMemo } from 'react';

export function FavoriteCities(): JSX.Element {
  const { data: cities } = useCities();
  const { data: citiesWithImageUrl } = useCitiesWithImageUrl(cities);
  const { data: favoriteCities } = useFavoriteCities();
  const { createFavCity } = useCreateFavCity();
  const { deleteFavCity } = useDeleteFavCity();

  async function handleToggleFavCities(cityId: string, isNewFav: boolean) {
    if (isNewFav) {
      await createFavCity({ cityId });
    } else {
      await deleteFavCity({ cityId });
    }
  }

  const favoriteCitiesWithImage = useMemo(() => {
    if (favoriteCities?.length && citiesWithImageUrl?.length) {
      const favCitiesIds = favoriteCities.reduce(
        (acc: Record<string, boolean>, item) => {
          acc[item.cityId] = true;
          return acc;
        },
        {},
      );
      return citiesWithImageUrl?.filter(
        ({ city }) => !city.isHidden && favCitiesIds[city.cityId],
      );
    }
    return [];
  }, [citiesWithImageUrl, favoriteCities]);

  return (
    <CitiesWrapper>
      {favoriteCitiesWithImage.map((cityWithImageUrl) => (
        <ExploreImageCard
          key={cityWithImageUrl.city.cityId}
          id={cityWithImageUrl.city.cityId}
          title={cityWithImageUrl.city.name}
          description={cityWithImageUrl.city.state}
          imageUrl={cityWithImageUrl.imageUrl}
          routePath={`/cities/${cityWithImageUrl.city.cityPath}`}
          isFavorite={true}
          onToggleFavorite={handleToggleFavCities}
        />
      ))}
    </CitiesWrapper>
  );
}

const CitiesWrapper = styled(Stack)`
  margin: 32px auto 0 !important;
  width: 100%;
  align-items: center;
  gap: 8px;

  ${device.tablet} {
    width: 674px;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 22px;
  }

  ${device.laptopN} {
    margin: 36px auto 0 !important;
    padding: 0 16px;
    flex-direction: row;
    overflow-x: auto;
    width: 100%;
    flex-wrap: nowrap;
    gap: 16px;
  }

  ${device.desktop} {
    gap: 21px;
  }
`;
