import { FileId, ReviewId, ReviewImage } from '@jaunt/api';
import { getFileDownloadUrl } from '../services/file.api';
import { getReviewImages } from '../services/review.api';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import QueryKeys from './constants/query-keys';
import { replaceReviewImages } from '../services/review-image.api';

export function useReviewImages(
  reviewId: ReviewId | null,
): UseQueryResult<ReviewImage[], unknown> {
  return useQuery<ReviewImage[]>(
    [QueryKeys.reviewImages, reviewId],
    async () => (reviewId ? await getReviewImages(reviewId) : []),
    {
      placeholderData() {
        return [];
      },
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading ReviewImages: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!reviewId,
    },
  );
}

export const useReplaceReviewImages = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({ reviewId, data }: { reviewId: ReviewId; data: FileId[] }) => {
      return await replaceReviewImages(reviewId, data);
    },
    {
      onSuccess: (_, { reviewId }) => {
        client.invalidateQueries([QueryKeys.reviewImages, reviewId]);
      },
    },
  );
  return { replaceReviewImages: mutateAsync, isLoading, isSuccess, isError };
};

export function useReviewImagesUrls(
  reviewId: ReviewId | null,
): UseQueryResult<string[], unknown> {
  const client = useQueryClient();
  return useQuery<string[]>(
    [QueryKeys.reviewImages, reviewId, 'urls'],
    async () => {
      if (reviewId) {
        let reviewImages: ReviewImage[] =
          client.getQueryData([QueryKeys.reviewImages, reviewId]) ?? [];
        if (!reviewImages?.length) {
          reviewImages = reviewId ? await getReviewImages(reviewId) : [];
          client.setQueryData([QueryKeys.reviewImages, reviewId], reviewImages);
        }
        return await Promise.all(
          reviewImages.map(async (image) => {
            const url = await getFileDownloadUrl(image.fileId);
            client.setQueryData([QueryKeys.fileDownloadUrl, image.fileId], url);
            return url;
          }),
        );
      }
      return [];
    },
    {
      placeholderData() {
        return [];
      },
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading useReviewImagesUrls: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
    },
  );
}
