import { Tag, TagType } from '@jaunt/api';
import { capitalize, sortBy } from 'lodash';

export const tagTypeOptions = [
  {
    value: TagType.ACTIVITY,
    label: 'Activity',
  },
  {
    value: TagType.SUMMARY,
    label: 'Summary',
  },
  {
    value: TagType.SECONDARY_ACTIVITY,
    label: 'Secondary Activity',
  },
];

export function sortTags(tags: Tag[]): Tag[] {
  return sortBy(tags, (x) => x.name);
}

export function getSummaryText(tags: Tag[] | undefined): string {
  if (!tags?.length) return '';

  const names = tags
    .filter((x) => x.type === TagType.SUMMARY)
    .map((x) => x.name.toLowerCase());
  return names.length === 0
    ? ''
    : capitalize(
        [names.slice(0, -1).join(', '), names[names.length - 1]].join(' and '),
      );
}
