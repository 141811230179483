import { NeighborhoodId } from '@jaunt/api';
import { useQuery, UseQueryResult } from 'react-query';
import QueryKeys from './constants/query-keys';
import { getFirstNeighborhoodImageUrl } from '../services/neigborhood.util';

export function useFirstNeighborhoodImageUrl(
  neighborhoodId: NeighborhoodId | undefined | null,
): UseQueryResult<string | null, unknown> {
  return useQuery<string | null>(
    [QueryKeys.firstNeighborhoodImageUrl, neighborhoodId],
    async () => {
      return neighborhoodId
        ? await getFirstNeighborhoodImageUrl(neighborhoodId)
        : null;
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading FirstNeighborhoodImageUrl: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!neighborhoodId,
    },
  );
}
