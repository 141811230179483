import { CityId, Neighborhood } from '@jaunt/api';
import { getCityNeighborhoods } from '../services/city.api';
import { useQuery, UseQueryResult } from 'react-query';
import QueryKeys from './constants/query-keys';

export function useCityNeighborhoods(
  cityId: CityId | null | undefined,
): UseQueryResult<Neighborhood[], unknown> {
  return useQuery<Neighborhood[]>(
    [QueryKeys.cityNeighborhoods, cityId],
    async () => {
      return cityId ? await getCityNeighborhoods(cityId) : [];
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading CityNeighborhoods: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!cityId,
    },
  );
}
