import { ReviewObj } from '@jaunt/api';
import { Stack } from '@mui/material';
import { common } from '@mui/material/colors';
import { useReviewImagesUrls } from 'src/hooks/use-review-images';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styled from '@emotion/styled';

interface Props {
  review: ReviewObj;
}

export function PlaceReviewCard(props: Props): JSX.Element {
  const { review } = props;
  const { data: reviewImageUrl } = useReviewImagesUrls(review.reviewId);

  return (
    <Wrapper>
      <a href={review.reviewUrl} target="_blank" rel="noreferrer">
        <LazyLoadImage
          key={review.reviewId}
          alt={review.reviewer}
          src={reviewImageUrl?.[0]}
          width="100px"
          height="auto"
        />
      </a>
    </Wrapper>
  );
}

const Wrapper = styled(Stack)`
  align-items: center;
  justify-content: center;
  background-color: ${common.white};
  border-radius: 16px;
  padding: 16px 8px;
`;
