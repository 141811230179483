import { PlaceId, TripId, TripPlace, TripPlaceData } from '@jaunt/api';
import {
  createTripPlace,
  getTripPlaces,
  replaceTripPlaces,
  updateTripPlaces,
} from '../services/trip.api';
import { NEW_TRIP } from '../constants';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import QueryKeys from './constants/query-keys';

export function useTripPlaces(
  tripId: TripId | null | undefined,
): UseQueryResult<TripPlace[], unknown> {
  return useQuery<TripPlace[]>(
    [QueryKeys.tripPlaces, tripId],
    async () => {
      if (!tripId || tripId === NEW_TRIP) {
        return [];
      } else {
        return await getTripPlaces(tripId);
      }
    },
    {
      placeholderData() {
        return [];
      },
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading TripPlaces: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!tripId,
    },
  );
}

export const useCreateTripPlace = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async (data: TripPlaceData) => {
      return await createTripPlace(data);
    },
    {
      onSuccess: (tripPlace, { tripId }) => {
        client.setQueryData(
          [QueryKeys.tripPlaces, tripPlace.tripId],
          tripPlace,
        );
        client.invalidateQueries([QueryKeys.tripPlaces, tripId]);
        client.invalidateQueries(QueryKeys.tripPlaces);
      },
    },
  );
  return { createTripPlace: mutateAsync, isLoading, isSuccess, isError };
};

export const useUpdateTripPlaces = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({
      tripId,
      tripPlaceDatas,
    }: {
      tripId: TripId;
      tripPlaceDatas: TripPlaceData[];
    }) => {
      return await updateTripPlaces(tripId, tripPlaceDatas);
    },
    {
      onSuccess: (_, { tripId }) => {
        client.invalidateQueries([QueryKeys.tripPlaces, tripId]);
        client.invalidateQueries(QueryKeys.tripPlaces);
      },
    },
  );
  return { updateTripPlaces: mutateAsync, isLoading, isSuccess, isError };
};

export const useReplaceTripPlaces = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({ tripId, data }: { tripId: TripId; data: TripPlaceData[] }) => {
      return await replaceTripPlaces(tripId, data);
    },
    {
      onSuccess: (_, { tripId }) => {
        client.invalidateQueries([QueryKeys.tripPlaces, tripId]);
        client.invalidateQueries(QueryKeys.placeTags);
        client.invalidateQueries(QueryKeys.tripPlaces);
      },
    },
  );
  return { replaceTripPlaces: mutateAsync, isLoading, isSuccess, isError };
};

export const useLoadPlacesTrips = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async (tripIds: TripId[]) => {
      return await Promise.allSettled(
        tripIds.map(async (tripId): Promise<TripPlace[]> => {
          let tripPlaces: TripPlace[] | undefined = client.getQueryData([
            QueryKeys.tripPlaces,
            tripId,
          ]);
          if (tripPlaces) return tripPlaces;
          tripPlaces = await getTripPlaces(tripId);
          client.setQueryData([QueryKeys.tripPlaces, tripId], tripPlaces);
          return tripPlaces;
        }),
      )
        .then((tripPlacesSettled: PromiseSettledResult<TripPlace[]>[]) => {
          return tripPlacesSettled.reduce(
            (acc: Record<PlaceId, TripId[]>, tripPlacesSettled) => {
              if (tripPlacesSettled.status === 'fulfilled') {
                tripPlacesSettled.value.forEach((tripPlace) => {
                  if (!acc[tripPlace.placeId]) {
                    acc[tripPlace.placeId] = [tripPlace.tripId];
                  } else {
                    acc[tripPlace.placeId].push(tripPlace.tripId);
                  }
                });
              }
              return acc;
            },
            {},
          );
        })
        .catch(() => {
          return {};
        });
    },
  );
  return { loadPlacesTrips: mutateAsync, isLoading, isSuccess, isError };
};
