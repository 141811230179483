import { useEffect, useState } from 'react';
import { Tex } from '../../components/shared/Tex';
import TripTimingDayPicker from '../../components/TripTimingDayPicker';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { addDays } from 'date-fns';
import { DateRange } from 'react-day-picker';
import styled from '@emotion/styled';
import { device } from '../../settings/jaunt-theme';

const SELECT_THESE_DATES = 'SELECT THESE DATES';
const CHOOSE_AN_END_DATE_ON_THE_CALENDAR = 'Choose an End Date on the calendar';

const currentDate = new Date();

const defaultSelected: DateRange = {
  from: addDays(currentDate, 7),
  to: addDays(currentDate, 12),
};

function getDefaultRange(dates: Record<number, string | null> | null) {
  const firstDate = dates?.[0];
  const lastDate = dates?.[Object.keys(dates).length - 1];
  return {
    from: firstDate ? new Date(firstDate) : defaultSelected.from,
    to: lastDate ? new Date(lastDate) : defaultSelected.to,
  };
}

interface Props {
  onSubmit: (dates: Record<number, string>, isWithDates: boolean) => void;
  dates: Record<number, string | null> | null;
  loading: boolean;
}

export const AddDates = ({
  onSubmit,
  loading,
  dates,
}: Props): JSX.Element | null => {
  const [range, setRange] = useState<DateRange | undefined>(
    getDefaultRange(dates),
  );
  const [confirmButtonText, setConfirmButtonText] =
    useState<string>(SELECT_THESE_DATES);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (!range?.to) {
      setConfirmButtonText(CHOOSE_AN_END_DATE_ON_THE_CALENDAR);
      timeout = setTimeout(() => {
        setConfirmButtonText(SELECT_THESE_DATES);
      }, 4000);
    } else {
      setConfirmButtonText(SELECT_THESE_DATES);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [range]);

  function saveDates() {
    if (range?.from && range?.to) {
      const endDate = addDays(range?.to, 1);
      let loopDay = range.from;
      let index = 0;
      const dates: Record<number, string> = {};
      while (loopDay < endDate) {
        dates[index] = loopDay.toISOString();
        index++;
        loopDay = addDays(loopDay, 1);
      }
      onSubmit(dates, true);
    }
  }

  return (
    <ContentContainer>
      {range?.from && !range?.to ? (
        <NoEndDateBlock>
          <Tex fontSize={16} fontWeight={500}>
            Choose an End Date on the calendar
          </Tex>
        </NoEndDateBlock>
      ) : null}
      <TripTimingWrapper>
        <TripTimingDayPicker
          range={range}
          setRange={setRange}
          defaultMonth={range?.from ? new Date(range.from) : currentDate}
          currentDate={currentDate}
        />
      </TripTimingWrapper>
      <LoadingButton
        variant="contained"
        loading={loading}
        onClick={saveDates}
        disabled={!range?.to || loading}
        sx={{
          px: 2,
          py: 1,
          mt: 2,
          mb: 1,
          borderRadius: '9999px',
          backgroundColor: '#D6472A',
          height: '40px',
          width: '80%',
          maxWidth: '280px',
        }}
      >
        {confirmButtonText}
      </LoadingButton>
    </ContentContainer>
  );
};

const ContentContainer = styled(Stack)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  padding: 28px 8px 0;
  overflow: auto;
  position: relative;
`;

const NoEndDateBlock = styled(Stack)`
  background-color: #d6472a;
  color: #fff;
  padding: 8px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
`;

const TripTimingWrapper = styled(Stack)`
  margin-top: 8px;

  ${device.tablet} {
    margin-top: 16px;
  }
`;
