import { NoteId, PlaceId, UserId, UserPlaceNote } from '@jaunt/api';
import { createNote, getNote, updateNote } from '../services/note.api';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import QueryKeys from './constants/query-keys';

export function useNote(
  placeId: PlaceId,
  userId: UserId,
): UseQueryResult<UserPlaceNote | null, unknown> {
  return useQuery<UserPlaceNote | null>(
    [QueryKeys.notes, userId, placeId],
    async () => {
      return userId && placeId ? (await getNote(placeId, userId))?.[0] : null;
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading Note: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
    },
  );
}

export const useCreateNote = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({
      placeId,
      userId,
      note,
    }: {
      placeId: PlaceId;
      userId: UserId;
      note: string;
    }) => {
      return await createNote({
        placeId,
        userId,
        note,
      });
    },
    {
      onSuccess: (_, { userId, placeId }) => {
        client.invalidateQueries([QueryKeys.notes, userId, placeId]);
      },
    },
  );
  return { createNote: mutateAsync, isLoading, isSuccess, isError };
};

export const useUpdateNote = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({
      noteId,
      placeId,
      userId,
      note,
    }: {
      noteId: NoteId;
      placeId: PlaceId;
      userId: UserId;
      note: string;
    }) => {
      return await updateNote(noteId, {
        placeId: placeId,
        userId: userId,
        note: note,
      });
    },
    {
      onSuccess: (_, { userId, placeId }) => {
        client.invalidateQueries([QueryKeys.notes, userId, placeId]);
      },
    },
  );
  return { updateNote: mutateAsync, isLoading, isSuccess, isError };
};
