import { CityId, Timestamp, TripId } from './shared';

export enum TripVibe {
  CULTURAL = 'cultural',
  OUTDOORSY = 'outdoorsy',
  FOODIE = 'foodie',
  historical = 'historical',
  TRENDY = 'trendy',
  lively = 'lively',
}

export enum TripHotelType {
  LUXURY = 'luxury',
  BOUTIQUE = 'boutique',
}

export interface TripDetail {
  readonly days: Record<number, string | null>;
  readonly isWithDates?: boolean | null;
  readonly vibes: TripVibe[];
  readonly budget: number;
  readonly createdBy: string;
}

export interface TripData {
  readonly name: string;
  readonly isCurated: boolean;
  readonly detail: TripDetail;
  readonly description: string | undefined;
  readonly creatorLink: string | undefined;
  readonly tripPath: string;
  readonly priority?: number;
  readonly isHidden?: boolean;
  readonly isPublic?: boolean;
}

export interface Trip extends TripData, Timestamp {
  readonly tripId: TripId;
}

export interface ListTripsQuery {
  readonly isCurated: boolean;
}

export interface ReplaceTripCityData {
  readonly cityId: CityId;
}

export interface SingleTripResponse {
  readonly trip: Trip;
}

export interface MultipleTripsResponse {
  readonly trips: Trip[];
}
