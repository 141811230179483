import styled from '@emotion/styled';
import { Button, Stack, TextField } from '@mui/material';
import { useState } from 'react';
import { device } from 'src/settings/jaunt-theme';
import { Tex } from '../shared/Tex';

interface Props {
  onChange(name: string): void;
}

export function PlanTripNameStep(props: Props): JSX.Element {
  const { onChange } = props;
  const [name, setName] = useState('');

  return (
    <Wrapper spacing={6} alignItems="center">
      <Stack spacing={4} alignItems="center" sx={{ width: '100%' }}>
        <Tex color="primary" textAlign="center" fontSize={20}>
          <b>Name your trip:</b>
        </Tex>
        <TripTextField
          onChange={(event) => setName(event.target.value)}
          variant="standard"
          size="small"
          placeholder="e.g. Austin Bachelorette"
        />
      </Stack>
      <NextButtonContainer alignItems="center">
        <NextButton
          variant="contained"
          onClick={() => onChange(name)}
          disabled={!name}
        >
          START PLANNING
        </NextButton>
      </NextButtonContainer>
    </Wrapper>
  );
}

const Wrapper = styled(Stack)`
  width: 100%;
  box-sizing: border-box;
`;

const NextButton = styled(Button)`
  padding: 8px 48px;
  border-radius: 9999px;

  ${device.tablet} {
    width: 245px;
  }
`;

const NextButtonContainer = styled(Stack)`
  ${device.tablet} {
    padding-top: 150px;
  }
`;

const TripTextField = styled(TextField)`
  padding: 0 8px;
  width: 100%;
  max-width: 450px;

  ${device.tablet} {
    padding: 0 32px;
    width: 400px;
  }
`;
