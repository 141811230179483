import { format, parse, parseISO } from 'date-fns';
import { groupBy } from 'lodash';

const isoDateStringRegexPattern =
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;

function jsonParserWithDate<T>(_key: string, value: T): T | Date {
  // parse the date only if it's an ISO-formatted date string
  return typeof value === 'string' && isoDateStringRegexPattern.exec(value)
    ? new Date(value)
    : value;
}

export function hydrateWithDate<T>(data: T): T {
  const stringified = JSON.stringify(data);
  return JSON.parse(stringified, jsonParserWithDate);
}

export function formatTimestamp(timestamp: Date): string {
  return format(timestamp, 'M/d H:mm');
}

// Date => '2010-01-02'
export function toIsoDateString(date: Date): string {
  return format(date, 'yyyy-MM-dd');
}

// '2010-01-02' => Date
export function fromIsoDateString(isoDateString: string): Date {
  const date = parseISO(isoDateString);
  if (isNaN(date.getTime())) {
    throw new Error('invalid date');
  }
  return date;
}

// Date => '01/02/2010'
export function toUsDateString(date: Date): string {
  return format(date, 'yyyy/MM/dd');
}

// '01/02/2010' => Date
export function fromUsDateString(usDateString: string): Date {
  const date = parse(usDateString, 'yyyy/MM/dd', new Date());
  if (isNaN(date.getTime())) {
    throw new Error('invalid date');
  }
  return date;
}

export function move<T>(
  oldItems: T[],
  fromIndex: number,
  toIndex: number,
): T[] {
  const items = [...oldItems];
  const item = items[fromIndex];
  items.splice(fromIndex, 1);
  items.splice(toIndex, 0, item);
  return items;
}

// random 6-digit hex code like 'c92a82'
// lowerBound and upperBound (0 ~ 1) to control the range
export function randomColorHex(
  lowerBound = 0,
  upperBound = 1,
  seed = '',
): string {
  const rgb = seed // 0 ~ 1
    ? [
        randomBetween01(seed.slice(0, seed.length / 3)),
        randomBetween01(seed.slice(seed.length / 3, (seed.length * 2) / 3)),
        randomBetween01(seed.slice((seed.length * 2) / 3)),
      ]
    : [Math.random(), Math.random(), Math.random()];
  // lower bound ~ upper bound
  const scaled = rgb.map(
    (color) => lowerBound + color * (upperBound - lowerBound),
  );
  // hex code for each of RGB
  const hexCodes = scaled.map((point) =>
    Math.floor(255 * point)
      .toString(16)
      .padStart(2, '0'),
  );
  return hexCodes.join('').toUpperCase();
}

function randomBetween01(text: string): number {
  // 32-bit integer
  const hash = text.split('').reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
  return Math.abs(hash / (Math.pow(2, 31) - 1)); // 0 ~ 1
}

export function truncateText(text: string, length: number): string {
  if (text.length > length) {
    const head = Math.floor(length / 2) - 1;
    const tail = text.length - head - (length % 2 === 0 ? 0 : 1);
    return `${text.slice(0, head - 1)}...${text.slice(tail)}`;
  }
  return text;
}

export function isEscapeKey(event: KeyboardEvent): boolean {
  const { key } = event;
  return key === 'Esc' || key === 'Escape';
}

export function getAppOrigin(): string {
  return window.location.origin;
}

export function toggleSet<T>(set: Set<T>, item: T): Set<T> {
  return set.has(item)
    ? new Set([...set].filter((x) => x !== item))
    : new Set([...set, item]);
}

export function downloadFile(fileName: string, data: string): void {
  const fileData = encodeURI(`data:text/csv;charset=utf-8,${data}`);
  const link = document.createElement('a');
  link.setAttribute('href', fileData);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
}

export async function readFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target?.result;
      if (data) {
        resolve(data as string);
      } else {
        reject(data);
      }
    };
    reader.readAsText(file);
  });
}

export function scrollToTop(): void {
  window.scrollTo(0, 0);
}

export async function copyToClipboard(text: string): Promise<void> {
  try {
    await window.navigator.clipboard.writeText(text);
  } catch (_) {
    //TODO add logging error
  }
}

export function keyToMap<T, K extends keyof T>(
  items: T[],
  key: K,
): Map<T[K], T> {
  return new Map(items.map((x) => [x[key], x]));
}

export function keyValueToMap<T, K extends keyof T, V extends keyof T>(
  items: T[],
  key: K,
  value: V,
): Map<T[K], T[V]> {
  return new Map(items.map((x) => [x[key], x[value]]));
}

export function groupToMap<T>(items: T[], key: string): Map<string, T[]> {
  return new Map(Object.entries(groupBy(items, key)));
}

export function mapTruthy<T, K extends keyof T>(items: T[], key: K): T[K][] {
  return items.map((x) => x[key]).filter((x) => x);
}

export function replaceBy<T, K extends keyof T>(
  items: T[],
  newItem: T,
  key: K,
): T[] {
  return items.map((item) => (item[key] === newItem[key] ? newItem : item));
}

export function assertNever(x: unknown): never {
  throw new Error(`Unexpected code path with data: ${x}`);
}

// 'foo=bar&baz=42'
export function toQueryParams(data: Record<string, string>): string {
  return new URLSearchParams(data).toString();
}

export function cap(n: number, lower: number, upper: number): number {
  return Math.min(Math.max(n, lower), upper);
}

export function swap<T>(list: T[], fromIndex: number, toIndex: number): T[] {
  const cappedFromIndex = cap(fromIndex, 0, list.length - 1);
  const cappedToIndex = cap(toIndex, 0, list.length - 1);
  const result = [...list];
  const [item] = result.splice(cappedFromIndex, 1);
  return [
    ...result.slice(0, cappedToIndex),
    item,
    ...result.slice(cappedToIndex),
  ];
}

export function moveTo<T>(list: T[], item: T, toIndex: number): T[] {
  const fromIndex = list.indexOf(item);
  const cappedFromIndex = cap(fromIndex, 0, list.length - 1);
  const cappedToIndex = cap(toIndex, 0, list.length - 1);
  const result = [...list];
  result.splice(cappedFromIndex, 1);
  return [
    ...result.slice(0, cappedToIndex),
    item,
    ...result.slice(cappedToIndex),
  ];
}
