import { del, get, post, put } from './base.api';
import {
  File as FileEntity,
  FileData,
  FileId,
  FileUrlResponse,
  SingleFileResponse,
} from '@jaunt/api';

export async function getFile(fileId: FileId): Promise<FileEntity> {
  const { file } = await get<SingleFileResponse>(`/files/${fileId}`, {});
  return file;
}

export async function getFileDownloadUrl(fileId: FileId): Promise<string> {
  const { url } = await get<FileUrlResponse>(`/files/${fileId}/download`, {});
  return url;
}

export async function createFile(name: string): Promise<FileEntity> {
  const { file } = await post<SingleFileResponse, FileData>(
    '/files',
    {},
    { name },
  );
  return file;
}

export async function uploadFile(fileId: string, file: File): Promise<string> {
  const formData = new FormData();
  formData.append('file', file);
  const { url } = await post<FileUrlResponse, FormData>(
    `/files/${fileId}/upload`,
    {},
    formData,
  );
  return url;
}

export async function updateFile(
  fileId: FileId,
  data: FileData,
): Promise<FileEntity> {
  const { file } = await put<SingleFileResponse, FileData>(
    `/files/${fileId}`,
    {},
    data,
  );
  return file;
}

export async function deleteFile(fileId: FileId): Promise<FileEntity> {
  const { file } = await del<SingleFileResponse>(`/files/${fileId}`, {});
  return file;
}
