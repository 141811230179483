import { post, get } from './base.api';
import {
  SingleTripEmailAppResponse,
  TripEmailApp,
  MultipleTripEmailAppResponse,
  AppId,
  TripId,
} from '@jaunt/api';

export async function getTripEmailAppList(): Promise<TripEmailApp[]> {
  const { apps } = await get<MultipleTripEmailAppResponse>(
    '/trips-email-app',
    {},
  );
  return apps;
}

export async function getTripEmailAppById(appId: AppId): Promise<TripEmailApp> {
  const { app } = await get<SingleTripEmailAppResponse>(
    `/trips-email-app/${appId}`,
    {},
  );
  return app;
}

export async function saveTripEmailApp(data: {
  tripId: TripId;
  isGeneratedTrip: boolean;
}): Promise<TripEmailApp> {
  const { app } = await post<
    SingleTripEmailAppResponse,
    { tripId: TripId; isGeneratedTrip: boolean }
  >('/trips-email-app', {}, data);
  return app;
}

export async function deactivateTripEmailApps(
  appIds: AppId[],
): Promise<TripEmailApp[]> {
  const { apps } = await post<
    MultipleTripEmailAppResponse,
    { appIds: AppId[] }
  >('/trips-email-app/deactivate', {}, { appIds });
  return apps;
}
