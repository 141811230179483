import { useMutation, useQueryClient } from 'react-query';
import QueryKeys from './constants/query-keys';

export function getLastModifiedActivityDay(): string | undefined {
  const client = useQueryClient();
  return client.getQueryData(QueryKeys.lastModifiedActivityDay);
}

export const useModifyLastActivityDay = () => {
  const client = useQueryClient();
  const { mutateAsync } = useMutation(async (day: string | undefined) => {
    client.setQueryData(QueryKeys.lastModifiedActivityDay, day);
  });
  return { modifyLastActivityDay: mutateAsync };
};
