import { FavoriteTrip, TripId } from '@jaunt/api';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import QueryKeys from './constants/query-keys';

import {
  listFavoriteTrips,
  getFavoriteTrip,
  createFavoriteTrip,
  deleteFavoriteTrip,
} from '../services/trip.api';

export function useFavoriteTrips(): UseQueryResult<FavoriteTrip[], unknown> {
  return useQuery<FavoriteTrip[]>(QueryKeys.favoriteTrips, listFavoriteTrips, {
    onError(error) {
      // tslint:disable-next-line:no-console
      console.log('Error loading FavoriteTrips: ', error); // eslint-disable-line no-console
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: 3,
    retryDelay: 2000,
  });
}

export function useFavoriteTrip(
  tripId?: TripId | null,
): UseQueryResult<FavoriteTrip | undefined, unknown> {
  return useQuery<FavoriteTrip | undefined>(
    [QueryKeys.favoriteTrips, tripId],
    async () => {
      return tripId ? await getFavoriteTrip(tripId) : undefined;
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading FavoriteTrip: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!tripId,
    },
  );
}

export const useCreateFavTrip = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({ tripId }: { tripId: TripId }) => {
      return await createFavoriteTrip({ tripId });
    },
    {
      onSuccess: () => {
        client.invalidateQueries(QueryKeys.favoriteTrips);
      },
    },
  );
  return { createFavTrip: mutateAsync, isLoading, isSuccess, isError };
};

export const useDeleteFavTrip = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({ tripId }: { tripId: TripId }) => {
      await deleteFavoriteTrip(tripId);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(QueryKeys.favoriteTrips);
      },
    },
  );
  return { deleteFavTrip: mutateAsync, isLoading, isSuccess, isError };
};
