import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/lab';
import { JauntTheme } from './settings/jaunt-theme';
import { initializeFirebase } from './settings/firebase-config';
import JauntRoutes from './routes';
import { PageContext } from './hooks/page.context';
import { queryClient } from './settings/query-client';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

initializeFirebase();

export function App(): JSX.Element {
  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <PageContext>
          <JauntTheme>
            <JauntRoutes />
          </JauntTheme>
        </PageContext>
      </LocalizationProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
