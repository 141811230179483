import Icon from '@mdi/react';
import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  icon: string; // icon path imported from @mdi/js
}

export function MdiIcon(props: Props): JSX.Element {
  const { icon, ...otherProps } = props;

  return (
    <SvgIcon {...otherProps}>
      <Icon path={icon} />
    </SvgIcon>
  );
}
