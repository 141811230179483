// import { yupResolver } from '@hookform/resolvers/yup';
// import { useForm } from 'react-hook-form';
// import * as yup from 'yup';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Stack } from '@mui/material';
import { useMemo, useRef, useState } from 'react';
import { LogoHeader } from '../components/LogoHeader';
import { Tex } from '../components/shared/Tex';
import { PRIMARY_COLOR } from '../settings/jaunt-theme';
// import { mdiArrowRight, mdiFormatQuoteOpen } from '@mdi/js';
// import { noop } from 'lodash';
import { LandingImageCard } from '../components/LandingImageCard';
import { useCitiesWithImageUrl } from '../hooks/use-cities-with-image-url';
import { PlanTripDialog } from '../components/plan-trip-dialog/PlanTripDialog';
import { useCuratedTripsWithImageUrl } from '../hooks/use-curated-trips-with-image-url';
import { Footer } from '../components/Footer';
// import { EmailCollector } from '../components/EmailCollector';
import { common } from '@mui/material/colors';
import { useCities } from '../hooks/use-cities';
import styled from '@emotion/styled';
import { CityInputAutocomplete } from '../components/CityInputAutocomplete';
import { City, CityDetailsGPT } from '@jaunt/api';
import {
  useCreateFavTrip,
  useDeleteFavTrip,
  useFavoriteTrips,
} from '../hooks/use-favorite-trips';
import {
  useCreateFavCity,
  useDeleteFavCity,
  useFavoriteCities,
} from '../hooks/use-favorite-cities';

// const steps = [
//   {
//     subject: 'Tell us your travel preferences',
//     description: "How many days, what's your budget - that type of stuff.",
//   },
//   {
//     subject: 'Get personalized recommendations',
//     description:
//       'Browse a curated list of the best restaurants, bars, hotels, and activities.',
//   },
//   {
//     subject: 'Build your perfect trip',
//     description:
//       'Easily organize, save, and share your daily itinerary with your crew.',
//   },
// ];

// const resolver = yupResolver(
//   yup.object({
//     email: yup.string().email('Must be a valid email').required('Required'),
//   }),
// );

// interface FormValues {
//   email: string;
// }

export function LandingPage(): JSX.Element {
  // const { register, handleSubmit, formState } = useForm<FormValues>({
  //   resolver,
  // });
  const landingImageRef = useRef<HTMLDivElement>(null);
  const { data: cities } = useCities();
  const { data: citiesWithImageUrl } = useCitiesWithImageUrl(cities);
  const [isQuizDialogOpen, setIsQuizDialogOpen] = useState(false);
  const [initialCityGpt, setInitialCityGpt] = useState<CityDetailsGPT>();
  const [initialCity, setInitialCity] = useState<City>();
  const [initialQuizStep, setInitialQuizStep] = useState<number>(0);
  // const [isEmailCollectorOpen, setIsEmailCollectorOpen] = useState(true);
  const { data: tripsWithImageUrl } = useCuratedTripsWithImageUrl();
  // const errorMessage = formState.errors.email?.message;

  // async function onSubmit(formValues: FormValues): Promise<void> {
  //   const { email } = formValues;
  //   noop(email);
  // }

  const { data: favoriteTrips } = useFavoriteTrips();
  const { createFavTrip } = useCreateFavTrip();
  const { deleteFavTrip } = useDeleteFavTrip();
  const { data: favoriteCities } = useFavoriteCities();
  const { createFavCity } = useCreateFavCity();
  const { deleteFavCity } = useDeleteFavCity();

  const favTripsObj = useMemo(() => {
    if (favoriteTrips) {
      return favoriteTrips.reduce((acc: Record<string, boolean>, item) => {
        acc[item.tripId] = true;
        return acc;
      }, {});
    }
    return {};
  }, [favoriteTrips]);

  const favCitiesObj = useMemo(() => {
    if (favoriteCities) {
      return favoriteCities.reduce((acc: Record<string, boolean>, item) => {
        acc[item.cityId] = true;
        return acc;
      }, {});
    }
    return {};
  }, [favoriteCities]);

  async function handleToggleFavCities(cityId: string, isNewFav: boolean) {
    if (isNewFav) {
      await createFavCity({ cityId });
    } else {
      await deleteFavCity({ cityId });
    }
  }
  async function handleToggleFavTrips(tripId: string, isNewFav: boolean) {
    if (isNewFav) {
      await createFavTrip({ tripId });
    } else {
      await deleteFavTrip({ tripId });
    }
  }

  async function handleSelectCity(city: City) {
    setInitialCity(city);
    setInitialQuizStep(1);
    setIsQuizDialogOpen(true);
  }
  async function handleSelectGptCity(gptCity: CityDetailsGPT) {
    setInitialCityGpt(gptCity);
    setInitialQuizStep(6);
    setIsQuizDialogOpen(true);
  }

  return (
    <Stack sx={{ width: '100%' }}>
      <LogoHeader sticky showAuthLinks />
      <Stack
        ref={landingImageRef}
        justifyContent="center"
        spacing={6}
        sx={{
          p: 4,
          paddingTop: 10,
          height: '70vh',
          backgroundImage: 'url(/landing.jpeg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Stack>
          <Tex
            component="h1"
            variant="h4"
            color={PRIMARY_COLOR}
            fontSize={55}
            fontFamily="canela-bold"
          >
            Jauntmore
          </Tex>
          <Tex fontSize={20} sx={{ color: common.black }}>
            The fastest way to plan your perfect trip.
          </Tex>
          <CitySearchBlock>
            <CityInputAutocomplete
              onSelectCity={handleSelectCity}
              onSelectGptCity={handleSelectGptCity}
            />
          </CitySearchBlock>
        </Stack>
        {/* TODO uncomment when ChatGPT be ready*/}
        {/*<Stack spacing={2} sx={{ paddingTop: 14 }}>*/}
        {/*  <Tex fontSize={16} fontWeight={300} sx={{ color: common.black }}>*/}
        {/*    Use our JAUNT BUILDER tool to plan, save, and book your travel, all*/}
        {/*    on one platform.*/}
        {/*  </Tex>*/}
        {/*  <Box sx={{ margin: 0 }}>*/}
        {/*    <Button*/}
        {/*      variant="contained"*/}
        {/*      onClick={() => setIsPlanTripDialogOpen(true)}*/}
        {/*      sx={{ px: 3, py: 1, borderRadius: '9999px', margin: 0 }}*/}
        {/*    >*/}
        {/*      TRY JAUNT BUILDER*/}
        {/*    </Button>*/}
        {/*  </Box>*/}
        {/*</Stack>*/}
      </Stack>
      {/* <Stack spacing={4} sx={{ p: 4 }}>
        <Tex fontSize={24} fontWeight={700} align="center">
          We&apos;re on a mission to make travel easier, for everyone.
        </Tex>
        <Tex align="center" fontSize={18}>
          Here&apos;s how it works:
        </Tex>
        <Stack>
          {steps.map((step, index) => (
            <Stack
              key={step.subject}
              direction="row"
              spacing={1}
              sx={{ position: 'relative' }}
            >
              {/* the connection line between steps */}
      {/* <Box
                sx={{
                  borderLeft: `1px solid ${PRIMARY_COLOR}`,
                  position: 'absolute',
                  left: 20,
                  height: '100%',
                  ...(index === steps.length - 1 && { opacity: 0 }),
                }}
              >
                <Tex> </Tex>
              </Box>
              <Box>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    width: 24,
                    height: 24,
                    borderRadius: '50%',
                    backgroundColor: PRIMARY_COLOR,
                    color: common.white,
                  }}
                >
                  <Tex fontWeight={700} zIndex={0}>
                    {index + 1}
                  </Tex>
                </Stack>
              </Box>
              <Box sx={{ pb: 2 }}>
                <Tex fontWeight={700}>{step.subject}</Tex>
                <Tex fontWeight={300}>{step.description}</Tex>
              </Box>
            </Stack>
          ))}
        </Stack>
        <Link component={RouterLink} to="/">
          <Tex fontWeight={700} align="center">
            GET STARTED
          </Tex>
        </Link>
      </Stack> */}
      {/* <Stack spacing={2} sx={{ p: 4, backgroundColor: common.white }}>
        <Stack alignItems="center">
          <MdiIcon icon={mdiFormatQuoteOpen} sx={{ fontSize: 50 }} />
        </Stack>
        <Stack spacing={4}>
          <Tex align="center" fontWeight={500}>
            I LOVE JAUNT. Goodbye to all the tabs, windows, and spreadsheets I
            used to use. This is way easier.
          </Tex>
          <Tex align="center" fontWeight={300}>
            Brenda, NY, 33 yrs old
          </Tex>
          <Stack alignItems="center">
            <Button
              variant="contained"
              onClick={() => setIsPlanTripDialogOpen(true)}
              sx={{ px: 6, py: 1, borderRadius: 9999 }}
            >
              PLAN MY TRIP
            </Button>
          </Stack>
        </Stack>
      </Stack> */}
      <ContentBlock>
        <Stack spacing={2} sx={{ padding: 2 }}>
          <Tex
            fontSize={24}
            fontWeight={700}
            align="center"
            color="primary"
            fontFamily="canela-bold"
            sx={{ paddingTop: 2 }}
          >
            Curated Trips
          </Tex>
          <Tex align="center" fontWeight={300} sx={{ paddingLeft: 0 }}>
            Short on time? Skip the hunting with our curated trips: daily
            itineraries vetted by travel experts who know all the best places to
            go and things to do.
          </Tex>
        </Stack>
        <Stack spacing={2} sx={{ paddingLeft: 2, paddingTop: 2 }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{ overflowX: 'auto', height: '400px' }}
          >
            {tripsWithImageUrl
              ?.filter(({ trip }) => !trip.isHidden)
              .map((tripWithImageUrl) => (
                <LandingImageCard
                  key={tripWithImageUrl.trip.tripId}
                  id={tripWithImageUrl.trip.tripId}
                  title={tripWithImageUrl.trip.name}
                  tip={`${
                    Object.keys(tripWithImageUrl.trip.detail.days)?.length ||
                    '0'
                  }-Day Jaunt`}
                  description={`Curated by ${tripWithImageUrl.trip.detail.createdBy}`}
                  imageUrl={tripWithImageUrl.imageUrl}
                  routePath={`/trips/${tripWithImageUrl.trip.tripPath}?isCurated=true`}
                  isFavorite={favTripsObj[tripWithImageUrl.trip.tripId]}
                  onToggleFavorite={handleToggleFavTrips}
                />
              ))}
          </Stack>
          <Link component={RouterLink} to="/explore/trips">
            <Tex fontSize={14}>
              <b>EXPLORE ALL TRIPS</b>
            </Tex>
          </Link>
        </Stack>
        <Stack spacing={2} sx={{ paddingBottom: 2, paddingTop: 4 }}>
          <Tex
            fontSize={24}
            fontWeight={700}
            align="center"
            color="primary"
            fontFamily="canela-bold"
          >
            City Guides
          </Tex>
          <Tex align="center" fontWeight={300}>
            A one-stop-shop for travel tips and curated recommendations for top
            destinations around the world. No tourist traps or ads, just the
            good stuff.
          </Tex>
        </Stack>
        <Stack spacing={2} sx={{ paddingLeft: 2, paddingBottom: 2 }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{ overflowX: 'auto', height: '400px' }}
          >
            {citiesWithImageUrl
              ?.filter(({ city }) => !city.isHidden)
              .map((cityWithImageUrl) => (
                <LandingImageCard
                  key={cityWithImageUrl.city.cityId}
                  id={cityWithImageUrl.city.cityId}
                  title={cityWithImageUrl.city.name}
                  description={cityWithImageUrl.city.state}
                  imageUrl={cityWithImageUrl.imageUrl}
                  routePath={`/cities/${cityWithImageUrl.city.cityPath}`}
                  isFavorite={favCitiesObj[cityWithImageUrl.city.cityId]}
                  onToggleFavorite={handleToggleFavCities}
                />
              ))}
          </Stack>
          <Link component={RouterLink} to="/explore/cities">
            <Tex fontSize={14}>
              <b>EXPLORE ALL CITIES</b>
            </Tex>
          </Link>
        </Stack>
      </ContentBlock>
      {/* <Stack spacing={4} sx={{ p: 4, backgroundColor: PRIMARY_COLOR }}>
        <Stack spacing={2}>
          <Tex
            fontSize={24}
            fontWeight={700}
            align="center"
            fontFamily="canela-bold"
            sx={{ color: common.white }}
          >
            The Jaunt
          </Tex>
          <Tex align="center" fontWeight={300} sx={{ color: common.white }}>
            Our favorite things, in our favorite places.
          </Tex>
          <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
            <Stack
              direction="row"
              sx={{ px: 1, backgroundColor: common.white, borderRadius: 9999 }}
            >
              <TextField
                {...register('email')}
                name="email"
                size="small"
                placeholder="Enter your email"
                fullWidth
                sx={{ '& fieldset': { border: 'none' } }}
              />
              <IconButton type="submit" size="small" disableRipple>
                <MdiIcon icon={mdiArrowRight} />
              </IconButton>
            </Stack>
            {errorMessage && (
              <Tex variant="caption" color="error" align="center">
                {errorMessage}
              </Tex>
            )}
          </Stack>
        </Stack>
        <Divider sx={{ backgroundColor: common.white, borderWidth: 1 }} /> */}
      {citiesWithImageUrl ? (
        <PlanTripDialog
          isOpen={isQuizDialogOpen}
          initialCityGpt={initialCityGpt}
          initialCity={initialCity}
          initialStep={initialQuizStep}
          citiesWithImageUrl={citiesWithImageUrl}
          onClose={() => setIsQuizDialogOpen(false)}
        />
      ) : null}
      {/* <EmailCollector
        isOpen={isEmailCollectorOpen}
        onClose={() => setIsEmailCollectorOpen(false)}
      /> */}
      {/* <Stack direction="row" sx={{ color: common.white }}>
          <Stack spacing={2} sx={{ width: '50%' }}>
            <Tex fontSize={14} fontWeight={700}>
              BRAND
            </Tex>
            <Tex fontSize={14} fontWeight={300}>
              Our Story
            </Tex>
            <Tex fontSize={14} fontWeight={300}>
              Contact
            </Tex>
          </Stack>
          <Stack spacing={2} sx={{ width: '50%' }}>
            <Tex fontSize={14} fontWeight={700}>
              INFORMATION
            </Tex>
            <Tex fontSize={14} fontWeight={300}>
              Privacy Policy
            </Tex>
            <Tex fontSize={14} fontWeight={300}>
              Terms of Service
            </Tex>
          </Stack>
        </Stack>
        <Stack direction="row" sx={{ color: common.white }}>
          <Tex fontSize={14} fontWeight={300}>
            {' '}
            © 2022 Jaunt Travel App Inc. All rights reserved.
          </Tex>
        </Stack> */}
      <Footer />
    </Stack>

    // </Stack>
  );
}

// const ImageCardContainer = styled(Stack)`
//   height: 400px;
// `;

const ContentBlock = styled(Stack)`
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
`;

const CitySearchBlock = styled(Stack)`
  margin-top: 22px;
  width: 100%;
  max-width: 370px;
`;
