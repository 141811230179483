import { del, get, post, put } from './base.api';
import {
  ReviewId,
  SinglePlaceReviewResponse,
  MultiplePlaceReviewResponse,
  PlaceId,
  PlaceReviewData,
  SinglePlaceReviewsObjResponse,
  ReviewObj,
} from '@jaunt/api';
import { PlaceReview } from '@jaunt/api';

export async function listPlaceReviews(): Promise<PlaceReview[]> {
  const { placeReviews } = await get<MultiplePlaceReviewResponse>(
    '/place-reviews',
    {},
  );
  return placeReviews;
}

export async function createPlaceReview(
  data: PlaceReviewData,
): Promise<PlaceReview> {
  const { placeReview } = await post<
    SinglePlaceReviewResponse,
    PlaceReviewData
  >('/place-reviews', {}, data);
  return placeReview;
}

export async function getPlaceReviews(placeId: PlaceId): Promise<ReviewObj[]> {
  const { reviewObjs } = await get<SinglePlaceReviewsObjResponse>(
    `/place-reviews/places/${placeId}/reviews`,
    {},
  );
  return reviewObjs;
}

export async function updatePlaceReview(
  placeId: PlaceId,
  reviewId: ReviewId,
  data: PlaceReviewData,
): Promise<PlaceReview> {
  const { placeReview } = await put<SinglePlaceReviewResponse, PlaceReviewData>(
    `/place-reviews/places/${placeId}/reviews/${reviewId}`,
    {},
    data,
  );
  return placeReview;
}

export async function deletePlaceReview(
  placeId: PlaceId,
  reviewId: ReviewId,
): Promise<PlaceReview> {
  const { placeReview } = await del<SinglePlaceReviewResponse>(
    `/place-reviews/places/${placeId}/reviews/${reviewId}`,
    {},
  );
  return placeReview;
}

export async function deleteAllPlaceReviews(
  placeId: PlaceId,
): Promise<PlaceReview[]> {
  const { placeReviews } = await del<MultiplePlaceReviewResponse>(
    `/place-reviews/places/${placeId}`,
    {},
  );
  return placeReviews;
}
