import React from 'react';
import { Box, LinearProgress, Stack } from '@mui/material';
import { Tex } from '../shared/Tex';
import styled from '@emotion/styled';
import { PRIMARY_COLOR } from '../../settings/jaunt-theme';

const loading = [
  'Your perfect trip is on its way',
  'Searching for hot hotels',
  'Adding some incredible restaurants',
  'Throwing in some fun activities',
  "Almost there! (Rome wasn't built in a day)",
];

export const LoadingContainerGPT = (): JSX.Element | null => {
  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(3);
  const [loadingText, setLoadingText] = React.useState(loading[0]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const progressRef = React.useRef(() => {});

  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(3);
      } else {
        const diff = Math.random() * 3;
        const diff2 = Math.random() * 5;
        const newProgress = progress + diff;
        setProgress(newProgress);
        setBuffer(progress + diff + diff2);
        if (newProgress > 80) {
          setLoadingText(loading[4]);
          return;
        }
        if (newProgress > 60) {
          setLoadingText(loading[3]);
          return;
        }
        if (newProgress > 40) {
          setLoadingText(loading[2]);
          return;
        }
        if (newProgress > 20) {
          setLoadingText(loading[1]);
          return;
        }
      }
    };
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <Container>
      <Box
        margin="4px 0"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '32px',
          width: '100%',
        }}
      >
        <LoadingTex
          variant="h5"
          fontWeight={700}
          fontFamily="canela-bold"
          color={PRIMARY_COLOR}
        >
          {loadingText}...
        </LoadingTex>
        <LinearProgress
          variant="buffer"
          color="primary"
          value={progress}
          valueBuffer={buffer}
          sx={{
            width: '95%',
            margin: '0 auto',
          }}
        />
      </Box>
    </Container>
  );
};

const Container = styled(Stack)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  position: absolute;
  top: calc(50% - 50px);
`;

const LoadingTex = styled(Tex)`
  font-weight: bold;
  display: inline-block;
  font-size: 19px;
  clip-path: inset(0 1ch 0 0);
  animation: l 2s steps(4) infinite;

  @keyframes l {
    to {
      clip-path: inset(0 -0.4ch 0 0);
    }
  }
`;
