import { City, CityId } from '@jaunt/api';
import { listCities, toggleCityHidden } from '../services/city.api';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import QueryKeys from './constants/query-keys';

export function useCities(): UseQueryResult<City[], unknown> {
  return useQuery<City[]>(QueryKeys.cities, listCities, {
    onError(error) {
      // tslint:disable-next-line:no-console
      console.log('Error loading Cities: ', error); // eslint-disable-line no-console
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: 3,
    retryDelay: 2000,
  });
}

export const useToggleCityHidden = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({
      cityId,
      data,
    }: {
      cityId: CityId;
      data: { isHidden: boolean };
    }) => {
      return toggleCityHidden(cityId, data);
    },
    {
      onSuccess: (city) => {
        client.setQueryData([QueryKeys.cities, city.cityId], city);
        client.invalidateQueries(QueryKeys.cities);
        client.invalidateQueries(QueryKeys.citiesWithImageUrl);
      },
    },
  );
  return { toggleCityHidden: mutateAsync, isLoading, isSuccess, isError };
};
