import { Link, Stack } from '@mui/material';
import { LogoHeader } from '../components/LogoHeader';
import { Tex } from '../components/shared/Tex';
import { device, PRIMARY_COLOR } from '../settings/jaunt-theme';
import { Footer } from '../components/Footer';
import { TripCard } from 'src/components/TripCard';
import { useMe } from '../hooks/use-me';
import { useUserTrips } from '../hooks/use-user-trips';
import { LoadingContainer } from 'src/components/loading/LoadingContainer';
import { NotFoundContainer } from 'src/components/NotFoundContainer';
import styled from '@emotion/styled';
import { grey } from '@mui/material/colors';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useGeneratedTrips } from '../hooks/use-generated-trips';
import { TripGeneratedCard } from '../components/TripGeneratedCard';
import { useMemo } from 'react';

import {
  useCreateFavTrip,
  useDeleteFavTrip,
  useFavoriteTrips,
} from '../hooks/use-favorite-trips';

enum TripsType {
  MANUAL = 'manual',
  GENERATED = 'generated',
}

const tabs = [
  { key: TripsType.MANUAL, url: '/mytrips/manual', label: 'Jauntmore Curated' },
  {
    key: TripsType.GENERATED,
    url: '/mytrips/generated',
    label: 'ChatGPT Curated',
  },
];

export function MyTripsPage(): JSX.Element {
  const { tripsType } = useParams();
  const { data: userId, isLoading: isMeLoading } = useMe(true);
  const { data: userTrips, isLoading: isTripsLoading } = useUserTrips(userId);
  const { data: userGeneratedTrips, isLoading: isGeneratedTripsLoading } =
    useGeneratedTrips();

  const { data: favoriteTrips } = useFavoriteTrips();
  const { createFavTrip } = useCreateFavTrip();
  const { deleteFavTrip } = useDeleteFavTrip();

  const favTripIds = useMemo(() => {
    if (favoriteTrips?.length) {
      return favoriteTrips.reduce((acc: Record<string, boolean>, item) => {
        acc[item.tripId] = true;
        return acc;
      }, {});
    }
    return {};
  }, [favoriteTrips]);

  async function handleToggleFavTrips(tripId: string, isNewFav: boolean) {
    if (isNewFav) {
      await createFavTrip({ tripId });
    } else {
      await deleteFavTrip({ tripId });
    }
  }

  if (isTripsLoading || isGeneratedTripsLoading || isMeLoading) {
    return <LoadingContainer />;
  }

  return userId && userTrips ? (
    <>
      <LogoHeader showAuthLinks={true} />
      <Stack className="main">
        <Stack sx={{ mb: 2, width: '100%' }}>
          <ContentBlock spacing={1}>
            <Stack
              direction="row"
              justifyContent={'center'}
              borderBottom={`1px solid ${grey[400]}`}
            >
              {tabs.map((tab) => (
                <TabContainer
                  key={tab.key}
                  to={tab.url}
                  component={RouterLink}
                  underline="none"
                >
                  <Tex
                    textAlign="center"
                    sx={{
                      py: 0.5,
                      ...(tab.key === tripsType
                        ? {
                            borderBottom: `3px solid ${PRIMARY_COLOR}`,
                            fontWeight: 700,
                            color: PRIMARY_COLOR,
                          }
                        : {}),
                    }}
                  >
                    {tab.label}
                  </Tex>
                </TabContainer>
              ))}
            </Stack>
            {tripsType === TripsType.GENERATED ? (
              <Stack>
                <Title
                  align="center"
                  fontSize={24}
                  fontWeight={700}
                  color={PRIMARY_COLOR}
                  fontFamily="canela-bold"
                >
                  My Trips
                </Title>
                <TripWrapper>
                  {userGeneratedTrips?.map((trip) => (
                    <TripGeneratedCard key={trip.tripId} trip={trip} />
                  ))}
                </TripWrapper>
              </Stack>
            ) : (
              <Stack>
                <Title
                  align="center"
                  fontSize={24}
                  fontWeight={700}
                  color={PRIMARY_COLOR}
                  fontFamily="canela-bold"
                >
                  My Trips
                </Title>
                <TripWrapper>
                  {userTrips.map((trip) => (
                    <TripCard
                      key={trip.tripId}
                      tripId={trip.tripId}
                      isFavorite={favTripIds[trip.tripId]}
                      onToggleFavorite={handleToggleFavTrips}
                    />
                  ))}
                </TripWrapper>
              </Stack>
            )}
          </ContentBlock>
        </Stack>
      </Stack>
      <Footer />
    </>
  ) : (
    <NotFoundContainer />
  );
}

const ContentBlock = styled(Stack)`
  margin: 0 auto !important;
  padding: 8px;
  width: 100%;
  max-width: 1440px;

  ${device.tablet} {
    padding: 8px 24px 16px;
  }

  ${device.laptopN} {
    padding: 8px 38px 20px;
  }
`;

const TabContainer = styled(Link)`
  padding-left: 16px;
  padding-right: 16px;
  color: inherit;
  text-underline: none;
  width: ${100 / tabs.length}%;

  ${device.tablet} {
    width: ${50 / tabs.length}%;
  }

  ${device.laptopN} {
    width: ${50 / tabs.length}%;
  }
` as typeof Link;

const Title = styled(Tex)`
  margin: 8px;
`;

const TripWrapper = styled(Stack)`
  margin: 0 auto !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  ${device.tablet} {
    width: 648px;
    gap: 24px;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  ${device.laptopN} {
    width: 948px;
  }

  ${device.laptopN} {
    width: 948px;
  }

  ${device.desktop} {
    width: 1272px;
  }
`;
