import { Box, Button, Chip, Stack } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import {
  CityId,
  // Place,
  PlaceId,
  TripId,
  TripPlace,
  TripPlaceData,
} from '@jaunt/api';
import { useCreateTrip, useTrip } from 'src/hooks/use-trip';
import { device, PRIMARY_COLOR, size } from 'src/settings/jaunt-theme';
import { common } from '@mui/material/colors';
import { useCity } from 'src/hooks/use-city';
import { MyTripSection } from './my-trip-section/MyTripSection';
import { useReplaceTripPlaces, useTripPlaces } from 'src/hooks/use-trip-places';
import { LogInOrSignUpDialog } from './LogInOrSignUpDialog';
import { NEW_TRIP, NEW_TRIP_CITY_ID, NEW_TRIP_PLACE_IDS } from 'src/constants';
import { Tex } from './shared/Tex';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from 'src/services/local-storage.util';
import { useNavigate } from 'react-router-dom';
import { useMe } from 'src/hooks/use-me';
import { usePlacesByCity } from 'src/hooks/use-places-by-single-city';
import { useReplaceTripUsers } from 'src/hooks/use-trip-users';
import { useReplaceTripCity } from 'src/hooks/use-trip-city';
import { useQueryClient } from 'react-query';
import QueryKeys from 'src/hooks/constants/query-keys';
import { UserTripPlaceCard } from './UserTripPlaceCard';

const activityTags = ['Stay', 'Eat', 'Drink', 'Do'];

interface Props {
  cityId: CityId;
  tripId: TripId;
  isPreview: boolean;
}

export function PlanningTripSection({
  cityId,
  tripId,
  isPreview,
}: Props): JSX.Element {
  const navigate = useNavigate();
  const client = useQueryClient();
  const { data: trip } = useTrip(tripId);
  const { data: city } = useCity(cityId);
  const { data: places } = usePlacesByCity(city?.cityId);
  const { data: tripPlaces } = useTripPlaces(tripId);
  const [isMyTripOpen, setIsMyTripOpen] = useState(isPreview);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [placeIdsInTrip, setPlaceIdsInTrip] = useState(new Set<PlaceId>());
  const [chosenActivity, setChosenActivity] = useState<string | null>('Eat');
  const { data: userId } = useMe(true);
  const { replaceTripUsers } = useReplaceTripUsers();
  const { replaceTripCity } = useReplaceTripCity();
  const { replaceTripPlaces } = useReplaceTripPlaces();
  const { createTrip } = useCreateTrip();
  // const [isMobile, setIsMobile] = useState(false);
  // const [isTablet, setIsTablet] = useState(false);
  // const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    function handleResize() {
      // setIsMobile(window.innerWidth < 745);
      // setIsTablet(window.innerWidth >= 745 && window.innerWidth < 1024);
      // setIsDesktop(window.innerWidth >= 1024);
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // function groupPlacesInPairs(places: Place[]) {
  //   const result = [];
  //   for (let i = 0; i < places.length; i += 2) {
  //     result.push(places.slice(i, i + 2));
  //   }
  //   return result.reverse();
  // }

  // function groupPlacesInTrios(places: Place[]) {
  //   if (places.length < 3) {
  //     return [places];
  //   }
  //
  //   const result = [];
  //   for (let i = 0; i < places.length; i += 3) {
  //     result.push(places.slice(i, i + 3));
  //   }
  //   if (result.length > 0 && result[result.length - 1].length < 3) {
  //     const lastTrio = result.pop();
  //     if (lastTrio) {
  //       result.push([lastTrio[0], lastTrio[1]]);
  //       if (lastTrio[2]) {
  //         result.push([lastTrio[2]]);
  //       }
  //     }
  //   }
  //   return result.reverse();
  // }

  const placesInTrip = useMemo(
    () => places?.filter((place) => placeIdsInTrip.has(place.placeId)) ?? [],
    [places, placeIdsInTrip],
  );

  const placeIds = useMemo(
    () => places?.map((place) => place.placeId) ?? [],
    [places],
  );

  useEffect(() => {
    async function initialiseUser(userId: string) {
      const newTrip = getLocalStorageItem(NEW_TRIP);
      const { tripId } = await createTrip(newTrip);

      removeLocalStorageItem(NEW_TRIP);

      if (tripId && userId) {
        await replaceTripCity({
          tripId,
          data: { cityId },
        });
        await replaceTripUsers({
          tripId,
          data: [{ tripId, userId }],
        });

        const tripPlaceDatas: TripPlaceData[] = Array.from(placeIdsInTrip).map(
          (placeId: PlaceId) => ({
            tripId,
            placeId,
            day: null,
            order: null,
          }),
        );

        removeLocalStorageItem(NEW_TRIP);
        removeLocalStorageItem(NEW_TRIP_CITY_ID);
        await replaceTripPlaces({
          tripId,
          data: tripPlaceDatas,
        });
        removeLocalStorageItem(NEW_TRIP_PLACE_IDS);
        navigate(`/trips/${trip?.tripPath}`);
      }
    }

    if (userId && tripId === NEW_TRIP) {
      initialiseUser(userId);
    } else if (!userId && tripId === NEW_TRIP) {
      client.invalidateQueries(QueryKeys.me);
    }
  }, [userId, tripId]);

  useEffect(() => {
    if (tripId === NEW_TRIP) {
      const placeIds = getLocalStorageItem(NEW_TRIP_PLACE_IDS);
      setPlaceIdsInTrip(new Set(placeIds));
    } else if (tripPlaces?.length) {
      setPlaceIdsInTrip(new Set(tripPlaces.map((x) => x.placeId)));
    }
  }, [tripId, tripPlaces]);

  async function handlePlaceTripChange(placeId: PlaceId): Promise<void> {
    if (trip) {
      if (tripId === NEW_TRIP) {
        const placeIds = getLocalStorageItem(NEW_TRIP_PLACE_IDS) || [];
        let newPlaceIds;
        if (placeIds && placeIds.includes(placeId)) {
          newPlaceIds = placeIds.filter(
            (placeIdItem: PlaceId) => placeIdItem !== placeId,
          );
        } else {
          newPlaceIds = [...placeIds, placeId];
        }
        setLocalStorageItem(NEW_TRIP_PLACE_IDS, newPlaceIds);
        setPlaceIdsInTrip(new Set(newPlaceIds));
        setIsLoginOpen(true);
      } else {
        const existPlacesInTrip =
          tripPlaces?.reduce((acc: Record<string, TripPlace>, tripPlace) => {
            acc[tripPlace.placeId] = tripPlace;
            return acc;
          }, {}) ?? {};
        const newPlaceIdsInTrip = placeIdsInTrip.has(placeId)
          ? [...placeIdsInTrip].filter((x) => x !== placeId)
          : [...placeIdsInTrip, placeId];
        const { tripId } = trip;
        const tripPlaceDatas: TripPlaceData[] = newPlaceIdsInTrip.map(
          (placeId) => {
            if (existPlacesInTrip[placeId]) {
              return { ...existPlacesInTrip[placeId] };
            }
            return {
              tripId,
              placeId,
              day: null,
              order: null,
            };
          },
        );
        await replaceTripPlaces({ tripId, data: tripPlaceDatas });
        setPlaceIdsInTrip(new Set(newPlaceIdsInTrip));
      }
    }
  }

  const openMyTripPreview = () => {
    // TODO fix this functionality
    navigate(`/trips/${trip?.tripPath}?preview=true`);
    setIsMyTripOpen(true);
  };

  const closeMyTripPreview = () => {
    navigate(`/trips/${trip?.tripPath}`);
    setIsMyTripOpen(false);
  };

  return (
    <Stack sx={{ width: '100%', flex: 1 }}>
      {trip && (
        <>
          <Stack sx={{ my: 2 }} direction="column" alignItems="center">
            <Tex
              textAlign="center"
              fontFamily="Canela"
              fontSize="30px"
              sx={{ color: PRIMARY_COLOR, fontWeight: 700, lineHeight: '30px' }}
            >
              {trip.name}
            </Tex>
            <Tex
              fontSize={14}
              fontFamily="CircularStd-Book"
              sx={{ p: 1, fontWeight: 300, textAlign: 'center' }}
            >
              Browse recommendations and add activities to your trip.
            </Tex>
          </Stack>

          <Stack spacing={2} sx={{ position: 'relative', flex: 1 }}>
            <ChipContainer direction="row" spacing={1}>
              {activityTags.map((activity) => (
                <Chip
                  key={activity}
                  label={activity === null ? 'Unscheduled' : `${activity}`}
                  color={chosenActivity === activity ? 'primary' : 'default'}
                  onClick={() => setChosenActivity(activity)}
                  sx={
                    chosenActivity === activity
                      ? {}
                      : { backgroundColor: common.white }
                  }
                />
              ))}
            </ChipContainer>
            {places?.length ? (
              <TripsWrapper spacing={1.5}>
                {places.map((place, i) => (
                  <UserTripPlaceCard
                    key={place.placeId + i}
                    tripId={tripId}
                    placeId={place.placeId}
                    placeIds={placeIds}
                    emptyInfo={'none'}
                    isInTrip={placeIdsInTrip.has(place.placeId)}
                    onToggle={handlePlaceTripChange}
                    activity={chosenActivity}
                  />
                ))}
              </TripsWrapper>
            ) : null}
            {placeIdsInTrip.size > 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'sticky',
                  bottom: '16px',
                  left: 0,
                  width: '100%',
                  margin: 0,
                  background: 'rgba(0,0,0,0)',
                  paddingBottom: '15px',
                }}
              >
                <Button
                  variant="contained"
                  onClick={openMyTripPreview}
                  sx={{
                    px: 6,
                    py: 1,
                    borderRadius: '9999px',
                    width: '90%',
                    maxWidth: '230px',
                    fontSize: '16px',
                    lineHeight: '26px',
                  }}
                >
                  VIEW MY TRIP
                </Button>
              </Box>
            ) : null}
          </Stack>
        </>
      )}

      <Stack>
        {userId && placesInTrip?.length ? (
          <MyTripSection
            tripId={tripId}
            cityId={cityId}
            places={placesInTrip}
            isOpen={isMyTripOpen}
            userId={userId}
            onClose={closeMyTripPreview}
          />
        ) : null}
      </Stack>
      <LogInOrSignUpDialog
        isOpen={isLoginOpen}
        onClose={() => setIsLoginOpen(false)}
        tripId={tripId}
      />
    </Stack>
  );
}

const ChipContainer = styled(Stack)`
  padding: 0 9px;

  ${device.tablet} {
    align-self: center;
  }
`;

const TripsWrapper = styled(Stack)`
  margin: 30px auto 0 !important;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  width: 100%;

  ${device.tablet} {
    margin: 32px auto 0 !important;
    width: 674px;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 22px;
  }

  ${device.laptopN} {
    width: 100%;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    overflow-x: auto;
    margin: 36px auto 0 !important;
    padding: 0 36px;
    gap: 16px;
  }

  ${device.desktop} {
    max-width: ${size.desktop}px;
    padding: 0;
    gap: 21px;
  }
`;
