import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Stack, Box, Link, Dialog, IconButton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { common } from '@mui/material/colors';
import { Tex } from './shared/Tex';
import { LoadingButton } from '@mui/lab';
import { PRIMARY_COLOR } from '../settings/jaunt-theme';
import { FormTextField } from 'src/components/shared/FormTextField';
import { useState } from 'react';
import { translateFirebaseError } from '../services/firebase.util';
import { FirebaseError } from 'firebase/app';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'src/hooks/use-query';
import CloseIcon from '@mui/icons-material/Close';
import { TripId } from '@jaunt/api';
import { useRefetchUser, useSignUp } from '../hooks/use-me';
import { useCreateTrip } from '../hooks/use-trip';
import { useReplaceTripCity } from '../hooks/use-trip-city';
import { useReplaceTripUsers } from '../hooks/use-trip-users';
import { NEW_TRIP, NEW_TRIP_CITY_ID } from 'src/constants';
import { getLocalStorageItem } from 'src/services/local-storage.util';

interface Props {
  isOpen: boolean;
  tripId: TripId;
  onClose(): void;
}

const resolver = yupResolver(
  yup.object({
    email: yup.string().email('Enter a valid email').required('Required'),
    password: yup.string().min(8, 'Minimum 8 characters').required('Required'),
  }),
);

interface FormValues {
  email: string;
  password: string;
}

export function LogInOrSignUpDialog(props: Props): JSX.Element {
  const { isOpen, onClose } = props;
  const navigate = useNavigate();
  const query = useQuery();
  const email = query.get('email');
  const { register, handleSubmit, formState } = useForm<FormValues>({
    resolver,
  });
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { refetchUser } = useRefetchUser();
  const { signUp } = useSignUp();
  const { createTrip } = useCreateTrip();
  const { replaceTripCity } = useReplaceTripCity();
  const { replaceTripUsers } = useReplaceTripUsers();

  async function onSubmit(formValues: FormValues): Promise<void> {
    const { email, password } = formValues;
    setErrorMessage('');
    setIsSaving(true);
    try {
      await signUp({ email, password });
      const userId = await refetchUser();
      const newTrip = getLocalStorageItem(NEW_TRIP);
      const cityId = getLocalStorageItem(NEW_TRIP_CITY_ID);

      const createdTrip = await createTrip(newTrip);
      const { tripId } = createdTrip;
      await replaceTripCity({
        tripId,
        data: { cityId },
      });
      await replaceTripUsers({
        tripId,
        data: [{ tripId, userId }],
      });
      navigate(`/trips/${createdTrip.tripPath}`);
    } catch (e) {
      const message = translateFirebaseError(e as FirebaseError);
      setErrorMessage(message);
      setIsSaving(false);
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen
      sx={{
        '& .MuiPaper-root': {
          p: 2,
          borderRadius: '20px',
          position: 'relative',
          height: 'auto',
        },
        m: 2,
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          cursor: 'pointer',
          right: 4,
          top: 4,
          margin: 0,
          zIndex: 1,
          color: (theme) => theme.palette.primary.main,
        }}
      >
        <CloseIcon fontSize="large" />
      </IconButton>
      <Stack
        spacing={4}
        direction="column"
        alignItems="center"
        position="relative"
        sx={{ pt: 5, pb: 2 }}
      >
        <Tex
          fontSize={24}
          lineHeight="30px"
          fontWeight={700}
          align="center"
          fontFamily="CircularStd-Book"
          marginTop="16px"
          sx={{ color: common.black }}
        >
          Save your trip details by creating an account
        </Tex>
        <>
          <Stack
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ width: '90%', paddingBottom: 3, border: '1px' }}
          >
            <Stack
              direction="column"
              sx={{
                px: 1,
                py: 1,
                backgroundColor: common.white,
                '& .MuiBox-root': {
                  border: '1px solid #DADADA',
                  padding: '8px 12px',
                },
              }}
            >
              <FormTextField
                defaultValue={email}
                register={register}
                formState={formState}
                fieldName="email"
                label="Email"
                required
                sx={{
                  width: '100%',
                  '& fieldset': { border: '1px', borderColor: common.black },
                }}
              />
              <FormTextField
                register={register}
                formState={formState}
                fieldName="password"
                label="Password"
                type="password"
                required
                sx={{
                  width: '100%',
                  '& fieldset': { border: '1px', borderColor: common.black },
                }}
              />
            </Stack>
            {errorMessage && (
              <Tex variant="caption" color="error" align="center">
                {errorMessage}
              </Tex>
            )}
            <Box sx={{ py: 3 }}>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={isSaving}
                disabled={isSaving}
                sx={{
                  px: 1,
                  py: 1,
                  width: '100%',
                  height: '40px',
                  alignItems: 'center',
                  borderRadius: '20px',
                  backgroundColor: PRIMARY_COLOR,
                  fontSize: '16px',
                  lineHeight: '20px',
                  fontWeight: '700',
                  fontFamily: 'CircularStd-Book',
                }}
              >
                SAVE MY TRIP
              </LoadingButton>
            </Box>
            <Stack
              spacing={0.5}
              direction="row"
              sx={{
                alignItems: 'center',
                justifyContent: 'Center',
                gap: 0.5,
                px: 2,
                pt: 1,
              }}
            >
              <Tex
                align="center"
                fontWeight={300}
                fontSize={16}
                fontFamily="CircularStd-Light"
                sx={{ color: common.black, lineHeight: 1.5 }}
              >
                Already have an account?
              </Tex>
              <Link
                component={RouterLink}
                to={'/login?redirectTo=back'}
                underline="none"
              >
                <Tex
                  fontWeight={300}
                  fontSize={16}
                  fontFamily="CircularStd-Light"
                  sx={{ color: common.black, lineHeight: 1.5 }}
                >
                  <u>Login {'>'}</u>
                </Tex>
              </Link>
            </Stack>
          </Stack>
        </>
        {errorMessage && (
          <Tex variant="caption" color="error" align="center">
            {errorMessage}
          </Tex>
        )}
      </Stack>
    </Dialog>
  );
}
