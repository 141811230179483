import { City, CityDetailsGPT, SearchCityItem } from '@jaunt/api';
import { Stack, TextField } from '@mui/material';
import { common } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import styled from '@emotion/styled';
import { CityWithImageUrl } from 'src/services/city.util';
import { device, PRIMARY_COLOR } from 'src/settings/jaunt-theme';
import { getCityGpt, getCityListGpt } from 'src/services/chat-gpt';
import { CityCard } from '../CityCard';
import { Tex } from '../shared/Tex';
import { LoadingContainerAbsolute } from '../loading/LoadingContainerAbsolute';

function transformToCityItems(citiesWithImageUrl: CityWithImageUrl[]) {
  return citiesWithImageUrl.map((item) => ({
    cityId: item.city.cityId,
    name: item.city.name,
    state: item.city.state,
    country: item.city.country,
    imageUrl: item.imageUrl,
    isGptCity: false,
  }));
}

interface Props {
  citiesWithImageUrl: CityWithImageUrl[];
  onCityChange(city: City): void;
  onGptCityChange(gptCity: CityDetailsGPT): void;
}

export function PlanTripCityStep(props: Props): JSX.Element {
  const { citiesWithImageUrl, onCityChange, onGptCityChange } = props;
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [cityItems, setCityItems] = useState<SearchCityItem[]>([]);

  const getFilteredCities = debounce(async (event) => {
    const newValue = event.target.value;

    if (!newValue || !newValue?.trim()) {
      const initialCityList = transformToCityItems(citiesWithImageUrl ?? []);
      setCityItems(initialCityList);
      setIsLoading(false);
      setError(false);
      return;
    }
    setIsLoading(true);

    let gptCityItems: SearchCityItem[] = [];
    const newCitiesWithImage =
      citiesWithImageUrl?.filter((x) =>
        x.city.name.toLowerCase().includes(newValue.toLowerCase()),
      ) ?? [];
    const newCityList: SearchCityItem[] =
      transformToCityItems(newCitiesWithImage);
    const storedCityDictionary = newCityList.reduce(
      (acc: Record<string, boolean>, cityItem) => {
        acc[cityItem.name.toLowerCase()] = true;
        return acc;
      },
      {},
    );

    try {
      const controller = new AbortController();

      setTimeout(() => {
        controller.abort();
        setError(true);
      }, 40000);

      const { cityList } = await getCityListGpt(newValue, controller.signal);

      gptCityItems = cityList.map((item) => {
        const list = item.split(',');
        const cityName = list[0].trim();
        const cityState = list.slice(1).join(',').trim();
        return {
          cityId: cityName.toLowerCase(),
          name: cityName,
          state: cityState,
          country: cityState,
          imageUrl: undefined,
          isGptCity: true,
        };
      });
    } catch (_) {
      setError(true);
    }

    gptCityItems.forEach((item) => {
      if (!storedCityDictionary[item.cityId]) {
        storedCityDictionary[item.cityId] = true;
        newCityList.push(item);
      }
    });
    setCityItems(newCityList);
    setIsLoading(false);
    setError(false);
  }, 1000);

  async function onSelectCity(city: SearchCityItem) {
    if (city.isGptCity) {
      setIsLoading(true);
      setCityItems([]);
      const cityName = city.name;
      try {
        const controller = new AbortController();

        setTimeout(() => {
          controller.abort();
          setError(true);
        }, 30000);

        const newGptCity = await getCityGpt(
          cityName.toLowerCase(),
          controller.signal,
        );

        onGptCityChange({ ...newGptCity, name: cityName });
        setError(false);
      } catch (_) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    } else {
      if (citiesWithImageUrl?.length) {
        const selectedCityWithImageUrl = citiesWithImageUrl.find(
          (item) => item.city.cityId === city.cityId,
        );
        if (selectedCityWithImageUrl) {
          onCityChange(selectedCityWithImageUrl.city);
          setError(false);
        } else {
          setError(true);
        }
      }
    }
  }

  useEffect(() => {
    if (citiesWithImageUrl?.length && !isMounted) {
      const initialCityList = transformToCityItems(citiesWithImageUrl);
      setCityItems(initialCityList);
      setIsMounted(true);
    }
  }, [citiesWithImageUrl]);

  return (
    <Container spacing={2}>
      <Tex color="primary" textAlign="center" fontSize={20}>
        <b>What city do you want to visit?</b>
      </Tex>
      <TextField
        onChange={getFilteredCities}
        size="small"
        color="primary"
        sx={{ backgroundColor: common.white }}
        placeholder="Type full city name"
      />
      <CitiesContainer spacing={2}>
        {isLoading ? (
          <Stack
            sx={{
              position: 'relative',
              height: '70px',
              marginTop: '50px',
              width: '100%',
            }}
          >
            <LoadingContainerAbsolute />
          </Stack>
        ) : null}
        {error ? (
          <Stack sx={{ textAlign: 'center', width: '100%' }}>
            <Tex
              fontFamily="CircularStd-Book"
              fontSize={18}
              lineHeight={'20px'}
              sx={{ marginTop: '16px', color: PRIMARY_COLOR }}
            >
              Can&lsquo;t find your city, try please again
            </Tex>
          </Stack>
        ) : null}
        {!isLoading && !error && cityItems.length
          ? cityItems.map((cityItem) => (
              <CityCard
                key={cityItem.cityId}
                city={cityItem}
                imageUrl={cityItem.imageUrl}
                onClick={() => onSelectCity(cityItem)}
              />
            ))
          : null}
      </CitiesContainer>
    </Container>
  );
}

const Container = styled(Stack)`
  margin-top: -30px !important;
  padding: 28px 0 12px;
`;

const CitiesContainer = styled(Stack)`
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;

  ${device.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
