import {
  GenerateAutoTrip,
  GenerateGptCity,
  TripGenerated,
  TripGeneratedData,
} from '@jaunt/api';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import QueryKeys from './constants/query-keys';
import {
  createGeneratedTrip,
  getGeneratedTripByPath,
  getPublicGeneratedTripByPath,
  listGeneratedTrips,
} from '../services/trip-generated.api';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '../services/local-storage.util';

export function useGeneratedQuiz(tripPath: string | null) {
  return useQuery<GenerateGptCity | GenerateAutoTrip | null>(
    [QueryKeys.generatedQuiz, tripPath],
    async () => {
      return tripPath ? await getLocalStorageItem(tripPath) : undefined;
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading GeneratedTrip: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
    },
  );
}

export const useSaveGeneratedQuiz = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async (data: GenerateGptCity | GenerateAutoTrip) => {
      const { tripPath } = data;
      client.setQueryData([QueryKeys.generatedQuiz, tripPath], data);
      setLocalStorageItem(tripPath, data);
    },
  );
  return { createGeneratedTrip: mutateAsync, isLoading, isSuccess, isError };
};

export function useGeneratedTripLocal(tripPath: string | null) {
  return useQuery<TripGeneratedData | TripGenerated | null>(
    [QueryKeys.generatedTripLocal, tripPath],
    async () => {
      return tripPath
        ? await getLocalStorageItem(`trip-${tripPath}`)
        : undefined;
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading GeneratedTripLocal: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!tripPath,
    },
  );
}

export const useSaveGeneratedTripLocal = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async (data: TripGeneratedData | TripGenerated) => {
      const { tripPath } = data;
      client.setQueryData([QueryKeys.generatedTripLocal, tripPath], data);
      setLocalStorageItem(`trip-${tripPath}`, data);
    },
  );
  return { saveGeneratedTripLocal: mutateAsync, isLoading, isSuccess, isError };
};
export function useGeneratedTrips(): UseQueryResult<
  TripGenerated[] | undefined,
  unknown
> {
  return useQuery<TripGenerated[] | undefined>(
    QueryKeys.userGeneratedTrips,
    async () => {
      return listGeneratedTrips();
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading City: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
    },
  );
}
export function useGeneratedTrip(tripPath: string | null) {
  const client = useQueryClient();
  return useQuery<TripGenerated | undefined>(
    [QueryKeys.userGeneratedTrips, tripPath],
    async () => {
      const tripGenerated: TripGenerated | undefined = client.getQueryData([
        QueryKeys.userGeneratedTrips,
        tripPath,
      ]);
      if (tripGenerated) {
        return tripGenerated;
      }
      return tripPath ? await getGeneratedTripByPath(tripPath) : undefined;
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading GeneratedTripLocal: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!tripPath,
    },
  );
}

export const useCreateGeneratedTrip = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async (data: TripGeneratedData) => {
      return await createGeneratedTrip(data);
    },
    {
      onSuccess: (trip) => {
        client.setQueryData(
          [QueryKeys.userGeneratedTrips, trip.tripPath],
          trip,
        );
      },
    },
  );
  return { createGeneratedTrip: mutateAsync, isLoading, isSuccess, isError };
};

export function usePublicGeneratedTrip(tripPath: string | null) {
  return useQuery<TripGenerated | undefined>(
    [QueryKeys.publicGeneratedTrips, tripPath],
    async () => {
      return tripPath
        ? await getPublicGeneratedTripByPath(tripPath)
        : undefined;
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading PublicGeneratedTrip: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!tripPath,
    },
  );
}
