import {
  getCuratedTripsWithImageUrl,
  getUserTripsWithImageUrl,
  TripWithImageUrl,
} from '../services/trip.util';
import { useQuery, UseQueryResult } from 'react-query';
import QueryKeys from './constants/query-keys';

export function useCuratedTripsWithImageUrl(): UseQueryResult<
  TripWithImageUrl[],
  unknown
> {
  return useQuery<TripWithImageUrl[]>(
    QueryKeys.curatedTripsWithImageUrl,
    getCuratedTripsWithImageUrl,
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading CuratedTripsWithImageUrl: ', error); // eslint-disable-line no-console
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: 3,
      retryDelay: 2000,
    },
  );
}

export function useUserTripsWithImageUrl(): UseQueryResult<
  TripWithImageUrl[],
  unknown
> {
  return useQuery<TripWithImageUrl[]>(
    QueryKeys.tripsWithImageUrl,
    getUserTripsWithImageUrl,
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading UserTripsWithImageUrl: ', error); // eslint-disable-line no-console
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: 3,
      retryDelay: 2000,
    },
  );
}
