import { Box, Link, Stack } from '@mui/material';
import { Tex } from './shared/Tex';
import { Link as RouterLink } from 'react-router-dom';
import styled from '@emotion/styled';

export const NoTripIdContainer = (): JSX.Element | null => {
  return (
    <Container>
      <Box margin="4px 0">
        <Tex fontSize={30} fontWeight={700} fontFamily="canela-bold">
          No trip found
        </Tex>
      </Box>
      <Box margin="8px 0">
        <Link component={RouterLink} to="/">
          <Tex variant="h5" fontFamily="canela-bold">
            Go Home
          </Tex>
        </Link>
      </Box>
    </Container>
  );
};

const Container = styled(Stack)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  position: fixed;
  top: calc(50% - 100px);
`;
