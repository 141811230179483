import React, { Suspense } from 'react';
import { Box, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { LogoHeader } from '../components/LogoHeader';
import {
  AdminPageKey,
  AdminSideBar,
} from '../components/admin-pages/AdminSideBar';
const AdminPlaces = React.lazy(
  () => import('../components/admin-pages/AdminPlaces'),
);
const AdminCities = React.lazy(
  () => import('../components/admin-pages/admin-cities/AdminCities'),
);
const AdminNeighborhoods = React.lazy(
  () => import('../components/admin-pages/AdminNeighborhoods'),
);
const AdminTrips = React.lazy(
  () => import('../components/admin-pages/admin-trips/AdminTrips'),
);
const AdminTags = React.lazy(
  () => import('../components/admin-pages/AdminTags'),
);
const AdminSecondaryTags = React.lazy(
  () => import('../components/admin-pages/AdminSecondaryTags'),
);
const AdminReviews = React.lazy(
  () => import('../components/admin-pages/AdminReviews'),
);
const AdminEmailCollector = React.lazy(
  () => import('../components/admin-pages/AdminEmailCollector'),
);
const AdminPlaceReviews = React.lazy(
  () => import('../components/admin-pages/AdminPlaceReviews'),
);

const AdminItineraryApplications = React.lazy(
  () => import('../components/admin-pages/AdminItineraryApplications'),
);

const AdminSearchCityAnalytics = React.lazy(
  () => import('../components/admin-pages/AdminSearchCityAnalytics'),
);

export function AdminPage(): JSX.Element {
  const { pageKey } = useParams();

  return (
    <Box>
      <LogoHeader />
      <Stack direction="row">
        <AdminSideBar pageKey={pageKey} />
        <Box flexGrow="1" sx={{ p: 2 }}>
          {pageKey === AdminPageKey.CITIES && (
            <Suspense fallback={<div>Loading...</div>}>
              <AdminCities />
            </Suspense>
          )}
          {pageKey === AdminPageKey.NEIGHBORHOODS && (
            <Suspense fallback={<div>Loading...</div>}>
              <AdminNeighborhoods />
            </Suspense>
          )}
          {pageKey === AdminPageKey.PLACES && (
            <Suspense fallback={<div>Loading...</div>}>
              <AdminPlaces />
            </Suspense>
          )}
          {pageKey === AdminPageKey.TAGS && (
            <Suspense fallback={<div>Loading...</div>}>
              <AdminTags />
            </Suspense>
          )}
          {pageKey === AdminPageKey.SECONDARY_TAGS && (
            <Suspense fallback={<div>Loading...</div>}>
              <AdminSecondaryTags />
            </Suspense>
          )}
          {pageKey === AdminPageKey.TRIPS && (
            <Suspense fallback={<div>Loading...</div>}>
              <AdminTrips />
            </Suspense>
          )}
          {pageKey === AdminPageKey.PLACE_REVIEWS && (
            <Suspense fallback={<div>Loading...</div>}>
              <AdminPlaceReviews />
            </Suspense>
          )}
          {pageKey === AdminPageKey.REVIEWS && (
            <Suspense fallback={<div>Loading...</div>}>
              <AdminReviews />
            </Suspense>
          )}
          {pageKey === AdminPageKey.WAITLIST && (
            <Suspense fallback={<div>Loading...</div>}>
              <AdminEmailCollector />
            </Suspense>
          )}
          {pageKey === AdminPageKey.ITINERARY_APPLICATIONS && (
            <Suspense fallback={<div>Loading...</div>}>
              <AdminItineraryApplications />
            </Suspense>
          )}
          {pageKey === AdminPageKey.SEARCH_CITY_ANALYTICS && (
            <Suspense fallback={<div>Loading...</div>}>
              <AdminSearchCityAnalytics />
            </Suspense>
          )}
        </Box>
      </Stack>
    </Box>
  );
}
