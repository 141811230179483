import { useParams } from 'react-router-dom';
import { NoTripIdContainer } from '../components/NoTripIdContainer';
import { LogoHeader } from '../components/LogoHeader';
import { Box, Button, Chip, Collapse, Stack } from '@mui/material';
import { Tex } from 'src/components/shared/Tex';
import { common } from '@mui/material/colors';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import { device, ORANGE_COLOR, PRIMARY_COLOR } from 'src/settings/jaunt-theme';
import { MdiIcon } from 'src/components/shared/MdiIcon';
import styled from '@emotion/styled';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  useCreateGeneratedTrip,
  useGeneratedQuiz,
  useGeneratedTrip,
  useGeneratedTripLocal,
  useSaveGeneratedTripLocal,
} from 'src/hooks/use-generated-trips';

import {
  createItineraryGpt,
  getHotelInfoInCityGpt,
  getItineraryByID,
  sendTripByEmailGpt,
} from '../services/chat-gpt';
import {
  ActivityDayGPT,
  ActivityGPT,
  ActivityGPTResponse,
  CityDetailsGPTDetails,
  GenerateAutoTrip,
  GenerateGptCity,
  HotelGPT,
  TripGenerated,
  TripGeneratedCity,
  TripGeneratedCityDetail,
  TripGeneratedData,
  TripHotelType,
  TripVibe,
} from '@jaunt/api';
import { LoadingContainerGPT } from 'src/components/loading/LoadingContainerGPT';
import { EnterEmail } from 'src/components/EnterEmail';
import { Footer } from 'src/components/Footer';
import { LoadingButton } from '@mui/lab';
import { LoadingContainer } from '../components/loading/LoadingContainer';
import { EmailMyBookingTrip } from '../components/EmailMyBookingTrip';
import { PublicMyTrip } from '../components/PublicMyTrip';

interface CityDetail {
  title: string;
  text: string;
}

const getCityDetails = (cityDetails: CityDetailsGPTDetails): CityDetail[] => {
  const details = [];
  if (cityDetails?.weather) {
    details.push({
      title: 'When To Go.',
      text: cityDetails.weather,
    });
  }

  if (cityDetails?.lodging) {
    details.push({
      title: 'Where To Stay.',
      text: cityDetails.lodging,
    });
  }

  if (cityDetails?.traffic) {
    details.push({
      title: 'Getting Around.',
      text: cityDetails.traffic,
    });
  }

  if (cityDetails?.notes) {
    details.push({
      title: 'Tips And Tricks.',
      text: cityDetails.notes,
    });
  }

  return details;
};

const getActivityTags = ({
  hotels,
  activityList,
}: {
  hotels?: HotelGPT[];
  activityList?: ActivityGPT[];
}): string[] => {
  const tags = [];
  if (hotels?.length) {
    tags.push('Stay');
  }

  if (activityList?.length) {
    activityList.forEach((activity, index) => {
      tags.push(activity.day ?? `Activity ${index + 1}`);
    });
  }

  return tags;
};

function generatedInitialTrip(
  quiz: GenerateGptCity | GenerateAutoTrip,
): TripGeneratedData {
  let description = '';
  let name = '';
  let detail: TripGeneratedCityDetail = {};

  if ('city' in quiz) {
    description = quiz.city.description;
    name = quiz.name;
    detail = quiz.city.detail;
  } else if ('gptCity' in quiz) {
    description = quiz.gptCity.description;
    name = quiz.name;
    detail = quiz.gptCity.detail;
  }

  const city: TripGeneratedCity = { name, description, detail };

  return {
    name: quiz.name,
    tripPath: quiz.tripPath,
    city: city,
    days: quiz.days,
    vibe: quiz.vibe,
    hotelType: quiz.hotelType,
    details: {},
  };
}

function processGeneratedTrip({
  quiz,
  hotels,
  activityList,
}: {
  quiz: GenerateGptCity | GenerateAutoTrip;
  hotels?: HotelGPT[];
  activityList?: ActivityGPT[];
}): TripGeneratedData {
  let description = '';
  let name = '';
  let detail: TripGeneratedCityDetail = {};

  if ('city' in quiz) {
    description = quiz.city.description;
    name = quiz.name;
    detail = quiz.city.detail;
  } else if ('gptCity' in quiz) {
    description = quiz.gptCity.description;
    name = quiz.name;
    detail = quiz.gptCity.detail;
  }

  const city: TripGeneratedCity = { name, description, detail };

  return {
    name: quiz.name,
    tripPath: quiz.tripPath,
    city: city,
    days: quiz.days,
    vibe: quiz.vibe,
    hotelType: quiz.hotelType,
    details: {
      hotels: hotels,
      activities: activityList,
    },
  };
}

export function GeneratedTripsPage(): JSX.Element {
  const params = useParams();
  const tripPath = params.tripPath || null;
  const [isExpanded, setIsExpanded] = useState(true);
  const [chosenActivity, setChosenActivity] = useState<string | undefined>(
    undefined,
  );
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [tripLocal, setTripLocal] = useState<
    TripGeneratedData | TripGenerated
  >();
  const isCalledRef = useRef(false);
  const isQuizMountedRef = useRef(false);
  const { data: generatedTripLocal, isLoading: isLocalLoading } =
    useGeneratedTripLocal(tripPath);
  const { data: generatedTrip, isLoading: isTripLoading } =
    useGeneratedTrip(tripPath);
  const { data: quiz } = useGeneratedQuiz(tripPath);
  const { saveGeneratedTripLocal } = useSaveGeneratedTripLocal();
  const { createGeneratedTrip } = useCreateGeneratedTrip();

  useEffect(() => {
    if (quiz && !isQuizMountedRef.current) {
      isQuizMountedRef.current = true;
      setTripLocal(generatedInitialTrip(quiz));
    }
  }, [quiz]);

  useEffect(() => {
    if (isCalledRef.current) {
      isCalledRef.current = false;
    }
  }, [tripPath]);

  useEffect(() => {
    if (generatedTripLocal) {
      setTripLocal(generatedTripLocal);
      return;
    }

    if (isTripLoading) {
      return;
    }

    if (generatedTrip) {
      setTripLocal(generatedTrip);
      saveGeneratedTripLocal(generatedTrip);
      return;
    }

    if (quiz && !isCalledRef.current) {
      isCalledRef.current = true;
      const { vibe, hotelType, days } = quiz;
      let cityName = '';
      let isHotelsLoaded = false;
      let isActivityLoaded = false;
      if ('city' in quiz) {
        cityName = quiz.city?.name;
      } else if ('gptCity' in quiz) {
        cityName = quiz.gptCity?.name ?? '';
      }

      if (!cityName) {
        setIsError(true);
      }

      setIsLoading(true);
      const controller = new AbortController();

      setTimeout(() => {
        if (isHotelsLoaded && isActivityLoaded) return;
        controller.abort();
        setIsError(true);
      }, 150000);

      Promise.all([
        getHotelInfoInCityGpt(
          cityName as string,
          hotelType as string,
          controller.signal,
        ),
        createItineraryGpt(cityName as string, days, vibe, controller.signal),
      ])
        .then(async ([hotelsGpt, itinerary]) => {
          let itineraryList;
          isHotelsLoaded = true;
          const retryQueryOnProcessing =
            async (): Promise<ActivityGPTResponse | void | null> => {
              return await getItineraryByID(
                itinerary.itinerary_code,
                controller.signal,
              )
                .then(async (data) => {
                  if (data.status === 'COMPLETE') {
                    isActivityLoaded = true;
                    return data;
                  } else {
                    const activityGPTResponse: ActivityGPTResponse | null | void =
                      await new Promise((resolve) => {
                        setTimeout(async () => {
                          resolve(retryQueryOnProcessing());
                        }, 10000);
                      });
                    return activityGPTResponse;
                  }
                })
                .catch(() => {
                  setIsError(true);
                })
                .finally(() => {
                  return null;
                });
            };

          if (itinerary.status === 'COMPLETE') {
            isActivityLoaded = true;
            itineraryList = itinerary;
          } else {
            itineraryList = await retryQueryOnProcessing();
          }

          const newItinerary = itineraryList?.dayItineraries.map((dayItem) => {
            dayItem.day = dayItem.day.replace(/[^a-zA-Z0-9-]+/g, '');
            return dayItem;
          });

          return {
            hotels: hotelsGpt,
            itinerary: newItinerary ?? undefined,
          };
        })
        .then(({ hotels, itinerary }) => {
          const newGeneratedTrip = processGeneratedTrip({
            quiz,
            hotels: hotels,
            activityList: itinerary,
          });
          saveGeneratedTripLocal(newGeneratedTrip);
        })
        .catch(() => {
          setIsError(true);
        });
    }
  }, [
    quiz,
    isLoading,
    generatedTrip,
    generatedTripLocal,
    isLocalLoading,
    isTripLoading,
  ]);

  useEffect(() => {
    if (
      tripLocal &&
      tripLocal.details?.activities &&
      tripLocal.details?.hotels
    ) {
      setIsLoading(false);
    }
  }, [tripLocal]);

  const tripDescription = useMemo(() => {
    if (tripLocal) {
      return tripLocal.city?.description;
    }
    return '';
  }, [tripLocal]);

  const details = useMemo(() => {
    if (tripLocal) {
      return getCityDetails(tripLocal.city.detail);
    }

    return [];
  }, [tripLocal]);

  const activityTags = useMemo(() => {
    if (tripLocal) {
      const newActivityTags = getActivityTags({
        hotels: tripLocal.details?.hotels,
        activityList: tripLocal.details?.activities,
      });
      setChosenActivity(newActivityTags[0]);
      return newActivityTags;
    }
    return [];
  }, [tripLocal]);

  const activityObj = useMemo(() => {
    if (tripLocal?.details?.activities?.length) {
      return tripLocal.details.activities.reduce(
        (acc: Record<string, ActivityDayGPT[]>, item: ActivityGPT) => {
          acc[item.day] = item.activity_detail_list;
          return acc;
        },
        {},
      );
    }

    return {};
  }, [tripLocal]);

  async function onSendEmail(email: string) {
    new Promise((res) => {
      if (quiz && 'city' in quiz) {
        const { vibe, city, days, hotelType } = quiz;
        const response = sendTripByEmailGpt({
          email,
          city: city.name,
          hotel_type: hotelType,
          vibe,
          number_of_trip_days: days,
        });
        res(response);
      }
      if (quiz && 'gptCity' in quiz) {
        const { gptCity, hotelType, vibe, days } = quiz;
        const response = sendTripByEmailGpt({
          email,
          city: gptCity.name ?? '',
          hotel_type: hotelType ?? TripHotelType.LUXURY,
          vibe: vibe ?? TripVibe.FOODIE,
          number_of_trip_days: days ?? 1,
        });
        res(response);
      }
    })
      .catch((error) => {
        console.error('Leave gpt email error: ', error); // eslint-disable-line no-console
      })
      .finally(() => {
        setIsEmailSent(true);
      });
  }

  async function saveGeneratedTrip() {
    await createGeneratedTrip(tripLocal as TripGeneratedData);
  }

  if ((isTripLoading || isLocalLoading) && !tripLocal) {
    return <LoadingContainer />;
  }

  return tripLocal ? (
    <>
      <LogoHeader showAuthLinks={true} />
      <Stack className="main">
        <Stack sx={{ mb: 2, width: '100%' }}>
          <Wrapper>
            <TripDescriptionWrapper>
              <TripDescriptionContainer spacing={3}>
                <Tex
                  align="center"
                  fontFamily="canela-bold"
                  fontSize={24}
                  fontWeight={700}
                  color={common.white}
                  sx={{ mt: 2, textTransform: 'capitalize' }}
                >
                  {tripLocal.name}
                </Tex>
                {tripDescription ? (
                  <TripDescription>{tripDescription}</TripDescription>
                ) : null}
                {details.length ? (
                  <Stack spacing={1}>
                    <Collapse in={isExpanded}>
                      <Stack spacing={1.5}>
                        {details.map((detail) => (
                          <Stack
                            key={detail.title}
                            sx={{
                              p: 1.5,
                              backgroundColor: common.white,
                              borderRadius: 4,
                            }}
                          >
                            <Tex>
                              <Tex component="span" fontWeight={700}>
                                {detail.title}{' '}
                              </Tex>
                              <Tex component="span" fontWeight={300}>
                                {detail.text}
                              </Tex>
                            </Tex>
                          </Stack>
                        ))}
                      </Stack>
                    </Collapse>
                    <Stack sx={{ position: 'relative' }}>
                      <Button
                        onClick={() => setIsExpanded((previous) => !previous)}
                      >
                        <Tex sx={{ color: common.white, fontSize: '13px' }}>
                          {isExpanded ? 'See Less' : 'See More'}
                        </Tex>
                        <MdiIcon
                          icon={isExpanded ? mdiChevronUp : mdiChevronDown}
                          sx={{
                            ml: 1,
                            color: common.white,
                            fontSize: '13px',
                          }}
                        />
                      </Button>
                      {generatedTrip ? (
                        <Stack
                          sx={{
                            position: 'absolute',
                            right: 0,
                            top: `calc(50% - ${12}px)`,
                          }}
                        >
                          <PublicMyTrip
                            tripId={generatedTrip.tripId}
                            tripPath={generatedTrip.tripPath}
                            tripName={generatedTrip.name}
                            isGeneratedTrip={true}
                            isPublic={generatedTrip.isPublic}
                            size={24}
                          />
                        </Stack>
                      ) : null}
                    </Stack>
                  </Stack>
                ) : null}
              </TripDescriptionContainer>
            </TripDescriptionWrapper>
            <ContentWrapper>
              <ContentContainer>
                {isError ? (
                  <Stack>
                    {!isEmailSent ? (
                      <TripGeneratingText>
                        Trip itinerary is generating. Provide your email to get
                        a generated trip!
                      </TripGeneratingText>
                    ) : null}
                    <EnterEmail
                      onSubmit={onSendEmail}
                      isEmailSent={isEmailSent}
                    />
                  </Stack>
                ) : isLoading || !activityTags.length ? (
                  <LoadingWrapper>
                    <LoadingContainerGPT />
                  </LoadingWrapper>
                ) : (
                  <Stack
                    spacing={2}
                    sx={{
                      position: 'relative',
                      flex: 1,
                    }}
                  >
                    {!generatedTrip && activityTags.length ? (
                      <Box sx={{ py: 3, margin: '0 auto' }}>
                        <SaveMyTripButton
                          onClick={saveGeneratedTrip}
                          variant="contained"
                          loading={false}
                          disabled={false}
                        >
                          SAVE MY TRIP
                        </SaveMyTripButton>
                      </Box>
                    ) : null}
                    {generatedTrip ? (
                      <TripConstructorActions>
                        <EmailMyBookingTrip
                          tripId={generatedTrip.tripId}
                          isGeneratedTrip={true}
                        />
                      </TripConstructorActions>
                    ) : null}
                    {activityTags.length ? (
                      <Stack direction="row" spacing={1}>
                        {activityTags.map((activity) => (
                          <Chip
                            key={activity}
                            label={activity}
                            color={
                              chosenActivity === activity
                                ? 'primary'
                                : 'default'
                            }
                            onClick={() => setChosenActivity(activity)}
                            sx={{
                              backgroundColor:
                                chosenActivity === activity
                                  ? 'none'
                                  : common.white,
                              textTransform: 'capitalize',
                            }}
                          />
                        ))}
                      </Stack>
                    ) : null}
                    {chosenActivity === 'Stay' &&
                    tripLocal?.details?.hotels &&
                    Array.isArray(tripLocal.details.hotels) ? (
                      <ActivityWrapper>
                        {tripLocal.details.hotels.map((hotel: HotelGPT) => (
                          <PlaceWrapper key={hotel.hotel_name}>
                            <Tex fontWeight="700">{hotel.hotel_name}</Tex>
                            <Tex fontWeight="300">
                              {hotel.hotel_description}
                            </Tex>
                          </PlaceWrapper>
                        ))}
                      </ActivityWrapper>
                    ) : null}
                    {chosenActivity && activityObj[chosenActivity] ? (
                      <ActivityWrapper>
                        {activityObj[chosenActivity].map(
                          (activity: ActivityDayGPT) => (
                            <PlaceWrapper key={activity.activity_name}>
                              <Tex fontWeight="700">
                                {activity.activity_name}
                              </Tex>
                              <Tex fontWeight="300">
                                {activity.activity_description}
                              </Tex>
                            </PlaceWrapper>
                          ),
                        )}
                      </ActivityWrapper>
                    ) : null}
                  </Stack>
                )}
              </ContentContainer>
            </ContentWrapper>
          </Wrapper>
        </Stack>
      </Stack>
      <Footer />
    </>
  ) : (
    <NoTripIdContainer />
  );
}
const Wrapper = styled(Stack)`
  flex-direction: column;

  ${device.tablet} {
    flex-direction: row;
    width: 770px;
    margin: 6px auto 0;
  }

  ${device.laptopN} {
    max-width: none;
    width: 830px;
    margin: 20px auto 0;
  }

  ${device.desktop} {
    width: 870px;
    margin: 40px auto 0;
  }
`;

const TripDescriptionWrapper = styled(Stack)`
  width: 100%;
  margin-top: 20px;
  ${device.tablet} {
    width: 50%;
    min-width: 300px;
    border-right: solid 1px #dadada;
  }
`;

const TripDescription = styled(Tex)`
  text-align: center;
  font-weight: 300;
  font-size: 16px;
  color: ${common.white};
  margin-top: 16px !important;
  line-height: 24px;
`;

const LoadingWrapper = styled(Stack)`
  position: relative;
  height: 200px;
  width: 100%;
`;

const TripDescriptionContainer = styled(Stack)`
  margin: 0 auto;
  padding: 8px 16px;
  background-color: ${PRIMARY_COLOR};
  border-radius: 20px;
  max-width: 390px;
  width: 94%;

  ${device.tablet} {
    padding: 16px 24px;
    width: 368px;
  }
`;

const ContentWrapper = styled(Stack)`
  width: 100%;

  ${device.tablet} {
    width: 50%;
    min-width: 300px;
  }
`;

const ContentContainer = styled(Stack)`
  width: 94%;
  max-width: 390px;
  margin: 20px auto 4px;

  ${device.tablet} {
    width: 368px;
  }
`;

const TripGeneratingText = styled(Tex)`
  color: ${PRIMARY_COLOR};
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin: 24px auto;
`;

const ActivityWrapper = styled(Stack)`
  width: 100%;
  margin: 20px auto 0 !important;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  gap: 16px;

  ${device.tablet} {
    gap: 11px;
  }
`;

const PlaceWrapper = styled(Stack)`
  width: 100%;
  padding: 16px;
  background: #fff;
  border-radius: 20px;
`;

const SaveMyTripButton = styled(LoadingButton)`
  align-items: center;
  padding: 8px;
  width: 240px;
  height: 40px;
  border-radius: 20px;
  background-color: ${ORANGE_COLOR};
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  font-family: 'CircularStd-Book', sans-serif;
`;

const TripConstructorActions = styled(Stack)`
  gap: 16px;
`;
