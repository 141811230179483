import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { memoryCache } from './memory-cache';
import { createSession } from '../services/auth.api';
import { env } from './env';

export function initializeFirebase(): void {
  initializeApp({
    projectId: env.FIREBASE_PROJECT_ID,
    apiKey: env.FIREBASE_API_KEY,
    authDomain: env.FIREBASE_AUTH_DOMAIN,
  });
  const auth = getAuth();
  auth.useDeviceLanguage();
  onAuthStateChanged(auth, async () => {
    await createSession();
    memoryCache.isFirebaseInitialized = true;
  });
}
