import { TripId, TripStay, TripStayData } from '@jaunt/api';
import { getTripStays, replaceTripStays } from '../services/trip.api';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import QueryKeys from './constants/query-keys';

export function useTripStays(
  tripId: TripId | null,
): UseQueryResult<TripStay[], unknown> {
  return useQuery<TripStay[]>(
    [QueryKeys.tripStays, tripId],
    async () => {
      return tripId ? await getTripStays(tripId) : [];
    },
    {
      placeholderData() {
        return [];
      },
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading TripStays: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!tripId,
    },
  );
}

export const useReplaceTripStays = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({ tripId, data }: { tripId: TripId; data: TripStayData[] }) => {
      return await replaceTripStays(tripId, data);
    },
    {
      onSuccess: (_, { tripId }) => {
        client.invalidateQueries([QueryKeys.tripStays, tripId]);
      },
    },
  );
  return { replaceTripStays: mutateAsync, isLoading, isSuccess, isError };
};
