import { FirebaseError } from 'firebase/app';

export function translateFirebaseError(firebaseError: FirebaseError): string {
  switch (firebaseError.code) {
    case 'auth/user-not-found':
      return 'Incorrect email or password';
    case 'auth/wrong-password':
      return 'Incorrect email or password';
    case 'auth/email-already-in-use':
      return 'Email already in-use';
    default:
      return firebaseError.message;
  }
}
