import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import './fonts/canela-bold.ttf';
import './fonts/CircularStd-BlackItalic.otf';
import './fonts/CircularStd-Black.otf';
import './fonts/CircularStd-BoldItalic.otf';
import './fonts/CircularStd-Bold.otf';
import './fonts/CircularStd-BookItalic.otf';
import './fonts/CircularStd-Book.otf';
import './fonts/CircularStd-LightItalic.otf';
import './fonts/CircularStd-Light.otf';
import './fonts/CircularStd-MediumItalic.otf';
import './fonts/CircularStd-Medium.otf';

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
