import { Chip, Stack } from '@mui/material';
import { common } from '@mui/material/colors';

interface CuratedTripActivityChipsProps {
  day: number | null;
  onSetDay: (day: number | null) => void;
  days: (number | null)[];
}

export function CuratedTripActivityChips({
  day,
  onSetDay,
  days,
}: CuratedTripActivityChipsProps): JSX.Element {
  return (
    <Stack direction="row" spacing={1} sx={{ px: '20px' }}>
      <Chip
        key="stay"
        label="Stay"
        color={day === 9999 ? 'primary' : 'default'}
        onClick={() => onSetDay(9999)}
        sx={day === 9999 ? {} : { backgroundColor: common.white }}
      />
      {days.map((dayItem) => (
        <Chip
          key={dayItem}
          label={dayItem === null ? 'Unscheduled' : `Day ${dayItem + 1}`}
          color={day === dayItem ? 'primary' : 'default'}
          onClick={() => onSetDay(dayItem)}
          sx={day === dayItem ? {} : { backgroundColor: common.white }}
        />
      ))}
    </Stack>
  );
}
