import '../index.css';
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import '@mui/lab/themeAugmentation'; // setup for @mui/lab
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { ReactNode } from 'react';
import { CssBaseline } from '@mui/material';

interface Props {
  children: ReactNode;
}

export const PRIMARY_COLOR = '#174240';
export const SECONDARY_COLOR = '#F5C6AB';
export const ORANGE_COLOR = '#D6472A';
export const BACKGROUND_COLOR = '#F1EEE5';
export const WHITE_TEXT_COLOR = '#E7ECEC';

export const size = {
  mobileM: 400,
  mobileL: 500,
  tablet: 744,
  laptopS: 900,
  laptopM: 1100,
  laptop: 1200,
  laptopL: 1440,
  laptopXL: 1600,
  laptopN: 1024,
  desktop: 1440,
  desktopL: 2500,
};

export const device = {
  mobileM: `@media (min-width: ${size.mobileM}px)`,
  mobileL: `@media (min-width: ${size.mobileL}px)`,
  tablet: ` @media (min-width: ${size.tablet}px)`,
  laptopS: `@media (min-width: ${size.laptopS}px)`,
  laptop: `@media (min-width: ${size.laptop}px)`,
  laptopL: `@media (min-width: ${size.laptopL}px)`,
  laptopXL: `@media (min-width: ${size.laptopXL}px)`,
  laptopN: `@media (min-width: ${size.laptopN}px)`,
  desktop: `@media (min-width: ${size.desktop}px)`,
  desktopL: `@media (min-width: ${size.desktopL}px)`,
};

export function JauntTheme(props: Props): JSX.Element {
  const { children } = props;

  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: PRIMARY_COLOR,
      },
      success: {
        main: PRIMARY_COLOR,
      },
      secondary: {
        main: SECONDARY_COLOR,
      },
      warning: {
        main: ORANGE_COLOR,
      },
      background: {
        default: BACKGROUND_COLOR,
      },
    },
    typography: {
      button: {
        textTransform: 'none',
      },
    },
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: red[700], // make 'required' label have a red asterisk
            '&$error': {
              color: red[700],
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
