import { addDays, format } from 'date-fns';
import { DateRange, DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import { Tex } from './shared/Tex';

interface Props {
  range: DateRange | undefined;
  setRange: (value: DateRange | undefined) => void;
  defaultMonth: Date;
  currentDate: Date;
}

export default function TripTimingDayPicker(props: Props): JSX.Element {
  const { range, setRange, currentDate, defaultMonth } = props;

  let footer = <p>Please pick the first day.</p>;

  if (range?.from) {
    if (!range.to) {
      footer = (
        <SelectedDateWrapper>
          <Stack>
            <Tex fontSize={14}>Trip start</Tex>
            <Tex></Tex>
            <Tex fontWeight={700} fontSize={16}>
              {format(range.from, 'EEE, MMM d')}
            </Tex>
          </Stack>
          <hr />
          <Stack alignItems="flex-end">
            <Tex fontSize={14}>Trip end</Tex>
            <Tex fontWeight={700} fontSize={16}>
              ---
            </Tex>
          </Stack>
        </SelectedDateWrapper>
      );
    } else if (range.to) {
      footer = (
        <SelectedDateWrapper>
          <Stack>
            <Tex fontSize={14}>Trip start</Tex>
            <Tex></Tex>
            <Tex fontWeight={700} fontSize={16}>
              {format(range.from, 'EEE, MMM d')}
            </Tex>
          </Stack>
          <hr />
          <Stack alignItems="flex-end">
            <Tex fontSize={14}>Trip end</Tex>
            <Tex fontWeight={700} fontSize={16}>
              {format(range.to, 'EEE, MMM d')}
            </Tex>
          </Stack>
        </SelectedDateWrapper>
      );
    }
  }

  return (
    <Container>
      <DayPicker
        numberOfMonths={1}
        mode="range"
        defaultMonth={defaultMonth}
        selected={range}
        footer={footer}
        onSelect={setRange}
        fromDate={addDays(currentDate, 1)}
        modifiersClassNames={{
          selected: 'my-selected',
          today: 'my-today',
        }}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  .rdp {
    --rdp-cell-size: 50px;
    margin: 4px;
  }

  .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: #000;
    border-radius: 0;
    color: #fff;
  }

  .my-selected:not([disabled]) {
    font-weight: bold;
    border: 2px solid #000;
    border-radius: 0;
    background: #000;
    color: #fff;
  }

  .my-selected:hover:not([disabled]) {
    font-weight: bold;
    border: 2px solid black;
    border-radius: 0;
    background: #000;
    color: #fff;
  }

  .rdp-day.rdp-day_range_middle {
    background-color: #505050;
    border: 2px solid #505050;
  }
`;

const SelectedDateWrapper = styled(Stack)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-top: 1px solid #d6d6d6;
  padding: 10px;

  hr {
    height: 50px;
    margin: auto -20px;
    border: none;
    border-left: 1px solid #d6d6d6;
  }
`;
