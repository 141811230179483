import { Dialog, IconButton, Link, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { PRIMARY_COLOR } from '../settings/jaunt-theme';
import { Tex } from './shared/Tex';
import { mdiClose } from '@mdi/js';
import { common } from '@mui/material/colors';
import { MdiIcon } from './shared/MdiIcon';
import { mdiInstagram } from '@mdi/js';
import { mdiTwitter } from '@mdi/js';
import TikTokIcon from '../icons/tiktok';
import { Spacer } from './shared/Spacer';
import { PlanTripDialog } from './plan-trip-dialog/PlanTripDialog';
import { useCitiesWithImageUrl } from '../hooks/use-cities-with-image-url';
import { useState } from 'react';
import React from 'react';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useCities } from '../hooks/use-cities';
import { useLogOut } from '../hooks/use-me';

interface Props {
  isOpen: boolean;
  onClose(): void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export function NavBar(props: Props): JSX.Element {
  const { isOpen, onClose } = props;
  const { logOut } = useLogOut();
  const { data: cities } = useCities();
  const { data: citiesWithImageUrl } = useCitiesWithImageUrl(cities);
  const [isPlanTripDialogOpen, setIsPlanTripDialogOpen] = useState(false);

  async function handleLogout() {
    await logOut();
    window.location.replace('/');
  }

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      fullScreen
      sx={{
        '& .MuiPaper-root': {
          p: 2,
          // borderRadius: '20px 0px 0px 20px',
          backgroundColor: PRIMARY_COLOR,
        },
        marginLeft: 7,
        // backgroundColor: PRIMARY_COLOR,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          width: '100%',
          px: 2,
          py: 0.5,
          color: common.white,
        }}
      >
        <Spacer />
        <IconButton size="small" onClick={() => onClose()} disableRipple>
          <MdiIcon
            icon={mdiClose}
            sx={{ size: 'small', color: common.white }}
          />
        </IconButton>
      </Stack>
      <Stack spacing={4} sx={{ paddingTop: '35px' }}>
        <Link
          component={RouterLink}
          to="/explore/trips"
          underline="none"
          sx={{ color: 'inherit' }}
        >
          <Tex
            textAlign="left"
            fontSize={20}
            onClick={() => onClose()}
            fontFamily="CircularStd-Light"
            sx={{ color: common.white }}
          >
            CURATED TRIPS
          </Tex>
        </Link>
        <Link
          component={RouterLink}
          to="/explore/cities"
          underline="none"
          sx={{ color: 'inherit' }}
        >
          <Tex
            textAlign="left"
            fontSize={20}
            onClick={() => onClose()}
            fontFamily="CircularStd-Light"
            sx={{ color: common.white }}
          >
            CITY GUIDES
          </Tex>
        </Link>
        {/*//TODO uncomment when mytrips be ready */}
        <Tex
          textAlign="left"
          fontSize={20}
          onClick={() => {
            setIsPlanTripDialogOpen(true);
          }}
          fontFamily="CircularStd-Light"
          sx={{ color: common.white }}
        >
          PLAN MY TRIP
        </Tex>
        <Link
          component={RouterLink}
          to="/mytrips/manual"
          underline="none"
          sx={{ color: 'inherit' }}
        >
          <Tex
            textAlign="left"
            fontSize={20}
            onClick={onClose}
            fontFamily="CircularStd-Light"
            sx={{ color: common.white }}
          >
            MY TRIPS
          </Tex>
        </Link>
        <Link
          component={RouterLink}
          to="/favorites/trips"
          underline="none"
          sx={{ color: 'inherit' }}
        >
          <Tex
            textAlign="left"
            fontSize={20}
            onClick={onClose}
            fontFamily="CircularStd-Light"
            sx={{ color: common.white }}
          >
            FAVORITES
          </Tex>
        </Link>
        <Link
          component={RouterLink}
          to="/account"
          underline="none"
          sx={{ color: 'inherit' }}
        >
          <Tex
            textAlign="left"
            fontSize={20}
            onClick={onClose}
            fontFamily="CircularStd-Light"
            sx={{ color: common.white }}
          >
            MY ACCOUNT
          </Tex>
        </Link>
        <Stack>
          <Tex
            textAlign="left"
            fontSize={20}
            onClick={handleLogout}
            fontFamily="CircularStd-Light"
            sx={{ color: common.white }}
          >
            Log out
          </Tex>
        </Stack>
        <Tex
          textAlign="center"
          fontSize={14}
          fontFamily="CircularStd-Light"
          sx={{ color: common.white, paddingTop: '200px' }}
        >
          follow @jauntmore
        </Tex>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap="52px"
          sx={{
            width: '100%',
            px: 5,
            py: 0.5,
            color: common.white,
            // paddingLeft: '23%',
          }}
        >
          <IconButton size="small" disableRipple>
            <a
              href="https://www.instagram.com/jauntmore/"
              target="_blank"
              rel="noreferrer"
            >
              <MdiIcon
                icon={mdiInstagram}
                sx={{ fontSize: '32px', color: common.white }}
              />
            </a>
          </IconButton>
          <a
            href="https://www.tiktok.com/@jauntmore"
            target="_blank"
            rel="noreferrer"
          >
            <TikTokIcon color={common.white} size="32px" />
          </a>
          <IconButton size="small" disableRipple>
            <a
              href="https://twitter.com/jauntmore"
              target="_blank"
              rel="noreferrer"
            >
              <MdiIcon
                icon={mdiTwitter}
                sx={{ fontSize: '32px', color: common.white }}
              />
            </a>
          </IconButton>
        </Stack>
      </Stack>
      {citiesWithImageUrl ? (
        <PlanTripDialog
          isOpen={isPlanTripDialogOpen}
          citiesWithImageUrl={citiesWithImageUrl}
          onClose={() => setIsPlanTripDialogOpen(false)}
        />
      ) : null}
    </Dialog>
  );
}
