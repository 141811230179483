import { memoryCache } from '../settings/memory-cache';
import {
  ConfirmationResult,
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  User as FirebaseUser,
} from 'firebase/auth';
import { updateInterceptorMiddleware } from './base.api';

export async function signUp(
  email: string,
  password: string,
): Promise<FirebaseUser> {
  const auth = getAuth();
  await createUserWithEmailAndPassword(auth, email, password);
  const { user } = await signInWithEmailAndPassword(auth, email, password);
  return user;
}

export async function logIn(
  email: string,
  password: string,
): Promise<FirebaseUser> {
  const auth = getAuth();
  const { user } = await signInWithEmailAndPassword(auth, email, password);
  await createSession();
  return user;
}

export async function confirmVerificationCode(
  confirmationResult: ConfirmationResult,
  verificationCode: string,
): Promise<void> {
  await confirmationResult.confirm(verificationCode);
}

export async function createSession(): Promise<void> {
  const { currentUser } = getAuth();
  if (currentUser) {
    const idToken = await currentUser.getIdToken(true);
    memoryCache.idToken = idToken;
    await updateInterceptorMiddleware();
  }
}

export async function logOut(): Promise<void> {
  const auth = getAuth();
  await signOut(auth);
}
