import { Box, InputLabel, TextField, TextFieldProps } from '@mui/material';
import { FieldError, FormState, Path, UseFormRegister } from 'react-hook-form';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../settings/jaunt-theme';

type Props<T> = TextFieldProps & {
  register: UseFormRegister<T>;
  formState: FormState<T>;
  fieldName: Path<T>;
  color?: 'primary' | 'secondary';
  label?: string;
  required?: boolean;
};

export function FormTextField<T>(props: Props<T>): JSX.Element {
  const {
    register,
    formState,
    fieldName,
    color,
    label,
    required,
    ...otherProps
  } = props;
  const fieldColor = color === 'primary' ? PRIMARY_COLOR : SECONDARY_COLOR;
  const errors = formState.errors as Record<Path<T>, FieldError>;
  const error = errors[fieldName]?.message;

  return (
    <Box>
      {label && (
        <InputLabel
          htmlFor={fieldName}
          required={required}
          shrink
          sx={{ fontWeight: 700, ...(color && { color: fieldColor }) }}
        >
          {label}
        </InputLabel>
      )}
      <TextField
        {...register(fieldName)}
        id={fieldName}
        name={fieldName}
        error={!!error}
        helperText={error}
        variant="outlined"
        size="small"
        sx={{
          ...(color && {
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: fieldColor,
              },
              '&:hover fieldset': {
                borderColor: fieldColor,
              },
              '& input': {
                color: fieldColor,
              },
            },
          }),
        }}
        {...otherProps}
      />
    </Box>
  );
}
