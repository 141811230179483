import { TripPlace } from '@jaunt/api';
import { Chip, Stack } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import styled from '@emotion/styled';
import { useMemo } from 'react';

type Props = { tripPlace: TripPlace };

export function TripPlaceCardLabels({ tripPlace }: Props): JSX.Element {
  const eventTime = useMemo(() => {
    if (tripPlace.time !== null && tripPlace.time !== undefined) {
      const ampm = Number(tripPlace.time) >= 12 ? 'PM' : 'AM';
      let hours = Number(tripPlace.time) % 12;
      hours = hours ? hours : 12;
      return `${hours}:00 ${ampm}`;
    }

    return null;
  }, [tripPlace]);
  return (
    <>
      <LabelChip label={tripPlace.activity} />
      {!!eventTime && <LabelChip label={eventTime} />}
      {!!tripPlace.people && (
        <LabelChip
          label={
            <Stack className="row">
              {tripPlace.people}
              <PersonIcon fontSize="small" />
            </Stack>
          }
        />
      )}
    </>
  );
}

const LabelChip = styled(Chip)`
  border-radius: 2px;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;

  &.MuiChip-root {
    padding: 4px 6px;
  }

  .MuiChip-label {
    padding: 0;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;
