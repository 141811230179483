import { Stack } from '@mui/material';
import { Tex } from '../components/shared/Tex';
import { useState } from 'react';

export function PlannerPage(): JSX.Element {
  const [step] = useState(1);

  return (
    <Stack alignItems="center" sx={{ p: 1, width: '100vw', height: '100vh' }}>
      <Tex sx={{ opacity: 0.5 }}>Question {step} of 4</Tex>
      <Stack alignItems="center" justifyContent="center" flexGrow={1}>
        <Tex color="primary">
          <Tex component="span">Ok, first thing&apos;s first. </Tex>
          <Tex component="span" fontWeight={700}>
            Where are you going?
          </Tex>
        </Tex>
      </Stack>
    </Stack>
  );
}
