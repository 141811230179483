import { put } from './base.api';
import {
  FileId,
  MultipleReviewImagesResponse,
  ReplaceReviewImagesData,
  ReviewId,
  ReviewImage,
} from '@jaunt/api';

export async function replaceReviewImages(
  reviewId: ReviewId,
  imageFileIds: FileId[],
): Promise<ReviewImage[]> {
  const { reviewImages } = await put<
    MultipleReviewImagesResponse,
    ReplaceReviewImagesData
  >('/review-images', {}, { reviewId, imageFileIds });
  return reviewImages;
}
