import React from 'react';
import { TripId } from '@jaunt/api';
import { AlertTitle, Snackbar, Stack } from '@mui/material';
import styled from '@emotion/styled';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
import { useSaveTripEmailApp } from 'src/hooks/use-send-trip-itinerary-email';
import { BACKGROUND_COLOR } from '../settings/jaunt-theme';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type Props = {
  tripId: TripId;
  isGeneratedTrip: boolean;
};

export function EmailMyBookingTrip({
  tripId,
  isGeneratedTrip,
}: Props): JSX.Element {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const { saveTripEmailApp, isLoading } = useSaveTripEmailApp();
  async function generateMyBookingTripEmail() {
    const { appId } = await saveTripEmailApp({ tripId, isGeneratedTrip });

    if (appId) {
      setOpenSnackbar(true);
    }
  }

  const handleCloseSnackbar = (
    _?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <Stack
      sx={{
        width: '100%',
        alignItems: 'center',
      }}
    >
      <CTAButton
        variant="contained"
        onClick={generateMyBookingTripEmail}
        loading={isLoading}
        disabled={!tripId}
      >
        Email My Booking Summary
      </CTAButton>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="outlined"
          color="success"
          sx={{
            width: '100%',
            minWidth: '320px',
            background: BACKGROUND_COLOR,
          }}
        >
          <AlertTitle>Success</AlertTitle>
          Generating your booking summary is in progress!
        </Alert>
      </Snackbar>
    </Stack>
  );
}

const CTAButton = styled(LoadingButton)`
  width: 80%;
  min-width: 300px;
  max-width: 320px;
  padding: 8px 0;
  align-items: center;
  border-radius: 9999px;
  background-color: #d6472a;
  font-size: 16px;
  font-weight: 450;
  font-family: CircularStd-Book, sans-serif;
  text-transform: uppercase;
`;
