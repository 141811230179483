import { put } from './base.api';
import {
  CityId,
  MultipleCityImagesResponse,
  CityImage,
  ReplaceCityImagesData,
  FileId,
} from '@jaunt/api';

export async function replaceCityImages(
  cityId: CityId,
  imageFileIds: FileId[],
): Promise<CityImage[]> {
  const { cityImages } = await put<
    MultipleCityImagesResponse,
    ReplaceCityImagesData
  >('/city-images', {}, { cityId, imageFileIds });
  return cityImages;
}
