import { City, CityData, CityId, FileId, TagId } from '@jaunt/api';
import {
  createCity,
  deleteCity,
  getCity,
  getCityByPath,
  replaceCityTags,
  updateCity,
} from '../services/city.api';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import QueryKeys from './constants/query-keys';
import { replaceCityImages } from '../services/city-image.api';
import { useMemo } from 'react';

export function useCity(
  cityId?: CityId | null,
): UseQueryResult<City | undefined, unknown> {
  return useQuery<City | undefined>(
    [QueryKeys.cities, cityId],
    async () => {
      return cityId ? await getCity(cityId) : undefined;
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading City: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!cityId,
    },
  );
}

export function useCityPath(cityPath?: string | undefined | null) {
  const client = useQueryClient();
  const result = useQuery<City | undefined>(
    [QueryKeys.cities, 'path', cityPath],
    async () => {
      return cityPath ? await getCityByPath(cityPath) : undefined;
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading CityPath: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!cityPath,
    },
  );
  return useMemo(() => {
    const cities: City[] = client.getQueryData(QueryKeys.cities) ?? [];
    const city =
      cityPath && cities.length > 0
        ? cities.find((city) => city?.cityPath === cityPath)
        : undefined;
    if (city) {
      return {
        isSuccess: result.isSuccess,
        isError: result.isError,
        isLoading: result.isLoading,
        data: city,
      };
    } else {
      if (result.isSuccess && result.data) {
        client.setQueryData(QueryKeys.cities, [...cities, result.data]);
        client.setQueryData(
          [QueryKeys.cities, result.data?.cityId],
          result.data,
        );
      }

      return {
        isSuccess: result.isSuccess,
        isError: result.isError,
        isLoading: result.isLoading,
        data: result.data,
      };
    }
  }, [result.isSuccess, result.isError, result.isLoading, result.data]);
}

export const useCreateCity = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({
      cityData,
      imageFileIds,
      summaryTagIds,
    }: {
      cityData: CityData;
      imageFileIds: FileId[];
      summaryTagIds: TagId[];
    }) => {
      const createdCity = await createCity(cityData);
      const { cityId } = createdCity;
      await Promise.all([
        replaceCityImages(cityId, imageFileIds),
        replaceCityTags(cityId, summaryTagIds),
      ]);

      return createdCity;
    },
    {
      onSuccess: (city: City) => {
        client.invalidateQueries(QueryKeys.cities);
        client.invalidateQueries([QueryKeys.citiesWithImageUrl, city.cityId]);
        client.invalidateQueries([QueryKeys.cityTags, city.cityId]);
      },
    },
  );
  return { createCity: mutateAsync, isLoading, isSuccess, isError };
};

export const useUpdateCity = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({
      cityId,
      cityData,
      imageFileIds,
      summaryTagIds,
    }: {
      cityId: CityId;
      cityData: CityData;
      imageFileIds: FileId[];
      summaryTagIds: TagId[];
    }) => {
      const [updatedCity] = await Promise.all([
        updateCity(cityId, cityData),
        replaceCityImages(cityId, imageFileIds),
        replaceCityTags(cityId, summaryTagIds),
      ]);

      return updatedCity;
    },
    {
      onSuccess: (city: City) => {
        client.invalidateQueries(QueryKeys.cities);
        client.invalidateQueries([QueryKeys.citiesWithImageUrl, city.cityId]);
        client.invalidateQueries([QueryKeys.cityTags, city.cityId]);
      },
    },
  );
  return { updateCity: mutateAsync, isLoading, isSuccess, isError };
};

export const useDeleteCity = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({ cityId }: { cityId: CityId }) => {
      await deleteCity(cityId);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(QueryKeys.cities);
      },
    },
  );
  return { deleteCity: mutateAsync, isLoading, isSuccess, isError };
};
