import { Stack } from '@mui/material';
import { Tex } from '../shared/Tex';
import { yupResolver } from '@hookform/resolvers/yup';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { ReactComponent as GoogleIcon } from 'src/assets/svg/google-icon.svg';
import { LoadingButton } from '@mui/lab';
import { common } from '@mui/material/colors';
import * as yup from 'yup';
import { useSignUp, useLogIn, useRefetchUser } from 'src/hooks/use-me';
import { SECONDARY_COLOR } from 'src/settings/jaunt-theme';
import { translateFirebaseError } from 'src/services/firebase.util';
import { FirebaseError } from 'firebase/app';
import { FormTextField } from '../shared/FormTextField';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { createSession } from '../../services/auth.api';
import styled from '@emotion/styled';

interface Props {
  onChange(): void;
}

const resolver = yupResolver(
  yup.object({
    email: yup.string().email('Enter a valid email').required('Required'),
    password: yup.string().min(8, 'Minimum 8 characters').required('Required'),
  }),
);

interface FormValues {
  email: string;
  password: string;
}

export function SignUpStep(props: Props): JSX.Element {
  const { onChange } = props;
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  const { register, handleSubmit, formState } = useForm<FormValues>({
    resolver,
  });
  const [isSaving, setIsSaving] = useState(false);
  const [isSignUp, setIsSignUp] = useState(true);
  const [buttonText, setButtonText] = useState('Create account');
  const [headerText, setHeaderText] = useState('Sign up to save your trip');
  const [errorMessage, setErrorMessage] = useState('');
  const { refetchUser } = useRefetchUser();
  const { signUp } = useSignUp();
  const { logIn } = useLogIn();

  async function onGoogleSubmit(): Promise<void> {
    signInWithPopup(auth, provider)
      .then(async () => {
        await createSession();
        await refetchUser();
        onChange();
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData?.email;
        console.error('google signIn error: ', errorCode, errorMessage, email); // eslint-disable-line no-console
      });
  }

  useEffect(() => {
    if (isSignUp) {
      setButtonText('Create account');
      setHeaderText('Sign up to save your trip');
    } else {
      setButtonText('Log In');
      setHeaderText('Sign in to save your trip');
    }
  }, [isSignUp]);

  async function onSubmit(formValues: FormValues): Promise<void> {
    const { email, password } = formValues;
    setErrorMessage('');
    setIsSaving(true);
    try {
      if (isSignUp) {
        await signUp({ email, password });
      } else {
        await logIn({ email, password });
      }

      await refetchUser();
      onChange();
    } catch (e) {
      const message = translateFirebaseError(e as FirebaseError);
      setErrorMessage(message);
      setIsSaving(false);
    }
  }

  return (
    <Stack spacing={2} alignItems="center">
      <Tex color="primary" textAlign="center" fontSize={20} fontWeight={'700'}>
        {headerText}
      </Tex>
      <LoadingButton
        variant="outlined"
        onClick={onGoogleSubmit}
        sx={{
          px: 2,
          py: 1,
          borderRadius: '9999px',
          height: '45px',
          width: '267px',
        }}
        disableElevation
      >
        <Stack spacing={1} direction="row" alignItems="center">
          <StyledGoogleIcon />
          <Tex fontSize="14px" color={common.black} sx={{ fontWeight: 500 }}>
            continue with Google
          </Tex>
        </Stack>
      </LoadingButton>
      <Stack sx={{ my: -2 }}>
        <Tex fontSize={'16px'} fontWeight={'300'}>
          or
        </Tex>
      </Stack>
      <Stack component="form" onSubmit={handleSubmit(onSubmit)} spacing={2}>
        <FormTextField
          register={register}
          formState={formState}
          fieldName="email"
          placeholder="Email"
          color="secondary"
          required
          sx={{
            height: '45px',
            width: '267px',
            borderRadius: '10px',
          }}
        />
        <FormTextField
          register={register}
          formState={formState}
          fieldName="password"
          placeholder="Password"
          type="password"
          color="secondary"
          required
          sx={{
            height: '45px',
            width: '267px',
            borderRadius: '10',
          }}
        />
        <Tex variant="caption" color="error">
          {errorMessage}
        </Tex>
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          loading={isSaving}
          disabled={isSaving}
          disableRipple
          sx={{
            width: '267px',
            height: '45px',
            '.MuiLoadingButton-loadingIndicator': { color: SECONDARY_COLOR },
          }}
        >
          {buttonText}
        </LoadingButton>
        {isSignUp ? (
          <Tex textAlign={'center'}>
            Already have an account?{' '}
            <Tex
              component="span"
              color="#D6472A"
              onClick={() => setIsSignUp(false)}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline #D6472A',
                },
              }}
            >
              Log in
            </Tex>
          </Tex>
        ) : (
          <Tex textAlign={'center'}>
            No account?{' '}
            <Tex
              component="span"
              color="#D6472A"
              onClick={() => setIsSignUp(true)}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline #D6472A',
                },
              }}
            >
              Create One
            </Tex>
          </Tex>
        )}
      </Stack>
    </Stack>
  );
}

const StyledGoogleIcon = styled(GoogleIcon)`
  height: 32px;
  width: 32px;
`;
