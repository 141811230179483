import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { noop } from 'lodash';
import * as yup from 'yup';
import { Divider, IconButton, Link, Stack, TextField } from '@mui/material';
import { common } from '@mui/material/colors';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from '@emotion/styled';
import { mdiArrowRight } from '@mdi/js';
import { device, PRIMARY_COLOR } from 'src/settings/jaunt-theme';
import { Tex } from './shared/Tex';
import { MdiIcon } from './shared/MdiIcon';
import { useState, useEffect } from 'react';

const resolver = yupResolver(
  yup.object({
    email: yup.string().email('Must be a valid email').required('Required'),
  }),
);

interface FormValues {
  email: string;
}

export function Footer(): JSX.Element {
  const { register, handleSubmit, formState } = useForm<FormValues>({
    resolver,
  });
  const errorMessage = formState.errors.email?.message;
  const [isMobile, setIsMobile] = useState(false);

  async function onSubmit(formValues: FormValues): Promise<void> {
    const { email } = formValues;
    noop(email);
  }

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 745);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {isMobile ? (
        <FooterContainer>
          <Wrapper>
            <Stack spacing={2}>
              <Tex
                fontSize={24}
                fontWeight={700}
                align="center"
                fontFamily="canela-bold"
                sx={{ color: common.white }}
              >
                The Jaunt
              </Tex>
              <Tex align="center" fontWeight={300} sx={{ color: common.white }}>
                Our favorite things, in our favorite places.
              </Tex>
              <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
                <Stack
                  direction="row"
                  sx={{
                    px: 1,
                    backgroundColor: common.white,
                    borderRadius: 9999,
                  }}
                >
                  <TextField
                    {...register('email')}
                    name="email"
                    size="small"
                    placeholder="Enter your email"
                    fullWidth
                    sx={{ '& fieldset': { border: 'none' } }}
                  />
                  <IconButton type="submit" size="small" disableRipple>
                    <MdiIcon icon={mdiArrowRight} />
                  </IconButton>
                </Stack>
                {errorMessage && (
                  <Tex variant="caption" color="error" align="center">
                    {errorMessage}
                  </Tex>
                )}
              </Stack>
            </Stack>
            <Divider sx={{ backgroundColor: common.white, borderWidth: 1 }} />
            <Stack sx={{ width: '100%' }}>
              <Stack direction="row" sx={{ color: common.white }}>
                <Stack spacing={2} sx={{ width: '50%' }}>
                  <Tex fontSize={14} fontWeight={700}>
                    BRAND
                  </Tex>
                  <Link
                    component={RouterLink}
                    to="/our-story"
                    sx={{ color: common.white }}
                  >
                    <Tex
                      fontSize={14}
                      fontWeight={300}
                      sx={{ color: common.white }}
                    >
                      Our Story
                    </Tex>
                  </Link>
                  <Tex fontSize={14} fontWeight={300}>
                    Contact
                  </Tex>
                </Stack>
                <Stack spacing={2} sx={{ width: '50%' }}>
                  <Tex fontSize={14} fontWeight={700}>
                    INFORMATION
                  </Tex>
                  <Link
                    component={RouterLink}
                    to="/privacy"
                    sx={{ color: common.white }}
                  >
                    <Tex
                      fontSize={14}
                      fontWeight={300}
                      sx={{ color: common.white }}
                    >
                      Privacy Policy
                    </Tex>
                  </Link>
                  <Link
                    component={RouterLink}
                    to="/terms"
                    sx={{ color: common.white }}
                  >
                    <Tex
                      fontSize={14}
                      fontWeight={300}
                      sx={{ color: common.white }}
                    >
                      Terms of Service
                    </Tex>
                  </Link>
                </Stack>
              </Stack>
              <Stack
                direction="column"
                spacing={2}
                sx={{ color: common.white, paddingTop: '9px' }}
              >
                <Tex fontSize={14} fontWeight={300}>
                  &nbsp; © 2022 Jaunt Travel App Inc. All rights reserved.
                </Tex>
              </Stack>
            </Stack>
          </Wrapper>
        </FooterContainer>
      ) : (
        <FooterContainer>
          <Wrapper>
            <Stack direction="column">
              <Stack direction="row" spacing={8}>
                <InnerContainer spacing={1}>
                  <Tex
                    fontSize={28}
                    fontWeight={700}
                    align="left"
                    fontFamily="canela-bold"
                    sx={{ color: common.white }}
                  >
                    The Jaunt
                  </Tex>
                  <Tex
                    align="left"
                    fontWeight={300}
                    fontSize={20}
                    sx={{ color: common.white }}
                  >
                    Our favorite things, in our favorite places.
                  </Tex>
                  <Stack
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                    sx={{ width: '315px', pt: '10px' }}
                  >
                    <Stack
                      direction="row"
                      sx={{
                        px: 1,
                        backgroundColor: common.white,
                        borderRadius: 9999,
                      }}
                    >
                      <TextField
                        {...register('email')}
                        name="email"
                        size="small"
                        placeholder="Enter your email"
                        fullWidth
                        sx={{ '& fieldset': { border: 'none' } }}
                      />
                      <IconButton type="submit" size="small" disableRipple>
                        <MdiIcon icon={mdiArrowRight} />
                      </IconButton>
                    </Stack>
                    {errorMessage && (
                      <Tex variant="caption" color="error" align="center">
                        {errorMessage}
                      </Tex>
                    )}
                  </Stack>
                </InnerContainer>

                <Stack sx={{ width: '100%', pt: '20px' }}>
                  <Stack
                    direction="row"
                    spacing={'50px'}
                    sx={{ color: common.white }}
                  >
                    <Stack spacing={2}>
                      <Tex fontSize={14} fontWeight={700}>
                        BRAND
                      </Tex>
                      <Link
                        component={RouterLink}
                        to="/our-story"
                        sx={{ color: common.white }}
                      >
                        <Tex
                          fontSize={14}
                          fontWeight={300}
                          sx={{ color: common.white }}
                        >
                          Our Story
                        </Tex>
                      </Link>
                      <Tex fontSize={14} fontWeight={300}>
                        Contact
                      </Tex>
                    </Stack>
                    <Stack spacing={2}>
                      <Tex fontSize={14} fontWeight={700}>
                        INFORMATION
                      </Tex>
                      <Link
                        component={RouterLink}
                        to="/privacy"
                        sx={{ color: common.white }}
                      >
                        <Tex
                          fontSize={14}
                          fontWeight={300}
                          sx={{ color: common.white }}
                        >
                          Privacy Policy
                        </Tex>
                      </Link>
                      <Link
                        component={RouterLink}
                        to="/terms"
                        sx={{ color: common.white }}
                      >
                        <Tex
                          fontSize={14}
                          fontWeight={300}
                          sx={{ color: common.white }}
                        >
                          Terms of Service
                        </Tex>
                      </Link>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                direction="column"
                spacing={2}
                sx={{ color: common.white, paddingTop: '30px' }}
              >
                <Tex fontSize={14} fontWeight={300} align="center">
                  &nbsp; © 2022 Jaunt Travel App Inc. All rights reserved.
                </Tex>
              </Stack>
            </Stack>
          </Wrapper>
        </FooterContainer>
      )}
    </>
  );
}

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${PRIMARY_COLOR};
  min-height: auto;
`;

const Wrapper = styled(Stack)`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  background-color: ${PRIMARY_COLOR};
  min-height: auto;
`;

const InnerContainer = styled(Stack)`
  ${device.tablet} {
    padding-left: 50px;
  }

  ${device.laptopS} {
    padding-left: 300px;
  }
`;
