import React from 'react';
import { TripId } from '@jaunt/api';
import { AlertTitle, Snackbar, Stack } from '@mui/material';
import styled from '@emotion/styled';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import { BACKGROUND_COLOR } from '../settings/jaunt-theme';
import {
  usePublicMyGeneratedTrip,
  usePublicMyTrip,
} from '../hooks/use-public-my-trip';
import { copyToClipboard, getAppOrigin } from '../services/base.util';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function generatePublicLink(
  tripPath: string,
  isGeneratedTrip: boolean,
): string {
  const origin = getAppOrigin();

  if (isGeneratedTrip) {
    return `${origin}/public/generated-trips/${tripPath}`;
  } else {
    return `${origin}/public/trips/${tripPath}`;
  }
}

type Props = {
  tripId: TripId;
  tripPath: string;
  tripName: string;
  isGeneratedTrip: boolean;
  isPublic?: boolean;
  size?: number;
};

export function PublicMyTrip({
  tripId,
  tripPath,
  tripName,
  isGeneratedTrip,
  isPublic,
  size,
}: Props): JSX.Element {
  const [snackbarText, setSnackbarText] = React.useState<string | null>(null);
  const { publicMyTrip } = usePublicMyTrip();
  const { publicMyGeneratedTrip } = usePublicMyGeneratedTrip();

  async function shareTripLink() {
    let newPublicLink;
    if (isPublic) {
      newPublicLink = generatePublicLink(tripPath, isGeneratedTrip);
    } else {
      if (isGeneratedTrip) {
        await publicMyGeneratedTrip({
          tripId,
          isPublic: true,
        });
      } else {
        await publicMyTrip({
          tripId,
          isPublic: true,
        });
      }

      newPublicLink = generatePublicLink(tripPath, isGeneratedTrip);
    }

    const shareDetails = {
      url: newPublicLink,
      title: tripName,
    };

    if (navigator.share) {
      await copyToClipboard(newPublicLink);
      await navigator.share(shareDetails);
    } else {
      await copyToClipboard(newPublicLink);
      setSnackbarText('Trip link copied to clipboard!');
    }
  }

  const handleCloseSnackbar = (
    _?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarText(null);
  };

  return (
    <Stack sx={{ alignItems: 'center' }}>
      <ActionBlock>
        <ReplyOutlinedIcon
          fontSize="large"
          onClick={shareTripLink}
          sx={{
            cursor: 'pointer',
            color: '#fff',
            transform: 'scaleX(-1)',
            fontSize: size ? `${size}px` : '30px',
          }}
        />
      </ActionBlock>
      <Snackbar
        open={!!snackbarText}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="outlined"
          color="success"
          sx={{
            width: '100%',
            minWidth: '320px',
            background: BACKGROUND_COLOR,
          }}
        >
          <AlertTitle>Success</AlertTitle>
          {snackbarText}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

const ActionBlock = styled(Stack)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  max-width: 350px;
`;
