import { assertNever } from '../services/base.util';

type JauntEnv = 'development' | 'staging' | 'production';

interface EnvironmentVariables {
  FIREBASE_API_KEY: string;
  FIREBASE_PROJECT_ID: string;
  FIREBASE_AUTH_DOMAIN: string;
  JAUNT_API_URL: string;
}

function getEnvironmentVariables(): EnvironmentVariables {
  const jauntEnv = process.env.REACT_APP_JAUNT_ENV as JauntEnv;

  if (!process.env.REACT_APP_FIREBASE_APIKEY) {
    assertNever(jauntEnv);
  }

  return {
    FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_APIKEY ?? '',
    FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ?? '',
    FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID ?? '',
    JAUNT_API_URL: process.env.REACT_APP_JAUNT_API_URL ?? '',
  };
}

export const env = getEnvironmentVariables();
