import { User } from '@jaunt/api';
import { LogoHeader } from '../components/LogoHeader';
import { Footer } from '../components/Footer';
import { NotFoundContainer } from '../components/NotFoundContainer';
import { useLogOut, useMe } from '../hooks/use-me';
import { LoadingContainer } from '../components/loading/LoadingContainer';
import { Link, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Tex } from '../components/shared/Tex';
import styled from '@emotion/styled';
import { device, PRIMARY_COLOR } from '../settings/jaunt-theme';
import { useQueryClient } from 'react-query';
import QueryKeys from '../hooks/constants/query-keys';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { ReactComponent as PinAltFill } from 'src/assets/svg/pin-alt-fill.svg';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { mdiAccount } from '@mdi/js';
import { MdiIcon } from '../components/shared/MdiIcon';

export function AccountPage(): JSX.Element {
  const { data: userId, isLoading } = useMe(true);
  const client = useQueryClient();
  const { logOut } = useLogOut();
  const user: User | undefined = client.getQueryData(QueryKeys.userData);

  async function handleLogout() {
    await logOut();
    window.location.replace('/');
  }

  if (isLoading) {
    return <LoadingContainer />;
  }

  return user && userId ? (
    <>
      <LogoHeader showAuthLinks={true} />
      <Stack className="main">
        <Stack sx={{ mb: 2, width: '100%' }}>
          <ContentContainer>
            <Title
              lineHeight="32px"
              fontFamily="Canela, serif"
              fontWeight={700}
              color={PRIMARY_COLOR}
            >
              Account
            </Title>
            <ContainerText>{user.email}</ContainerText>
            <ContainerText>
              {user.firstName} {user.lastName}
            </ContainerText>
          </ContentContainer>
          <LinkWrapper>
            <Link component={RouterLink} to="/mytrips/manual" underline="none">
              <LinkContent>
                <PinAltFillStyled />
                <LinkText>My Trips</LinkText>
                <KeyboardArrowRightIconStyled />
              </LinkContent>
            </Link>
            <Link component={RouterLink} to="/favorites/trips" underline="none">
              <LinkContent>
                <FavoriteIconStyled color="primary" />
                <LinkText>Favorites</LinkText>
                <KeyboardArrowRightIconStyled />
              </LinkContent>
            </Link>
            <Stack>
              <LinkContent onClick={handleLogout}>
                <MdiIconStyled icon={mdiAccount} color="primary" />
                <LinkText>Log out</LinkText>
                <KeyboardArrowRightIconStyled />
              </LinkContent>
            </Stack>
          </LinkWrapper>
        </Stack>
      </Stack>
      <Footer />
    </>
  ) : (
    <NotFoundContainer />
  );
}

const ContentContainer = styled(Stack)`
  margin: 14px 0;
  align-items: center;

  ${device.tablet} {
    margin: 36px;
  }

  ${device.laptopN} {
    align-items: flex-start;
    margin: 50px 76px;
  }
`;
const Title = styled(Tex)`
  margin-bottom: 8px;
  font-size: 24px;

  ${device.laptopN} {
    margin-bottom: 12px;
    font-size: 32px;
  }
`;

const ContainerText = styled(Tex)`
  font-size: 16px;
  margin: 2px 0;
  color: ${PRIMARY_COLOR};

  ${device.laptopN} {
    margin: 4px 0;
  }
`;

const LinkWrapper = styled(Stack)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  min-height: 300px;

  ${device.tablet} {
    gap: 12px;
    min-height: 600px;
  }
  ${device.laptopN} {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
    min-height: 400px;
  }
`;

const LinkContent = styled(Stack)`
  display: flex;
  flex-direction: row;
  background: #fff;
  align-items: center;
  justify-content: flex-start;
  padding: 0 8px;
  gap: 8px;
  flex-shrink: 0;
  cursor: pointer;
  position: relative;

  width: 100%;
  min-width: 320px;
  height: 56px;

  ${device.tablet} {
    justify-content: center;
    align-items: center;
    width: 310px;
    height: 160px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #fff;
    gap: 4px;
  }

  ${device.laptopN} {
    width: 310px;
    height: 160px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #fff;
  }
`;

const PinAltFillStyled = styled(PinAltFill)`
  width: 32px;
  height: 32px;

  ${device.tablet} {
    width: 50px;
    height: 50px;
  }
`;

const FavoriteIconStyled = styled(FavoriteIcon)`
  width: 28px;
  height: 28px;

  ${device.tablet} {
    width: 46px;
    height: 46px;
  }
`;

const MdiIconStyled = styled(MdiIcon)`
  width: 28px;
  height: 28px;

  ${device.tablet} {
    width: 46px;
    height: 46px;
  }
`;

const LinkText = styled(Tex)`
  font-family: 'CircularStd-Book', sans-serif;
  color: #174240;
  font-size: 20px;
  font-weight: 500;
`;

const KeyboardArrowRightIconStyled = styled(KeyboardArrowRightIcon)`
  position: absolute;
  right: 10px;
  top: 12px;
  font-size: 32px;
  color: ${PRIMARY_COLOR};

  ${device.tablet} {
    display: none;
  }
`;
