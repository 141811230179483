import { Button } from '@mui/material';
import { MdiIcon } from './shared/MdiIcon';

interface Props {
  icon: string;
  text: string;
  url: string;
}

export function VMTPlaceExternalLinkBook(props: Props): JSX.Element {
  const { icon, text, url } = props;

  return (
    <Button
      href={url}
      target="_blank"
      color="primary"
      variant="contained"
      sx={{
        px: 3,
        py: 1,
        borderRadius: 9999,
        height: '25px',
        width: '80px',
        fontSize: '10px',
        overflow: 'visible',
      }}
    >
      <MdiIcon icon={icon} sx={{ mr: 1, fontSize: '20px' }} />
      {text}
    </Button>
  );
}
