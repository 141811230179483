import { useState } from 'react';
import { LogoHeader } from '../../components/LogoHeader';
import { Footer } from '../../components/Footer';
import { Stack } from '@mui/material';
import { useNeighborhoodPath } from '../../hooks/use-neighborhood';
import { useParams } from 'react-router-dom';
import { Tex } from '../../components/shared/Tex';
//TODO uncomment when mytrips be ready
import { Button } from '@mui/material';
import { common } from '@mui/material/colors';
import styled from '@emotion/styled';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
  device,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
} from 'src/settings/jaunt-theme';
import { NeighborhoodStay } from './NeighborhoodStay';
import { NeighborhoodEat } from './NeighborhoodEat';
import { NeighborhoodDrink } from './NeighborhoodDrink';
import { NeighborhoodDo } from './NeighborhoodDo';
import { useCity } from 'src/hooks/use-city';
import { usePlacesByNeighborhood } from 'src/hooks/use-paces-by-neighborhood';
import { usePlacesTags } from 'src/hooks/use-places-tags';
import { useTags } from 'src/hooks/use-tags';
import { PlanTripDialog } from 'src/components/plan-trip-dialog/PlanTripDialog';
import { useCities } from 'src/hooks/use-cities';
import { useCitiesWithImageUrl } from 'src/hooks/use-cities-with-image-url';
import { useFirstNeighborhoodImageUrl } from 'src/hooks/use-first-neighborhood-image-url';
import { NotFoundContainer } from 'src/components/NotFoundContainer';
import { LoadingContainer } from 'src/components/loading/LoadingContainer';

export function NeighborhoodPage(): JSX.Element {
  const params = useParams();
  const neighborhoodPath = params.neighborhoodPath || null;
  const { data: neighborhood, isLoading: isNeighborhoodLoading } =
    useNeighborhoodPath(neighborhoodPath);
  const { data: city } = useCity(neighborhood?.cityId);
  const { data: placesByNeighborhood } = usePlacesByNeighborhood(
    neighborhood?.neighborhoodId,
  );
  const { data: placesTags } = usePlacesTags(placesByNeighborhood);
  const { data: tags } = useTags();
  const { data: cities } = useCities();
  const { data: citiesWithImageUrl } = useCitiesWithImageUrl(cities);
  const { data: neighborhoodImageUrl } = useFirstNeighborhoodImageUrl(
    neighborhood?.neighborhoodId,
  );
  const [isPlanTripDialogOpen, setIsPlanTripDialogOpen] = useState(false);

  if (isNeighborhoodLoading) {
    return <LoadingContainer />;
  }

  return neighborhood?.neighborhoodId ? (
    <>
      <LogoHeader showAuthLinks />
      <Stack className="main">
        <Stack
          spacing={6}
          sx={{ margin: '0 auto', width: '100%', maxWidth: '1440px' }}
        >
          <Stack>
            <HeaderImage
              key={neighborhood.neighborhoodId}
              alt={neighborhood.name}
              src={neighborhoodImageUrl ? neighborhoodImageUrl : ''}
              placeholder={<Stack className="placeholder-block" />}
            />
            <DescriptionContainer spacing={3}>
              <Tex
                align="center"
                fontFamily="Canela"
                fontSize={26}
                fontWeight={700}
                lineHeight="26px"
                color={common.white}
                sx={{ mt: 2 }}
              >
                {neighborhood.name}
              </Tex>
              {city?.name ? (
                <Tex
                  align="center"
                  fontWeight={500}
                  fontSize={24}
                  color={common.white}
                  sx={{ marginTop: '0 !important' }}
                >
                  {city.name}
                </Tex>
              ) : null}
              <Tex
                align="center"
                fontWeight={300}
                color={common.white}
                sx={{ marginTop: '12px !important' }}
              >
                {neighborhood.description}
              </Tex>
            </DescriptionContainer>
          </Stack>
          <NeighborhoodStay
            tags={tags}
            places={placesByNeighborhood}
            placesTags={placesTags}
            neighborhoodPath={neighborhoodPath}
            cityPath={city?.cityPath}
          />
          <NeighborhoodEat
            tags={tags}
            places={placesByNeighborhood}
            placesTags={placesTags}
            neighborhoodPath={neighborhoodPath}
            cityPath={city?.cityPath}
          />
          <NeighborhoodDrink
            tags={tags}
            places={placesByNeighborhood}
            placesTags={placesTags}
            neighborhoodPath={neighborhoodPath}
            cityPath={city?.cityPath}
          />
          <NeighborhoodDo
            tags={tags}
            places={placesByNeighborhood}
            placesTags={placesTags}
            neighborhoodPath={neighborhoodPath}
            cityPath={city?.cityPath}
          />
        </Stack>
        {/*//TODO uncomment when mytrips be ready */}
        <Stack alignItems="center" width="100%">
          <CreateYourTripButton
            variant="contained"
            onClick={() => setIsPlanTripDialogOpen(true)}
          >
            Create Your Trip
          </CreateYourTripButton>
        </Stack>
      </Stack>
      <Footer />
      {citiesWithImageUrl ? (
        <PlanTripDialog
          isOpen={isPlanTripDialogOpen}
          citiesWithImageUrl={citiesWithImageUrl}
          onClose={() => setIsPlanTripDialogOpen(false)}
        />
      ) : null}
    </>
  ) : (
    <NotFoundContainer />
  );
}

const HeaderImage = styled(LazyLoadImage)`
  background-size: cover;
  background-position: center;
  background-color: ${SECONDARY_COLOR};
  object-fit: cover;
  height: 446px;
  width: 100%;

  ${device.tablet} {
    height: 500px;
  }

  ${device.laptopN} {
    height: 700px;
  }

  & .placeholder-block {
    height: 500px;
    background-color: ${SECONDARY_COLOR};

    ${device.tablet} {
      height: 500px;
    }

    ${device.laptopN} {
      height: 700px;
    }
  }
`;

const DescriptionContainer = styled(Stack)`
  margin: -50px auto 0;
  padding: 8px 16px;
  background-color: ${PRIMARY_COLOR};
  border-radius: 20px;
  max-width: 390px;
  width: 94%;
  min-height: 210px;

  ${device.tablet} {
    margin-top: -100px;
    padding: 16px 24px;
    max-width: 730px;
  }

  ${device.laptopN} {
    margin-top: -160px;
    max-width: 860px;
  }
`;
//TODO uncomment when mytrips be ready
const CreateYourTripButton = styled(Button)`
  padding: 8px 16px;
  margin: 24px 0 40px;
  border-radius: 9999px;
  background-color: ${PRIMARY_COLOR};
  height: 46px;
  width: 80%;
  max-width: 260px;
`;
