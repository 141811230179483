import { CityTrip, ReplaceTripCityData, TripId } from '@jaunt/api';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import QueryKeys from './constants/query-keys';
import { getTripCity, replaceTripCity } from '../services/trip.api';
import { NEW_TRIP } from '../constants';

export function useTripCity(
  tripId?: TripId | null,
): UseQueryResult<CityTrip | undefined, unknown> {
  return useQuery<CityTrip | undefined>(
    [QueryKeys.tripCity, tripId],
    async () => {
      if (!tripId || tripId === NEW_TRIP) return undefined;
      return await getTripCity(tripId);
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading Trip: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!tripId,
    },
  );
}

export const useRefetchTripCity = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async (tripId?: TripId | null) => {
      return tripId ? await getTripCity(tripId) : undefined;
    },
    {
      onSuccess: (cityTrip, tripId) => {
        client.setQueryData([QueryKeys.tripCity, tripId], cityTrip);
      },
    },
  );
  return { refetchTripCity: mutateAsync, isLoading, isSuccess, isError };
};

export const useReplaceTripCity = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({ tripId, data }: { tripId: TripId; data: ReplaceTripCityData }) => {
      return await replaceTripCity(tripId, data);
    },
    {
      onSuccess: (_, { tripId }) => {
        client.invalidateQueries(QueryKeys.trips);
        client.invalidateQueries([QueryKeys.tripCity, tripId]);
        client.invalidateQueries([QueryKeys.trips, tripId]);
      },
    },
  );
  return { replaceTripCity: mutateAsync, isLoading, isSuccess, isError };
};
