import { Button, Stack } from '@mui/material';
import { useState } from 'react';
import { Tex } from '../shared/Tex';
import styled from '@emotion/styled';
import { device } from '../../settings/jaunt-theme';

interface Props {
  onChange(days: number): void;
}

export function PlanTripDaysStep(props: Props): JSX.Element {
  const { onChange } = props;
  const [days, setDays] = useState(0);

  return (
    <Stack spacing={6}>
      <Tex color="primary" textAlign="center" fontSize={20}>
        How many days is your trip?
      </Tex>
      <ControlWrapper>
        <ControlButton
          variant="text"
          onClick={() => setDays(days - 1)}
          disabled={!days}
        >
          &ndash;
        </ControlButton>
        <DaysContainer>
          <DaysText>{days}</DaysText>
        </DaysContainer>
        <ControlButton
          variant="text"
          onClick={() => setDays(days + 1)}
          disabled={days === 7}
        >
          &#43;
        </ControlButton>
      </ControlWrapper>
      <Stack alignItems="center">
        <Button
          variant="contained"
          onClick={() => onChange(days)}
          disabled={days === 0}
          sx={{ px: 6, py: 1, borderRadius: '9999px' }}
        >
          NEXT
        </Button>
      </Stack>
    </Stack>
  );
}

const ControlWrapper = styled(Stack)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

const DaysContainer = styled(Stack)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85px;
  width: 85px;
  border-radius: 20px;
  background-color: #f1eee5;

  &:hover {
    background-color: #f1eee5;
  }

  ${device.tablet} {
    height: 97px;
    width: 97px;
  }
`;

const DaysText = styled(Tex)`
  color: #505050;
  font-weight: 500;
  font-size: 50px;
  line-height: 64px;
`;

const ControlButton = styled(Button)`
  font-size: 36px;
  line-height: 40px;
  color: #505050;
  height: 56px;
  width: 56px;
  min-width: 56px;
  border-radius: 100%;
  background-color: #f1eee5;
  &:hover {
    background-color: #f1eee5;
  }
`;
