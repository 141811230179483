import { useParams } from 'react-router-dom';
import { NoTripIdContainer } from '../components/NoTripIdContainer';
import { LogoHeader } from '../components/LogoHeader';
import { Button, Chip, Collapse, Stack } from '@mui/material';
import { Tex } from 'src/components/shared/Tex';
import { common } from '@mui/material/colors';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import { device, PRIMARY_COLOR } from 'src/settings/jaunt-theme';
import { MdiIcon } from 'src/components/shared/MdiIcon';
import styled from '@emotion/styled';
import { useMemo, useState } from 'react';
import { usePublicGeneratedTrip } from 'src/hooks/use-generated-trips';

import {
  ActivityDayGPT,
  ActivityGPT,
  CityDetailsGPTDetails,
  HotelGPT,
} from '@jaunt/api';
import { LoadingContainerGPT } from 'src/components/loading/LoadingContainerGPT';
import { Footer } from 'src/components/Footer';
import { LoadingContainer } from '../components/loading/LoadingContainer';

interface CityDetail {
  title: string;
  text: string;
}

const getCityDetails = (cityDetails: CityDetailsGPTDetails): CityDetail[] => {
  const details = [];
  if (cityDetails?.weather) {
    details.push({
      title: 'When To Go.',
      text: cityDetails.weather,
    });
  }

  if (cityDetails?.lodging) {
    details.push({
      title: 'Where To Stay.',
      text: cityDetails.lodging,
    });
  }

  if (cityDetails?.traffic) {
    details.push({
      title: 'Getting Around.',
      text: cityDetails.traffic,
    });
  }

  if (cityDetails?.notes) {
    details.push({
      title: 'Tips And Tricks.',
      text: cityDetails.notes,
    });
  }

  return details;
};

const getActivityTags = ({
  hotels,
  activityList,
}: {
  hotels?: HotelGPT[];
  activityList?: ActivityGPT[];
}): string[] => {
  const tags = [];
  if (hotels?.length) {
    tags.push('Stay');
  }

  if (activityList?.length) {
    activityList.forEach((activity, index) => {
      tags.push(activity.day ?? `Activity ${index + 1}`);
    });
  }

  return tags;
};

export function PublicGeneratedTripPage(): JSX.Element {
  const params = useParams();
  const tripPath = params.tripPath || null;
  const [isExpanded, setIsExpanded] = useState(true);
  const [chosenActivity, setChosenActivity] = useState<string | undefined>(
    undefined,
  );
  const { data: generatedTrip, isLoading } = usePublicGeneratedTrip(tripPath);

  const tripDescription = useMemo(() => {
    if (generatedTrip) {
      return generatedTrip.city?.description;
    }
    return '';
  }, [generatedTrip]);

  const details = useMemo(() => {
    if (generatedTrip) {
      return getCityDetails(generatedTrip.city.detail);
    }

    return [];
  }, [generatedTrip]);

  const activityTags = useMemo(() => {
    if (generatedTrip) {
      const newActivityTags = getActivityTags({
        hotels: generatedTrip.details?.hotels,
        activityList: generatedTrip.details?.activities,
      });
      setChosenActivity(newActivityTags[0]);
      return newActivityTags;
    }
    return [];
  }, [generatedTrip]);

  const activityObj = useMemo(() => {
    if (generatedTrip?.details?.activities?.length) {
      return generatedTrip.details.activities.reduce(
        (acc: Record<string, ActivityDayGPT[]>, item: ActivityGPT) => {
          acc[item.day] = item.activity_detail_list;
          return acc;
        },
        {},
      );
    }

    return {};
  }, [generatedTrip]);

  if (isLoading) {
    return <LoadingContainer />;
  }

  return generatedTrip ? (
    <>
      <LogoHeader showAuthLinks={true} />
      <Stack className="main">
        <Stack sx={{ mb: 2, width: '100%' }}>
          <Wrapper>
            <TripDescriptionWrapper>
              <TripDescriptionContainer spacing={3}>
                <Tex
                  align="center"
                  fontFamily="canela-bold"
                  fontSize={24}
                  fontWeight={700}
                  color={common.white}
                  sx={{ mt: 2, textTransform: 'capitalize' }}
                >
                  {generatedTrip.name}
                </Tex>
                {tripDescription ? (
                  <TripDescription>{tripDescription}</TripDescription>
                ) : null}
                {details.length ? (
                  <Stack spacing={1}>
                    <Collapse in={isExpanded}>
                      <Stack spacing={1.5}>
                        {details.map((detail) => (
                          <Stack
                            key={detail.title}
                            sx={{
                              p: 1.5,
                              backgroundColor: common.white,
                              borderRadius: 4,
                            }}
                          >
                            <Tex>
                              <Tex component="span" fontWeight={700}>
                                {detail.title}{' '}
                              </Tex>
                              <Tex component="span" fontWeight={300}>
                                {detail.text}
                              </Tex>
                            </Tex>
                          </Stack>
                        ))}
                      </Stack>
                    </Collapse>
                    <Button
                      onClick={() => setIsExpanded((previous) => !previous)}
                    >
                      <Tex sx={{ color: common.white, fontSize: '13px' }}>
                        {isExpanded ? 'See Less' : 'See More'}
                      </Tex>
                      <MdiIcon
                        icon={isExpanded ? mdiChevronUp : mdiChevronDown}
                        sx={{
                          ml: 1,
                          color: common.white,
                          fontSize: '13px',
                        }}
                      />
                    </Button>
                  </Stack>
                ) : null}
              </TripDescriptionContainer>
            </TripDescriptionWrapper>
            <ContentWrapper>
              <ContentContainer>
                {isLoading || !activityTags.length ? (
                  <LoadingWrapper>
                    <LoadingContainerGPT />
                  </LoadingWrapper>
                ) : (
                  <Stack
                    spacing={2}
                    sx={{
                      position: 'relative',
                      flex: 1,
                    }}
                  >
                    {activityTags.length ? (
                      <Stack direction="row" spacing={1}>
                        {activityTags.map((activity) => (
                          <Chip
                            key={activity}
                            label={activity}
                            color={
                              chosenActivity === activity
                                ? 'primary'
                                : 'default'
                            }
                            onClick={() => setChosenActivity(activity)}
                            sx={{
                              backgroundColor:
                                chosenActivity === activity
                                  ? 'none'
                                  : common.white,
                              textTransform: 'capitalize',
                            }}
                          />
                        ))}
                      </Stack>
                    ) : null}
                    {chosenActivity === 'Stay' &&
                    generatedTrip?.details?.hotels &&
                    Array.isArray(generatedTrip.details.hotels) ? (
                      <ActivityWrapper>
                        {generatedTrip.details.hotels.map((hotel: HotelGPT) => (
                          <PlaceWrapper key={hotel.hotel_name}>
                            <Tex fontWeight="700">{hotel.hotel_name}</Tex>
                            <Tex fontWeight="300">
                              {hotel.hotel_description}
                            </Tex>
                          </PlaceWrapper>
                        ))}
                      </ActivityWrapper>
                    ) : null}
                    {chosenActivity && activityObj[chosenActivity] ? (
                      <ActivityWrapper>
                        {activityObj[chosenActivity].map(
                          (activity: ActivityDayGPT) => (
                            <PlaceWrapper key={activity.activity_name}>
                              <Tex fontWeight="700">
                                {activity.activity_name}
                              </Tex>
                              <Tex fontWeight="300">
                                {activity.activity_description}
                              </Tex>
                            </PlaceWrapper>
                          ),
                        )}
                      </ActivityWrapper>
                    ) : null}
                  </Stack>
                )}
              </ContentContainer>
            </ContentWrapper>
          </Wrapper>
        </Stack>
      </Stack>
      <Footer />
    </>
  ) : (
    <NoTripIdContainer />
  );
}
const Wrapper = styled(Stack)`
  flex-direction: column;

  ${device.tablet} {
    flex-direction: row;
    width: 770px;
    margin: 6px auto 0;
  }

  ${device.laptopN} {
    max-width: none;
    width: 830px;
    margin: 20px auto 0;
  }

  ${device.desktop} {
    width: 870px;
    margin: 40px auto 0;
  }
`;

const TripDescriptionWrapper = styled(Stack)`
  width: 100%;
  margin-top: 20px;
  ${device.tablet} {
    width: 50%;
    min-width: 300px;
    border-right: solid 1px #dadada;
  }
`;

const TripDescription = styled(Tex)`
  text-align: center;
  font-weight: 300;
  font-size: 16px;
  color: ${common.white};
  margin-top: 16px !important;
  line-height: 24px;
`;

const LoadingWrapper = styled(Stack)`
  position: relative;
  height: 200px;
  width: 100%;
`;

const TripDescriptionContainer = styled(Stack)`
  margin: 0 auto;
  padding: 8px 16px;
  background-color: ${PRIMARY_COLOR};
  border-radius: 20px;
  max-width: 390px;
  width: 94%;

  ${device.tablet} {
    padding: 16px 24px;
    width: 368px;
  }
`;

const ContentWrapper = styled(Stack)`
  width: 100%;

  ${device.tablet} {
    width: 50%;
    min-width: 300px;
  }
`;

const ContentContainer = styled(Stack)`
  width: 94%;
  max-width: 390px;
  margin: 20px auto 4px;

  ${device.tablet} {
    width: 368px;
  }
`;

const ActivityWrapper = styled(Stack)`
  width: 100%;
  margin: 20px auto 0 !important;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  gap: 16px;

  ${device.tablet} {
    gap: 11px;
  }
`;

const PlaceWrapper = styled(Stack)`
  width: 100%;
  padding: 16px;
  background: #fff;
  border-radius: 20px;
`;
