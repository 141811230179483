import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Tex } from '../components/shared/Tex';
import { PRIMARY_COLOR, device } from '../settings/jaunt-theme';
import { LogoHeader } from '../components/LogoHeader';
import { grey } from '@mui/material/colors';
import { ExploreImageCard } from 'src/components/ExploreImageCard';
import { useCitiesWithImageUrl } from 'src/hooks/use-cities-with-image-url';
import { useCuratedTripsWithImageUrl } from 'src/hooks/use-curated-trips-with-image-url';
import { Footer } from 'src/components/Footer';
import { useCities } from 'src/hooks/use-cities';
import { LoadingContainer } from 'src/components/loading/LoadingContainer';
import { NotFoundContainer } from 'src/components/NotFoundContainer';
import styled from '@emotion/styled';
import {
  useCreateFavTrip,
  useDeleteFavTrip,
  useFavoriteTrips,
} from '../hooks/use-favorite-trips';
import {
  useCreateFavCity,
  useDeleteFavCity,
  useFavoriteCities,
} from '../hooks/use-favorite-cities';

enum PageKey {
  CITIES = 'cities',
  TRIPS = 'trips',
}

const tabs = [
  { key: PageKey.CITIES, url: '/explore/cities', label: 'Cities' },
  { key: PageKey.TRIPS, url: '/explore/trips', label: 'Trips' },
];

export function ExplorePage(): JSX.Element {
  const { pageKey } = useParams();
  const { data: cities, isLoading: isCitiesLoading } = useCities();
  const { data: citiesWithImageUrl, isLoading: isImageLoading } =
    useCitiesWithImageUrl(cities);
  const { data: tripsWithImageUrl } = useCuratedTripsWithImageUrl();

  const { data: favoriteTrips } = useFavoriteTrips();
  const { createFavTrip } = useCreateFavTrip();
  const { deleteFavTrip } = useDeleteFavTrip();
  const { data: favoriteCities } = useFavoriteCities();
  const { createFavCity } = useCreateFavCity();
  const { deleteFavCity } = useDeleteFavCity();

  const favTripsObj = useMemo(() => {
    if (favoriteTrips) {
      return favoriteTrips.reduce((acc: Record<string, boolean>, item) => {
        acc[item.tripId] = true;
        return acc;
      }, {});
    }
    return {};
  }, [favoriteTrips]);

  const favCitiesObj = useMemo(() => {
    if (favoriteCities) {
      return favoriteCities.reduce((acc: Record<string, boolean>, item) => {
        acc[item.cityId] = true;
        return acc;
      }, {});
    }
    return {};
  }, [favoriteCities]);

  async function handleToggleFavCities(cityId: string, isNewFav: boolean) {
    if (isNewFav) {
      await createFavCity({ cityId });
    } else {
      await deleteFavCity({ cityId });
    }
  }
  async function handleToggleFavTrips(tripId: string, isNewFav: boolean) {
    if (isNewFav) {
      await createFavTrip({ tripId });
    } else {
      await deleteFavTrip({ tripId });
    }
  }

  if (isCitiesLoading || isImageLoading) {
    return <LoadingContainer />;
  }

  return cities ? (
    <>
      <LogoHeader showAuthLinks={true} />
      <Stack className="main">
        <Stack sx={{ mb: 2, width: '100%' }}>
          <ContentBlock>
            <Stack
              direction="row"
              justifyContent="center"
              borderBottom={`1px solid ${grey[400]}`}
            >
              {tabs.map((tab) => (
                <TabContainer
                  key={tab.key}
                  to={tab.url}
                  component={RouterLink}
                  underline="none"
                >
                  <Tex
                    textAlign="center"
                    sx={{
                      py: 0.5,
                      ...(tab.key === pageKey
                        ? {
                            borderBottom: `3px solid ${PRIMARY_COLOR}`,
                            fontWeight: 700,
                            color: PRIMARY_COLOR,
                          }
                        : {}),
                    }}
                  >
                    {tab.label}
                  </Tex>
                </TabContainer>
              ))}
            </Stack>

            {pageKey === 'cities' ? (
              <Stack alignItems="center" spacing={2} sx={{ p: 1, pt: 4 }}>
                <TripsWrapper spacing={1.5}>
                  {citiesWithImageUrl
                    ?.filter(({ city }) => !city.isHidden)
                    .map((cityWithImageUrl) => (
                      <ExploreImageCard
                        key={cityWithImageUrl.city.cityId}
                        id={cityWithImageUrl.city.cityId}
                        title={cityWithImageUrl.city.name}
                        description={cityWithImageUrl.city.state}
                        imageUrl={cityWithImageUrl.imageUrl}
                        routePath={`/cities/${cityWithImageUrl.city.cityPath}`}
                        isFavorite={favCitiesObj[cityWithImageUrl.city.cityId]}
                        onToggleFavorite={handleToggleFavCities}
                      />
                    ))}
                </TripsWrapper>
              </Stack>
            ) : (
              <Stack alignItems="center" spacing={2} sx={{ p: 1, pt: 4 }}>
                <TripsWrapper spacing={1.5}>
                  {tripsWithImageUrl
                    ?.filter(({ trip }) => !trip.isHidden)
                    .map((tripWithImageUrl) => (
                      <ExploreImageCard
                        key={tripWithImageUrl.trip.tripId}
                        id={tripWithImageUrl.trip.tripId}
                        title={tripWithImageUrl.trip.name}
                        tip={`${
                          Object.keys(tripWithImageUrl.trip.detail.days)
                            ?.length || '0'
                        }-Day Jaunt`}
                        description={`Curated by ${tripWithImageUrl.trip.detail.createdBy}`}
                        imageUrl={tripWithImageUrl.imageUrl}
                        routePath={`/trips/${tripWithImageUrl.trip.tripPath}?isCurated=true`}
                        isFavorite={favTripsObj[tripWithImageUrl.trip.tripId]}
                        onToggleFavorite={handleToggleFavTrips}
                      />
                    ))}
                </TripsWrapper>
              </Stack>
            )}
          </ContentBlock>
        </Stack>
      </Stack>
      <Footer />
    </>
  ) : (
    <NotFoundContainer />
  );
}

const ContentBlock = styled(Stack)`
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
`;

const TabContainer = styled(Link)`
  padding-left: 16px;
  padding-right: 16px;
  color: inherit;
  text-underline: none;
  width: ${100 / tabs.length}%;

  ${device.tablet} {
    width: ${50 / tabs.length}%;
  }

  ${device.laptopN} {
    width: ${50 / tabs.length}%;
  }
` as typeof Link;

const TripsWrapper = styled(Stack)`
  margin: 30px auto 0 !important;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  gap: 22px;

  ${device.tablet} {
    margin: 32px auto 0 !important;
    max-width: 674px;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 22px;
    justify-content: center;
  }

  ${device.laptopN} {
    margin: 32px auto 0 !important;
    max-width: 1100px;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 22px;
  }

  ${device.desktop} {
    margin: 32px auto 0 !important;
    max-width: 1100px;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 22px;
  }
`;
