import { CityId } from '@jaunt/api';
import {
  getCityCuratedTripsWithImageUrl,
  TripWithImageUrl,
} from '../services/trip.util';
import { useQuery, UseQueryResult } from 'react-query';
import QueryKeys from './constants/query-keys';

export function useCityCuratedTripWithImageUrls(
  cityId: CityId | null | undefined,
): UseQueryResult<TripWithImageUrl[], unknown> {
  return useQuery<TripWithImageUrl[]>(
    [QueryKeys.cityCuratedTripWithImageUrls, cityId],
    async () => {
      return cityId ? await getCityCuratedTripsWithImageUrl(cityId) : [];
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading CityCuratedTripWithImageUrls: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!cityId,
    },
  );
}
