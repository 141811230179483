import { Place, PlaceId } from '@jaunt/api';
import { FormSelectOption } from '../components/shared/FormSelect';
import { getPlaceImages } from './place.api';
import { getFileDownloadUrl } from './file.api';

export interface PlaceWithImageUrl {
  place: Place;
  imageUrl: string;
}

export async function getFirstPlaceImageUrl(placeId: PlaceId): Promise<string> {
  const [placeImage] = await getPlaceImages(placeId);
  return placeImage ? await getFileDownloadUrl(placeImage.fileId) : '';
}

export async function getPlacesWithImageUrl(
  places: Place[],
): Promise<PlaceWithImageUrl[]> {
  const imageUrls = await Promise.all(
    places.map((place) => getFirstPlaceImageUrl(place.placeId)),
  );
  return places.map((place, index) => ({ place, imageUrl: imageUrls[index] }));
}

export function getPlaceOptions(places: Place[]): FormSelectOption[] {
  return places.map((place) => ({
    value: place.placeId,
    label: place.name,
  }));
}
