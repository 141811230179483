import { Place, Tag } from '@jaunt/api';
import { useQueryClient } from 'react-query';
import QueryKeys from './constants/query-keys';
import { getPlaceTags } from 'src/services/place.api';
import { useEffect, useState } from 'react';

interface TagsByPlaces {
  data: Record<string, Tag[]>;
  isLoading?: boolean;
  error?: Error | null | string | unknown;
}

export function usePlacesTags(places?: Place[]): TagsByPlaces {
  const client = useQueryClient();
  const [newPlacesTags, setNewPlacesTags] = useState({ data: {} });

  async function getTagsByPlaces(places: Place[]) {
    return await Promise.all(
      places?.map(async ({ placeId }) => {
        const placeTags = client.getQueryData([QueryKeys.placeTags, placeId]);
        if (!placeTags) {
          const tags = await getPlaceTags(placeId);
          client.setQueryData([QueryKeys.placeTags, placeId], tags);
          return {
            placeId: placeId,
            tags,
          };
        }
        return {
          placeId: placeId,
          tags: placeTags as Tag[],
        };
      }),
    ).then((newTagsByPlaces) => {
      return newTagsByPlaces.reduce(
        (acc: TagsByPlaces, { placeId, tags }) => {
          acc.data[placeId] = tags ?? [];
          return acc;
        },
        { data: {} },
      );
    });
  }

  useEffect(() => {
    if (!places?.length) {
      setNewPlacesTags({ data: {} });
      return;
    }
    getTagsByPlaces(places).then(({ data }) => {
      setNewPlacesTags({ data: data });
    });
  }, [places]);

  return newPlacesTags;
}
