import { Link, Stack } from '@mui/material';
import { CityId, TagType } from '@jaunt/api';
import { device, PRIMARY_COLOR } from '../../settings/jaunt-theme';
import { Tex } from '../shared/Tex';
import { useCuratedPlacesByCity } from '../../hooks/use-places-by-single-city';
import { usePlacesTags } from '../../hooks/use-places-tags';
import { useTags } from '../../hooks/use-tags';
import { Link as RouterLink } from 'react-router-dom';
import { toQueryParams } from '../../services/base.util';
import { useMemo } from 'react';
import { PlacesTileGallery } from '../places-tile-gallery';
import styled from '@emotion/styled';

type CityDetailTopHotelsProps = {
  cityId?: CityId;
  cityPath: string | null;
};

export const CityDetailTopHotels = ({
  cityId,
  cityPath,
}: CityDetailTopHotelsProps): JSX.Element | null => {
  const { data: placesByCity } = useCuratedPlacesByCity(cityId);
  const { data: placesTags } = usePlacesTags(placesByCity);
  const { data: tags } = useTags();
  const stayTag = tags?.find(
    (tag) => tag.type === TagType.ACTIVITY && tag.name === 'Stay',
  );
  const stayPlaces = useMemo(() => {
    const placeIds = Object.keys(placesTags).filter((placeId) => {
      return placesTags[placeId]
        .map((tag) => tag.tagId)
        .includes(stayTag?.tagId ?? '');
    });

    return placesByCity
      ?.filter((place) => placeIds.includes(place.placeId))
      .slice(0, 4);
  }, [placesByCity, placesTags]);

  return stayPlaces?.length ? (
    <Container spacing={1}>
      <Title
        align="center"
        lineHeight="32px"
        fontFamily="Canela, serif"
        fontWeight={700}
        color={PRIMARY_COLOR}
      >
        Top Hotel Picks
      </Title>
      <TileGalleryWrapper>
        <PlacesTileGallery places={stayPlaces} placesTags={placesTags} />
      </TileGalleryWrapper>
      <Link
        component={RouterLink}
        to={
          cityPath
            ? `/places?${toQueryParams({ city: cityPath, tagName: 'Stay' })}`
            : '/'
        }
        margin="8px 0 20px 0 !important"
      >
        <Tex
          fontFamily="CircularStd-Light"
          fontWeight={300}
          fontSize={16}
          lineHeight="24px"
        >
          View All Hotels
        </Tex>
      </Link>
    </Container>
  ) : null;
};

const Container = styled(Stack)`
  padding: 0 8px;
  margin-top: 16px !important;

  ${device.tablet} {
    padding: 0 30px;
  }

  ${device.laptopN} {
    padding: 0 70px;
  }
`;

const TileGalleryWrapper = styled(Stack)`
  ${device.tablet} {
    max-width: 580px;
    margin: 0 auto !important;
  }

  ${device.laptopN} {
    max-width: 100%;
  }
`;

const Title = styled(Tex)`
  font-size: 28px;
  margin: 24px !important;

  ${device.laptopN} {
    font-size: 34px;
    margin-bottom: 36px !important;
  }
`;
