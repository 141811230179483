import { Box, IconButton, Link, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { common } from '@mui/material/colors';
import { Tex } from './shared/Tex';
import { TripId } from '@jaunt/api';
import { useTrip } from '../hooks/use-trip';
import { mdiChevronRight } from '@mdi/js';
import { MdiIcon } from './shared/MdiIcon';
import { useTripCity } from 'src/hooks/use-trip-city';
import { device, SECONDARY_COLOR } from 'src/settings/jaunt-theme';
import { useFirstCityImageUrl } from 'src/hooks/use-first-city-image-url';
import styled from '@emotion/styled';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';

interface Props {
  tripId: TripId;
  isFavorite: boolean;
  onToggleFavorite: (id: string, isNewFav: boolean) => void;
}

export function TripCard(props: Props): JSX.Element {
  const { tripId, isFavorite, onToggleFavorite } = props;
  const { data: trip } = useTrip(tripId);
  const { data: cityTrip } = useTripCity(tripId);
  const { data: cityImageUrl } = useFirstCityImageUrl(cityTrip?.cityId);

  async function handleToggleFavorite(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    onToggleFavorite(tripId, !isFavorite);
  }

  return trip ? (
    <Link
      component={RouterLink}
      to={`/trips/${trip?.tripPath}?preview=true`}
      underline="none"
    >
      <Container cityimageurl={cityImageUrl}>
        <>
          <Stack
            sx={{
              position: 'absolute',
              top: '8px',
              left: '8px',
              py: 1,
              px: 1.5,
            }}
            onClick={handleToggleFavorite}
          >
            {isFavorite ? (
              <FavoriteOutlinedIcon fontSize="large" htmlColor="#FE0000" />
            ) : (
              <FavoriteBorderOutlinedIcon
                fontSize="large"
                htmlColor="#CCD2E3"
              />
            )}
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              p: 1,
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              color: common.white,
            }}
          >
            <Box>
              <Tex>
                <Tex component="span" fontWeight={700} fontSize={20}>
                  {trip.name}
                </Tex>{' '}
              </Tex>
            </Box>
            <IconButton>
              <MdiIcon
                icon={mdiChevronRight}
                sx={{ color: common.white, fontSize: 40 }}
              />
            </IconButton>
          </Stack>
        </>
      </Container>
    </Link>
  ) : (
    <></>
  );
}

const Container = styled(Stack)<{ cityimageurl?: string | null }>`
  justify-content: flex-end;
  width: 350px;
  height: 400px;
  flex-shrink: 0;
  background-image: ${({ cityimageurl }) =>
    cityimageurl ? `url(${cityimageurl})` : null};
  background-size: cover;
  background-color: ${SECONDARY_COLOR};
  background-position: center;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;

  ${device.tablet} {
    width: 312px;
  }

  ${device.laptopN} {
    width: 300px;
  }
`;
