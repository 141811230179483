import { Place, PlaceData, PlaceId } from '@jaunt/api';
import {
  createPlace,
  deletePlace,
  getPlace,
  getPlaceByPath,
  updatePlace,
} from '../services/place.api';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import QueryKeys from './constants/query-keys';
import { useMemo } from 'react';

export function usePlace(
  placeId: PlaceId | null,
): UseQueryResult<Place | null, unknown> {
  return useQuery<Place | null>(
    [QueryKeys.places, placeId],
    async () => {
      return placeId ? await getPlace(placeId) : null;
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading Place: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!placeId,
    },
  );
}

export function usePlacePath(placePath?: string | undefined | null) {
  const client = useQueryClient();
  const result = useQuery<Place | undefined>(
    [QueryKeys.places, 'path', placePath],
    async () => {
      return placePath ? await getPlaceByPath(placePath) : undefined;
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading PlacePath: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!placePath,
    },
  );
  return useMemo(() => {
    const places: Place[] = client.getQueryData(QueryKeys.places) ?? [];
    const place =
      placePath && places.length > 0
        ? places.find((place) => place?.placePath === placePath)
        : null;
    if (place) {
      return {
        isSuccess: result.isSuccess,
        isError: result.isError,
        isLoading: result.isLoading,
        data: place,
      };
    } else {
      if (result.isSuccess && result.data) {
        client.setQueryData(QueryKeys.places, [...places, result.data]);
        client.setQueryData(
          [QueryKeys.places, result.data?.placeId],
          result.data,
        );
      }

      return {
        isSuccess: result.isSuccess,
        isError: result.isError,
        isLoading: result.isLoading,
        data: result.data,
      };
    }
  }, [result.isSuccess, result.isError, result.isLoading, result.data]);
}

export const useCreatePlace = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async (place: PlaceData) => {
      return await createPlace(place);
    },
    {
      onSuccess: (place) => {
        client.setQueryData([QueryKeys.places, place.placeId], place);
        client.invalidateQueries(QueryKeys.places);
      },
    },
  );
  return { createPlace: mutateAsync, isLoading, isSuccess, isError };
};

export const useUpdatePlace = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({ placeId, data }: { placeId: PlaceId; data: PlaceData }) => {
      return await updatePlace(placeId, data);
    },
    {
      onMutate: ({ placeId, data }) => {
        const oldPlace = client.getQueryData([QueryKeys.places, placeId]);

        client.setQueryData([QueryKeys.neighborhoods, placeId], (prevData) => {
          if (prevData && prevData instanceof Object) {
            return {
              ...prevData,
              ...data,
            };
          }
          return null;
        });

        return oldPlace;
      },
      onSuccess: (place) => {
        client.invalidateQueries(QueryKeys.places);
        client.setQueryData([QueryKeys.places, place.placeId], place);
      },
      onError: (_, { placeId }, oldPlace) => {
        client.setQueryData([QueryKeys.neighborhoods, placeId], oldPlace);
      },
    },
  );
  return { updatePlace: mutateAsync, isLoading, isSuccess, isError };
};

export const useDeletePlace = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({ placeId }: { placeId: PlaceId }) => {
      await deletePlace(placeId);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(QueryKeys.places);
      },
    },
  );
  return { deletePlace: mutateAsync, isLoading, isSuccess, isError };
};
