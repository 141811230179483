import { PlaceId, Tag, TagId } from '@jaunt/api';
import { getPlaceTags, replacePlaceTags } from 'src/services/place.api';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import QueryKeys from './constants/query-keys';

export function usePlaceTags(
  placeId: PlaceId | null | undefined,
): UseQueryResult<Tag[], unknown> {
  return useQuery<Tag[]>(
    [QueryKeys.placeTags, placeId],
    async () => {
      return placeId ? await getPlaceTags(placeId) : [];
    },
    {
      placeholderData() {
        return [];
      },
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading PlaceTags: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      refetchOnWindowFocus: false,
      staleTime: 120000,
      cacheTime: 120000,
      retryDelay: 2000,
      enabled: !!placeId,
    },
  );
}

export const useReplacePlaceTags = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({ placeId, data }: { placeId: PlaceId; data: TagId[] }) => {
      await replacePlaceTags(placeId, data);
    },
    {
      onSuccess: (placeTags, { placeId }) => {
        client.setQueryData([QueryKeys.placeTags, placeId], placeTags);
        client.invalidateQueries([QueryKeys.placeTags, placeId]);
      },
    },
  );
  return { replacePlaceTags: mutateAsync, isLoading, isSuccess, isError };
};
