import { useParams } from 'react-router-dom';
import { usePublicTripPath } from '../../hooks/use-trip';
import { Stack } from '@mui/material';
import { LogoHeader } from 'src/components/LogoHeader';
import { Footer } from '../../components/Footer';
import { NoTripIdContainer } from '../../components/NoTripIdContainer';
import { LoadingContainer } from 'src/components/loading/LoadingContainer';
import { useCity } from 'src/hooks/use-city';

import { useTripCity } from 'src/hooks/use-trip-city';
import { useFirstCityImageUrl } from '../../hooks/use-first-city-image-url';
import { useTripPlaces } from '../../hooks/use-trip-places';
import { useCuratedPlacesByCity } from '../../hooks/use-places-by-single-city';
import { usePlacesTags } from '../../hooks/use-places-tags';
import { PublicTripContent } from './PublicTripContent';

export function PublicTripPage(): JSX.Element {
  const params = useParams();
  const tripPath = params.tripPath || null;
  const { data: trip, isLoading } = usePublicTripPath(tripPath);
  const tripCityId = useTripCity(trip?.tripId);
  const { data: city, isLoading: isCityLoading } = useCity(
    tripCityId.data?.cityId,
  );
  const { data: places, isLoading: isPlacesLoading } = useCuratedPlacesByCity(
    city?.cityId,
  );
  const { data: placesTags } = usePlacesTags(places);
  const { data: cityImageUrl } = useFirstCityImageUrl(tripCityId.data?.cityId);
  const { data: tripPlaces } = useTripPlaces(trip?.tripId);

  if (isLoading || isCityLoading || isPlacesLoading) {
    return <LoadingContainer />;
  }

  return trip?.tripId && city?.cityId ? (
    <>
      <LogoHeader showAuthLinks={true} />
      <Stack className="main">
        <Stack sx={{ mb: 2, width: '100%' }}>
          <PublicTripContent
            trip={trip}
            city={city}
            places={places}
            cityImageUrl={cityImageUrl}
            tripPlaces={tripPlaces}
            placesTags={placesTags}
          />
        </Stack>
      </Stack>
      <Footer />
    </>
  ) : (
    <NoTripIdContainer />
  );
}
