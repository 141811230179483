import { Grid, MenuItem, SelectChangeEvent, Stack, Link } from '@mui/material';
import { LogoHeader } from '../components/LogoHeader';
import { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  NeighborhoodId,
  Place,
  PlaceId,
  TagId,
  TagType,
  TripId,
} from '@jaunt/api';
import { Tex } from '../components/shared/Tex';
import { useQuery } from '../hooks/use-query';
import { PRIMARY_COLOR, device } from '../settings/jaunt-theme';
import { PrimarySelect } from '../components/shared/PrimarySelect';
import { useCityPath } from '../hooks/use-city';
import { useCityNeighborhoods } from '../hooks/use-city-neighborhoods';
import { useTags } from '../hooks/use-tags';
import { useCuratedPlacesByCity } from '../hooks/use-places-by-single-city';
import { PlaceCardByCity } from '../components/PlaceCardByCity';
import { Footer } from '../components/Footer';
import { PlaceToTripDialog } from '../components/PlaceToTripDialog';
import { useMe } from '../hooks/use-me';
import { useCustomUserTripsByCity } from '../hooks/use-trip';
import { useLoadPlacesTrips } from '../hooks/use-trip-places';
import { NotFoundContainer } from 'src/components/NotFoundContainer';
import { LoadingContainer } from 'src/components/loading/LoadingContainer';
import {
  useCreateFavPlace,
  useDeleteFavPlace,
  useFavoritePlaces,
} from '../hooks/use-favorite-places';

export function PlacesPage(): JSX.Element {
  const query = useQuery();
  const cityPath = query.get('city');
  const tagName = query.get('tagName');
  const neighborhoodPath = query.get('neighborhood');
  const { data: city, isLoading: isCityLoading } = useCityPath(cityPath);
  const { data: placesByCity } = useCuratedPlacesByCity(city?.cityId);
  const { data: neighborhoods } = useCityNeighborhoods(city?.cityId);
  const { data: tags } = useTags();
  const navigate = useNavigate();
  const { data: userId } = useMe(true);
  const [chosenTagIds, setChosenTagIds] = useState<TagId[]>([]);
  const [chosenNeighborhoodIds, setChosenNeighborhoodIds] = useState<
    NeighborhoodId[]
  >([]);
  const [selectedPlace, setSelectedPlace] = useState<Place | null>();
  const { data: trips } = useCustomUserTripsByCity(userId, city?.cityId, false);
  const { loadPlacesTrips } = useLoadPlacesTrips();
  const [placesTrips, setPlacesTrips] = useState<Record<PlaceId, TripId[]>>();
  const [shouldUpdateTrips, setShouldUpdateTrips] = useState<boolean>(true);
  const { data: favoritePlaces } = useFavoritePlaces();
  const { createFavPlace } = useCreateFavPlace();
  const { deleteFavPlace } = useDeleteFavPlace();

  const activityTags = tags?.filter((x) => x.type === TagType.ACTIVITY) ?? [];

  useEffect(() => {
    if (!shouldUpdateTrips) return;
    let mounted = true;

    async function loadTrips() {
      if (trips?.length) {
        const tripIds = trips.map((trip) => trip.tripId);
        const placesTrips = await loadPlacesTrips(tripIds);

        if (mounted) {
          setPlacesTrips(placesTrips);
          setShouldUpdateTrips(false);
        }
      }
    }

    loadTrips();

    return () => {
      mounted = false;
    };
  }, [trips, shouldUpdateTrips]);

  useEffect(() => {
    parseInitialTag();
  }, [tags]);

  useEffect(() => {
    parseInitialNeighborhood();
  }, [neighborhoods]);

  const cityPlaceIds = useMemo(
    () => placesByCity?.map((place) => place.placeId) ?? [],
    [placesByCity],
  );

  const favPlacesIds = useMemo(() => {
    if (favoritePlaces?.length) {
      return favoritePlaces.reduce((acc: Record<string, boolean>, item) => {
        acc[item.placeId] = true;
        return acc;
      }, {});
    }
    return {};
  }, [favoritePlaces]);

  function parseInitialTag(): void {
    if (activityTags && tagName) {
      const initialTag = activityTags.find((x) => x.name === tagName);
      if (initialTag) {
        setChosenTagIds([initialTag.tagId]);
      }
    }
  }

  function parseInitialNeighborhood(): void {
    let initialNeighborhood;
    if (neighborhoodPath && neighborhoods?.length) {
      initialNeighborhood = neighborhoods.find(
        (neighborhood) => neighborhood.neighborhoodPath === neighborhoodPath,
      );
    }
    if (initialNeighborhood) {
      setChosenNeighborhoodIds([initialNeighborhood.neighborhoodId]);
    } else {
      setChosenNeighborhoodIds(
        neighborhoods?.map((neighborhood) => neighborhood.neighborhoodId) ?? [],
      );
    }
  }

  function parseSelectValues(
    event: SelectChangeEvent<string | string[]>,
  ): string[] {
    const { value } = event.target;
    return typeof value === 'string' ? value.split(',') : value;
  }

  function handleSelectPlace(place: Place) {
    if (!userId) {
      navigate('/login?redirectTo=back');
      return;
    }
    setSelectedPlace(place);
  }

  function handleCloseDialog() {
    setSelectedPlace(null);
    setShouldUpdateTrips(true);
  }

  function onSelectNeighborhood(event: SelectChangeEvent<string | string[]>) {
    setChosenNeighborhoodIds(parseSelectValues(event));
  }

  function onSelectActivity(event: SelectChangeEvent<string | string[]>) {
    setChosenTagIds(parseSelectValues(event));
  }

  async function handleToggleFavPlaces(placeId: string, isNewFav: boolean) {
    if (isNewFav) {
      await createFavPlace({ placeId });
    } else {
      await deleteFavPlace({ placeId });
    }
  }

  if (isCityLoading) {
    return <LoadingContainer />;
  }

  return city ? (
    <>
      <LogoHeader showAuthLinks={true} />
      <Stack className="main">
        <Stack sx={{ mb: 2, width: '100%' }}>
          <ContentBlock>
            {city && (
              <Stack spacing={1} sx={{ p: 2 }}>
                <Link
                  component={RouterLink}
                  to={`/cities/${city.cityPath}`}
                  underline="none"
                >
                  <Tex
                    align="center"
                    fontSize={24}
                    fontWeight={700}
                    color={PRIMARY_COLOR}
                    fontFamily="canela-bold"
                    sx={{ p: 2 }}
                  >
                    {city.name}
                  </Tex>
                </Link>
                <GridContainer container>
                  <Grid item xs={6} sx={{ pr: 0.5 }}>
                    <PrimarySelect
                      value={chosenNeighborhoodIds}
                      onChange={onSelectNeighborhood}
                      multiple
                      displayEmpty
                      size="small"
                      fullWidth
                      renderValue={(values) => {
                        if (
                          values.length === neighborhoods?.length ||
                          values.length === 0
                        ) {
                          return 'All neighborhoods';
                        } else if (values.length === 1) {
                          return '1 neighborhood';
                        } else {
                          return `${values.length} neighborhoods`;
                        }
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>All neighborhoods</em>
                      </MenuItem>
                      {neighborhoods?.map((neighborhood) => (
                        <MenuItem
                          key={neighborhood.neighborhoodId}
                          value={neighborhood.neighborhoodId}
                        >
                          {neighborhood.name}
                        </MenuItem>
                      ))}
                    </PrimarySelect>
                  </Grid>
                  <Grid item xs={6} sx={{ pl: 0.5 }}>
                    <PrimarySelect
                      value={chosenTagIds}
                      onChange={onSelectActivity}
                      multiple
                      displayEmpty
                      size="small"
                      fullWidth
                      renderValue={(values) => {
                        if (
                          values.length === activityTags.length ||
                          values.length === 0
                        ) {
                          return 'All activities';
                        } else if (values.length === 1) {
                          return '1 activity';
                        } else {
                          return `${values.length} activities`;
                        }
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>All activities</em>
                      </MenuItem>
                      {activityTags.map((tag) => (
                        <MenuItem key={tag.tagId} value={tag.tagId}>
                          {tag.name}
                        </MenuItem>
                      ))}
                    </PrimarySelect>
                  </Grid>
                </GridContainer>
                <PlacesWrapper>
                  {cityPlaceIds.map((placeId) => (
                    <PlaceCardByCity
                      key={placeId}
                      placeId={placeId}
                      chosenTagIds={chosenTagIds}
                      chosenNeighborhoodIds={chosenNeighborhoodIds}
                      placeIds={cityPlaceIds}
                      onSelectPlace={handleSelectPlace}
                      isPlaceInTrip={!!(placesTrips && placesTrips[placeId])}
                      emptyInfo="no plan for the day"
                      isFavorite={favPlacesIds[placeId]}
                      onToggleFavorite={handleToggleFavPlaces}
                    />
                  ))}
                </PlacesWrapper>
              </Stack>
            )}
            {trips ? (
              <PlaceToTripDialog
                isOpen={!!selectedPlace}
                place={selectedPlace}
                trips={trips}
                placeTripIds={
                  placesTrips && selectedPlace
                    ? placesTrips[selectedPlace?.placeId]
                    : []
                }
                cityId={city?.cityId}
                userId={userId}
                onClose={handleCloseDialog}
              />
            ) : null}
          </ContentBlock>
        </Stack>
      </Stack>
      <Footer />
    </>
  ) : (
    <NotFoundContainer />
  );
}

const ContentBlock = styled(Stack)`
  margin: 0 auto !important;
  width: 100%;
  max-width: 1440px;
`;

const GridContainer = styled(Grid)`
  align-self: center;

  ${device.tablet} {
    width: 500px;
  }
`;

const PlacesWrapper = styled(Stack)`
  margin: 32px auto 0 !important;
  width: 100%;
  align-items: center;

  ${device.tablet} {
    width: 674px;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 22px;
  }

  ${device.laptopN} {
    margin: 36px auto 0 !important;
    padding: 0 16px;
    flex-direction: row;
    overflow-x: auto;
    width: 100%;
    flex-wrap: nowrap;
    gap: 16px;
  }

  ${device.desktop} {
    gap: 21px;
  }
`;
