import { Tex } from '../shared/Tex';
import { Stack } from '@mui/material';
import { CityId, Neighborhood } from '@jaunt/api';
import styled from '@emotion/styled';
import { device, PRIMARY_COLOR } from '../../settings/jaunt-theme';
import { useCityNeighborhoods } from '../../hooks/use-city-neighborhoods';
import { useCuratedPlacesByCity } from '../../hooks/use-places-by-single-city';
import { useEffect, useState } from 'react';
import { CityDetailNeighborhoodsItem } from './CityDetailNeighborhoodsItem';
import { usePlacesTags } from '../../hooks/use-places-tags';

type CityDetailNeighborhoodsProps = {
  cityId?: CityId;
};

export const CityDetailNeighborhoods = ({
  cityId,
}: CityDetailNeighborhoodsProps): JSX.Element | null => {
  const { data: neighborhoods } = useCityNeighborhoods(cityId);
  const { data: placesByCity } = useCuratedPlacesByCity(cityId);
  const { data: placesTags } = usePlacesTags(placesByCity);

  const [neighborhoodsList, setNeighborhoodsList] = useState<Neighborhood[]>(
    [],
  );

  useEffect(() => {
    if (neighborhoods?.length) {
      const neighborhoodsPlaceNumber = neighborhoods.reduce(
        (
          acc: Record<string, { id: string; value: number }>,
          neighborhoodItem,
        ) => {
          if (!acc[neighborhoodItem.neighborhoodId]) {
            acc[neighborhoodItem.neighborhoodId] = {
              id: neighborhoodItem.neighborhoodId,
              value: 0,
            };
          }
          return acc;
        },
        {},
      );
      placesByCity?.forEach((place) => {
        const isStayPlace = !!placesTags[place.placeId]?.filter(
          (tag) => tag.name.toLowerCase() === 'stay',
        ).length;
        if (neighborhoodsPlaceNumber[place.neighborhoodId] && isStayPlace) {
          neighborhoodsPlaceNumber[place.neighborhoodId].value += 1;
        }
      });
      const newNeighborhoodIds = Object.values(neighborhoodsPlaceNumber)
        .sort((place1, place2) => place1.value - place2.value)
        .reverse()
        .map((neighborhoodsPlaceNumber) => neighborhoodsPlaceNumber.id);

      if (newNeighborhoodIds.length > 4) {
        newNeighborhoodIds.length = 4;
      }
      newNeighborhoodIds.length = 2;
      const newNeighborhoods = neighborhoods.filter((neighborhood) =>
        newNeighborhoodIds.includes(neighborhood.neighborhoodId),
      );
      setNeighborhoodsList(newNeighborhoods);
    }
  }, [neighborhoods, placesByCity, placesTags]);

  return neighborhoodsList.length ? (
    <Container>
      <Title
        align="center"
        lineHeight="32px"
        fontFamily="Canela, serif"
        fontWeight={700}
        color={PRIMARY_COLOR}
      >
        Top Neighborhoods
      </Title>
      <NeighborhoodsWrapper>
        {neighborhoodsList.map((neighborhood) => (
          <CityDetailNeighborhoodsItem
            key={neighborhood.neighborhoodId}
            neighborhood={neighborhood}
          />
        ))}
      </NeighborhoodsWrapper>
    </Container>
  ) : null;
};

const Container = styled(Stack)`
  padding: 12px 20px;
  margin-top: 24px !important;

  ${device.tablet} {
    padding: 12px 32px;
  }

  ${device.laptopN} {
    padding: 24px 70px 20px;
  }

  ${device.desktop} {
    padding: 40px 70px 20px;
  }
`;

const Title = styled(Tex)`
  font-size: 28px;
  margin: 24px !important;

  ${device.laptopN} {
    font-size: 32px;
    margin-bottom: 36px !important;
  }
`;

const NeighborhoodsWrapper = styled(Stack)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;

  ${device.laptopN} {
    flex-direction: row;
    gap: 16px;
  }
`;
