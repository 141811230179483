import { useState } from 'react';
import { Tex } from '../../components/shared/Tex';
import { Button, Link, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import styled from '@emotion/styled';
import { device } from '../../settings/jaunt-theme';

const NO_DATES_HEADER = 'How many days is your trip?';
const CLEAR_SELECTION = 'CLEAR SELECTION';

interface Props {
  onSubmit: (
    dates: Record<number, string | null>,
    isWithDates: boolean,
  ) => void;
  daysNumber: number | null;
  loading: boolean;
}

export const NoDates = ({
  onSubmit,
  loading,
  daysNumber,
}: Props): JSX.Element | null => {
  const [days, setDays] = useState<number>(daysNumber ?? 0);

  async function saveDays() {
    if (days) {
      const dates = Array.from(Array(days).keys()).reduce(
        (acc: Record<number, string | null>, index) => {
          acc[index] = null;
          return acc;
        },
        {},
      );

      await onSubmit(dates, false);
    }
  }

  return (
    <ContentContainer>
      <Tex fontSize={20} fontWeight={500} sx={{ mt: 3, mb: 3 }}>
        {NO_DATES_HEADER}
      </Tex>
      <ControlWrapper>
        <ControlButton
          variant="text"
          onClick={() => setDays(days - 1)}
          disabled={!days}
        >
          &ndash;
        </ControlButton>
        <DaysContainer>
          <DaysText>{days}</DaysText>
        </DaysContainer>
        <ControlButton variant="text" onClick={() => setDays(days + 1)}>
          &#43;
        </ControlButton>
      </ControlWrapper>
      <ResetProgress>
        <Link
          underline="none"
          onClick={() => setDays(0)}
          sx={{ cursor: 'pointer' }}
        >
          <Tex>{CLEAR_SELECTION}</Tex>
        </Link>
        <SaveButton
          variant="contained"
          loading={loading}
          onClick={saveDays}
          disabled={!days || loading}
          sx={{
            px: 2,
            py: 1,
            mt: 2,
            mb: 1,
            borderRadius: '9999px',
            backgroundColor: '#D6472A',
            height: '40px',
            width: '80%',
            maxWidth: '280px',
          }}
        >
          SAVE
        </SaveButton>
      </ResetProgress>
    </ContentContainer>
  );
};

const ContentContainer = styled(Stack)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  padding: 28px 8px 0;
  overflow: auto;
  position: relative;
`;

const ControlWrapper = styled(Stack)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const ResetProgress = styled(Stack)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 240px;

  ${device.laptopN} {
    margin-top: 160px;
  }
`;

const DaysContainer = styled(Stack)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 94px;
  width: 94px;
  border-radius: 20px;
  background-color: #fff;

  &:hover {
    background-color: #fff;
  }
`;

const DaysText = styled(Tex)`
  color: #505050;
  font-weight: 500;
  font-size: 50px;
  line-height: 64px;
`;

const ControlButton = styled(Button)`
  font-size: 36px;
  line-height: 40px;
  color: #505050;
  height: 56px;
  width: 56px;
  min-width: 56px;
  border-radius: 100%;
  background-color: #fff;

  &:hover {
    background-color: #fff;
  }
`;

const SaveButton = styled(LoadingButton)`
  padding: 8px 16px;
  margin: 16px 0 8px;
  border-radius: 9999px;
  background-color: #d6472a;
  height: 40px;
  width: 80%;
`;
