import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Chip,
  Collapse,
  Dialog,
  Stack,
  Link,
  CircularProgress,
} from '@mui/material';
import { common } from '@mui/material/colors';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import styled from '@emotion/styled';
import { format } from 'date-fns';
import {
  Place,
  TripId,
  CityId,
  UserId,
  TagType,
  City,
  PlaceId,
} from '@jaunt/api';
import {
  BACKGROUND_COLOR,
  device,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
} from 'src/settings/jaunt-theme';
import { Link as RouterLink } from 'react-router-dom';
import { useTrip } from 'src/hooks/use-trip';
import { useCity } from 'src/hooks/use-city';
import { useFirstCityImageUrl } from 'src/hooks/use-first-city-image-url';
import { useTripPlaces } from 'src/hooks/use-trip-places';
import { useCityTags } from 'src/hooks/use-city-tags';
import { usePlacesTags } from 'src/hooks/use-places-tags';
import { getLastModifiedActivityDay } from 'src/hooks/use-last-modified-activity';
import { ReactComponent as ListIcon } from 'src/assets/svg/list-outlined.svg';
import { ReactComponent as CompassIcon } from 'src/assets/svg/compass-outlined.svg';
import { ReactComponent as CalendarIcon } from 'src/assets/svg/calendar-outlined.svg';
import { getSummaryText } from 'src/services/tag.util';
import { Tex } from '../shared/Tex';
import { MdiIcon } from '../shared/MdiIcon';
import { Footer } from '../Footer';
import { FilteredMyTripPlaceCard } from '../FilteredMyTripPlaceCard';
import { ComingSoonDialog } from '../ComingSoonDialog';
import { LogoHeader } from '../LogoHeader';
import { ActivitySummary } from '../ActivitySummary';
import { EmailMyBookingTrip } from '../EmailMyBookingTrip';
import { PublicMyTrip } from '../PublicMyTrip';

const activityTags = ['Eat', 'Drink', 'Do', 'Stay'];

enum ChipType {
  activity,
  days,
}

function getDetails(city: City | null | undefined) {
  if (city) {
    return [
      {
        title: 'When To Go.',
        text: city.detail.weather,
      },
      {
        title: 'Where To Stay.',
        text: city.detail.lodging,
      },
      {
        title: 'Getting Around.',
        text: city.detail.traffic,
      },
      {
        title: 'Tips And Tricks.',
        text: city.detail.notes,
      },
    ];
  }
  return [];
}

interface Props {
  tripId: TripId;
  cityId: CityId;
  places: Place[];
  isOpen: boolean;
  userId: UserId;
  onClose(): void;
}

export function MyTripSection(props: Props): JSX.Element {
  const { isOpen, onClose, places, tripId, cityId, userId } = props;
  const { data: trip } = useTrip(tripId);
  const { data: city } = useCity(cityId);
  const { data: tags } = useCityTags(cityId);
  const { data: cityImageUrl } = useFirstCityImageUrl(cityId);
  const { data: placesTags } = usePlacesTags(places);
  const summaryText = getSummaryText(tags);
  const [isExpanded, setIsExpanded] = useState(false);
  const [comingSoon, setComingSoon] = useState(false);
  const { data: tripPlaces } = useTripPlaces(tripId);
  const [chipType, setChipType] = useState(ChipType.activity);
  const [chosenActivity, setChosenActivity] = useState<string | number | null>(
    'Eat',
  );
  const lastModifiedActivityDay = getLastModifiedActivityDay();
  const [placesActivityTagsObj, setPlacesActivityTagsObj] = useState<
    Record<string, string[]>
  >({});
  const [isShowUnscheduled, setIsShowUnscheduled] = useState(true);
  const [showPlaces, setShowPlaces] = useState<Place[] | null>();
  const [isMobile, setIsMobile] = useState(false);
  const [activePlace, setActivePlace] = useState<Place | null>(null);

  const tripDays = useMemo(
    () =>
      trip?.detail?.days
        ? Object.keys(trip.detail.days).map((day) => parseInt(day))
        : [],
    [trip],
  );

  function onSelectTripPlace(placeId: PlaceId) {
    if (isMobile) return;
    const selectedPlace = showPlaces?.find(
      (place) => place.placeId === placeId,
    );
    if (selectedPlace) {
      setActivePlace(selectedPlace);
    }
  }

  const tripDaysTags = useMemo(() => {
    if (tripDays.length) {
      return tripDays.reduce((acc: Record<number, string>, day) => {
        if (trip?.detail?.days[day]) {
          acc[day] = `${format(
            new Date(trip.detail.days[day] as string),
            'EEE, MM/d',
          )}`;
        } else {
          acc[day] = `Day ${day + 1}`;
        }
        return acc;
      }, {});
    }
    return {};
  }, [tripDays]);

  useEffect(() => {
    if (lastModifiedActivityDay) {
      setChosenActivity(+lastModifiedActivityDay);
    } else if (tripDays.length) {
      setChosenActivity(null);
    }
    function handleResize() {
      setIsMobile(window.innerWidth < 745);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (places.length && Object.keys(placesTags).length) {
      const data = places.reduce((acc: Record<string, string[]>, place) => {
        const tags = placesTags[place.placeId];
        const activityTags =
          tags?.filter((x) => x.type === TagType.ACTIVITY) ?? [];
        acc[place.placeId] = activityTags.map((tag) => tag.name);
        return acc;
      }, {});
      setPlacesActivityTagsObj(data);
    }
  }, [placesTags, places]);

  useEffect(() => {
    if (chosenActivity !== null && Object.keys(placesActivityTagsObj)?.length) {
      if (chipType === ChipType.activity) {
        const newShowPlaces = places.filter((place) => {
          return placesActivityTagsObj[place.placeId]?.includes(
            `${chosenActivity}`,
          );
        });
        setShowPlaces(newShowPlaces);
        setActivePlace(newShowPlaces[0]);
      } else {
        const showPlacesObj = tripPlaces
          ?.filter((tripPlace) => tripPlace.day === +chosenActivity)
          .reduce(
            (acc: Record<string, number | undefined | null>, tripPlace) => {
              if (tripPlace.placeId) {
                acc[tripPlace.placeId] = tripPlace.time
                  ? Number(tripPlace.time)
                  : null;
              }
              return acc;
            },
            {},
          );

        if (showPlacesObj && Object.keys(showPlacesObj).length) {
          const newShowPlaces = places
            .filter((place) => {
              return (
                showPlacesObj[place.placeId] !== undefined &&
                showPlacesObj[place.placeId] !== null
              );
            })
            .sort((first, second) => {
              return (
                (showPlacesObj[first.placeId] as number) -
                (showPlacesObj[second.placeId] as number)
              );
            });
          setShowPlaces(newShowPlaces);
          setActivePlace(newShowPlaces[0]);
        } else {
          setShowPlaces(null);
          setActivePlace(null);
        }

        const unscheduledPlaceIds =
          tripPlaces
            ?.filter((tripPlace) => typeof tripPlace.day !== 'number')
            .map((tripPlace) => tripPlace.placeId) ?? [];

        const unscheduledPlaces = places.filter((place) =>
          unscheduledPlaceIds.includes(place.placeId),
        );

        if (unscheduledPlaces.length) {
          setIsShowUnscheduled(true);
        } else {
          setIsShowUnscheduled(false);
        }
      }
    } else if (tripPlaces?.length && places?.length) {
      const unscheduledPlaceIds =
        tripPlaces
          ?.filter((tripPlace) => typeof tripPlace.day !== 'number')
          .map((tripPlace) => tripPlace.placeId) ?? [];

      const unscheduledPlaces = places.filter((place) =>
        unscheduledPlaceIds.includes(place.placeId),
      );

      if (unscheduledPlaces.length) {
        const newShowPlaces = places.filter((place) =>
          unscheduledPlaceIds.includes(place.placeId),
        );

        setShowPlaces(newShowPlaces);
        setActivePlace(newShowPlaces[0]);
        setIsShowUnscheduled(true);
      } else {
        setIsShowUnscheduled(false);
        setShowPlaces(null);
        setActivePlace(null);
        setChosenActivity(0);
      }
    }
  }, [chosenActivity, placesActivityTagsObj, places, tripPlaces]);

  useEffect(() => {
    if (tripDays.length) {
      setChipType(ChipType.days);
    } else {
      setChipType(ChipType.activity);
    }
  }, [tripDays]);

  const details = getDetails(city);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      style={{ backgroundColor: BACKGROUND_COLOR }}
      fullScreen
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: BACKGROUND_COLOR,
        },
      }}
    >
      <Stack sx={{ width: '100%', minHeight: '100vh' }}>
        <LogoHeader showAuthLinks />
        <Stack className="main">
          {trip && city ? (
            <>
              <Stack>
                <Stack spacing={6}>
                  <Stack>
                    <BackgroundImage
                      justifyContent="end"
                      alignItems="center"
                      cityimageurl={cityImageUrl}
                    />
                    <OverviewContainer>
                      <Tex
                        align="center"
                        fontSize={28}
                        fontWeight={700}
                        color={common.white}
                      >
                        {trip.name}
                      </Tex>
                      <Tex
                        align="center"
                        fontSize={16}
                        fontWeight={300}
                        color={common.white}
                      >
                        {city.name}
                      </Tex>
                      <Button
                        variant="contained"
                        onClick={() => setComingSoon(true)}
                        sx={{
                          px: 3,
                          py: 1,
                          borderRadius: '9999px',
                          backgroundColor: common.white,
                          color: PRIMARY_COLOR,
                          width: '150px',
                        }}
                      >
                        + Add friends
                      </Button>
                      <Tex align="center" fontWeight={300} color={common.white}>
                        {summaryText && (
                          <Tex component="span" fontWeight={700}>
                            {summaryText}.{' '}
                          </Tex>
                        )}
                        {city.description}
                      </Tex>
                      <Stack spacing={1}>
                        <Collapse in={isExpanded}>
                          <Stack spacing={1.5}>
                            {details.map((detail) => (
                              <Stack
                                key={detail.title}
                                sx={{
                                  p: 1.5,
                                  backgroundColor: common.white,
                                  borderRadius: 4,
                                }}
                              >
                                <Tex>
                                  <Tex component="span" fontWeight={700}>
                                    {detail.title}&nbsp;
                                  </Tex>
                                  <Tex component="span" fontWeight={300}>
                                    {detail.text}
                                  </Tex>
                                </Tex>
                              </Stack>
                            ))}
                          </Stack>
                        </Collapse>
                        <Stack sx={{ position: 'relative' }}>
                          <Button
                            onClick={() =>
                              setIsExpanded((previous) => !previous)
                            }
                          >
                            <Tex sx={{ color: common.white }}>
                              {isExpanded ? 'See Less' : 'See More'}
                            </Tex>
                            <MdiIcon
                              icon={isExpanded ? mdiChevronUp : mdiChevronDown}
                              sx={{ ml: 1, color: common.white }}
                            />
                          </Button>
                          <Stack
                            sx={{
                              position: 'absolute',
                              right: '20px',
                              top: `calc(50% - ${15}px)`,
                            }}
                          >
                            <PublicMyTrip
                              tripId={tripId}
                              tripPath={trip.tripPath}
                              tripName={trip.name}
                              isGeneratedTrip={false}
                              isPublic={trip.isPublic}
                              size={30}
                            />
                          </Stack>
                        </Stack>
                      </Stack>
                    </OverviewContainer>
                  </Stack>
                </Stack>
              </Stack>
              <MainContentWrapper>
                <TripConstructorWrapper iscompressed={activePlace ? 1 : 0}>
                  <TripConstructorActions>
                    {tripPlaces?.length ? (
                      <EmailMyBookingTrip
                        tripId={tripId}
                        isGeneratedTrip={false}
                      />
                    ) : null}
                  </TripConstructorActions>
                  <Stack spacing={2} sx={{ width: '100%' }}>
                    <DateControlBlock>
                      <Link
                        component={RouterLink}
                        to={`/trips/${trip.tripPath}/timing`}
                      >
                        <Tex fontSize={14}>
                          <b>
                            {chipType === ChipType.activity
                              ? 'Add Trip Dates'
                              : 'Edit Trip Dates'}
                          </b>
                        </Tex>
                      </Link>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ gap: 2 }}
                      >
                        <Stack alignItems="center">
                          <CalendarIcon />
                          <Tex fontSize={10} color="#cccccc">
                            Cal View
                          </Tex>
                        </Stack>
                        <Stack alignItems="center">
                          <CompassIcon />
                          <Tex fontSize={10} color="#cccccc">
                            Map View
                          </Tex>
                        </Stack>
                        <Stack alignItems="center">
                          <ListIcon />
                          <Tex fontSize={10} color={PRIMARY_COLOR}>
                            List View
                          </Tex>
                        </Stack>
                      </Stack>
                    </DateControlBlock>
                  </Stack>
                  <Stack spacing={2} sx={{ width: '100%' }}>
                    <ChipWrapper
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1}
                    >
                      {chipType === ChipType.activity ? (
                        <>
                          <Stack direction="row" spacing={1}>
                            {activityTags.map((activity) => (
                              <Chip
                                key={activity}
                                label={activity}
                                color={
                                  chosenActivity === activity
                                    ? 'primary'
                                    : 'default'
                                }
                                onClick={() => setChosenActivity(activity)}
                                sx={
                                  chosenActivity === activity
                                    ? {}
                                    : { backgroundColor: common.white }
                                }
                              />
                            ))}
                          </Stack>
                        </>
                      ) : (
                        <>
                          <Stack
                            direction="row"
                            spacing={1}
                            sx={{ overflowX: 'scroll' }}
                          >
                            {isShowUnscheduled ? (
                              <Chip
                                key="unscheduled"
                                label="Unscheduled"
                                color={
                                  chosenActivity === null
                                    ? 'primary'
                                    : 'default'
                                }
                                onClick={() => setChosenActivity(null)}
                                sx={{
                                  backgroundColor:
                                    chosenActivity === null
                                      ? null
                                      : common.white,
                                }}
                              />
                            ) : null}
                            {tripDays.map((day) => (
                              <Chip
                                key={day}
                                label={tripDaysTags[day]}
                                color={
                                  chosenActivity === day ? 'primary' : 'default'
                                }
                                onClick={() => setChosenActivity(day)}
                                sx={
                                  chosenActivity === day
                                    ? {}
                                    : { backgroundColor: common.white }
                                }
                              />
                            ))}
                          </Stack>
                        </>
                      )}
                    </ChipWrapper>
                    <PlacesContainer>
                      {showPlaces?.length ? (
                        showPlaces.map((placeItem) => (
                          <FilteredMyTripPlaceCard
                            key={placeItem.placeId}
                            tripPlace={tripPlaces?.find(
                              (tripPlace) =>
                                tripPlace.placeId === placeItem.placeId,
                            )}
                            onClick={onSelectTripPlace}
                            place={placeItem}
                            city={city}
                            trip={trip}
                            userId={userId}
                            isActive={
                              !isMobile &&
                              placeItem.placeId === activePlace?.placeId
                            }
                          />
                        ))
                      ) : chipType === ChipType.activity ? (
                        <Stack sx={{ mt: 4, mb: 1 }}>
                          <Tex
                            fontSize={12}
                            lineHeight={'18px'}
                            textAlign="center"
                          >
                            Your trip is empty. Use the Jaunt Builder to browse
                            recommendations and add activities to your trip.
                          </Tex>
                        </Stack>
                      ) : (
                        <Stack sx={{ mt: 5, mb: 4 }}>
                          <Tex
                            fontWeight={500}
                            fontSize={14}
                            textAlign="center"
                          >
                            Nothing is scheduled yet!
                          </Tex>
                        </Stack>
                      )}
                    </PlacesContainer>
                  </Stack>
                  <ActionContainer>
                    <CTAButton variant="contained" onClick={onClose}>
                      BROWSE RECOMMENDATIONS
                    </CTAButton>
                    <Stack sx={{ alignItems: 'center', my: 2 }}>
                      <Tex
                        fontSize={12}
                        fontWeight={450}
                        sx={{ fontFamily: 'CircularStd-Book' }}
                      >
                        <Link
                          component={RouterLink}
                          to={`/trips/${trip.tripPath}/add-place?cityId=${cityId}`}
                        >
                          <b>ADD MY OWN ACTIVITY</b>
                        </Link>
                      </Tex>
                    </Stack>
                  </ActionContainer>
                </TripConstructorWrapper>
                {!isMobile && activePlace ? (
                  <ActivitySummaryWrapper>
                    <ActivitySummary
                      place={activePlace}
                      tripPath={trip.tripPath}
                      tripPlace={tripPlaces?.find(
                        (tripPlace) =>
                          tripPlace.placeId === activePlace.placeId,
                      )}
                      userId={userId}
                      isScheduleEnabled={!(chipType === ChipType.activity)}
                    />
                  </ActivitySummaryWrapper>
                ) : null}
              </MainContentWrapper>
            </>
          ) : (
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ p: 2, flex: 1, height: '200px' }}
            >
              <CircularProgress
                variant="indeterminate"
                color="primary"
                size="40px"
                value={40}
              />
            </Stack>
          )}
        </Stack>
        <Footer />
      </Stack>
      <ComingSoonDialog
        isOpen={comingSoon}
        onClose={() => setComingSoon(false)}
      />
    </Dialog>
  );
}

const BackgroundImage = styled(Stack)<{
  cityimageurl: string | null | undefined;
}>`
  height: 215px;
  background-image: ${({ cityimageurl }) =>
    cityimageurl ? `url(${cityimageurl})` : null};
  background-size: cover;
  background-position: center;
  background-color: ${SECONDARY_COLOR};

  ${device.tablet} {
    height: 280px;
  }

  ${device.laptopN} {
    height: 402px;
  }
`;

const OverviewContainer = styled(Stack)`
  width: 100%;
  align-items: center;
  gap: 8px;
  margin: -60px auto 0;
  padding: 12px;
  background-color: ${PRIMARY_COLOR};
  border-radius: 20px 20px;
  max-width: 380px;

  ${device.tablet} {
    max-width: 544px;
    margin: -80px auto 0;
  }

  ${device.laptopN} {
    max-width: 734px;
    margin: -135px auto 0;
  }
`;

const MainContentWrapper = styled(Stack)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 auto;
  width: 100%;

  ${device.laptopN} {
    max-width: 1024px;
    padding: 0 80px;
  }
`;

const ActionContainer = styled(Stack)`
  width: 100%;
  align-items: center;
  margin: 16px 0;

  ${device.tablet} {
    padding: 0;
    margin-top: 48px;
  }

  ${device.laptopN} {
    margin-top: 56px;
  }
`;

const DateControlBlock = styled(Stack)`
  width: 100%;
  padding: 8px 16px;
  max-width: 410px;
  margin: 0 auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  ${device.tablet} {
    max-width: 544px;
    padding: 8px 0;
  }

  ${device.laptopN} {
    max-width: 544px;
    padding: 8px 0;
  }
`;

const ChipWrapper = styled(Stack)`
  width: 100%;
  padding: 8px 16px 0;
  max-width: 410px;
  margin: 0 auto;

  ${device.tablet} {
    max-width: 544px;
    padding: 0;
  }

  ${device.laptopN} {
    max-width: 544px;
    padding: 0;
  }
`;

const PlacesContainer = styled(Stack)`
  padding: 0 8px;
  margin-bottom: 8px !important;
  gap: 16px;

  ${device.tablet} {
    padding: 0;
    margin-top: 26px !important;
  }
`;

const TripConstructorWrapper = styled(Stack)<{
  iscompressed: number;
}>`
  width: 100%;
  padding: 22px 12px 8px;
  max-width: 410px;
  margin: 0 auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  ${device.tablet} {
    width: 100%;
    max-width: ${({ iscompressed }) => (iscompressed ? '374px' : '544px')};
    padding: 20px 12px 8px;
    align-items: flex-start;
  }

  ${device.laptopN} {
    max-width: ${({ iscompressed }) => (iscompressed ? '380px' : '734px')};
    padding: 20px 0 8px;
  }
`;

const TripConstructorActions = styled(Stack)`
  width: 100%;
  gap: 16px;
`;

const ActivitySummaryWrapper = styled(Stack)`
  display: none;

  ${device.tablet} {
    border-left: 1px solid #dadada;
    display: block;
    width: 100%;
    padding: 8px 0;
    margin-left: 12px;
  }

  ${device.laptopN} {
    padding: 16px 0 0;
  }
`;

const CTAButton = styled(Button)`
  width: 80%;
  min-width: 300px;
  max-width: 320px;
  padding: 8px 0;
  align-items: center;
  border-radius: 9999px;
  background-color: #d6472a;
  font-size: 16px;
  font-weight: 450;
  font-family: CircularStd-Book, sans-serif;
  text-transform: uppercase;
`;
