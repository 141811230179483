import { Box, CircularProgress, Stack } from '@mui/material';
import styled from '@emotion/styled';
import { PRIMARY_COLOR } from 'src/settings/jaunt-theme';
import { Tex } from '../shared/Tex';

export const LoadingContainer = (): JSX.Element | null => {
  return (
    <Container>
      <Box
        margin="4px 0"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '32px',
        }}
      >
        <CircularProgress color="primary" />
        <Tex
          variant="h5"
          fontWeight={700}
          fontFamily="canela-bold"
          color={PRIMARY_COLOR}
        >
          Loading...
        </Tex>
      </Box>
    </Container>
  );
};

const Container = styled(Stack)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  position: fixed;
  top: calc(50% - 50px);
`;
