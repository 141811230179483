import { Tag, TagData, TagId } from '@jaunt/api';
import { createTag, deleteTag, listTags, updateTag } from '../services/tag.api';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import QueryKeys from './constants/query-keys';
import { sortTags } from '../services/tag.util';

export function useTags(): UseQueryResult<Tag[], unknown> {
  return useQuery<Tag[]>(
    QueryKeys.tags,
    async () => {
      const tags = await listTags();
      return sortTags(tags);
    },
    {
      placeholderData() {
        return [];
      },
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading Tags: ', error); // eslint-disable-line no-console
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: 3,
      retryDelay: 2000,
    },
  );
}

export const useCreateTag = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async (data: TagData) => {
      return await createTag(data);
    },
    {
      onSuccess: (tag: Tag) => {
        client.setQueryData([QueryKeys.tags, tag.tagId], tag);
        client.invalidateQueries(QueryKeys.tags);
      },
    },
  );
  return { createTag: mutateAsync, isLoading, isSuccess, isError };
};

export const useUpdateTag = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({ tagId, data }: { tagId: TagId; data: TagData }) => {
      return await updateTag(tagId, data);
    },
    {
      onSuccess: (tag: Tag) => {
        client.setQueryData([QueryKeys.tags, tag.tagId], tag);
        client.invalidateQueries(QueryKeys.tags);
      },
    },
  );
  return { updateTag: mutateAsync, isLoading, isSuccess, isError };
};

export const useDeleteTag = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async (tagId: TagId) => {
      await deleteTag(tagId);
    },
    {
      onSuccess: (_, tagId) => {
        client.resetQueries([QueryKeys.tags, tagId]);
        client.invalidateQueries(QueryKeys.tags);
      },
    },
  );
  return { deleteTag: mutateAsync, isLoading, isSuccess, isError };
};
