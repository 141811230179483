const QueryKeys = {
  me: 'me',
  userData: 'userData',
  cities: 'cities',
  citiesWithImageUrl: 'citiesWithImageUrl',
  citiesImages: 'citiesImages',
  cityCuratedTripWithImageUrls: 'cityCuratedTripWithImageUrls',
  cityNeighborhoods: 'cityNeighborhoods',
  cityTags: 'cityTags',
  cityTrip: 'cityTrip',
  searchCityAnalytics: 'searchCityAnalytics',
  curatedTripsWithImageUrl: 'curatedTripsWithImageUrl',
  tripsWithImageUrl: 'tripsWithImageUrl',
  fileDownloadUrl: 'fileDownloadUrl',
  fileEntity: 'fileEntity',
  firstCityImageUrl: 'firstCityImageUrl',
  firstTripImageUrl: 'firstTripImageUrl',
  neighborhoods: 'neighborhoods',
  neighborhoodsWithImageUrl: 'neighborhoodsWithImageUrl',
  neighborhoodsImages: 'neighborhoodsImages',
  firstNeighborhoodImageUrl: 'firstNeighborhoodImageUrl',
  nonStaysByCity: 'nonStaysByCity',
  notes: 'notes',
  placeFirstImage: 'placeFirstImage',
  placesWithImageUrl: 'placesWithImageUrl',
  placeImages: 'placeImages',
  imageUrls: 'imageUrls',
  placeReviews: 'placeReviews',
  placeTags: 'placeTags',
  places: 'places',
  placesByCities: 'placesByCities',
  curatedPlacesByCities: 'curatedPlacesByCities',
  placesByNeighborhood: 'placesByNeighborhood',
  reviewImages: 'reviewImages',
  reviews: 'reviews',
  staysByCity: 'staysByCity',
  tags: 'tags',
  trips: 'trips',
  generatedQuiz: 'generatedQuiz',
  generatedTripLocal: 'generatedTripLocal',
  userTrips: 'userTrips',
  userGeneratedTrips: 'userGeneratedTrips',
  publicGeneratedTrips: 'publicGeneratedTrips',
  publicTripsPath: 'publicTripsPath',
  customUserTripsByCity: 'customUserTripsByCity',
  tripPlaces: 'tripPlaces',
  tripImages: 'tripImages',
  tripStays: 'tripStays',
  waitlistEntries: 'waitlistEntries',
  tripCity: 'tripCity',
  lastModifiedActivityDay: 'lastModifiedActivityDay',
  tripEmailApps: 'TripEmailApps',
  favoriteCities: 'favoriteCities',
  favoriteTrips: 'favoriteTrips',
  favoritePlaces: 'favoritePlaces',
};

export default QueryKeys;
