import * as React from 'react';
import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import {
  Autocomplete,
  CircularProgress,
  Stack,
  TextField,
} from '@mui/material';
import { useCities } from '../hooks/use-cities';
import { useCitiesWithImageUrl } from '../hooks/use-cities-with-image-url';
import { debounce } from 'lodash';
import { PRIMARY_COLOR } from 'src/settings/jaunt-theme';
import { CityWithImageUrl } from '../services/city.util';
import { CityCard } from './CityCard';
import { getCityGpt, getCityListGpt } from '../services/chat-gpt';
import { City, CityDetailsGPT, SearchCityItem } from '@jaunt/api';
import { Tex } from './shared/Tex';
import {
  increaseCityClick,
  increaseCitySearch,
} from '../services/search-city.api';

function transformToCityItems(citiesWithImageUrl: CityWithImageUrl[]) {
  return citiesWithImageUrl.map((item) => ({
    cityId: item.city.cityId,
    name: item.city.name,
    state: item.city.state,
    country: item.city.country,
    imageUrl: item.imageUrl,
    isGptCity: false,
  }));
}

type Props = {
  onSelectCity: (city: City) => void;
  onSelectGptCity: (gptCity: CityDetailsGPT) => void;
};

export function CityInputAutocomplete({
  onSelectCity,
  onSelectGptCity,
}: Props): JSX.Element {
  const { data: cities } = useCities();
  const { data: citiesWithImageUrl } = useCitiesWithImageUrl(cities);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [cityItems, setCityItems] = useState<SearchCityItem[]>([]);

  const getFilteredCities = debounce(
    async (_, value: string, reason: string) => {
      if (reason !== 'input') return;
      setIsLoading(true);
      const newValue = value.toLowerCase();
      if (!newValue || !newValue?.trim()) {
        const initialCityList = transformToCityItems(citiesWithImageUrl ?? []);
        setCityItems(initialCityList);
        setIsLoading(false);
        setError(false);
        return;
      }
      setCityItems([]);

      let gptCityItems: SearchCityItem[] = [];
      const newCitiesWithImage =
        citiesWithImageUrl?.filter((x) =>
          x.city.name.toLowerCase().includes(newValue.toLowerCase()),
        ) ?? [];
      const newCityList: SearchCityItem[] =
        transformToCityItems(newCitiesWithImage);
      const storedCityDictionary = newCityList.reduce(
        (acc: Record<string, boolean>, cityItem) => {
          acc[cityItem.name.toLowerCase()] = true;
          return acc;
        },
        {},
      );
      try {
        const controller = new AbortController();

        setTimeout(() => {
          controller.abort();
          setError(true);
        }, 40000);

        await increaseCitySearch(newValue);
        const { cityList } = await getCityListGpt(newValue, controller.signal);

        gptCityItems = cityList.map((item) => {
          const list = item.split(',');
          const cityName = list[0].trim();
          const cityState = list.slice(1).join(',').trim();
          return {
            cityId: cityName.toLowerCase(),
            name: cityName,
            state: cityState,
            country: cityState,
            imageUrl: undefined,
            isGptCity: true,
          };
        });
      } catch (_) {
        setError(true);
      }

      gptCityItems.forEach((item) => {
        if (!storedCityDictionary[item.cityId]) {
          storedCityDictionary[item.cityId] = true;
          newCityList.push(item);
        }
      });
      setCityItems(newCityList);
      setIsLoading(false);
      setError(false);
    },
    1000,
  );

  async function onSelect(_: React.SyntheticEvent, value: unknown) {
    const city = value as SearchCityItem;
    if (city.isGptCity) {
      setIsLoading(true);
      setCityItems([]);
      const cityName = city.name;
      // setIsLoading(true);
      try {
        await increaseCityClick(cityName);
        const controller = new AbortController();

        setTimeout(() => {
          controller.abort();
          setError(true);
        }, 30000);

        const newGptCity = await getCityGpt(
          cityName.toLowerCase(),
          controller.signal,
        );

        onSelectGptCity({ ...newGptCity, name: cityName });
        setError(false);
      } catch (_) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    } else {
      if (citiesWithImageUrl?.length) {
        const selectedCityWithImageUrl = citiesWithImageUrl.find(
          (item) => item.city.cityId === city.cityId,
        );
        if (selectedCityWithImageUrl) {
          onSelectCity(selectedCityWithImageUrl.city);
          setError(false);
        } else {
          setError(true);
        }
      }
    }
  }

  useEffect(() => {
    if (citiesWithImageUrl?.length && !isMounted) {
      const initialCityList = transformToCityItems(citiesWithImageUrl);
      setCityItems(initialCityList);
      setIsMounted(true);
    }
  }, [citiesWithImageUrl]);

  return (
    <Container spacing={2} sx={{ width: 300 }}>
      <AutocompleteInput
        freeSolo
        id="city-input-autocomplete"
        disableClearable
        options={cityItems}
        getOptionLabel={(option) => (option as SearchCityItem).name}
        renderOption={(props, option) => (
          <CityListItem {...props}>
            <CityCard
              fullWidth
              key={(option as SearchCityItem).cityId}
              city={option as SearchCityItem}
              imageUrl={(option as SearchCityItem).imageUrl}
            />
          </CityListItem>
        )}
        onInputChange={getFilteredCities}
        onChange={onSelect}
        onBlur={() => setError(false)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Where to? Type in any city..."
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
      />
      {isLoading ? (
        <MessageWrapper>
          <CircularProgress color="inherit" size={20} />
          <Tex
            color="rgba(0, 0, 0, 0.6)"
            fontSize={16}
            lineHeight="16px"
            fontFamily="CircularStd-Book"
          >
            Loading...
          </Tex>
        </MessageWrapper>
      ) : null}
      {error ? (
        <MessageWrapper>
          <Tex
            color="#e53e3e"
            fontSize={16}
            lineHeight="16px"
            fontFamily="CircularStd-Book"
          >
            Something went wrong. Please try again!
          </Tex>
        </MessageWrapper>
      ) : null}
    </Container>
  );
}

const Container = styled(Stack)`
  width: 100%;
  position: relative;

  & .MuiAutocomplete-hasClearIcon {
    color: ${PRIMARY_COLOR};
  }
`;

const AutocompleteInput = styled(Autocomplete)`
  font-family: 'CircularStd-Book', sans-serif;

  & .MuiAutocomplete-input {
    background: #fff;
  }
  & .MuiOutlinedInput-root {
    background: #fff;
  }
`;

const CityListItem = styled.li`
  width: 100%;
`;

const MessageWrapper = styled(Stack)`
  width: 100%;
  position: absolute;
  top: 45px;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.6);
  padding: 14px 16px;
  box-sizing: border-box;
  border-radius: 4px;
`;
