import { useMutation, useQueryClient } from 'react-query';
import { PlaceId, TripId } from '@jaunt/api';
import QueryKeys from './constants/query-keys';
import { replacePlaceToTrips } from '../services/place.api';

export const useReplacePlaceToTrips = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({ placeId, data }: { placeId: PlaceId; data: TripId[] }) => {
      return await replacePlaceToTrips(placeId, data);
    },
    {
      onSuccess: () => {
        client.removeQueries(QueryKeys.tripPlaces);
      },
    },
  );
  return { replacePlaceToTrips: mutateAsync, isLoading, isSuccess, isError };
};
