import { Box, Link, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { Tex } from './shared/Tex';
import { FormTextField } from './shared/FormTextField';
import { PRIMARY_COLOR } from '../settings/jaunt-theme';

const resolver = yupResolver(
  yup.object({
    email: yup.string().email('Enter a valid email').required('Required'),
  }),
);

interface FormValues {
  email: string;
}

type Props = {
  onSubmit: (email: string) => void;
  isEmailSent?: boolean;
};

export function EnterEmail(props: Props): JSX.Element {
  const { onSubmit, isEmailSent } = props;
  const { register, handleSubmit, formState } = useForm<FormValues>({
    resolver,
    mode: 'onChange',
  });

  function onSubmitForm(formValues: FormValues): void {
    const { email } = formValues;
    onSubmit(email);
  }

  return (
    <Stack spacing={6}>
      {isEmailSent ? (
        <Stack justifyContent="center" alignItems="center">
          <Tex fontSize={20} color="primary" textAlign="center">
            Thank you, see you soon!
          </Tex>
          <Box margin="16px 0">
            <Link component={RouterLink} to="/">
              <Tex variant="h5" fontFamily="canela-bold">
                Go Home
              </Tex>
            </Link>
          </Box>
        </Stack>
      ) : (
        <Stack
          component="form"
          onSubmit={handleSubmit(onSubmitForm)}
          spacing={4}
          sx={{ width: '100%' }}
        >
          <FormTextField
            register={register}
            formState={formState}
            fieldName="email"
            color="secondary"
            variant="standard"
            placeholder="example@gmail.com"
            required
            sx={{ px: 4, width: '100%' }}
          />
          <Stack alignItems="center">
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              size="small"
              disabled={!formState.isValid}
              disableRipple
              sx={{
                px: 6,
                py: 1,
                borderRadius: '9999px',
                '.MuiLoadingButton-loadingIndicator': {
                  color: PRIMARY_COLOR,
                },
              }}
            >
              Send
            </LoadingButton>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
