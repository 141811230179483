import { Link as RouterLink } from 'react-router-dom';
import { Link, Stack } from '@mui/material';
import { Tex } from 'src/components/shared/Tex';

export enum AdminPageKey {
  CITIES = 'cities',
  NEIGHBORHOODS = 'neighborhoods',
  PLACES = 'places',
  TAGS = 'tags',
  SECONDARY_TAGS = 'secondary-tags',
  TRIPS = 'trips',
  PLACE_REVIEWS = 'place-reviews',
  REVIEWS = 'reviews',
  WAITLIST = 'waitlist',
  ITINERARY_APPLICATIONS = 'itinerary-applications',
  SEARCH_CITY_ANALYTICS = 'search-city-analytics',
}

const pages = [
  { key: AdminPageKey.CITIES, url: '/admin/cities', label: 'Cities' },
  {
    key: AdminPageKey.NEIGHBORHOODS,
    url: '/admin/neighborhoods',
    label: 'Neighborhoods',
  },
  { key: AdminPageKey.PLACES, url: '/admin/places', label: 'Places' },
  { key: AdminPageKey.TAGS, url: '/admin/tags', label: 'Tags' },
  {
    key: AdminPageKey.SECONDARY_TAGS,
    url: '/admin/secondary-tags',
    label: 'Secondary Tags',
  },
  { key: AdminPageKey.TRIPS, url: '/admin/trips', label: 'Trips' },
  {
    key: AdminPageKey.PLACE_REVIEWS,
    url: '/admin/place-reviews',
    label: 'Place Review',
  },
  { key: AdminPageKey.REVIEWS, url: '/admin/reviews', label: 'Review source' },
  { key: AdminPageKey.WAITLIST, url: '/admin/waitlist', label: 'Waitlist' },
  {
    key: AdminPageKey.ITINERARY_APPLICATIONS,
    url: '/admin/itinerary-applications',
    label: 'Itineraty Applications',
  },
  {
    key: AdminPageKey.SEARCH_CITY_ANALYTICS,
    url: '/admin/search-city-analytics',
    label: 'Search City Analytics',
  },
];

interface Props {
  pageKey?: string;
}

export function AdminSideBar(props: Props): JSX.Element {
  const { pageKey } = props;
  const activePage = pages.find((x) => x.key === pageKey);

  return (
    <Stack spacing={1} sx={{ p: 2 }}>
      {pages.map((page) => (
        <Link
          key={page.key}
          component={RouterLink}
          to={page.url}
          underline="none"
        >
          <Tex fontWeight={page.key === activePage?.key ? 700 : 400}>
            {page.label}
          </Tex>
        </Link>
      ))}
    </Stack>
  );
}
