import { CityId, Tag } from '@jaunt/api';
import { getCityTags } from '../services/city.api';
import { useQuery, UseQueryResult } from 'react-query';
import QueryKeys from './constants/query-keys';

export function useCityTags(
  cityId: CityId | null | undefined,
): UseQueryResult<Tag[], unknown> {
  return useQuery<Tag[]>(
    [QueryKeys.cityTags, cityId],
    async () => {
      return cityId ? await getCityTags(cityId) : [];
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading CityTags: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!cityId,
    },
  );
}
