import { Place, Tag, TagType } from '@jaunt/api';
import { useMemo } from 'react';
import { Link, Stack } from '@mui/material';
import { Tex } from '../../components/shared/Tex';
import { device, PRIMARY_COLOR } from '../../settings/jaunt-theme';
import { Link as RouterLink } from 'react-router-dom';
import { toQueryParams } from '../../services/base.util';
import { PlacesTileGallery } from '../../components/places-tile-gallery';
import styled from '@emotion/styled';

type NeighborhoodDrinkProps = {
  neighborhoodPath: string | null;
  cityPath?: string;
  tags?: Tag[];
  places?: Place[];
  placesTags: Record<string, Tag[]>;
};

export function NeighborhoodDrink({
  neighborhoodPath,
  cityPath,
  tags,
  places,
  placesTags,
}: NeighborhoodDrinkProps): JSX.Element | null {
  const drinkTag = tags?.find(
    (tag) => tag.type === TagType.ACTIVITY && tag.name === 'Drink',
  );
  const drinkPlaces = useMemo(() => {
    const placeIds = Object.keys(placesTags).filter((placeId) => {
      return placesTags[placeId]
        .map((tag) => tag.tagId)
        .includes(drinkTag?.tagId ?? '');
    });

    return places
      ?.filter((place) => placeIds.includes(place.placeId))
      .slice(0, 6);
  }, [places, placesTags]);

  return drinkPlaces?.length ? (
    <Wrapper>
      <Tex
        align="center"
        fontFamily="Canela"
        fontSize={28}
        lineHeight="32px"
        fontWeight={700}
        color={PRIMARY_COLOR}
        sx={{ margin: '8px !important', marginTop: '16px !important' }}
      >
        Where To Drink
      </Tex>
      <PlacesTileGallery places={drinkPlaces} placesTags={placesTags} />
      <Link
        component={RouterLink}
        to={
          cityPath
            ? `/places?${toQueryParams({
                city: cityPath,
                tagName: 'Drink',
                neighborhood: neighborhoodPath ?? '',
              })}`
            : '/'
        }
        margin="12px 0 16px 0 !important"
      >
        <Tex
          fontFamily="CircularStd-Light"
          fontWeight={300}
          fontSize={16}
          lineHeight="24px"
        >
          View All Places To Drink
        </Tex>
      </Link>
    </Wrapper>
  ) : null;
}

const Wrapper = styled(Stack)`
  padding: 0 8px;
  margin: 32px auto 0 !important;
  gap: 16px;
  max-width: 400px;
  width: 100%;

  ${device.tablet} {
    padding: 0 30px;
    max-width: 640px;
  }

  ${device.laptopN} {
    padding: 0 70px 0;
    max-width: none;
  }
`;
