import { Link as RouterLink, useParams } from 'react-router-dom';
import { Link, Stack } from '@mui/material';
import { LogoHeader } from 'src/components/LogoHeader';
import { Footer } from 'src/components/Footer';
import { NotFoundContainer } from 'src/components/NotFoundContainer';
import { LoadingContainer } from 'src/components/loading/LoadingContainer';
import { useMe } from 'src/hooks/use-me';
import styled from '@emotion/styled';
import { device, PRIMARY_COLOR } from '../../settings/jaunt-theme';
import { grey } from '@mui/material/colors';
import { Tex } from '../../components/shared/Tex';
import { FavoriteTrips } from './FavoritesTrips';
import { FavoriteCities } from './FavoriteCities';
import { FavoritePlaces } from './FavoritePlaces';

enum FavoritesType {
  TRIPS = 'trips',
  CITIES = 'cities',
  ACTIVITIES = 'activities',
}

const tabs = [
  { key: FavoritesType.TRIPS, url: '/favorites/trips', label: 'Trips' },
  {
    key: FavoritesType.CITIES,
    url: '/favorites/cities',
    label: 'Cities',
  },
  {
    key: FavoritesType.ACTIVITIES,
    url: '/favorites/activities',
    label: 'Hotels and Activities',
  },
];

export function FavoritesPage(): JSX.Element {
  const { type } = useParams();
  const { data: userId, isLoading: isMeLoading } = useMe(true);

  if (isMeLoading) {
    return <LoadingContainer />;
  }

  return userId ? (
    <>
      <LogoHeader showAuthLinks={true} />
      <Stack className="main">
        <Stack sx={{ mb: 2, width: '100%' }}>
          <ContentBlock spacing={1}>
            <Stack
              direction="row"
              justifyContent={'center'}
              borderBottom={`1px solid ${grey[400]}`}
              sx={{ marginTop: '8px' }}
            >
              {tabs.map((tab) => (
                <TabContainer
                  key={tab.key}
                  to={tab.url}
                  component={RouterLink}
                  underline="none"
                >
                  <TabText
                    sx={{
                      ...(tab.key === type
                        ? {
                            borderBottom: `3px solid ${PRIMARY_COLOR}`,
                            fontWeight: 700,
                            color: PRIMARY_COLOR,
                          }
                        : {}),
                    }}
                  >
                    {tab.label}
                  </TabText>
                </TabContainer>
              ))}
            </Stack>
            {/*<Stack>*/}
            {/*  <Title*/}
            {/*    align="center"*/}
            {/*    fontSize={24}*/}
            {/*    fontWeight={700}*/}
            {/*    color={PRIMARY_COLOR}*/}
            {/*    fontFamily="canela-bold"*/}
            {/*  >*/}
            {/*    My Trips*/}
            {/*  </Title>*/}
            {/*  <TripWrapper>*/}
            {/*    <Tex>{type}</Tex>*/}
            {/*    <FavoriteTrips />*/}
            {/*  </TripWrapper>*/}
            {/*</Stack>*/}
            <Stack>
              {type === FavoritesType.TRIPS ? (
                <FavoriteTrips />
              ) : type === FavoritesType.CITIES ? (
                <FavoriteCities />
              ) : type === FavoritesType.ACTIVITIES ? (
                <FavoritePlaces />
              ) : null}
            </Stack>
          </ContentBlock>
        </Stack>
      </Stack>
      <Footer />
    </>
  ) : (
    <NotFoundContainer />
  );
}

const ContentBlock = styled(Stack)`
  margin: 0 auto !important;
  padding: 8px;
  width: 100%;
  max-width: 1440px;

  ${device.tablet} {
    padding: 8px 24px 16px;
  }

  ${device.laptopN} {
    padding: 8px 38px 20px;
  }
`;

const TabContainer = styled(Link)`
  padding-left: 16px;
  padding-right: 16px;
  color: inherit;
  text-underline: none;
  width: ${100 / tabs.length}%;

  ${device.tablet} {
    width: ${80 / tabs.length}%;
  }

  ${device.laptopN} {
    width: ${60 / tabs.length}%;
  }
` as typeof Link;

const TabText = styled(Tex)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 4px;
  height: 56px;

  ${device.tablet} {
    height: auto;
  }
`;

// const Title = styled(Tex)`
//   margin: 8px;
// `;

// const TripWrapper = styled(Stack)`
//   margin: 0 auto !important;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   gap: 16px;
//
//   ${device.tablet} {
//     width: 648px;
//     gap: 24px;
//     flex-direction: row;
//     justify-content: flex-start;
//     flex-wrap: wrap;
//   }
//
//   ${device.laptopN} {
//     width: 948px;
//   }
//
//   ${device.laptopN} {
//     width: 948px;
//   }
//
//   ${device.desktop} {
//     width: 1272px;
//   }
// `;
