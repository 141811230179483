import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import { device } from '../../settings/jaunt-theme';
import { ExploreImageCard } from '../../components/ExploreImageCard';
import {
  useCuratedTripsWithImageUrl,
  useUserTripsWithImageUrl,
} from '../../hooks/use-curated-trips-with-image-url';
import {
  useCreateFavTrip,
  useDeleteFavTrip,
  useFavoriteTrips,
} from '../../hooks/use-favorite-trips';
import { useMemo } from 'react';

export function FavoriteTrips(): JSX.Element {
  const { data: tripsWithImageUrl } = useUserTripsWithImageUrl();
  const { data: curatedTripsWithImageUrl } = useCuratedTripsWithImageUrl();
  const { data: favoriteTrips } = useFavoriteTrips();
  const { createFavTrip } = useCreateFavTrip();
  const { deleteFavTrip } = useDeleteFavTrip();

  const favoriteTripsWithImage = useMemo(() => {
    if (tripsWithImageUrl && curatedTripsWithImageUrl) {
      const trips = curatedTripsWithImageUrl.concat(tripsWithImageUrl);

      if (favoriteTrips?.length && trips) {
        const favTripIds = favoriteTrips.reduce(
          (acc: Record<string, boolean>, item) => {
            acc[item.tripId] = true;
            return acc;
          },
          {},
        );
        return trips?.filter(
          ({ trip }) => !trip.isHidden && favTripIds[trip.tripId],
        );
      }
    }
    return [];
  }, [tripsWithImageUrl, curatedTripsWithImageUrl, favoriteTrips]);

  async function handleToggleFavTrips(tripId: string, isNewFav: boolean) {
    if (isNewFav) {
      await createFavTrip({ tripId });
    } else {
      await deleteFavTrip({ tripId });
    }
  }

  return (
    <TripsWrapper>
      {favoriteTripsWithImage.map((tripWithImageUrl) => (
        <ExploreImageCard
          key={tripWithImageUrl.trip.tripId}
          id={tripWithImageUrl.trip.tripId}
          title={tripWithImageUrl.trip.name}
          tip={`${
            Object.keys(tripWithImageUrl.trip.detail.days)?.length || '0'
          }-Day Jaunt`}
          description={`Curated by ${tripWithImageUrl.trip.detail.createdBy}`}
          imageUrl={tripWithImageUrl.imageUrl}
          routePath={`/trips/${tripWithImageUrl.trip.tripPath}?isCurated=true`}
          isFavorite={true}
          onToggleFavorite={handleToggleFavTrips}
        />
      ))}
    </TripsWrapper>
  );
}

const TripsWrapper = styled(Stack)`
  margin: 32px auto 0 !important;
  width: 100%;
  align-items: center;
  gap: 8px;

  ${device.tablet} {
    width: 674px;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 22px;
  }

  ${device.laptopN} {
    margin: 36px auto 0 !important;
    padding: 0 16px;
    flex-direction: row;
    overflow-x: auto;
    width: 100%;
    flex-wrap: nowrap;
    gap: 16px;
  }

  ${device.desktop} {
    gap: 21px;
  }
`;
