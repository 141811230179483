import { Box, Link, Stack } from '@mui/material';
import { Tex } from '../components/shared/Tex';
import { Link as RouterLink } from 'react-router-dom';
import styled from '@emotion/styled';

export function NotFoundPage(): JSX.Element {
  return (
    <Container>
      <Stack>
        <Box margin="4px 0">
          <Tex
            fontSize={24}
            fontWeight={700}
            textAlign="center"
            fontFamily="canela-bold"
          >
            The page does not exist.
          </Tex>
        </Box>
        <Box margin="4px 0">
          <Tex
            fontSize={24}
            fontWeight={700}
            textAlign="center"
            fontFamily="canela-bold"
          >
            Please make sure the URL is correct.
          </Tex>
        </Box>
      </Stack>

      <Box margin="16px 0">
        <Link component={RouterLink} to="/">
          <Tex variant="h5" fontFamily="canela-bold">
            Go Home
          </Tex>
        </Link>
      </Box>
    </Container>
  );
}

const Container = styled(Stack)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin-bottom: 16px;
  padding: 20% 12px;
`;
