import { getMe } from '../services/user.api';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import QueryKeys from './constants/query-keys';
import { logIn, logOut, signUp } from '../services/auth.api';

export function useMe(retry: boolean): UseQueryResult<string, unknown> {
  const client = useQueryClient();
  return useQuery<string>(
    QueryKeys.me,
    async () => {
      const user = await getMe(false);
      client.setQueryData(QueryKeys.userData, user);
      return user.userId;
    },
    {
      placeholderData: () => {
        return undefined;
      },
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading Me: ', error); // eslint-disable-line no-console
      },
      refetchOnWindowFocus: false,
      staleTime: 10 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
      retry: retry ? 3 : 0,
      retryDelay: 2000,
    },
  );
}

export const useRefetchUser = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async () => {
      const user = await getMe(false);
      client.setQueryData(QueryKeys.userData, user);
      return user.userId;
    },
    {
      onSuccess: (userId) => {
        client.setQueryData(QueryKeys.me, userId);
      },
    },
  );
  return { refetchUser: mutateAsync, isLoading, isSuccess, isError };
};

export const useSignUp = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({ email, password }: { email: string; password: string }) => {
      return await signUp(email, password);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(QueryKeys.me);
      },
    },
  );
  return { signUp: mutateAsync, isLoading, isSuccess, isError };
};

export const useLogIn = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({ email, password }: { email: string; password: string }) => {
      return await logIn(email, password);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(QueryKeys.me);
      },
    },
  );
  return { logIn: mutateAsync, isLoading, isSuccess, isError };
};

export const useLogOut = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async () => {
      return await logOut();
    },
    {
      onSuccess: () => {
        client.invalidateQueries(QueryKeys.me);
      },
    },
  );
  return { logOut: mutateAsync, isLoading, isSuccess, isError };
};
