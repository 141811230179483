import { del, get, post, put } from './base.api';
import {
  Tag,
  TagId,
  TagData,
  SingleTagResponse,
  MultipleTagsResponse,
} from '@jaunt/api';

export async function listTags(): Promise<Tag[]> {
  const { tags } = await get<MultipleTagsResponse>('/tags', {});
  return tags;
}

export async function createTag(data: TagData): Promise<Tag> {
  const { tag } = await post<SingleTagResponse, TagData>('/tags', {}, data);
  return tag;
}

export async function getTag(tagId: TagId): Promise<Tag> {
  const { tag } = await get<SingleTagResponse>(`/tags/${tagId}`, {});
  return tag;
}

export async function updateTag(tagId: TagId, data: TagData): Promise<Tag> {
  const { tag } = await put<SingleTagResponse, TagData>(
    `/tags/${tagId}`,
    {},
    data,
  );
  return tag;
}

export async function deleteTag(tagId: TagId): Promise<Tag> {
  const { tag } = await del<SingleTagResponse>(`/tags/${tagId}`, {});
  return tag;
}
