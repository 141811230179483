import { del, get, post, put } from './base.api';
import {
  SingleUserPlaceNoteResponse,
  UserPlaceNote,
  UserPlaceNoteData,
  PlaceId,
  UserId,
  NoteId,
  MultipleUserPlaceNoteResponse,
} from '@jaunt/api';

export async function createNote(
  data: UserPlaceNoteData,
): Promise<UserPlaceNote> {
  const { userPlaceNote } = await post<
    SingleUserPlaceNoteResponse,
    UserPlaceNoteData
  >('/notes', {}, data);
  return userPlaceNote;
}

export async function getNote(
  placeId: PlaceId,
  userId: UserId,
): Promise<UserPlaceNote[]> {
  const { userPlaceNotes } = await get<MultipleUserPlaceNoteResponse>(
    `/notes/${placeId}/${userId}`,
    {},
  );
  return userPlaceNotes;
}

export async function updateNote(
  noteId: NoteId,
  data: UserPlaceNoteData,
): Promise<UserPlaceNote> {
  const { userPlaceNote } = await put<
    SingleUserPlaceNoteResponse,
    UserPlaceNoteData
  >(`/notes/${noteId}`, {}, data);
  return userPlaceNote;
}

export async function deleteNote(noteId: NoteId): Promise<UserPlaceNote> {
  const { userPlaceNote } = await del<SingleUserPlaceNoteResponse>(
    `/notes/${noteId}`,
    {},
  );
  return userPlaceNote;
}
