import { TripId } from '@jaunt/api';
import { getFirstTripImageUrl } from '../services/trip.util';
import { NEW_TRIP } from '../constants';
import { useQuery, UseQueryResult } from 'react-query';
import QueryKeys from './constants/query-keys';

export function useFirstTripImageUrl(
  tripId: TripId | null | undefined,
): UseQueryResult<string | undefined, unknown> {
  return useQuery<string | undefined>(
    [QueryKeys.firstTripImageUrl, tripId],
    async () => {
      if (!tripId || tripId === NEW_TRIP) {
        return undefined;
      } else {
        return await getFirstTripImageUrl(tripId);
      }
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading FirstTripImageUrl: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!tripId,
    },
  );
}
