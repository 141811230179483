import React from 'react';
import { Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { common } from '@mui/material/colors';
import { Tex } from './shared/Tex';
import { PlaceId, TagType, TripId } from '@jaunt/api';
import { usePlace } from 'src/hooks/use-place';
import { usePlaceFirstImage } from 'src/hooks/use-place-first-image';
import { useNeighborhood } from 'src/hooks/use-neighborhood';
import styled from '@emotion/styled';
import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  device,
} from 'src/settings/jaunt-theme';
import { PlaceExternalLink } from './PlaceExternalLink';
import {
  mdiBookOpenVariant,
  mdiCameraOutline,
  mdiMagnify,
  mdiPlusCircleOutline,
} from '@mdi/js';
import { usePlaceTags } from 'src/hooks/use-place-tags';
import { MdiIcon } from './shared/MdiIcon';

interface Props {
  placeId: PlaceId;
  placeIds: PlaceId[];
  emptyInfo: string;
  isInTrip: boolean;
  onToggle(placeId: PlaceId): Promise<void>;
  tripId: TripId;
  activity: string | null;
}

export function UserTripPlaceCard(props: Props): JSX.Element {
  const navigate = useNavigate();
  const { placeId, placeIds, emptyInfo, onToggle, isInTrip, activity } = props;
  const { data: place } = usePlace(placeId);
  const { data: imageUrl } = usePlaceFirstImage(placeId);
  const { data: neighborhood } = useNeighborhood(place?.neighborhoodId || null);
  const { data: tags } = usePlaceTags(placeId);
  const activityTags = tags?.filter((x) => x.type === TagType.ACTIVITY);
  const activityTagNames = activityTags?.map((tag) => tag.name);
  const included = activity && activityTagNames?.includes(activity);

  const openPlacePage = () => {
    if (place?.placePath) {
      navigate(`/places/${place.placePath}`);
    }
  };

  async function handleToggle(e: React.MouseEvent): Promise<void> {
    e.stopPropagation();
    onToggle(placeId);
  }

  return included && place && placeIds.length > 0 ? (
    <Container
      onClick={openPlacePage}
      justifyContent="space-between"
      sx={{ cursor: 'pointer' }}
    >
      <LazyLoadImageContainer
        key={`${place.placeId}-image`}
        alt={place.name}
        src={imageUrl ?? undefined}
        height="360px"
        style={{
          objectFit: 'cover',
          borderRadius: '20px 20px 0 0',
        }}
        placeholder={<Stack className="placeholder-block" />}
      />
      <Stack
        flex={1}
        direction="column"
        justifyContent="space-between"
        sx={{
          backgroundColor: common.white,
          color: common.black,
        }}
      >
        <ContentBox>
          <Tex sx={{ pt: 1 }}>
            <Tex
              component="span"
              fontWeight={700}
              fontSize={16}
              lineHeight="24px"
              fontFamily="CircularStd-Medium"
              sx={{ letterSpacing: 0 }}
            >
              {place.name}
            </Tex>{' '}
            {neighborhood && (
              <Tex
                component="span"
                fontSize={14}
                lineHeight="24px"
                fontFamily="CircularStd-Light"
              >
                in {neighborhood.name}
              </Tex>
            )}
          </Tex>
          <DescriptionBlock>{place.description}</DescriptionBlock>
        </ContentBox>
        <Stack
          direction="row"
          flexWrap="nowrap"
          spacing={1}
          sx={{
            pl: 1.5,
            pb: 1,
            overflow: 'auto',
            mt: 2,
            bottom: 10,
          }}
        >
          {place.book ? (
            <PlaceExternalLink
              icon={mdiBookOpenVariant}
              text="BOOK"
              url={place.book}
            />
          ) : null}
          {place.instagram ? (
            <PlaceExternalLink
              icon={mdiCameraOutline}
              text="INSTAGRAM"
              url={place.instagram}
            />
          ) : null}
          {place.website ? (
            <PlaceExternalLink
              icon={mdiMagnify}
              text="WEBSITE"
              url={place.website}
            />
          ) : null}
        </Stack>
        <TripButtonContainer
          direction="row"
          alignItems="center"
          spacing={0.5}
          onClick={handleToggle}
          sx={{
            backgroundColor: isInTrip
              ? 'rgba(255, 255, 255, 1.0)'
              : 'rgba(255, 255, 255, 0.6)',
          }}
        >
          {isInTrip ? null : <MdiIcon icon={mdiPlusCircleOutline} />}
          <Tex fontSize={12} fontWeight={700}>
            {isInTrip ? 'On My Trip' : 'Add To Trip'}
          </Tex>
        </TripButtonContainer>
        <Box
          sx={{
            textAlign: 'center',
            backgroundColor: '#DADADA',
            height: '48px',
            mt: 1,
            p: 1.5,
          }}
        >
          <Tex
            component="span"
            fontFamily="CircularStd-Light"
            fontSize={14}
            lineHeight="18px"
          >
            Additional Information
          </Tex>
        </Box>
      </Stack>
    </Container>
  ) : place && placeIds.length === 0 ? (
    <Stack>
      <Tex textAlign="center" sx={{ opacity: 0.5 }}>
        {emptyInfo}
      </Tex>
    </Stack>
  ) : (
    <></>
  );
}

const TripButtonContainer = styled(Stack)`
  padding: 5px 10px;
  color: ${PRIMARY_COLOR};
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 9999px;
  cursor: pointer;
`;

const Container = styled(Stack)`
  max-width: 390px;
  height: 675px;
  flex-shrink: 0;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  margin: 0 0 16px !important;
  width: 100%;
  padding: 0 8px;
  box-sizing: border-box;

  .placeholder-block {
    height: 360px;
    border-radius: 20px 20px 0 0;
    background-color: ${SECONDARY_COLOR};
  }

  ${device.tablet} {
    padding: 0;
    width: 326px;
  }

  ${device.laptopN} {
    width: 300px;
  }
`;

const LazyLoadImageContainer = styled(LazyLoadImage)`
  background-color: ${SECONDARY_COLOR};
  height: 360px;
  max-height: 360px;
  width: auto;

  & .placeholder-block {
    height: 360px;
    width: auto;
    border-radius: 20px 20px 0 0;
    background-color: ${SECONDARY_COLOR};
  }
`;

const ContentBox = styled(Box)`
  padding: 12px;
  min-height: 175px;
`;

const DescriptionBlock = styled(Tex)`
  display: -webkit-box;
  font-size: 16px;
  line-height: 24px;
  font-family: 'CircularStd-Light', sans-serif;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 276px;
  min-width: 276px;
  height: 116px;
`;
