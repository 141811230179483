import {
  ActivityGPTResponse,
  CityDetailsGPT,
  CityListGPT,
  HotelGPT,
  SendEmailGPT,
} from '@jaunt/api';
import axios from 'axios';

const CHAT_GPT_URL = 'https://api.jauntengine.com/eva/api-v1';

export async function getCityGpt(
  city: string,
  signal: AbortSignal,
): Promise<CityDetailsGPT> {
  const { data } = await axios.get<CityDetailsGPT>(
    `${CHAT_GPT_URL}/getCityDetails?city=${city.toLowerCase()}`,
    { signal: signal },
  );

  return data;
}

export async function getCityListGpt(
  city: string,
  signal: AbortSignal,
): Promise<CityListGPT> {
  const { data } = await axios.get<CityListGPT>(
    `${CHAT_GPT_URL}/getCityAutoSuggestion?cityString=${city.toLowerCase()}`,
    { signal: signal },
  );

  return data;
}

export async function getItineraryByID(
  code: string,
  signal: AbortSignal,
): Promise<ActivityGPTResponse> {
  const { data } = await axios.get(
    `${CHAT_GPT_URL}/fetchItineraryByCode?code=${code}`,
    {
      signal: signal,
    },
  );
  return data;
}

export async function createItineraryGpt(
  city: string,
  numberOfTripDays: number,
  vibe: string,
  signal: AbortSignal,
): Promise<ActivityGPTResponse> {
  const { data } = await axios.get(
    `${CHAT_GPT_URL}/createItinerary?city=${city.toLowerCase()}&numberOfTripDays=${numberOfTripDays}&vibe=${vibe.toLowerCase()}`,
    {
      signal: signal,
    },
  );
  return data;
}

export async function getHotelInfoInCityGpt(
  city: string,
  hotelType: string,
  signal: AbortSignal,
): Promise<HotelGPT[]> {
  const { data } = await axios.get<HotelGPT[]>(
    `${CHAT_GPT_URL}/getHotelInfoInCity?city=${city.toLowerCase()}&hotelType=${hotelType.toLowerCase()}`,
    {
      signal: signal,
    },
  );
  return data;
}

export async function sendTripByEmailGpt(body: SendEmailGPT): Promise<unknown> {
  return await axios.post<SendEmailGPT, unknown>(
    `${CHAT_GPT_URL}/emailItinerary`,
    { body },
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Content-Type': 'application/json;charset=utf-8',
      },
      withCredentials: false,
    },
  );
}
