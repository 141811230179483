import { Stack } from '@mui/material';
import { usePlaceFirstImage } from '../hooks/use-place-first-image';
import { City, Place, Trip, UserId, TripPlace, PlaceId } from '@jaunt/api';
import { useNeighborhood } from '../hooks/use-neighborhood';
import { common } from '@mui/material/colors';
import { Tex } from './shared/Tex';
import { mdiMagnify, mdiBookOpenVariant } from '@mdi/js';
import { mdiCameraOutline } from '@mdi/js';
import { VMTPlaceExternalLinkWebsite } from './VMTPlaceExternalLinkWebsite';
import { VMTPlaceExternalLinkInstagram } from './VMTPlaceExternalLinkInstagram';
import { VMTPlaceExternalLinkBook } from './VMTPlaceExternalLinkBook';
import { useState, useEffect } from 'react';
import { ComingSoonDialog } from './ComingSoonDialog';
import { LogInOrSignUpDialog } from './LogInOrSignUpDialog';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { TripPlaceCardLabels } from './TripPlaceCardLabels';
import { device, SECONDARY_COLOR } from '../settings/jaunt-theme';

interface Props {
  place: Place;
  city: City;
  trip: Trip;
  userId: UserId;
  tripPlace?: TripPlace;
  onClick?: (placeId: PlaceId) => void;
  isActive?: boolean;
}

export function FilteredMyTripPlaceCard({
  place,
  trip,
  userId,
  tripPlace,
  onClick,
  isActive = false,
}: Props): JSX.Element {
  const navigate = useNavigate();
  const { placeId } = place;
  const { tripId } = trip;
  const [comingSoon, setComingSoon] = useState(false);
  const [comingSoonText, setComingSoonText] = useState<string>();
  const { data: neighborhood } = useNeighborhood(place.neighborhoodId);
  const { data: imageUrl } = usePlaceFirstImage(placeId);
  const [showLogin, setShowLogin] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 745);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setLoaded(true);
  }, [userId]);

  async function onCloseComingSoonDialog() {
    setComingSoon(false);
    setComingSoonText(undefined);
  }

  async function onEditPlaceSummary() {
    if (isMobile) {
      navigate(
        `/trips/${trip.tripPath}/places/${place.placePath}/edit-summary`,
      );
    } else if (onClick) {
      onClick(placeId);
    }
  }

  return loaded ? (
    <Container
      justifyContent="start"
      direction="row"
      onClick={onEditPlaceSummary}
      isactive={isActive ? 1 : 0}
    >
      <BackgroundImage image_url={imageUrl} />
      <Stack direction="column" sx={{ width: '100%', py: 1 }}>
        {tripPlace?.activity ? (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ paddingLeft: 1, paddingBottom: 1 }}
          >
            <TripPlaceCardLabels tripPlace={tripPlace} />
          </Stack>
        ) : null}
        {/*TODO enable MoreHorizIcon when design be ready */}
        {/*<MoreHorizIcon sx={{ position: 'absolute', right: '8px' }} />*/}
        <Tex
          variant="body1"
          overflow="hidden"
          width="100%"
          sx={{
            paddingLeft: 1,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            display: 'flex',
            maxWidth: '250px',
          }}
        >
          <PlaceName>{place.name}</PlaceName>
          {neighborhood ? (
            <NeighborhoodName>in {neighborhood.name}</NeighborhoodName>
          ) : null}
        </Tex>
        <Stack sx={{ width: '100%', overflowX: 'scroll' }}>
          <ChipWrapper direction="row">
            {place.book ? (
              <Stack sx={{ overflowX: 'visible', m: 0 }}>
                <VMTPlaceExternalLinkBook
                  icon={mdiBookOpenVariant}
                  text="BOOK"
                  url={place.book}
                />
              </Stack>
            ) : null}
            {place.instagram ? (
              <Stack sx={{ overflowX: 'visible', m: 0 }}>
                <VMTPlaceExternalLinkInstagram
                  icon={mdiCameraOutline}
                  text="INSTAGRAM"
                  url={place.instagram}
                />
              </Stack>
            ) : null}
            {place.website ? (
              <Stack sx={{ overflowX: 'visible', m: 0 }}>
                <VMTPlaceExternalLinkWebsite
                  icon={mdiMagnify}
                  text="WEBSITE"
                  url={place.website}
                />
              </Stack>
            ) : null}
          </ChipWrapper>
        </Stack>
      </Stack>
      <ComingSoonDialog
        text={comingSoonText}
        isOpen={comingSoon}
        onClose={onCloseComingSoonDialog}
      />
      {showLogin && (
        <LogInOrSignUpDialog
          isOpen={showLogin}
          onClose={() => setShowLogin(false)}
          tripId={tripId}
        />
      )}
    </Container>
  ) : (
    <></>
  );
}

const Container = styled(Stack)<{ isactive: number }>`
  flex-shrink: 0;
  border-radius: 16px;
  overflow: visible;
  position: relative;
  min-height: 84px;
  background-color: ${({ isactive }) => (isactive ? '#F5C6AB' : common.white)};
  cursor: pointer;

  ${device.tablet} {
    min-height: 64px;
  }
`;

const BackgroundImage = styled(Stack)<{
  image_url: string | null | undefined;
}>`
  width: 26%;
  flex-shrink: 0;
  background-image: url(${(props) => props.image_url});
  background-color: ${SECONDARY_COLOR};
  background-size: cover;
  background-position: center;
  border-radius: 10px 0 0 10px;
  position: relative;
`;

const ChipWrapper = styled(Stack)`
  padding: 8px 4px;
  flex-wrap: wrap;
  gap: 4px;

  '& div': {
    margin: 0 !important;
  },
`;

const PlaceName = styled.span`
  font-weight: 700;
  font-size: 16px;
`;

const NeighborhoodName = styled.span`
  padding-left: 4px;
  padding-top: 4px;
  font-weight: 300;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
