import React from 'react';
import { Button } from '@mui/material';
import { MdiIcon } from './shared/MdiIcon';

interface Props {
  icon: string;
  text: string;
  url: string;
}

export function PlaceExternalLink(props: Props): JSX.Element {
  const { icon, text, url } = props;
  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <Button
      onClick={onClick}
      href={url}
      target="_blank"
      rel="noreferrer"
      size="small"
      color="primary"
      variant="contained"
      sx={{
        marginTop: '0 !important',
        px: 3,
        py: 1,
        borderRadius: 9999,
        flexShrink: 0,
      }}
    >
      <MdiIcon icon={icon} sx={{ mr: 1 }} />
      {text}
    </Button>
  );
}
