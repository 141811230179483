import { usePlace } from '../../hooks/use-place';
import styled from '@emotion/styled';
import { PlaceId, TagType } from '@jaunt/api';
import { Tex } from '../shared/Tex';
import { PRIMARY_COLOR } from '../../settings/jaunt-theme';
import { common } from '@mui/material/colors';
import { Stack } from '@mui/material';
import { usePlaceTags } from '../../hooks/use-place-tags';

type CuratedTripSummaryItemProps = {
  placeId: PlaceId;
  index: number;
};

export const CuratedTripSummaryItem = ({
  placeId,
  index,
}: CuratedTripSummaryItemProps): JSX.Element | null => {
  const { data: place } = usePlace(placeId);
  const { data: tags } = usePlaceTags(placeId);
  const activityTags = tags
    ?.filter((x) => x.type === TagType.ACTIVITY)
    ?.map((tag) => tag.name)
    .sort()
    .join(', ');

  return (
    <Container>
      <Tex
        height={22}
        width={22}
        display="flex"
        justifyContent="center"
        alignItems="center"
        lineHeight="16px"
        fontSize={14}
        fontWeight={700}
        sx={{
          backgroundColor: PRIMARY_COLOR,
          color: common.white,
          borderRadius: '50%',
        }}
      >
        {index}
      </Tex>
      <Stack sx={{ display: 'flex', flexDirection: 'column' }}>
        <Tex
          fontSize={14}
          lineHeight="22px"
          fontFamily="CircularStd-Medium"
          fontWeight="500"
        >
          {activityTags}
        </Tex>
        <Tex fontSize={16} lineHeight="24px" fontFamily="CircularStd-Light">
          {place?.name}
        </Tex>
      </Stack>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  white-space: normal;
  overflow: hidden;
  min-height: 46px;
`;
