import { Button, Dialog, Stack, TextField } from '@mui/material';
import { LogoHeader } from './LogoHeader';
import { UserId, PlaceId, Place } from '@jaunt/api';
import {
  BACKGROUND_COLOR,
  device,
  PRIMARY_COLOR,
} from 'src/settings/jaunt-theme';
import { Tex } from './shared/Tex';
import { common } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';

interface Props {
  placeId: PlaceId;
  place: Place;
  isOpen: boolean;
  userId: UserId;
  onClose(): void;
  onSave(note: string): void;
  defaultNote: string;
}

const placeholderValue =
  'Still need to confirm on who is coming to dinner? Have to call to get tickets? Leave yourself a note so you do not forget.';

export function AddNote(props: Props): JSX.Element {
  const { isOpen, onClose, place, defaultNote, onSave } = props;
  const [incomingNote, setIncomingNote] = useState(placeholderValue);
  const [isDirty, setIsDirty] = useState(false);

  async function mounted(): Promise<void> {
    if (defaultNote.length) {
      setIncomingNote(defaultNote);
    } else {
      setIncomingNote(placeholderValue);
    }
  }

  useEffect(() => {
    mounted();
  }, [defaultNote]);

  async function handleSave() {
    onSave(incomingNote);
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      style={{ backgroundColor: BACKGROUND_COLOR }}
      fullScreen
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: BACKGROUND_COLOR,
        },
      }}
    >
      <Stack sx={{ width: '100%', minHeight: '100vh' }}>
        <LogoHeader showAuthLinks />
        <Stack className="main">
          <Tex
            textAlign="center"
            fontFamily="Canela"
            fontSize="24px"
            sx={{ color: PRIMARY_COLOR, fontWeight: 700, paddingTop: 2 }}
          >
            Add A Note
          </Tex>
          <Tex
            textAlign="center"
            fontFamily="Canela"
            fontSize="18px"
            sx={{ color: common.black, fontWeight: 300 }}
          >
            {place.name}
          </Tex>
          <NoteContainer spacing={10} alignItems="center">
            <TextField
              variant="filled"
              multiline
              fullWidth
              rows={10}
              onFocus={(e) => {
                if (incomingNote === placeholderValue) {
                  e.target.value = '';
                }
              }}
              onChange={(e) => {
                setIncomingNote(e.target.value);
                setIsDirty(true);
              }}
              defaultValue={incomingNote}
            />
            <Stack spacing={2} alignItems="center">
              <Button
                variant="contained"
                onClick={handleSave}
                disabled={!incomingNote.trim().length || !isDirty}
                sx={{
                  borderRadius: '9999px',
                  width: '250px',
                }}
              >
                SAVE
              </Button>
              <Tex
                fontSize={14}
                onClick={onClose}
                fontWeight={300}
                sx={{ textDecorationLine: 'underline', cursor: 'pointer' }}
              >
                <b>Cancel</b>
              </Tex>
            </Stack>
          </NoteContainer>
        </Stack>
      </Stack>
    </Dialog>
  );
}

const NoteContainer = styled(Stack)`
  ${device.mobileM} {
    padding: 24px 42px;
  }

  ${device.tablet} {
    padding: 24px 104px;
  }

  ${device.laptopS} {
    padding: 24px 221px;
  }
`;
