import { del, get, patch, post } from './base.api';

import {
  MultipleSearchCityAnalyticsResponse,
  SearchCityAnalytic,
  SingleSearchCityAnalyticResponse,
} from '@jaunt/api/dist/search-city-analytics';
import { CityId } from '@jaunt/api';

export async function searchCityList(): Promise<SearchCityAnalytic[]> {
  const { searchCityAnalytics } =
    await get<MultipleSearchCityAnalyticsResponse>(
      '/search-city-analytics',
      {},
    );
  return searchCityAnalytics;
}

export async function increaseCitySearch(
  name: string,
): Promise<SearchCityAnalytic | null> {
  const { searchCityAnalytic } = await patch<
    SingleSearchCityAnalyticResponse,
    { name: string }
  >('/search-city-analytics/search', {}, { name });
  return searchCityAnalytic;
}
export async function increaseCityClick(
  name: string,
): Promise<SearchCityAnalytic | null> {
  const { searchCityAnalytic } = await patch<
    SingleSearchCityAnalyticResponse,
    { name: string }
  >('/search-city-analytics/click', {}, { name });
  return searchCityAnalytic;
}

export async function searchCityReset(): Promise<SearchCityAnalytic[]> {
  const { searchCityAnalytics } =
    await post<MultipleSearchCityAnalyticsResponse>(
      '/search-city-analytics/reset',
      {},
      {},
    );
  return searchCityAnalytics;
}
export async function searchCityDelete(
  cityId: CityId,
): Promise<SearchCityAnalytic | null> {
  const { searchCityAnalytic } = await del<SingleSearchCityAnalyticResponse>(
    `/search-city-analytics/${cityId}`,
    {},
  );
  return searchCityAnalytic;
}
