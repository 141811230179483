import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { FavoritePlace, PlaceId, TripId } from '@jaunt/api';
import QueryKeys from './constants/query-keys';
import {
  createFavoritePlace,
  deleteFavoritePlace,
  getFavoritePlace,
  listFavoritePlaces,
} from '../services/place.api';

export function useFavoritePlaces(): UseQueryResult<FavoritePlace[], unknown> {
  return useQuery<FavoritePlace[]>(
    QueryKeys.favoritePlaces,
    listFavoritePlaces,
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading FavoriteTrips: ', error); // eslint-disable-line no-console
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: 3,
      retryDelay: 2000,
    },
  );
}

export function useFavoritePlace(
  tripId?: TripId | null,
): UseQueryResult<FavoritePlace | undefined, unknown> {
  return useQuery<FavoritePlace | undefined>(
    [QueryKeys.favoritePlaces, tripId],
    async () => {
      return tripId ? await getFavoritePlace(tripId) : undefined;
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading FavoriteTrip: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!tripId,
    },
  );
}

export const useCreateFavPlace = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({ placeId }: { placeId: PlaceId }) => {
      return await createFavoritePlace({ placeId });
    },
    {
      onSuccess: () => {
        client.invalidateQueries(QueryKeys.favoritePlaces);
      },
    },
  );
  return { createFavPlace: mutateAsync, isLoading, isSuccess, isError };
};

export const useDeleteFavPlace = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({ placeId }: { placeId: PlaceId }) => {
      await deleteFavoritePlace(placeId);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(QueryKeys.favoritePlaces);
      },
    },
  );
  return { deleteFavPlace: mutateAsync, isLoading, isSuccess, isError };
};
