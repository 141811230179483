import { Box, IconButton, Link, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { common } from '@mui/material/colors';
import { Tex } from './shared/Tex';
import { TripGenerated } from '@jaunt/api';
import { mdiChevronRight } from '@mdi/js';
import { MdiIcon } from './shared/MdiIcon';
import { device, SECONDARY_COLOR } from 'src/settings/jaunt-theme';
import styled from '@emotion/styled';

interface Props {
  trip: TripGenerated;
}

export function TripGeneratedCard(props: Props): JSX.Element {
  const { trip } = props;

  return trip ? (
    <Link
      component={RouterLink}
      to={`/generated-trips/${trip.tripPath}`}
      underline="none"
    >
      <Container>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            p: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            color: common.white,
          }}
        >
          <Box>
            <Tex>
              <Tex component="span" fontWeight={700} fontSize={20}>
                {trip.name}
              </Tex>{' '}
            </Tex>
          </Box>
          <IconButton>
            <MdiIcon
              icon={mdiChevronRight}
              sx={{ color: common.white, fontSize: 40 }}
            />
          </IconButton>
        </Stack>
      </Container>
    </Link>
  ) : (
    <></>
  );
}

const Container = styled(Stack)`
  justify-content: flex-end;
  width: 350px;
  height: 200px;
  flex-shrink: 0;
  background-size: cover;
  background-color: ${SECONDARY_COLOR};
  background-position: center;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;

  ${device.tablet} {
    width: 312px;
  }

  ${device.laptopN} {
    width: 300px;
  }
`;
