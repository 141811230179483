import { Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { common } from '@mui/material/colors';
import { Tex } from '../shared/Tex';
import { PlaceId } from '@jaunt/api';
import { usePlace } from '../../hooks/use-place';
import { usePlaceFirstImage } from '../../hooks/use-place-first-image';
import { useNeighborhood } from '../../hooks/use-neighborhood';
import styled from '@emotion/styled';
import { device, SECONDARY_COLOR } from '../../settings/jaunt-theme';
import { PlaceExternalLink } from '../PlaceExternalLink';
import { mdiBookOpenVariant, mdiCameraOutline, mdiMagnify } from '@mdi/js';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';

interface Props {
  placeId: PlaceId;
  placeIds: PlaceId[];
  emptyInfo: string;
  isFavorite: boolean;
  onToggleFavorite: (id: string, isNewFav: boolean) => void;
}

export function CuratedTripPlaceCard(props: Props): JSX.Element {
  const navigate = useNavigate();
  const { placeId, placeIds, emptyInfo, isFavorite, onToggleFavorite } = props;
  const { data: place } = usePlace(placeId);
  const { data: imageUrl } = usePlaceFirstImage(placeId);
  const { data: neighborhood } = useNeighborhood(place?.neighborhoodId || null);

  const openPlacePage = () => {
    if (place?.placePath) {
      navigate(`/places/${place.placePath}`);
    }
  };
  async function handleToggleFavorite(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    onToggleFavorite(placeId, !isFavorite);
  }

  return place && placeIds.length > 0 ? (
    <Container
      justifyContent="space-between"
      onClick={openPlacePage}
      sx={{ cursor: 'pointer', position: 'relative' }}
    >
      <Stack
        sx={{
          position: 'absolute',
          top: '4px',
          left: 0,
          py: 1,
          px: 1.5,
        }}
        onClick={handleToggleFavorite}
      >
        {isFavorite ? (
          <FavoriteOutlinedIcon fontSize="large" htmlColor="#FE0000" />
        ) : (
          <FavoriteBorderOutlinedIcon fontSize="large" htmlColor="#CCD2E3" />
        )}
      </Stack>
      <LazyLoadImageContainer
        key={`${place.placeId}-image`}
        alt={place.name}
        src={imageUrl ?? undefined}
        width="auto"
        height="360px"
        style={{
          objectFit: 'cover',
          borderRadius: '20px 20px 0 0',
        }}
        placeholder={<Stack className="placeholder-block" />}
      />
      <Stack
        flex={1}
        direction="column"
        justifyContent="space-between"
        sx={{
          backgroundColor: common.white,
          color: common.black,
        }}
      >
        <Box sx={{ p: 1.5 }}>
          <Tex sx={{ pt: 1 }}>
            <Tex
              component="span"
              fontWeight={700}
              fontSize={16}
              lineHeight="24px"
              fontFamily="CircularStd-Medium"
              sx={{ letterSpacing: 0 }}
            >
              {place.name}
            </Tex>{' '}
            {neighborhood && (
              <Tex
                component="span"
                fontSize={14}
                lineHeight="24px"
                fontFamily="CircularStd-Light"
              >
                in {neighborhood.name}
              </Tex>
            )}
          </Tex>
          <Tex
            component="span"
            fontSize={16}
            lineHeight="24px"
            fontFamily="CircularStd-Light"
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 4,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {place.description}
          </Tex>
        </Box>
        <Stack
          direction="row"
          flexWrap="nowrap"
          spacing={1}
          sx={{ pl: 1.5, pb: 1, overflow: 'auto', mt: 2 }}
        >
          {place.book ? (
            <PlaceExternalLink
              icon={mdiBookOpenVariant}
              text="BOOK"
              url={place.book}
            />
          ) : null}
          {place.instagram ? (
            <PlaceExternalLink
              icon={mdiCameraOutline}
              text="INSTAGRAM"
              url={place.instagram}
            />
          ) : null}
          {place.website ? (
            <PlaceExternalLink
              icon={mdiMagnify}
              text="WEBSITE"
              url={place.website}
            />
          ) : null}
        </Stack>
        <Box
          sx={{
            textAlign: 'center',
            backgroundColor: '#DADADA',
            height: '48px',
            mt: 1,
            p: 1.5,
          }}
        >
          <Tex
            component="span"
            fontFamily="CircularStd-Light"
            fontSize={14}
            lineHeight="18px"
          >
            Additional Information
          </Tex>
        </Box>
      </Stack>
    </Container>
  ) : place && placeIds.length === 0 ? (
    <Stack>
      <Tex textAlign="center" sx={{ opacity: 0.5 }}>
        {emptyInfo}
      </Tex>
    </Stack>
  ) : (
    <></>
  );
}

const Container = styled(Stack)`
  width: 100%;
  min-height: 600px;
  flex: 1 auto;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  max-width: 389px;
  height: 640px;
  margin: 0 0 16px;

  ${device.tablet} {
    max-width: 326px;
  }

  ${device.laptopN} {
    max-width: 300px;
  }

  ${device.desktop} {
    max-width: 309px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .placeholder-block {
    height: 360px;
    width: auto;
    border-radius: 20px 20px 0 0;
    background-color: ${SECONDARY_COLOR};
  }
`;

const LazyLoadImageContainer = styled(LazyLoadImage)`
  background-color: ${SECONDARY_COLOR};
  height: 360px;
  max-height: 360px;
  width: auto;

  & .placeholder-block {
    height: 360px;
    width: auto;
    border-radius: 20px 20px 0 0;
    background-color: ${SECONDARY_COLOR};
  }
`;
