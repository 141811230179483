import styled from '@emotion/styled';
import { Box, Stack } from '@mui/material';
import { Trip } from '@jaunt/api';
import { useNavigate } from 'react-router-dom';
import { device, ORANGE_COLOR } from 'src/settings/jaunt-theme';
import { LoadingButton } from '@mui/lab';
import { useSaveCuratedTripByUser } from 'src/hooks/use-user-trips';
import { Tex } from '../shared/Tex';
import { useState } from 'react';

interface Props {
  trip: Trip;
}

export function CuratedTripSaveToMyTrip({ trip }: Props): JSX.Element {
  const navigate = useNavigate();
  const [error, setError] = useState<boolean>(false);
  const { saveTrip, isLoading, isError } = useSaveCuratedTripByUser();
  async function saveToMyTrip() {
    setError(false);
    if (trip.tripId) {
      const newTrip = await saveTrip(trip.tripId);
      navigate(`/trips/${newTrip.tripPath}?preview=true`);
      return;
    }
    setError(true);
  }

  return (
    <SaveMyTripWrapper>
      <SaveMyTripButton
        onClick={saveToMyTrip}
        variant="contained"
        loading={isLoading}
        disabled={isLoading}
      >
        SAVE MY TRIP
      </SaveMyTripButton>
      <ErrorBlock>
        {error || isError ? (
          <Tex fontSize={16} fontWeight={500} textAlign="center">
            Something went wrong. Try again later!
          </Tex>
        ) : null}
      </ErrorBlock>
    </SaveMyTripWrapper>
  );
}

const SaveMyTripWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 12px auto;
  padding: 8px 0;

  ${device.tablet} {
    margin: 18px auto;
    gap: 4px;
  }
`;

const SaveMyTripButton = styled(LoadingButton)`
  align-items: center;
  padding: 8px;
  width: 240px;
  height: 40px;
  border-radius: 20px;
  background-color: ${ORANGE_COLOR};
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  font-family: 'CircularStd-Book', sans-serif;
`;

const ErrorBlock = styled(Stack)`
  color: ${ORANGE_COLOR};
  padding: 8px;
  height: 40px;
`;
