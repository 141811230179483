import { del, get, patch, post, put } from './base.api';
import {
  City,
  CityId,
  CityData,
  SingleCityResponse,
  MultipleCitiesResponse,
  CityImage,
  MultipleCityImagesResponse,
  Neighborhood,
  MultipleNeighborhoodsResponse,
  MultiplePlacesResponse,
  Place,
  Trip,
  MultipleTripsResponse,
  MultipleTagsResponse,
  Tag,
  ReplaceCityTagsData,
  TagId,
  MultipleFavoriteCityResponse,
  FavoriteCity,
  SingleFavoriteCityResponse,
} from '@jaunt/api';

export async function listCities(): Promise<City[]> {
  const { cities } = await get<MultipleCitiesResponse>('/cities', {});
  return cities;
}

export async function createCity(data: CityData): Promise<City> {
  const { city } = await post<SingleCityResponse, CityData>(
    '/cities',
    {},
    data,
  );
  return city;
}

export async function toggleCityHidden(
  cityId: CityId,
  data: { isHidden: boolean },
): Promise<City> {
  const { city } = await patch<SingleCityResponse, { isHidden: boolean }>(
    `/cities/${cityId}/hide`,
    {},
    data,
  );
  return city;
}

export async function getCity(cityId: CityId): Promise<City> {
  const { city } = await get<SingleCityResponse>(`/cities/${cityId}`, {});
  return city;
}

export async function getCityByPath(cityPath: string): Promise<City> {
  const { city } = await get<SingleCityResponse>(
    `/cities/path/${cityPath}`,
    {},
  );
  return city;
}

export async function getCityImages(cityId: CityId): Promise<CityImage[]> {
  const { cityImages } = await get<MultipleCityImagesResponse>(
    `/cities/${cityId}/images`,
    {},
  );
  return cityImages;
}

export async function getCityNeighborhoods(
  cityId: CityId,
): Promise<Neighborhood[]> {
  const { neighborhoods } = await get<MultipleNeighborhoodsResponse>(
    `/cities/${cityId}/neighborhoods`,
    {},
  );
  return neighborhoods;
}

export async function getCityPlaces(cityId: CityId): Promise<Place[]> {
  const { places } = await get<MultiplePlacesResponse>(
    `/cities/${cityId}/places`,
    {},
  );
  return places;
}

export async function getCityCuratedPlaces(cityId: CityId): Promise<Place[]> {
  const { places } = await get<MultiplePlacesResponse>(
    `/cities/${cityId}/places?isCurated=true`,
    {},
  );
  return places;
}

export async function getCityTags(cityId: CityId): Promise<Tag[]> {
  const { tags } = await get<MultipleTagsResponse>(
    `/cities/${cityId}/tags`,
    {},
  );
  return tags;
}

export async function replaceCityTags(
  cityId: CityId,
  tagIds: TagId[],
): Promise<Tag[]> {
  const { tags } = await put<MultipleTagsResponse, ReplaceCityTagsData>(
    `/cities/${cityId}/tags`,
    {},
    { tagIds },
  );
  return tags;
}

export async function getCityCuratedTrips(cityId: CityId): Promise<Trip[]> {
  const { trips } = await get<MultipleTripsResponse>(
    `/cities/${cityId}/curated-trips`,
    {},
  );
  return trips;
}

export async function updateCity(
  cityId: CityId,
  data: CityData,
): Promise<City> {
  const { city } = await put<SingleCityResponse, CityData>(
    `/cities/${cityId}`,
    {},
    data,
  );
  return city;
}

export async function deleteCity(cityId: CityId): Promise<City> {
  const { city } = await del<SingleCityResponse>(`/cities/${cityId}`, {});
  return city;
}

export async function listFavoriteCities(): Promise<FavoriteCity[]> {
  const { favoriteCities } = await get<MultipleFavoriteCityResponse>(
    '/city-favorite',
    {},
  );
  return favoriteCities;
}

export async function getFavoriteCity(cityId: CityId): Promise<FavoriteCity> {
  const { favoriteCity } = await get<SingleFavoriteCityResponse>(
    `/city-favorite/${cityId}`,
    {},
  );
  return favoriteCity;
}

export async function createFavoriteCity(data: {
  cityId: CityId;
}): Promise<FavoriteCity> {
  const { favoriteCity } = await post<
    SingleFavoriteCityResponse,
    {
      cityId: CityId;
    }
  >('/city-favorite', {}, data);
  return favoriteCity;
}

export async function deleteFavoriteCity(
  cityId: CityId,
): Promise<FavoriteCity> {
  const { favoriteCity } = await del<SingleFavoriteCityResponse>(
    `/city-favorite/${cityId}`,
    {},
  );
  return favoriteCity;
}
