import { Trip, TripPlace, TripStay } from '@jaunt/api';
import styled from '@emotion/styled';
import { Tex } from '../shared/Tex';
import { common } from '@mui/material/colors';
import { CuratedTripSummaryItem } from './CuratedTripSummaryItem';
import { Stack } from '@mui/material';
import { device, PRIMARY_COLOR } from '../../settings/jaunt-theme';
import { CuratedTripActivityChips } from './CuratedTripActivityChips';
import { useEffect, useMemo, useState, MouseEvent } from 'react';
import { range } from 'lodash';

type CuratedTripSummaryProps = {
  chosenDay: number | null;
  trip: Trip | null;
  tripStays?: TripStay[];
  tripPlaces?: TripPlace[];
  setChosenDay: (day: number | null) => void;
};

export const CuratedTripSummary = ({
  chosenDay,
  trip,
  tripStays,
  tripPlaces,
  setChosenDay,
}: CuratedTripSummaryProps): JSX.Element | null => {
  const [isMobile, setIsMobile] = useState(true);
  const hasUnplannedPlaces = tripPlaces?.some((x) => x.day === null);
  const daysList = [
    ...(hasUnplannedPlaces ? [null] : []), // "unscheduled" tab
    ...(trip ? range(Object.keys(trip.detail.days)?.length) : []), // "day 123" tabs
  ];

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 744);
    }
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onSelectDay = (event: MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement;
    const day = target?.closest('[data-id]')?.getAttribute('data-id');
    (event.target as HTMLDivElement).scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });

    if (day) {
      if (day === 'stay') {
        setChosenDay(9999);
      } else {
        setChosenDay(+day);
      }
    }
  };

  const tripPlacesByDay = useMemo(() => {
    // TODO discuss non scheduled places
    // const notScheduledPlaces: { placeId: string; index: number }[] = [];

    const placesList: {
      id: string | number;
      title: string;
      events: { placeId: string; index: number }[];
    }[] = [
      {
        id: 'stay',
        title: 'Stay',
        events:
          tripStays?.map((stay, index) => ({
            placeId: stay.placeId,
            index: index + 1,
          })) ?? [],
      },
    ];

    const tripPlacesDaysObj =
      tripPlaces?.reduce(
        (
          acc: Record<string, { placeId: string; index: number }[]>,
          tripPlace: TripPlace,
        ) => {
          if (tripPlace.day || tripPlace.day === 0) {
            if (acc[tripPlace.day]) {
              acc[tripPlace.day].push({
                placeId: tripPlace.placeId,
                index: acc[tripPlace.day].length + 1,
              });
            } else {
              acc[tripPlace.day] = [{ placeId: tripPlace.placeId, index: 1 }];
            }
          }
          // TODO discuss non scheduled places
          // else {
          //   notScheduledPlaces.push({
          //     placeId: tripPlace.placeId,
          //     index: notScheduledPlaces.length + 1,
          //   });
          // }
          return acc;
        },
        {},
      ) ?? {};

    Object.keys(tripPlacesDaysObj)
      .sort((a, b) => +a - +b)
      .forEach((day) => {
        placesList.push({
          id: day,
          title: `Day ${+day + 1}`,
          events: [...tripPlacesDaysObj[day]],
        });
      });

    // TODO discuss non scheduled places
    // if (notScheduledPlaces.length) {
    //   placesList.push({
    //     id: 'notScheduled',
    //     title: 'Not Scheduled',
    //     events: notScheduledPlaces,
    //   });
    // }

    return placesList;
  }, [tripStays, tripPlaces]);

  const activeDayPlaceIds =
    chosenDay === 9999
      ? tripPlacesByDay.find((tripPlacesItem) => tripPlacesItem.id === 'stay')
      : tripPlacesByDay.find(
          (tripPlacesItem) => +tripPlacesItem.id === chosenDay,
        );

  return (
    <Container>
      {isMobile && (
        <CuratedTripActivityChips
          day={chosenDay}
          onSetDay={setChosenDay}
          days={daysList}
        />
      )}
      <Stack>
        <Tex
          align="center"
          fontFamily="Canela, serif"
          fontWeight={700}
          fontSize={24}
          lineHeight="26px"
          color={PRIMARY_COLOR}
          sx={{ margin: '32px 24px 24px !important' }}
        >
          The Summary
        </Tex>
        <SummaryWrapper>
          {isMobile ? (
            <Stack sx={{ alignItems: 'center', width: '100%' }}>
              <SummaryDayItem isselected={0}>
                {activeDayPlaceIds?.events?.length ? (
                  activeDayPlaceIds.events.map((stay, index) => (
                    <CuratedTripSummaryItem
                      key={stay.placeId}
                      placeId={stay.placeId}
                      index={index + 1}
                    />
                  ))
                ) : (
                  <Stack>
                    <Tex textAlign="center" sx={{ opacity: 0.5 }}>
                      No places in this list
                    </Tex>
                  </Stack>
                )}
              </SummaryDayItem>
            </Stack>
          ) : (
            <Stack direction="row" spacing={2} sx={{ overflowX: 'auto' }}>
              {tripPlacesByDay?.map((tripPlaceObj) => (
                <SummaryDayItem
                  key={tripPlaceObj.id}
                  data-id={tripPlaceObj.id}
                  isselected={
                    chosenDay === 9999
                      ? tripPlaceObj.id === 'stay'
                        ? 1
                        : 0
                      : +tripPlaceObj.id === chosenDay
                      ? 1
                      : 0
                  }
                  onClick={onSelectDay}
                >
                  <Stack sx={{ textAlign: 'center', m: 1 }}>
                    <SummaryDayTitle
                      isselected={
                        chosenDay === 9999
                          ? tripPlaceObj.id === 'stay'
                            ? 1
                            : 0
                          : +tripPlaceObj.id === chosenDay
                          ? 1
                          : 0
                      }
                    >
                      {tripPlaceObj.title}
                    </SummaryDayTitle>
                  </Stack>
                  <SummaryItemWrapper>
                    {tripPlaceObj.events.length ? (
                      tripPlaceObj.events.map((stay, index) => (
                        <CuratedTripSummaryItem
                          key={stay.placeId}
                          placeId={stay.placeId}
                          index={index + 1}
                        />
                      ))
                    ) : (
                      <Stack>
                        <Tex textAlign="center" sx={{ opacity: 0.5 }}>
                          No places in this list
                        </Tex>
                      </Stack>
                    )}
                  </SummaryItemWrapper>
                </SummaryDayItem>
              ))}
            </Stack>
          )}
        </SummaryWrapper>
      </Stack>
    </Container>
  );
};

const Container = styled(Stack)`
  margin: 20px 0 10px;

  ${device.tablet} {
    margin: 50px 0 10px;
  }

  ${device.laptopN} {
    margin: 50px 0 10px;
  }
`;

const SummaryWrapper = styled(Stack)`
  padding: 0 20px;

  ${device.tablet} {
    padding: 8px 0 8px 32px;
  }

  ${device.laptopN} {
    padding: 8px 0 8px 70px;
  }

  ${device.desktop} {
    padding: 8px 0 8px 75px;
  }
`;

const SummaryDayItem = styled.div<{ isselected: number }>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 16px;
  padding: 12px 16px;
  min-width: 330px;
  width: 100%;
  background-color: ${common.white};
  border: ${({ isselected }) =>
    `4px solid ${isselected ? PRIMARY_COLOR : 'transparent'}`};

  ${device.tablet} {
    cursor: pointer;
    width: 355px;
    &:last-child {
      margin-right: 32px;
    }
  }

  ${device.laptopN} {
    &:last-child {
      margin-right: 70px;
    }
  }

  ${device.desktop} {
    &:last-child {
      margin-right: 75px;
    }
  }
`;

const SummaryDayTitle = styled(Tex)<{ isselected: number }>`
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  text-decoration: ${({ isselected }) => (isselected ? 'underline' : 'none')};
`;

const SummaryItemWrapper = styled(Stack)`
  max-height: 420px;
  overflow-y: auto;

  ${device.tablet} {
    gap: 10px;
    max-height: 450px;
  }
`;
