import { Link, Stack } from '@mui/material';
import { Tex } from '../../components/shared/Tex';
import { useNavigate, useParams } from 'react-router-dom';
import { useTripPath, useUpdateTrip } from '../../hooks/use-trip';
import { Footer } from '../../components/Footer';
import { LogoHeader } from '../../components/LogoHeader';
import { grey } from '@mui/material/colors';
import { PRIMARY_COLOR } from '../../settings/jaunt-theme';
import { useState } from 'react';
import { AddDates } from './AddDates';
import { NoDates } from './NoDates';
import { NoTripIdContainer } from '../../components/NoTripIdContainer';
import { useQuery } from '../../hooks/use-query';
import { LoadingContainer } from 'src/components/loading/LoadingContainer';
import styled from '@emotion/styled';

enum TabKey {
  ADD_DATES = 'add-dates',
  NO_DATES = 'no-dates',
}

const tabs = [
  { key: TabKey.ADD_DATES, label: 'Add Dates' },
  { key: TabKey.NO_DATES, label: 'I Don’t Have Dates' },
];

export function TripTimingPage(): JSX.Element {
  const navigate = useNavigate();
  const params = useParams();
  const query = useQuery();
  const tripPath = params.tripPath || null;
  const cityId = query.get('cityId');
  const { data: trip, isLoading: isTripLoading } = useTripPath(tripPath);
  const [tabKey, setTabKey] = useState(TabKey.ADD_DATES);
  const { updateTrip, isLoading } = useUpdateTrip();

  async function updateTripTiming(
    tripDays: Record<number, string | null>,
    isWithDates: boolean,
  ) {
    if (trip?.tripId) {
      try {
        const tripData = {
          ...trip,
          description: '',
          creatorLink: '',
          detail: {
            ...trip.detail,
            isWithDates,
            days: tripDays,
          },
        };

        await updateTrip({ tripId: trip.tripId, data: tripData });

        if (trip.tripId && cityId) {
          navigate(`/trips/${trip.tripPath}?preview=true`);
        } else {
          navigate(-1);
        }
        // eslint-disable-next-line no-empty
      } catch (_) {}
    }
  }

  if (isTripLoading) {
    return <LoadingContainer />;
  }

  return trip?.tripId ? (
    <>
      <LogoHeader />
      <Stack className="main">
        <Stack sx={{ mb: 2, width: '100%' }}>
          <ContentBlock>
            {trip && (
              <Stack spacing={2}>
                <Tex
                  textAlign="center"
                  p="16px 16px 4px"
                  component="h1"
                  variant="h5"
                  fontFamily="canela-bold"
                  color={PRIMARY_COLOR}
                >
                  Add Trip Timing
                </Tex>
                <Stack
                  direction="row"
                  justifyContent="center"
                  borderBottom={`1px solid ${grey[400]}`}
                >
                  {tabs.map((tab) => (
                    <Link
                      key={tab.key}
                      onClick={() => setTabKey(tab.key)}
                      underline="none"
                      sx={{
                        px: 1,
                        width: `${100 / tabs.length}%`,
                        maxWidth: '300px',
                        color: 'inherit',
                        cursor: 'pointer',
                      }}
                    >
                      <Tex
                        textAlign="center"
                        sx={{
                          py: 1,
                          ...(tab.key === tabKey
                            ? {
                                borderBottom: `3px solid ${PRIMARY_COLOR}`,
                                fontWeight: 700,
                                color: PRIMARY_COLOR,
                                cursor: 'pointer',
                              }
                            : {}),
                        }}
                      >
                        {tab.label}
                      </Tex>
                    </Link>
                  ))}
                </Stack>
                {tabKey === TabKey.ADD_DATES && (
                  <AddDates
                    dates={trip.detail?.days}
                    onSubmit={updateTripTiming}
                    loading={isLoading}
                  />
                )}
                {tabKey === TabKey.NO_DATES && (
                  <NoDates
                    daysNumber={Object.keys(trip?.detail.days).length}
                    onSubmit={updateTripTiming}
                    loading={isLoading}
                  />
                )}
              </Stack>
            )}
          </ContentBlock>
        </Stack>
      </Stack>
      <Footer />
    </>
  ) : (
    <NoTripIdContainer />
  );
}

const ContentBlock = styled(Stack)`
  margin: 0 auto !important;
  width: 100%;
  max-width: 1440px;
`;
