import { Tex } from '../shared/Tex';
import { device, PRIMARY_COLOR } from '../../settings/jaunt-theme';
import { Stack } from '@mui/material';
import { useCityCuratedTripWithImageUrls } from '../../hooks/use-city-curated-trips-with-image-urls';
import { CityId } from '@jaunt/api';
import styled from '@emotion/styled';
import { ExploreImageCard } from '../ExploreImageCard';
import { useMemo } from 'react';
import {
  useCreateFavTrip,
  useDeleteFavTrip,
  useFavoriteTrips,
} from '../../hooks/use-favorite-trips';

type CityDetailCuratedTripsProps = {
  cityId: CityId | null;
  cityName?: string;
};

export const CityDetailCuratedTrips = ({
  cityId,
}: CityDetailCuratedTripsProps): JSX.Element | null => {
  const { data: tripsWithImageUrl } = useCityCuratedTripWithImageUrls(cityId);
  const { data: favoriteTrips } = useFavoriteTrips();
  const { createFavTrip } = useCreateFavTrip();
  const { deleteFavTrip } = useDeleteFavTrip();

  const favTripsObj = useMemo(() => {
    if (favoriteTrips) {
      return favoriteTrips.reduce((acc: Record<string, boolean>, item) => {
        acc[item.tripId] = true;
        return acc;
      }, {});
    }
    return {};
  }, [favoriteTrips]);

  async function handleToggleFavTrips(tripId: string, isNewFav: boolean) {
    if (isNewFav) {
      await createFavTrip({ tripId });
    } else {
      await deleteFavTrip({ tripId });
    }
  }

  return tripsWithImageUrl?.length ? (
    <Container spacing={6}>
      <Title
        align="center"
        lineHeight="32px"
        fontFamily="Canela, serif"
        fontWeight={700}
        color={PRIMARY_COLOR}
      >
        Curated Trips
      </Title>
      <TripsWrapper spacing={2}>
        {tripsWithImageUrl
          ?.filter(({ trip }) => !trip.isHidden)
          .map((tripWithImageUrl, index) => (
            <ExploreImageCard
              key={`${tripWithImageUrl.trip.tripId}-${index}`}
              id={tripWithImageUrl.trip.tripId}
              title={tripWithImageUrl.trip.name}
              tip={`${
                Object.keys(tripWithImageUrl.trip.detail.days)?.length || '0'
              }-Day Jaunt`}
              description={`Curated by ${tripWithImageUrl.trip.detail.createdBy}`}
              imageUrl={tripWithImageUrl.imageUrl}
              routePath={`/trips/${tripWithImageUrl.trip.tripPath}?isCurated=true`}
              isFavorite={favTripsObj[tripWithImageUrl.trip.tripId]}
              onToggleFavorite={handleToggleFavTrips}
            />
          ))}
      </TripsWrapper>
    </Container>
  ) : null;
};

const Container = styled(Stack)`
  margin-top: 24px !important;
`;

const Title = styled(Tex)`
  margin: 24px 24px 24px !important;
  font-size: 28px;

  ${device.laptopN} {
    font-size: 34px;
    margin-bottom: 36px !important;
  }
`;

const TripsWrapper = styled(Stack)`
  flex-direction: column;
  align-items: center;
  margin: 0 0 12px 0 !important;
  overflow-x: hidden;
  gap: 12px;

  ${device.tablet} {
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    overflow-x: auto;
    padding-right: 16px;
    margin: 0 0 12px 32px !important;
  }

  ${device.laptopN} {
    margin: 0 0 12px 70px !important;
  }
`;
