import { useParams } from 'react-router-dom';
import { CuratedTripSection } from '../components/curated-trip/CuratedTripSection';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTripPath } from '../hooks/use-trip';
import { Button, Collapse, Stack } from '@mui/material';
import { LogoHeader } from 'src/components/LogoHeader';
import { common } from '@mui/material/colors';
import { Tex } from 'src/components/shared/Tex';
import { Footer } from '../components/Footer';
import { NoTripIdContainer } from '../components/NoTripIdContainer';
import { LoadingContainer } from 'src/components/loading/LoadingContainer';
import { mdiChevronUp, mdiChevronDown } from '@mdi/js';
import { useMemo, useState } from 'react';
import { MdiIcon } from 'src/components/shared/MdiIcon';
import { useCity } from 'src/hooks/use-city';
import {
  device,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
} from 'src/settings/jaunt-theme';
import { useFirstTripImageUrl } from 'src/hooks/use-first-trip-image-url';
import { PlanningTripSection } from 'src/components/PlanningTripSection';
import { useTripCity } from 'src/hooks/use-trip-city';
import { City } from '@jaunt/api';
import styled from '@emotion/styled';
import { NEW_TRIP, NEW_TRIP_CITY_ID } from '../constants';
import { getLocalStorageItem } from '../services/local-storage.util';
import { useQuery } from 'src/hooks/use-query';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import {
  useCreateFavTrip,
  useDeleteFavTrip,
  useFavoriteTrips,
} from '../hooks/use-favorite-trips';

const getCityDetails = (curatedTripCity?: City) => {
  if (curatedTripCity) {
    return [
      {
        title: 'When To Go.',
        text: curatedTripCity.detail.weather,
      },
      {
        title: 'Where To Stay.',
        text: curatedTripCity.detail.lodging,
      },
      {
        title: 'Getting Around.',
        text: curatedTripCity.detail.traffic,
      },
      {
        title: 'Tips And Tricks.',
        text: curatedTripCity.detail.notes,
      },
    ];
  }
  return [];
};

export function TripPage(): JSX.Element {
  const query = useQuery();
  const params = useParams();
  const tripPath = params.tripPath || null;
  const preview = query.get('preview');
  const { data: trip, isLoading } = useTripPath(tripPath);
  const [isExpanded, setIsExpanded] = useState(false);
  const tripCityId = useTripCity(trip?.tripId);
  const { data: curatedTripCity } = useCity(tripCityId.data?.cityId);
  const { data: tripImageURL } = useFirstTripImageUrl(trip?.tripId);
  const localTripCityID = getLocalStorageItem(NEW_TRIP_CITY_ID);
  const { data: favoriteTrips } = useFavoriteTrips();
  const { createFavTrip } = useCreateFavTrip();
  const { deleteFavTrip } = useDeleteFavTrip();
  const cityId =
    trip?.tripId === NEW_TRIP
      ? getLocalStorageItem(NEW_TRIP_CITY_ID)
      : tripCityId.data?.cityId;

  const details = useMemo(
    () => getCityDetails(curatedTripCity),
    [curatedTripCity],
  );

  const isFav = useMemo(() => {
    if (trip && favoriteTrips) {
      return favoriteTrips.find((item) => item.tripId === trip.tripId);
    }
    return false;
  }, [favoriteTrips, trip]);

  async function handleToggleFavCities() {
    if (trip) {
      if (!isFav) {
        await createFavTrip({ tripId: trip.tripId });
      } else {
        await deleteFavTrip({ tripId: trip.tripId });
      }
    }
  }

  if (isLoading) {
    return <LoadingContainer />;
  }

  return trip?.tripId ? (
    <>
      <LogoHeader showAuthLinks={true} />
      <Stack className="main">
        <Stack sx={{ mb: 2, width: '100%' }}>
          {trip && (
            <Stack>
              {trip.isCurated ? (
                <>
                  <Stack>
                    <HeaderImage
                      key={trip.tripId}
                      alt={trip.name}
                      src={tripImageURL ? tripImageURL : ''}
                      placeholder={<Stack className="placeholder-block" />}
                    />
                    <TripDescriptionContainer spacing={3}>
                      <Tex
                        align="center"
                        fontFamily="canela-bold"
                        fontSize={24}
                        fontWeight={700}
                        color={common.white}
                        sx={{ mt: 2 }}
                      >
                        {trip.name}
                      </Tex>
                      {trip.creatorLink ? (
                        <Stack
                          direction="row"
                          spacing={0.5}
                          sx={{ justifyContent: 'center' }}
                          marginTop="0 !important"
                        >
                          <Tex
                            align="center"
                            fontSize={13}
                            fontWeight={300}
                            color={common.white}
                          >
                            {`${
                              Object.keys(trip.detail.days)?.length || '0'
                            }-Day Jaunt curated by `}{' '}
                          </Tex>
                          <Tex
                            align="center"
                            fontSize={14}
                            fontWeight={300}
                            color={common.black}
                          >
                            <a
                              href={trip.creatorLink}
                              target="_blank"
                              rel="noreferrer"
                              style={{
                                color: SECONDARY_COLOR,
                                textDecoration: 'none',
                              }}
                            >
                              @{trip.detail.createdBy}
                            </a>
                          </Tex>
                        </Stack>
                      ) : (
                        <Tex
                          align="center"
                          fontSize={13}
                          fontWeight={300}
                          color={common.white}
                          marginTop="0 !important"
                        >
                          {`${
                            Object.keys(trip.detail.days)?.length || '0'
                          }-Day Jaunt curated by ${trip.detail.createdBy}`}
                        </Tex>
                      )}
                      {trip.description ? (
                        <Tex
                          align="center"
                          fontWeight={300}
                          fontSize={16}
                          color={common.white}
                          marginTop="16px !important"
                          lineHeight="24px"
                        >
                          {trip.description}
                        </Tex>
                      ) : (
                        <Tex
                          align="center"
                          fontWeight={300}
                          fontSize={16}
                          color={common.white}
                          marginTop="16px !important"
                          lineHeight="24px"
                        >
                          {curatedTripCity?.description}
                        </Tex>
                      )}

                      <Stack spacing={1}>
                        <Collapse in={isExpanded}>
                          <Stack spacing={1.5}>
                            {details.map((detail) => (
                              <Stack
                                key={detail.title}
                                sx={{
                                  p: 1.5,
                                  backgroundColor: common.white,
                                  borderRadius: 4,
                                }}
                              >
                                <Tex>
                                  <Tex component="span" fontWeight={700}>
                                    {detail.title}{' '}
                                  </Tex>
                                  <Tex component="span" fontWeight={300}>
                                    {detail.text}
                                  </Tex>
                                </Tex>
                              </Stack>
                            ))}
                          </Stack>
                        </Collapse>
                        <Stack
                          sx={{
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Stack
                            sx={{
                              position: 'absolute',
                              top: '0px',
                              left: '0px',
                              py: 0,
                              px: 0,
                            }}
                            onClick={handleToggleFavCities}
                          >
                            {isFav ? (
                              <FavoriteOutlinedIcon
                                fontSize="large"
                                htmlColor="#FE0000"
                              />
                            ) : (
                              <FavoriteBorderOutlinedIcon
                                fontSize="large"
                                htmlColor="#CCD2E3"
                              />
                            )}
                          </Stack>
                          <Button
                            onClick={() =>
                              setIsExpanded((previous) => !previous)
                            }
                          >
                            <Tex sx={{ color: common.white, fontSize: '13px' }}>
                              {isExpanded ? 'See Less' : 'See More'}
                            </Tex>
                            <MdiIcon
                              icon={isExpanded ? mdiChevronUp : mdiChevronDown}
                              sx={{
                                ml: 1,
                                color: common.white,
                                fontSize: '13px',
                              }}
                            />
                          </Button>
                        </Stack>
                      </Stack>
                    </TripDescriptionContainer>
                  </Stack>
                  <ContentBlock>
                    <CuratedTripSection
                      tripId={trip?.tripId}
                      isCurated={trip.isCurated}
                    />
                  </ContentBlock>
                </>
              ) : null}
            </Stack>
          )}
        </Stack>
        {/*//TODO uncomment when mytrips be ready */}
        {cityId && !trip?.isCurated ? (
          <PlanningTripSection
            cityId={cityId}
            tripId={trip?.tripId}
            isPreview={!!preview}
          />
        ) : null}
      </Stack>
      <Footer />
    </>
  ) : tripPath === 'new-trip' ? (
    <>
      <LogoHeader showAuthLinks={true} />
      <PlanningTripSection
        cityId={localTripCityID}
        tripId={NEW_TRIP}
        isPreview={!!preview}
      />
    </>
  ) : (
    <NoTripIdContainer />
  );
}

const HeaderImage = styled(LazyLoadImage)`
  background-size: cover;
  background-position: center;
  object-fit: cover;
  height: 212px;
  width: 100%;

  ${device.tablet} {
    height: 400px;
  }

  ${device.laptopN} {
    height: 700px;
  }

  & .placeholder-block {
    height: 212px;
    background-color: ${SECONDARY_COLOR};

    ${device.tablet} {
      height: 400px;
    }

    ${device.laptopN} {
      height: 700px;
    }
  }
`;

const TripDescriptionContainer = styled(Stack)`
  margin: -30px auto 0;
  padding: 8px 16px;
  background-color: ${PRIMARY_COLOR};
  border-radius: 20px;
  max-width: 390px;
  width: 94%;

  ${device.tablet} {
    margin-top: -100px;
    padding: 16px 24px;
    max-width: 670px;
  }

  ${device.laptopN} {
    margin-top: -150px;
    max-width: 740px;
  }

  ${device.desktop} {
    margin-top: -170px;
    max-width: 1080px;
  }
`;

const ContentBlock = styled(Stack)`
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
`;
