import { del, get, post, put } from './base.api';
import {
  Review,
  ReviewId,
  ReviewData,
  SingleReviewResponse,
  MultipleReviewsResponse,
  ReviewImage,
  MultipleReviewImagesResponse,
} from '@jaunt/api';

export async function listReviews(): Promise<Review[]> {
  const { reviews } = await get<MultipleReviewsResponse>('/reviews', {});
  return reviews;
}

export async function createReview(data: ReviewData): Promise<Review> {
  const { review } = await post<SingleReviewResponse, ReviewData>(
    '/reviews',
    {},
    data,
  );
  return review;
}

export async function getReview(reviewId: ReviewId): Promise<Review> {
  const { review } = await get<SingleReviewResponse>(
    `/reviews/${reviewId}`,
    {},
  );
  return review;
}

export async function getReviewImages(
  reviewId: ReviewId,
): Promise<ReviewImage[]> {
  const { reviewImages } = await get<MultipleReviewImagesResponse>(
    `/reviews/${reviewId}/images`,
    {},
  );
  return reviewImages;
}

export async function updateReview(
  reviewId: ReviewId,
  data: ReviewData,
): Promise<Review> {
  const { review } = await put<SingleReviewResponse, ReviewData>(
    `/reviews/${reviewId}`,
    {},
    data,
  );
  return review;
}

export async function deleteReview(reviewId: ReviewId): Promise<Review> {
  const { review } = await del<SingleReviewResponse>(
    `/reviews/${reviewId}`,
    {},
  );
  return review;
}
