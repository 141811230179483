import { Link as RouterLink } from 'react-router-dom';
import { Link, IconButton, Stack, Button } from '@mui/material';
import { mdiAccount, mdiMenu } from '@mdi/js';
import { Spacer } from './shared/Spacer';
import { Tex } from './shared/Tex';
import { PRIMARY_COLOR } from 'src/settings/jaunt-theme';
import { common } from '@mui/material/colors';
import { MdiIcon } from './shared/MdiIcon';
import { useEffect, useState } from 'react';
import { NavBar } from './NavBar';
import { PlanTripDialog } from './plan-trip-dialog/PlanTripDialog';
import { useCitiesWithImageUrl } from 'src/hooks/use-cities-with-image-url';
import styled from '@emotion/styled';
import { useMe } from 'src/hooks/use-me';
import { useCities } from 'src/hooks/use-cities';

interface Props {
  sticky?: boolean;
  showAuthLinks?: boolean;
}

export function LogoHeader(props: Props): JSX.Element {
  const { sticky } = props;
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const { data: cities } = useCities();
  const { data: citiesWithImageUrl } = useCitiesWithImageUrl(cities);
  const { data: userId } = useMe(true);
  const [isPlanTripDialogOpen, setIsPlanTripDialogOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    if (userId) {
      setLoggedIn(true);
    }
  }, [userId]);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 745);
      setIsTablet(window.innerWidth >= 745 && window.innerWidth < 1024);
      setIsDesktop(window.innerWidth >= 1024);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <HeaderContainer sticky={sticky}>
      <Wrapper>
        <Link
          component={RouterLink}
          to="/"
          underline="none"
          sx={{ color: 'inherit' }}
        >
          <Tex component="h1" variant="h6" fontFamily="canela-bold">
            Jauntmore
          </Tex>
        </Link>
        {isMobile ? <Spacer /> : null}
        {isTablet || isDesktop ? (
          <Stack
            direction="row"
            spacing={isTablet ? 4 : isDesktop ? 8 : 0}
            sx={{ pl: '70px' }}
          >
            <Link
              component={RouterLink}
              to="/explore/trips"
              underline="none"
              sx={{ color: 'inherit' }}
            >
              <Tex
                fontSize={14}
                fontFamily="CircularStd-Light"
                sx={{ color: common.white }}
              >
                Curated Trips
              </Tex>
            </Link>
            <Link
              component={RouterLink}
              to="/explore/cities"
              underline="none"
              sx={{ color: 'inherit' }}
            >
              <Tex
                fontSize={14}
                fontFamily="CircularStd-Light"
                sx={{ color: common.white }}
              >
                City Guides
              </Tex>
            </Link>
            {/*//TODO uncomment when mytrips be ready */}
            <Link
              component={RouterLink}
              to="/mytrips/manual"
              underline="none"
              sx={{ color: 'inherit' }}
            >
              <Tex
                fontSize={14}
                fontFamily="CircularStd-Light"
                sx={{ color: common.white }}
              >
                My Trips
              </Tex>
            </Link>
            <Link
              component={RouterLink}
              to="/favorites/trips"
              underline="none"
              sx={{ color: 'inherit' }}
            >
              <Tex
                fontSize={14}
                fontFamily="CircularStd-Light"
                sx={{ color: common.white }}
              >
                Favorites
              </Tex>
            </Link>
          </Stack>
        ) : null}

        {isTablet || isDesktop ? <Spacer /> : null}
        {/* TODO uncomment when ChatGPT be ready*/}
        {isTablet || isDesktop ? (
          <Stack sx={{ pr: '20px' }}>
            <Button
              variant="contained"
              onClick={() => setIsPlanTripDialogOpen(true)}
              sx={{
                px: 2,
                py: 1,
                borderRadius: '9999px',
                backgroundColor: '#D6472A',
                height: '25px',
              }}
            >
              Create a Trip
            </Button>
          </Stack>
        ) : (
          <>
            <Button
              variant="contained"
              onClick={() => setIsPlanTripDialogOpen(true)}
              sx={{
                px: 2,
                py: 1,
                borderRadius: '9999px',
                backgroundColor: '#D6472A',
                height: '25px',
              }}
            >
              Create a Trip
            </Button>
            <Spacer />
          </>
        )}
        {loggedIn ? (
          <Link
            component={RouterLink}
            to="/account"
            underline="none"
            sx={{ color: 'inherit' }}
          >
            <IconButton size="small" disableRipple>
              <MdiIcon
                icon={mdiAccount}
                sx={{ size: 'small', color: common.white }}
              />
            </IconButton>
          </Link>
        ) : (
          <Link
            component={RouterLink}
            to="/login"
            underline="none"
            sx={{ color: 'inherit' }}
          >
            <Tex
              component="h1"
              variant="h6"
              fontFamily="CircularStd-Book"
              fontSize={14}
            >
              Login
            </Tex>
          </Link>
        )}
        {isMobile ? (
          <>
            <IconButton
              size="small"
              color="error"
              onClick={() => setIsNavOpen(true)}
              disableRipple
            >
              <MdiIcon
                icon={mdiMenu}
                sx={{ size: 'small', color: common.white }}
              />
            </IconButton>
            <NavBar isOpen={isNavOpen} onClose={() => setIsNavOpen(false)} />
          </>
        ) : null}
        <NavBar isOpen={isNavOpen} onClose={() => setIsNavOpen(false)} />
        {citiesWithImageUrl ? (
          <PlanTripDialog
            isOpen={isPlanTripDialogOpen}
            citiesWithImageUrl={citiesWithImageUrl}
            onClose={() => setIsPlanTripDialogOpen(false)}
          />
        ) : null}
      </Wrapper>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.header<{ sticky: boolean | undefined }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${common.white};
  background-color: ${PRIMARY_COLOR};

  ${({ sticky }) =>
    sticky && { position: 'fixed', left: 0, top: 0, zIndex: 2 }};
`;

const Wrapper = styled(Stack)`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 16px;
  background-color: ${PRIMARY_COLOR};
  color: ${common.white};
`;
