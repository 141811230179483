import { del, get, patch, post } from './base.api';
import {
  TripId,
  TripGeneratedData,
  TripGenerated,
  SingleTripGeneratedResponse,
  MultipleTripsGeneratedResponse,
} from '@jaunt/api';

export async function listGeneratedTrips(): Promise<TripGenerated[]> {
  const { tripsGenerated } = await get<MultipleTripsGeneratedResponse>(
    '/trips-generated',
    {},
  );
  return tripsGenerated;
}

export async function getGeneratedTrip(
  tripId: TripId,
): Promise<SingleTripGeneratedResponse> {
  return await get<SingleTripGeneratedResponse>(
    `/trips-generated/${tripId}`,
    {},
  );
}

export async function getGeneratedTripByPath(
  tripPath: string,
): Promise<TripGenerated> {
  const { tripGenerated } = await get<SingleTripGeneratedResponse>(
    `/trips-generated/path/${tripPath}`,
    {},
  );
  return tripGenerated;
}

export async function getPublicGeneratedTripByPath(
  tripPath: string,
): Promise<TripGenerated> {
  const { tripGenerated } = await get<SingleTripGeneratedResponse>(
    `/trips-generated/public/path/${tripPath}`,
    {},
  );
  return tripGenerated;
}

export async function createGeneratedTrip(
  data: TripGeneratedData,
): Promise<TripGenerated> {
  const { tripGenerated } = await post<
    SingleTripGeneratedResponse,
    TripGeneratedData
  >('/trips-generated', {}, data);
  return tripGenerated;
}

export async function deleteGeneratedTrip(
  tripId: TripId,
): Promise<TripGenerated> {
  const { tripGenerated } = await del<SingleTripGeneratedResponse>(
    `/trips-generated/${tripId}`,
    {},
  );
  return tripGenerated;
}

export async function publicGeneratedTrip(
  tripId: TripId,
  data: { isPublic: boolean },
): Promise<TripGenerated> {
  const { tripGenerated } = await patch<
    SingleTripGeneratedResponse,
    { isPublic: boolean }
  >(`/trips-generated/${tripId}/public`, {}, data);
  return tripGenerated;
}
