import { Stack } from '@mui/material';
import { useState } from 'react';
import { useTrip } from '../../hooks/use-trip';
import { useTripPlaces } from '../../hooks/use-trip-places';
import { TripId } from '@jaunt/api';
import { useTripStays } from '../../hooks/use-trip-stays';
import { CuratedTripDetails } from './CuratedTripDetails';
import { CuratedTripSummary } from './CuratedTripSummary';
import { useMe } from '../../hooks/use-me';
import { CuratedTripSaveToMyTrip } from './CuratedTripSaveToMyTrip';

interface Props {
  tripId: TripId;
  isCurated: boolean;
}

export function CuratedTripSection(props: Props): JSX.Element {
  const { tripId, isCurated } = props;
  const { data: trip } = useTrip(tripId);
  const { data: userId } = useMe(true);
  const { data: tripPlaces } = useTripPlaces(tripId);
  const { data: tripStays } = useTripStays(tripId);
  const [chosenDay, setChosenDay] = useState<number | null>(9999);

  return (
    <Stack sx={{ mb: 0.5, width: '100%' }}>
      {trip && (
        <Stack spacing={2}>
          <CuratedTripSummary
            chosenDay={chosenDay}
            trip={trip}
            tripStays={tripStays}
            tripPlaces={tripPlaces}
            setChosenDay={setChosenDay}
          />
          {isCurated && userId && trip.tripId ? (
            <CuratedTripSaveToMyTrip trip={trip} />
          ) : null}
          <CuratedTripDetails
            chosenDay={chosenDay}
            tripStays={tripStays}
            tripPlaces={tripPlaces}
          />
        </Stack>
      )}
    </Stack>
  );
}
