import {
  PlaceId,
  PlaceReview,
  PlaceReviewData,
  ReviewId,
  ReviewObj,
} from '@jaunt/api';
import {
  createPlaceReview,
  deletePlaceReview,
  getPlaceReviews,
  listPlaceReviews,
  updatePlaceReview,
} from '../services/place-review.api';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import QueryKeys from './constants/query-keys';

export function useListPlaceReviews(): UseQueryResult<PlaceReview[], unknown> {
  return useQuery<PlaceReview[]>(
    QueryKeys.placeReviews,
    async () => (await listPlaceReviews().then((list) => list.sort())) ?? [],
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading PlaceReviews: ', error); // eslint-disable-line no-console
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: 3,
      retryDelay: 2000,
    },
  );
}

export function usePlaceReviews(
  placeId: PlaceId | null | undefined,
): UseQueryResult<ReviewObj[], unknown> {
  return useQuery<ReviewObj[]>(
    [QueryKeys.placeReviews, placeId],
    async () => {
      return placeId ? await getPlaceReviews(placeId) : [];
    },
    {
      onError(error) {
        // tslint:disable-next-line:no-console
        console.log('Error loading PlaceReviews: ', error); // eslint-disable-line no-console
      },
      retry: 3,
      retryDelay: 2000,
      enabled: !!placeId,
    },
  );
}

export const useCreatePlaceReview = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async (data: PlaceReviewData) => {
      return await createPlaceReview(data);
    },
    {
      onSuccess: (placeReview: PlaceReview) => {
        client.invalidateQueries([QueryKeys.placeReviews, placeReview.placeId]);
        client.invalidateQueries(QueryKeys.placeReviews);
      },
    },
  );
  return { createPlaceReview: mutateAsync, isLoading, isSuccess, isError };
};

export const useUpdatePlaceReview = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({
      placeId,
      reviewId,
      data,
    }: {
      placeId: PlaceId;
      reviewId: ReviewId;
      data: PlaceReviewData;
    }) => {
      return await updatePlaceReview(placeId, reviewId, data);
    },
    {
      onSuccess: (placeReview: PlaceReview) => {
        client.invalidateQueries([QueryKeys.placeReviews, placeReview.placeId]);
        client.invalidateQueries(QueryKeys.placeReviews);
      },
    },
  );
  return { updatePlaceReview: mutateAsync, isLoading, isSuccess, isError };
};

export const useDeletePlaceReview = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({ placeId, reviewId }: { placeId: PlaceId; reviewId: ReviewId }) => {
      await deletePlaceReview(placeId, reviewId);
    },
    {
      onSuccess: (_, { placeId }) => {
        client.invalidateQueries([QueryKeys.placeReviews, placeId]);
        client.invalidateQueries(QueryKeys.placeReviews);
      },
    },
  );
  return { deletePlaceReview: mutateAsync, isLoading, isSuccess, isError };
};
