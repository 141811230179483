import { useNavigate, useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import styled from '@emotion/styled';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { LogoHeader } from 'src/components/LogoHeader';
import { Footer } from 'src/components/Footer';
import { useTripPath } from 'src/hooks/use-trip';
import { device, PRIMARY_COLOR } from 'src/settings/jaunt-theme';
import { useMe } from 'src/hooks/use-me';
import { usePlacePath } from 'src/hooks/use-place';
import { useTripPlaces } from 'src/hooks/use-trip-places';
import { LoadingContainer } from 'src/components/loading/LoadingContainer';
import { ActivitySummary } from 'src/components/ActivitySummary';
import { NotFoundPage } from './NotFoundPage';

export function EditPlaceSummary(): JSX.Element {
  const params = useParams();
  const navigate = useNavigate();
  const tripPath = params.tripPath || null;
  const placePath = params.placePath || null;
  const { data: place, isLoading: isPlaceLoading } = usePlacePath(placePath);
  const { data: trip, isLoading: isTripLoading } = useTripPath(tripPath);
  const { data: userId, isLoading: isUserLoading } = useMe(true);
  const { data: tripPlaces } = useTripPlaces(trip?.tripId);

  if (isPlaceLoading || isTripLoading || isUserLoading) {
    return <LoadingContainer />;
  }

  return userId && trip?.tripId && place?.placeId ? (
    <>
      <LogoHeader />
      <Stack className="main">
        <Container sx={{ mb: 2, width: '100%' }}>
          <ArrowBackOutlinedIcon
            onClick={() => navigate(`/trips/${tripPath}?preview=true&day=0`)}
            sx={{
              position: 'absolute',
              top: '26px',
              left: '20px',
              color: PRIMARY_COLOR,
            }}
          />
          <ActivitySummary
            tripPath={trip.tripPath}
            place={place}
            tripPlace={tripPlaces?.find(
              (tripPlace) => tripPlace.placeId === place.placeId,
            )}
            userId={userId}
            isImageEnabled
          />
        </Container>
      </Stack>
      <Footer />
    </>
  ) : (
    <NotFoundPage />
  );
}

const Container = styled(Stack)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  margin-bottom: 16px;
  gap: 24vh;

  ${device.laptopN} {
    gap: 8vh;
  }
`;
