import { Box, Stack } from '@mui/material';

import { Tex } from './shared/Tex';
import { City } from '@jaunt/api';
import {
  BACKGROUND_COLOR,
  SECONDARY_COLOR,
  device,
} from '../settings/jaunt-theme';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styled from '@emotion/styled';

interface Props {
  city: Pick<City, 'cityId' | 'name' | 'state' | 'country'>;
  imageUrl?: string;
  fullWidth?: boolean;
  onClick?: () => void;
}

export function CityCard(props: Props): JSX.Element {
  const { city, imageUrl, onClick, fullWidth = false } = props;

  return (
    <Wrapper
      direction="row"
      spacing={1}
      onClick={onClick}
      fullwidth={fullWidth ? 1 : 0}
    >
      {imageUrl ? (
        <LazyLoadImage
          key={city.cityId}
          alt={city.cityId}
          src={imageUrl}
          width="80px"
          height="70px"
          style={{ objectFit: 'cover' }}
          placeholder={
            <Stack
              sx={{
                width: '80px',
                height: '70px',
                backgroundColor: SECONDARY_COLOR,
              }}
            />
          }
        />
      ) : (
        <Box
          sx={{
            width: '80px',
            height: '70px',
            backgroundColor: SECONDARY_COLOR,
          }}
        />
      )}
      <Stack sx={{ py: 1.5 }}>
        <Stack>
          <Tex fontWeight={700}>{city.name}</Tex>
          <Tex fontSize={14} fontWeight={300}>
            {city.state || city.country}
          </Tex>
        </Stack>
      </Stack>
    </Wrapper>
  );
}

const Wrapper = styled(Stack)<{ fullwidth: number }>`
  width: 100%;
  margin: 0 !important;
  background-color: ${BACKGROUND_COLOR};
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;

  ${device.tablet} {
    width: ${({ fullwidth }) => (fullwidth ? '100%' : '48%')};
  }
`;
