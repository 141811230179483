import { Link as RouterLink } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Chip, Stack } from '@mui/material';
import { common } from '@mui/material/colors';
import { Tex } from '../shared/Tex';
import { usePlaceFirstImage } from '../../hooks/use-place-first-image';
import { Place, Tag, TagType } from '@jaunt/api';
import { device, SECONDARY_COLOR } from '../../settings/jaunt-theme';
import styled from '@emotion/styled';

type TileGalleryCardProps = {
  place: Place;
  tags: Tag[];
};

export function PlaceGalleryCard({
  place,
  tags,
}: TileGalleryCardProps): JSX.Element | null {
  const { data: imageUrl } = usePlaceFirstImage(place.placeId);
  const secondaryTags = tags?.filter(
    (x) => x.type === TagType.SECONDARY_ACTIVITY,
  );

  return (
    <PlaceLink key={place.placeId} to={`/places/${place.placePath}`}>
      {imageUrl ? (
        <LazyLoadImage
          key={place.placeId}
          src={imageUrl}
          alt={place.name}
          width="100%"
          height="100%"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          placeholder={
            <Stack
              sx={{
                width: '100%',
                height: '100%',
                backgroundColor: SECONDARY_COLOR,
              }}
            />
          }
        />
      ) : null}
      <DescriptionBlock
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <PlaceName
          fontFamily="CircularStd-Book"
          fontWeight={700}
          sx={{ mb: 0.5 }}
        >
          {place.name}
        </PlaceName>
        <SecondaryTagsContainer
          direction="row"
          spacing={1}
          width="100%"
          sx={{ overflowX: 'scroll', paddingRight: '8px' }}
        >
          {secondaryTags?.map((tag: Tag) => (
            <Chip
              key={tag.tagId}
              label={tag.name}
              size="small"
              sx={{
                backgroundColor: common.white,
                fontSize: '8px',
                height: '16px',
              }}
            />
          ))}
        </SecondaryTagsContainer>
      </DescriptionBlock>
    </PlaceLink>
  );
}

const PlaceLink = styled(RouterLink)`
  width: calc(50% - 5px);
  min-width: 180px;
  max-width: 200px;
  height: 321px;
  position: relative;
  margin: 0 !important;
  background-color: ${SECONDARY_COLOR};

  &:last-child {
    align-self: flex-start;
  }

  ${device.tablet} {
    height: 400px;
    min-width: 280px;
    max-width: 280px;
  }

  ${device.laptopS} {
    height: 400px;
    min-width: 280px;
    max-width: 280px;
  }

  ${device.laptopL} {
    height: 450px;
    min-width: 310px;
    max-width: 310px;
  }
`;

const DescriptionBlock = styled(Stack)`
  width: 100%;
  padding: 4px 0 4px 6px;
  background-color: rgba(0, 0, 0, 0.68);
  color: ${common.white};
  position: absolute;
  left: 0;
  bottom: 0;
  height: 28px;
  overflow: hidden;

  ${device.tablet} {
    height: 50px;
  }
`;

const PlaceName = styled(Tex)`
  width: 100%;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;

  ${device.tablet} {
    font-size: 14px;
    text-align: left;
  }
`;

const SecondaryTagsContainer = styled(Stack)`
  display: none;

  ${device.tablet} {
    display: flex;
  }
`;
