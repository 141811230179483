import { Timestamp, FileId, UserId } from './shared';

export enum UserRole {
  ADMIN = 'admin',
  USER = 'user',
}

export interface UserBio {
  readonly bestMeal: string;
  readonly bestTrip: string;
  readonly travelStyle: string;
}

export interface UserData {
  readonly firebaseUserId: string;
  readonly userName: string;
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly role: UserRole;
  readonly bio: UserBio;
  readonly imageFileId: FileId | null;
}

export interface User extends UserData, Timestamp {
  readonly userId: UserId;
}

export interface SingleUserResponse {
  readonly user: User;
}
