export const NEW_TRIP = 'new-trip';
export const NEW_TRIP_CITY_ID = 'new-trip-city-id';
export const NEW_TRIP_PLACE_IDS = 'new-trip-place-ids';

export const activityTags = ['Eat', 'Drink', 'Do', 'Stay'];

export enum BuildTripMode {
  auto = 'auto',
  manual = 'manual',
}
