import { useState, useEffect } from 'react';
import { Box, IconButton, Stack } from '@mui/material';
import { common } from '@mui/material/colors';
import { Tex } from './shared/Tex';
import { useNavigate } from 'react-router-dom';
import { NeighborhoodId, Place, PlaceId, TagId, TagType } from '@jaunt/api';
import { usePlace } from '../hooks/use-place';
import { usePlaceFirstImage } from '../hooks/use-place-first-image';
import { useNeighborhood } from '../hooks/use-neighborhood';
import { usePlaceTags } from '../hooks/use-place-tags';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import { device, SECONDARY_COLOR } from '../settings/jaunt-theme';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { PlaceExternalLink } from './PlaceExternalLink';
import styled from '@emotion/styled';
import { mdiBookOpenVariant, mdiCameraOutline, mdiMagnify } from '@mdi/js';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';

interface Props {
  placeId: PlaceId;
  isPlaceInTrip: boolean;
  placeIds: PlaceId[];
  chosenNeighborhoodIds: NeighborhoodId[];
  chosenTagIds: TagId[];
  onSelectPlace: (place: Place) => void;
  emptyInfo: string;
  isFavorite: boolean;
  onToggleFavorite: (id: string, isNewFav: boolean) => void;
}

export function PlaceCardByCity(props: Props): JSX.Element {
  const {
    placeId,
    chosenTagIds,
    chosenNeighborhoodIds,
    placeIds,
    //TODO uncomment when mytrip be ready
    isPlaceInTrip,
    onSelectPlace,
    emptyInfo,
    isFavorite,
    onToggleFavorite,
  } = props;
  const { data: place } = usePlace(placeId);
  const { data: imageUrl } = usePlaceFirstImage(placeId);
  const { data: neighborhood } = useNeighborhood(place?.neighborhoodId || null);
  const { data: tags } = usePlaceTags(placeId);
  const [included, setIncluded] = useState(false);
  const navigate = useNavigate();
  const activityTags = tags?.filter((x) => x.type === TagType.ACTIVITY);

  const openPlacePage = () => {
    if (place?.placePath) {
      navigate(`/places/${place.placePath}`);
    }
  };

  async function handleToggleFavorite(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    onToggleFavorite(placeId, !isFavorite);
  }

  useEffect(() => {
    getPlaces();
  }, [neighborhood, place, activityTags, chosenNeighborhoodIds, chosenTagIds]);

  function getPlaces() {
    if (place && neighborhood && activityTags) {
      const placeTagIds = activityTags.map((tag) => tag.tagId);
      const neighborhoodId = neighborhood.neighborhoodId;
      let validId = false;
      let validNeighborhood = false;
      if (chosenNeighborhoodIds.includes(neighborhoodId)) {
        validNeighborhood = true;
      } else if (chosenNeighborhoodIds.length === 0) {
        validNeighborhood = true;
      }
      placeTagIds.forEach((tag) => {
        if (chosenTagIds.includes(tag)) {
          validId = true;
        } else if (chosenTagIds.length === 0) {
          validId = true;
        }
      });
      if (validNeighborhood && validId) {
        setIncluded(true);
      } else {
        setIncluded(false);
      }
    }
  }
  //TODO uncomment when mytrip be ready
  function handleSelectPlace(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (place) {
      onSelectPlace(place);
    }
  }

  return place && included ? (
    <Container onClick={openPlacePage}>
      <LazyLoadImageContainer
        key={`${place.placeId}-image`}
        alt={place.name}
        src={imageUrl ?? undefined}
        width="390px"
        height="360px"
        style={{
          objectFit: 'cover',
          borderRadius: '20px 20px 0 0',
        }}
        placeholder={<Stack className="placeholder-block" />}
      />
      <Stack
        flex={1}
        direction="column"
        justifyContent="space-between"
        sx={{
          backgroundColor: common.white,
          color: common.black,
        }}
      >
        <Box sx={{ p: 1.5, minHeight: '175px' }}>
          <Stack sx={{ pt: 1 }}>
            {/*//TODO uncomment when mytrip be ready*/}
            <IconButton
              aria-label="add"
              onClick={handleSelectPlace}
              sx={{
                position: 'absolute',
                right: 10,
                top: 10,
                margin: 0,
                backgroundColor: isPlaceInTrip
                  ? SECONDARY_COLOR
                  : 'rgba(255, 255, 255, 0.52)',
                color: '#fff',
              }}
            >
              {isPlaceInTrip ? (
                <CheckIcon fontSize="large" htmlColor="#fff" />
              ) : (
                <AddIcon fontSize="large" htmlColor="#fff" />
              )}
            </IconButton>
            <Stack
              sx={{
                position: 'absolute',
                top: '4px',
                left: 0,
                py: 1,
                px: 1.5,
                fontSize: '46px',
              }}
              onClick={handleToggleFavorite}
            >
              {isFavorite ? (
                <FavoriteOutlinedIcon fontSize="inherit" htmlColor="#FE0000" />
              ) : (
                <FavoriteBorderOutlinedIcon
                  fontSize="inherit"
                  htmlColor="#CCD2E3"
                />
              )}
            </Stack>
            <Stack
              direction="row"
              spacing={0.75}
              sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
            >
              <Tex
                component="span"
                fontWeight={700}
                fontSize={16}
                lineHeight="24px"
                fontFamily="CircularStd-Medium"
                sx={{ letterSpacing: 0 }}
              >
                {place.name}
              </Tex>
              {neighborhood && (
                <Tex
                  component="span"
                  fontSize={14}
                  lineHeight="24px"
                  fontFamily="CircularStd-Light"
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  in {neighborhood.name}
                </Tex>
              )}
            </Stack>
          </Stack>
          <Tex
            component="span"
            fontSize={16}
            lineHeight="24px"
            fontFamily="CircularStd-Light"
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 4,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {place.description}
          </Tex>
        </Box>
        <Stack
          direction="row"
          flexWrap="nowrap"
          spacing={1}
          sx={{
            pl: 1.5,
            pb: 1,
            overflow: 'auto',
            mt: 1,
            scrollbarWidth: 'none',
          }}
        >
          {place.book ? (
            <PlaceExternalLink
              icon={mdiBookOpenVariant}
              text="BOOK"
              url={place.book}
            />
          ) : null}
          {place.instagram ? (
            <PlaceExternalLink
              icon={mdiCameraOutline}
              text="INSTAGRAM"
              url={place.instagram}
            />
          ) : null}
          {place.website ? (
            <PlaceExternalLink
              icon={mdiMagnify}
              text="WEBSITE"
              url={place.website}
            />
          ) : null}
        </Stack>
        <Box
          sx={{
            textAlign: 'center',
            backgroundColor: '#DADADA',
            height: '48px',
            mt: 1,
            p: 1.5,
          }}
        >
          <Tex
            component="span"
            fontFamily="CircularStd-Light"
            fontSize={14}
            lineHeight="18px"
          >
            Additional Information
          </Tex>
        </Box>
      </Stack>
    </Container>
  ) : place && placeIds.length === 0 ? (
    <Stack>
      <Tex textAlign="center" sx={{ opacity: 0.5 }}>
        {emptyInfo}
      </Tex>
    </Stack>
  ) : (
    <></>
  );
}

const Container = styled(Stack)`
  width: 100%;
  min-height: 600px;
  flex: 1 auto;
  justify-content: space-between;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  max-width: 389px;
  height: 640px;
  margin: 0 0 16px;
  cursor: pointer;

  ${device.tablet} {
    max-width: 326px;
  }

  ${device.laptopN} {
    min-width: 300px;
    max-width: 300px;
  }

  ${device.desktop} {
    min-width: 309px;
    max-width: 309px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .placeholder-block {
    height: 360px;
    width: auto;
    border-radius: 20px 20px 0 0;
    background-color: ${SECONDARY_COLOR};
  }
`;

const LazyLoadImageContainer = styled(LazyLoadImage)`
  background-color: ${SECONDARY_COLOR};
  height: 360px;
  max-height: 360px;
  width: auto;

  & .placeholder-block {
    height: 360px;
    width: auto;
    border-radius: 20px 20px 0 0;
    background-color: ${SECONDARY_COLOR};
  }
`;
