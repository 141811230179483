import { Select, SelectProps } from '@mui/material';
import { common } from '@mui/material/colors';
import { PRIMARY_COLOR } from '../../settings/jaunt-theme';

export function PrimarySelect<T>(props: SelectProps<T>): JSX.Element {
  const { sx, children, ...otherProps } = props;
  return (
    <Select
      {...otherProps}
      sx={{
        '& fieldset': { border: 'none' },
        '& .MuiSvgIcon-root': { color: common.white },
        backgroundColor: PRIMARY_COLOR,
        color: common.white,
        borderRadius: 9999,
        ...sx,
      }}
    >
      {children}
    </Select>
  );
}
