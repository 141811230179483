import { Link as RouterLink } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Chip, Link, Stack } from '@mui/material';
import { Place, TagType, TripPlace, UserId } from '@jaunt/api';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useCreateNote, useNote, useUpdateNote } from 'src/hooks/use-notes';
import { useModifyLastActivityDay } from 'src/hooks/use-last-modified-activity';
import { usePlaceTags } from 'src/hooks/use-place-tags';
import { useNeighborhood } from 'src/hooks/use-neighborhood';
import { usePlaceFirstImage } from 'src/hooks/use-place-first-image';
import { Tex } from './shared/Tex';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../settings/jaunt-theme';
import { VMTPlaceExternalLinkBook } from './VMTPlaceExternalLinkBook';
import { mdiBookOpenVariant, mdiCameraOutline, mdiMagnify } from '@mdi/js';
import { VMTPlaceExternalLinkInstagram } from './VMTPlaceExternalLinkInstagram';
import { VMTPlaceExternalLinkWebsite } from './VMTPlaceExternalLinkWebsite';
import { TripPlaceCardLabels } from './TripPlaceCardLabels';
import { AddNote } from './AddNote';

type ActivitySummaryProps = {
  place: Place;
  tripPath: string;
  tripPlace?: TripPlace;
  userId: UserId;
  isImageEnabled?: boolean;
  isScheduleEnabled?: boolean;
};

export const ActivitySummary: React.FC<ActivitySummaryProps> = ({
  place,
  tripPath,
  tripPlace,
  userId,
  isImageEnabled,
  isScheduleEnabled = true,
}): JSX.Element | null => {
  const { data: tags } = usePlaceTags(place?.placeId);
  const secondaryTags = useMemo(
    () =>
      tags
        ?.filter((x) => x.type === TagType.SECONDARY_ACTIVITY)
        .map((tag) => tag.name) ?? [],
    [tags],
  );
  const { data: imageUrl } = usePlaceFirstImage(place.placeId);
  const [addNote, setAddNote] = useState(false);
  const [defaultNote, setDefaultNote] = useState('');
  const { data: note } = useNote(place.placeId, userId);
  const { createNote, isSuccess: isCreateNoteSuccess } = useCreateNote();
  const { updateNote, isSuccess: isUpdateNoteSuccess } = useUpdateNote();
  const { modifyLastActivityDay } = useModifyLastActivityDay();
  const { data: neighborhood } = useNeighborhood(place?.neighborhoodId);

  useEffect(() => {
    const tripPlaceDay = tripPlace?.day;
    if (typeof tripPlaceDay === 'number') {
      modifyLastActivityDay(tripPlaceDay.toString());
    }
  }, []);

  useEffect(() => {
    if (note?.note) {
      setDefaultNote(note.note);
    } else {
      setDefaultNote('');
    }
  }, [note]);

  useEffect(() => {
    if (isCreateNoteSuccess || isUpdateNoteSuccess) {
      setAddNote(false);
    }
  }, [isCreateNoteSuccess, isUpdateNoteSuccess]);

  async function handleSaveNote(noteValue: string): Promise<void> {
    if (place.placeId) {
      if (!note || note.note.length === 0) {
        await createNote({
          placeId: place.placeId,
          userId: userId,
          note: noteValue,
        });
      } else if (note && note.note?.length > 0) {
        await updateNote({
          noteId: note.noteId,
          placeId: place.placeId,
          userId: userId,
          note: noteValue,
        });
      }
    }
  }

  return (
    <Container direction="column">
      <Stack
        direction="column"
        alignItems="center"
        sx={{ my: 2, width: '100%' }}
      >
        <Tex
          fontSize={23}
          fontWeight={700}
          fontFamily="Canela"
          lineHeight={'24px'}
          sx={{ color: PRIMARY_COLOR, my: 1 }}
        >
          Activity Summary:
        </Tex>
        {place?.name ? (
          <Stack direction="row" alignItems="center">
            <Tex fontSize={18} lineHeight="24px">
              {place?.name}
            </Tex>
            {neighborhood?.name ? (
              <NeighborhoodName>in {neighborhood.name}</NeighborhoodName>
            ) : null}
          </Stack>
        ) : null}
      </Stack>
      <Stack
        direction="column"
        alignItems="center"
        sx={{ pl: '20px', width: '100%' }}
      >
        <Stack
          sx={{
            pb: 3,
            borderBottom: '1px solid #dadada',
            width: '100%',
            paddingTop: '12px',
            fontSize: '13px',
          }}
        >
          <Tex
            fontFamily="CircularStd-Light"
            fontSize={16}
            fontWeight={450}
            lineHeight={'20px'}
            sx={{ padding: '0 16px 0 8px' }}
          >
            {place.description}
          </Tex>
          <Stack spacing={1} direction="row" sx={{ pt: 1.5 }}>
            {secondaryTags.map((tag) => (
              <Chip
                key={tag}
                label={tag}
                color="primary"
                size="small"
                sx={{
                  backgroundColor: '#fff',
                  color: '#000',
                }}
              />
            ))}
          </Stack>
        </Stack>
        <Stack
          sx={{
            pb: 3,
            borderBottom: '1px solid #DADADA',
            width: '100%',
            paddingTop: '12px',
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            {tripPlace?.activity ? (
              <TripPlaceCardLabels tripPlace={tripPlace} />
            ) : null}
          </Stack>
          <Link
            component={RouterLink}
            to={`/trips/${tripPath}/places/${place.placePath}/schedule`}
            sx={{
              cursor: 'pointer',
              py: 1,
              pointerEvents: isScheduleEnabled ? 'auto' : 'none',
              color: isScheduleEnabled ? PRIMARY_COLOR : '#dadada',
              textDecorationColor: isScheduleEnabled
                ? PRIMARY_COLOR
                : '#dadada',
            }}
          >
            <Tex sx={{ fontSize: '13px' }}>Schedule this activity</Tex>
          </Link>
        </Stack>
        <Stack
          sx={{
            width: '100%',
            pb: 3,
            pt: '12px',
            borderBottom: '1px solid #dadada',
          }}
        >
          {defaultNote ? (
            <Stack>
              <Tex fontSize={16} fontFamily="CircularStd-Medium">
                Note:
              </Tex>
              <Tex fontSize={16} fontFamily="CircularStd-Light">
                {defaultNote}
              </Tex>
            </Stack>
          ) : null}
          <Link
            component={Tex}
            onClick={() => setAddNote(true)}
            sx={{
              fontSize: '13px',
              color: PRIMARY_COLOR,
              my: 1,
              cursor: 'pointer',
            }}
          >
            Add a note
          </Link>
        </Stack>
        <Stack
          sx={{
            pb: 3,
            width: '100%',
            paddingTop: '12px',
          }}
        >
          <ChipWrapper direction="row">
            {place.book ? (
              <Stack sx={{ overflowX: 'visible', m: 0 }}>
                <VMTPlaceExternalLinkBook
                  icon={mdiBookOpenVariant}
                  text="BOOK"
                  url={place.book}
                />
              </Stack>
            ) : null}
            {place.instagram ? (
              <Stack sx={{ overflowX: 'visible', m: 0 }}>
                <VMTPlaceExternalLinkInstagram
                  icon={mdiCameraOutline}
                  text="INSTAGRAM"
                  url={place.instagram}
                />
              </Stack>
            ) : null}
            {place.website ? (
              <Stack sx={{ overflowX: 'visible', m: 0 }}>
                <VMTPlaceExternalLinkWebsite
                  icon={mdiMagnify}
                  text="WEBSITE"
                  url={place.website}
                />
              </Stack>
            ) : null}
          </ChipWrapper>
        </Stack>
        {isImageEnabled && imageUrl ? (
          <LazyLoadImage
            alt={place.name}
            src={imageUrl}
            width="100%"
            height="300px"
            style={{
              objectFit: 'cover',
            }}
            placeholder={
              <Stack
                sx={{
                  width: '100%',
                  height: '300px',
                  backgroundColor: SECONDARY_COLOR,
                }}
              />
            }
          />
        ) : null}
      </Stack>
      <AddNote
        isOpen={addNote}
        onClose={() => setAddNote(false)}
        placeId={place.placeId}
        place={place}
        userId={userId}
        defaultNote={defaultNote}
        onSave={handleSaveNote}
      />
    </Container>
  );
};

const Container = styled(Stack)`
  width: 100%;
`;

const NeighborhoodName = styled(Stack)`
  padding-left: 4px;
  padding-top: 4px;
  font-weight: 300;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ChipWrapper = styled(Stack)`
  padding: 8px 0;
  flex-wrap: wrap;
  gap: 8px;
  
  '& div': {
    margin: 0 !important;
  },
`;
