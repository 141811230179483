import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Stack } from '@mui/material';
import styled from '@emotion/styled';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { common } from '@mui/material/colors';
import { Tex } from './shared/Tex';
import { SECONDARY_COLOR } from '../settings/jaunt-theme';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';

interface Props {
  id: string;
  title: string;
  tip?: string;
  description: string;
  imageUrl: string;
  routePath: string;
  isFavorite: boolean;
  onToggleFavorite: (id: string, isNewFav: boolean) => void;
}

export function LandingImageCard({
  id,
  title,
  description,
  tip,
  imageUrl,
  routePath,
  isFavorite,
  onToggleFavorite,
}: Props): JSX.Element {
  async function handleToggleFavorite(event: React.MouseEvent) {
    event.preventDefault();
    onToggleFavorite(id, !isFavorite);
  }

  return (
    <Link component={RouterLink} to={routePath} underline="none">
      <Container justifyContent="end">
        <LazyLoadImage
          key={title}
          alt={title}
          src={imageUrl}
          threshold={150}
          width="100%"
          height="100%"
          className="image-card"
        />
        <Stack
          sx={{
            position: 'absolute',
            top: '8px',
            left: '8px',
            py: 1,
            px: 1.5,
          }}
          onClick={handleToggleFavorite}
        >
          {isFavorite ? (
            <FavoriteOutlinedIcon fontSize="large" htmlColor="#FE0000" />
          ) : (
            <FavoriteBorderOutlinedIcon fontSize="large" htmlColor="#CCD2E3" />
          )}
        </Stack>
        {!!tip && (
          <Stack
            sx={{
              position: 'absolute',
              top: '16px',
              right: '16px',
              py: 1,
              px: 1.5,
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              borderRadius: '20px',
            }}
          >
            <Tex
              fontSize={12}
              fontWeight={700}
              lineHeight="16px"
              fontFamily="CircularStd-Book"
            >
              {tip}
            </Tex>
          </Stack>
        )}
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            px: 1,
            py: 2,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            color: common.white,
            zIndex: 1,
          }}
        >
          <Box>
            <Tex fontSize={22} fontWeight={700}>
              {title}
            </Tex>
            <Tex fontWeight={300}>{description}</Tex>
          </Box>
          {/* <IconButton>
            <MdiIcon
              icon={mdiHeartOutline}
              sx={{ color: common.white, fontSize: 40 }}
            />
          </IconButton> */}
        </Stack>
      </Container>
    </Link>
  );
}

const Container = styled(Stack)`
  width: 250px;
  height: 400px;
  flex-shrink: 0;
  background-color: ${SECONDARY_COLOR};
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  .image-card {
    position: absolute;
    object-fit: cover;
  }
`;
