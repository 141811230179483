import { NeighborhoodId } from '@jaunt/api';
import { getFileDownloadUrl } from './file.api';
import { getNeighborhoodImages } from './neighborhood.api';

export async function getFirstNeighborhoodImageUrl(
  neighborhoodId: NeighborhoodId,
): Promise<string> {
  const [NeighborhoodImages] = await getNeighborhoodImages(neighborhoodId);
  return NeighborhoodImages
    ? getFileDownloadUrl(NeighborhoodImages.fileId)
    : '';
}
