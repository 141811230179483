import { del, get, post, put, patch } from './base.api';
import {
  CityId,
  CityTrip,
  FavoriteTrip,
  ListTripsQuery,
  MultipleFavoriteTripResponse,
  MultipleTripImagesResponse,
  MultipleTripPlacesResponse,
  MultipleTripsResponse,
  MultipleTripStaysResponse,
  MultipleTripUsersResponse,
  ReplaceTripCityData,
  ReplaceTripUsersData,
  SingleFavoriteTripResponse,
  SingleTripCityResponse,
  SingleTripPlaceResponse,
  SingleTripResponse,
  Trip,
  TripData,
  TripId,
  TripImage,
  TripPlace,
  TripPlaceData,
  TripStay,
  TripStayData,
  TripUser,
  TripUserData,
  UpdateTripPlacesData,
  UpdateTripStaysData,
  UserId,
} from '@jaunt/api';

export async function listTrips(isCurated: boolean): Promise<Trip[]> {
  const { trips } = await get<MultipleTripsResponse, ListTripsQuery>('/trips', {
    isCurated,
  });
  return trips;
}

export async function getTripsByUser(userId: UserId): Promise<TripUser[]> {
  return await get<TripUser[]>(`/trips/users/${userId}`, {});
}

export async function getUserTrips(): Promise<Trip[]> {
  return await get<Trip[]>('/trips/users/custom-trips', {});
}

export async function getCuratedTripByCityAndDays(
  cityId: CityId,
  daysNumber: number,
): Promise<SingleTripResponse> {
  return await get<SingleTripResponse>(
    `/trips/city/${cityId}/days/${daysNumber}?isCurated=true`,
    {},
  );
}

export async function getCustomUserTripsByCity(
  userId: UserId,
  cityId: CityId,
  isCurated = true,
): Promise<Trip[]> {
  return await get<Trip[]>(
    `/trips/users/${userId}/city/${cityId}?isCurated=${isCurated}`,
    {},
  );
}

export async function createTrip(data: TripData): Promise<Trip> {
  const { trip } = await post<SingleTripResponse, TripData>('/trips', {}, data);
  return trip;
}

export async function createTripByUser(data: TripData): Promise<Trip> {
  const { trip } = await post<SingleTripResponse, TripData>(
    '/trips/users',
    {},
    data,
  );
  return trip;
}

export async function saveCuratedTripToUser(tripId: TripId): Promise<Trip> {
  const { trip } = await post<SingleTripResponse, { tripId: TripId }>(
    '/trips/save-curated',
    {},
    { tripId },
  );
  return trip;
}

export async function getTrip(tripId: TripId): Promise<Trip> {
  const { trip } = await get<SingleTripResponse>(`/trips/${tripId}`, {});
  return trip;
}

export async function getTripByPath(tripPath: string): Promise<Trip> {
  const { trip } = await get<SingleTripResponse>(`/trips/path/${tripPath}`, {});
  return trip;
}

export async function getPublicTripByPath(tripPath: string): Promise<Trip> {
  const { trip } = await get<SingleTripResponse>(
    `/trips/public/path/${tripPath}`,
    {},
  );
  return trip;
}

export async function getTripImages(tripId: TripId): Promise<TripImage[]> {
  const { tripImages } = await get<MultipleTripImagesResponse>(
    `/trips/${tripId}/images`,
    {},
  );
  return tripImages;
}

export async function getTripPlaces(tripId: TripId): Promise<TripPlace[]> {
  const { tripPlaces } = await get<MultipleTripPlacesResponse>(
    `/trips/${tripId}/places`,
    {},
  );
  return tripPlaces;
}

export async function createTripPlace(data: TripPlaceData): Promise<TripPlace> {
  const { tripPlace } = await post<SingleTripPlaceResponse, TripPlaceData>(
    '/trips/trip-places',
    {},
    data,
  );
  return tripPlace;
}

export async function getTripStays(tripId: TripId): Promise<TripStay[]> {
  const { tripStays } = await get<MultipleTripStaysResponse>(
    `/trips/${tripId}/stays`,
    {},
  );
  return tripStays;
}

export async function getTripCity(tripId: TripId): Promise<CityTrip> {
  const { cityTrip } = await get<SingleTripCityResponse>(
    `/trips/${tripId}/city`,
    {},
  );
  return cityTrip;
}

export async function replaceTripPlaces(
  tripId: TripId,
  tripPlaceDatas: TripPlaceData[],
): Promise<TripPlace[]> {
  const { tripPlaces } = await put<
    MultipleTripPlacesResponse,
    UpdateTripPlacesData
  >(`/trips/${tripId}/places`, {}, { tripPlaceDatas });
  return tripPlaces;
}

export async function updateTripPlaces(
  tripId: TripId,
  tripPlaceDatas: TripPlaceData[],
): Promise<TripPlace[]> {
  const { tripPlaces } = await patch<
    MultipleTripPlacesResponse,
    UpdateTripPlacesData
  >(`/trips/${tripId}/places`, {}, { tripPlaceDatas });
  return tripPlaces;
}

export async function replaceTripStays(
  tripId: TripId,
  tripStayDatas: TripStayData[],
): Promise<TripStay[]> {
  const { tripStays } = await put<
    MultipleTripStaysResponse,
    UpdateTripStaysData
  >(`/trips/${tripId}/stays`, {}, { tripStayDatas });
  return tripStays;
}

export async function updateTrip(
  tripId: TripId,
  data: TripData,
): Promise<Trip> {
  const { trip } = await put<SingleTripResponse, TripData>(
    `/trips/${tripId}`,
    {},
    data,
  );
  return trip;
}
export async function toggleTripHidden(
  tripId: TripId,
  data: { isHidden: boolean },
): Promise<Trip> {
  const { trip } = await patch<SingleTripResponse, { isHidden: boolean }>(
    `/trips/${tripId}/hide`,
    {},
    data,
  );
  return trip;
}

export async function replaceTripCity(
  tripId: TripId,
  data: ReplaceTripCityData,
): Promise<void> {
  return put<void, ReplaceTripCityData>(`/trips/${tripId}/city`, {}, data);
}

export async function replaceTripUsers(
  tripId: TripId,
  tripUserDatas: TripUserData[],
): Promise<TripUser[]> {
  const { tripUsers } = await put<
    MultipleTripUsersResponse,
    ReplaceTripUsersData
  >(`/trips/${tripId}/users`, {}, { tripUserDatas });
  return tripUsers;
}

export async function deleteTrip(tripId: TripId): Promise<Trip> {
  const { trip } = await del<SingleTripResponse>(`/trips/${tripId}`, {});
  return trip;
}

export async function publicTrip(
  tripId: TripId,
  data: { isPublic: boolean },
): Promise<Trip> {
  const { trip } = await patch<SingleTripResponse, { isPublic: boolean }>(
    `/trips/${tripId}/public`,
    {},
    data,
  );
  return trip;
}

export async function listFavoriteTrips(): Promise<FavoriteTrip[]> {
  const { favoriteTrips } = await get<MultipleFavoriteTripResponse>(
    '/trip-favorite',
    {},
  );
  return favoriteTrips;
}

export async function getFavoriteTrip(tripId: TripId): Promise<FavoriteTrip> {
  const { favoriteTrip } = await get<SingleFavoriteTripResponse>(
    `/trip-favorite/${tripId}`,
    {},
  );
  return favoriteTrip;
}

export async function createFavoriteTrip(data: {
  tripId: TripId;
}): Promise<FavoriteTrip> {
  const { favoriteTrip } = await post<
    SingleFavoriteTripResponse,
    {
      tripId: TripId;
    }
  >('/trip-favorite', {}, data);
  return favoriteTrip;
}

export async function deleteFavoriteTrip(
  tripId: TripId,
): Promise<FavoriteTrip> {
  const { favoriteTrip } = await del<SingleFavoriteTripResponse>(
    `/trip-favorite/${tripId}`,
    {},
  );
  return favoriteTrip;
}
