import { useMutation, useQueryClient } from 'react-query';
import { TripId, TripUserData } from '@jaunt/api';
import QueryKeys from './constants/query-keys';
import { replaceTripUsers } from '../services/trip.api';

export const useReplaceTripUsers = () => {
  const client = useQueryClient();
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation(
    async ({ tripId, data }: { tripId: TripId; data: TripUserData[] }) => {
      return await replaceTripUsers(tripId, data);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(QueryKeys.userTrips);
      },
    },
  );
  return { replaceTripUsers: mutateAsync, isLoading, isSuccess, isError };
};
