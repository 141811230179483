import { Dialog, IconButton } from '@mui/material';
import { Tex } from './shared/Tex';
import { mdiClose } from '@mdi/js';
import { MdiIcon } from './shared/MdiIcon';

interface Props {
  isOpen: boolean;
  text?: string;
  onClose(): void;
}

export function ComingSoonDialog(props: Props): JSX.Element {
  const { isOpen, onClose, text } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen
      sx={{
        '& .MuiPaper-root': {
          p: 2,
          alignItems: 'center',
          borderRadius: '20px',
          position: 'relative',
          height: '150px',
          width: '75%',
        },
      }}
    >
      <IconButton size="small" onClick={onClose} sx={{ paddingLeft: 27 }}>
        <MdiIcon icon={mdiClose} sx={{ fontSize: '24px' }} />
      </IconButton>
      <Tex
        fontWeight={700}
        fontFamily="CircularStd-Book"
        sx={{ py: 2, textAlign: 'center' }}
      >
        {text ?? 'COMING SOON!'}
      </Tex>
    </Dialog>
  );
}
