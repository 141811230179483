import { common } from '@mui/material/colors';
import { Stack, Link } from '@mui/material';
import { Tex } from '../shared/Tex';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Neighborhood } from '@jaunt/api';
import { Link as RouterLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { device } from '../../settings/jaunt-theme';

type CityDetailNeighborhoodsItemProps = {
  neighborhood: Neighborhood;
};

export const CityDetailNeighborhoodsItem = ({
  neighborhood,
}: CityDetailNeighborhoodsItemProps): JSX.Element | null => {
  return (
    <Link
      component={RouterLink}
      to={`/neighborhoods/${neighborhood.neighborhoodPath}`}
      underline="none"
    >
      <NeighborhoodStack key={neighborhood.neighborhoodId}>
        <Stack gap="4px" sx={{ width: '100%' }}>
          <Tex
            fontSize={18}
            lineHeight="24px"
            component="span"
            fontWeight={700}
            fontFamily="CircularStd-Book"
          >
            {neighborhood.name}
          </Tex>
          <Tex
            fontSize={16}
            lineHeight="24px"
            component="p"
            fontWeight={300}
            fontFamily="CircularStd-Light"
            sx={{
              maxWidth: '336px',
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {neighborhood.description}
          </Tex>
        </Stack>
        <KeyboardArrowRightIconWrapper fontSize="medium" />
      </NeighborhoodStack>
    </Link>
  );
};

const NeighborhoodStack = styled(Stack)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 8px 16px;
  background-color: ${common.white};
  width: 100%;

  ${device.laptopN} {
    align-items: flex-start;
    gap: 20px;
    text-align: center;
    padding: 16px 8px;
    height: 184px;
    max-width: 310px;
    min-width: 310px;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${common.black};
  }
`;

const KeyboardArrowRightIconWrapper = styled(KeyboardArrowRightIcon)`
  ${device.laptopS} {
    display: none;
  }
`;
