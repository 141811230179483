import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import {
  Chip,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import styled from '@emotion/styled';
import { common } from '@mui/material/colors';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { TagType } from '@jaunt/api';
import { Tex } from 'src/components/shared/Tex';
import { LogoHeader } from 'src/components/LogoHeader';
import { Footer } from 'src/components/Footer';
import { useTripPath } from 'src/hooks/use-trip';
import { useTripPlaces, useUpdateTripPlaces } from 'src/hooks/use-trip-places';
import { NotFoundPage } from './NotFoundPage';
import {
  BACKGROUND_COLOR,
  device,
  PRIMARY_COLOR,
} from 'src/settings/jaunt-theme';
import { usePlaceTags } from 'src/hooks/use-place-tags';
import { SchedulePlaceDialog } from 'src/components/SchedulePlaceDialog';
import { usePlacePath } from 'src/hooks/use-place';
import { LoadingContainer } from 'src/components/loading/LoadingContainer';
import isEqual from 'lodash/isEqual';
import { useModifyLastActivityDay } from 'src/hooks/use-last-modified-activity';

const CLEAR_ALL = 'CLEAR ALL';
const SAVE = 'SAVE';
const INVALID_DATA_ERROR = 'Invalid data error';

enum ModalTypes {
  activity = 'activity',
  time = 'time',
  people = 'people',
}

const StayActivityList = ['Check-in', 'Check-out', 'Relax'];
const EatActivityList = [
  'Breakfast',
  'Lunch',
  'Dinner',
  'Coffee & snacks',
  'Drinks',
];

const timeStamp: Record<string, number> = {
  Midnight: 0,
  '1am': 1,
  '2am': 2,
  '3am': 3,
  '4am': 4,
  '5am': 5,
  '6am': 6,
  '7am': 7,
  '8am': 8,
  '9am': 9,
  '10am': 10,
  '11am': 11,
  Noon: 12,
  '1pm': 13,
  '2pm': 14,
  '3pm': 15,
  '4pm': 16,
  '5pm': 17,
  '6pm': 18,
  '7pm': 19,
  '8pm': 20,
  '9pm': 21,
  '10pm': 22,
  '11pm': 23,
};
const timeList = Object.keys(timeStamp);
const peopleList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export function SchedulePlace(): JSX.Element {
  const params = useParams();
  const navigate = useNavigate();
  const tripPath = params.tripPath || null;
  const placePath = params.placePath || null;
  const { data: place, isLoading: isPlaceLoading } = usePlacePath(placePath);
  const { data: trip, isLoading: isTripLoading } = useTripPath(tripPath);
  const { data: tags } = usePlaceTags(place?.placeId);
  const { data: tripPlaces } = useTripPlaces(trip?.tripId);
  const [day, setDay] = useState<string>();
  const [isSaving, setIsSaving] = useState(false);
  const [modalType, setModalType] = useState<string | undefined>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activity, setActivity] = useState('');
  const [activityList, setActivityList] = useState<string[]>([]);
  const [time, setTime] = useState('');
  const [peopleNumber, setPeopleNumber] = useState(1);
  const [error, setError] = useState('');
  const [draft, setDraft] = useState(true);
  const { modifyLastActivityDay } = useModifyLastActivityDay();
  const {
    updateTripPlaces,
    isSuccess: isUpdateTripPlacesSuccess,
    isError: isUpdateTripPlacesError,
  } = useUpdateTripPlaces();

  const tripPlace = useMemo(() => {
    if (tripPlaces?.length && place?.placeId) {
      return tripPlaces.find(
        (tripPlace) => tripPlace.placeId === place.placeId,
      );
    }
    return null;
  }, [tripPlaces, place]);

  useEffect(() => {
    if (tripPlace?.day !== null) {
      setDay(tripPlace?.day.toString());
    }

    if (tripPlace?.activity) {
      setActivity(tripPlace.activity);
    }

    if (tripPlace?.time) {
      const time = tripPlace.time;
      Object.entries(timeStamp).forEach((timeItem) => {
        if (timeItem[1] === +time) {
          setTime(timeItem[0]);
        }
      });
    }

    if (tripPlace?.people) {
      setPeopleNumber(+tripPlace?.people);
    }
  }, [tripPlace]);

  useEffect(() => {
    const activityTags = tags
      ?.filter((x) => x.type === TagType.ACTIVITY)
      .reduce((acc: string[], tag) => {
        if (tag.name === 'Eat' || tag.name === 'Drink') {
          acc = [...acc, ...EatActivityList];
        } else if (tag.name === 'Stay') {
          acc = [...acc, ...StayActivityList];
        } else if (tag.name === 'Do') {
          const secondaryTags = tags
            .filter((x) => x.type === TagType.SECONDARY_ACTIVITY)
            .map((tag) => tag.name);
          acc = [...acc, ...secondaryTags, 'Do'];
        }
        return acc;
      }, []);
    const newActivityList = Array.from(new Set(activityTags));

    if (!isEqual(newActivityList, activityList)) {
      setActivityList(newActivityList);
    }

    // setActivityList(Array.from(new Set(activityTags)));
  }, [tags]);

  useEffect(() => {
    if (isUpdateTripPlacesSuccess) {
      setIsSaving(false);
      navigate(-1);
    }
  }, [isUpdateTripPlacesSuccess]);

  useEffect(() => {
    if (isUpdateTripPlacesError) {
      setIsSaving(false);
    }
  }, [isUpdateTripPlacesError]);

  const handleSelectDay = (day: string) => {
    setDay(day);
    setActivity('');
    setTime('');
    setPeopleNumber(1);
    setError('');
    setDraft(false);
  };

  const handleSelectActivity = (activity: string) => {
    setActivity(activity);
    setError('');
    setIsModalOpen(false);
    setDraft(false);
  };

  const handleSelectTime = (time: string) => {
    setTime(time);
    setError('');
    setIsModalOpen(false);
    setDraft(false);
  };

  const handleSelectPeopleNumber = (peopleNumber: number) => {
    setPeopleNumber(peopleNumber);
    setError('');
    setIsModalOpen(false);
    setDraft(false);
  };

  const openModal = (type: string) => {
    setIsModalOpen(true);
    setModalType(type);
  };

  const resetSchedule = async () => {
    navigate(-1);
  };

  const saveSchedule = async () => {
    try {
      setIsSaving(true);
      if (tripPlace && day && timeList.includes(time)) {
        const updateTripPlace = {
          ...tripPlace,
          activity,
          day: +day,
          time: timeStamp[time as string],
          people: peopleNumber,
        };
        modifyLastActivityDay(day.toString());

        await updateTripPlaces({
          tripId: tripPlace.tripId,
          tripPlaceDatas: [updateTripPlace],
        });
      } else {
        throw new Error('Invalid data');
      }
    } catch (error) {
      setError(INVALID_DATA_ERROR);
    }
  };

  if (isPlaceLoading || isTripLoading) {
    return <LoadingContainer />;
  }

  return trip?.tripId && place?.placeId ? (
    <>
      <LogoHeader />
      <Stack className="main">
        <Container sx={{ mb: 2, width: '100%' }}>
          {!!error && (
            <ErrorBlock>
              <Tex fontSize={16} fontWeight={500} textAlign="center">
                {error}
              </Tex>
            </ErrorBlock>
          )}
          <Stack width="80%">
            {place?.name ? (
              <Stack direction="column" alignItems="center" sx={{ my: 3 }}>
                <TitleText>Schedule:</TitleText>
                <Tex fontSize={18} lineHeight="25px">
                  {place?.name}
                </Tex>
              </Stack>
            ) : null}
            <Stack direction="column" alignItems="center">
              {Object.keys(trip?.detail.days).length ? (
                <List dense={true}>
                  {Object.keys(trip.detail.days).map((dayNumber) => (
                    <DayListItem
                      key={dayNumber}
                      secondaryAction={
                        <AddDayIcon
                          edge="end"
                          aria-label="add"
                          onClick={() => handleSelectDay(dayNumber)}
                          is_active={dayNumber === day ? 1 : 0}
                        >
                          {dayNumber === day ? (
                            <CheckIcon fontSize="small" htmlColor="#fff" />
                          ) : (
                            <AddIcon fontSize="small" htmlColor="#fff" />
                          )}
                        </AddDayIcon>
                      }
                    >
                      <DateBlock>
                        {trip.detail.days[+dayNumber] ? (
                          <>
                            <DayItemPrimaryText>
                              {format(
                                new Date(
                                  trip.detail.days[+dayNumber] as string,
                                ),
                                'EEE',
                              )}
                            </DayItemPrimaryText>
                            <DayItemSecondaryText>
                              {format(
                                new Date(
                                  trip.detail.days[+dayNumber] as string,
                                ),
                                'MM/d',
                              )}
                            </DayItemSecondaryText>
                          </>
                        ) : (
                          <Tex>Day {+dayNumber + 1}</Tex>
                        )}
                      </DateBlock>
                      {dayNumber === day && (
                        <ActionsBlock>
                          <Chip
                            key="activity"
                            variant={activity ? 'filled' : 'outlined'}
                            label="Activity"
                            color="primary"
                            onClick={() =>
                              activityList && openModal(ModalTypes.activity)
                            }
                            sx={{ mx: 0.2 }}
                          />
                          <Chip
                            key="time"
                            variant={time ? 'filled' : 'outlined'}
                            label="Time"
                            color="primary"
                            onClick={() => openModal(ModalTypes.time)}
                            sx={{ mx: 0.2 }}
                          />
                          <Chip
                            key="people-number"
                            variant={peopleNumber ? 'filled' : 'outlined'}
                            color="primary"
                            label={<PersonAddAlt1Icon />}
                            sx={{
                              mx: 0.2,
                              '& span': {
                                px: 1,
                                display: 'flex',
                                alignItems: 'center',
                              },
                            }}
                            onClick={() => openModal(ModalTypes.people)}
                          />
                        </ActionsBlock>
                      )}
                    </DayListItem>
                  ))}
                </List>
              ) : (
                <Stack alignItems="center">
                  <Tex
                    fontSize={24}
                    fontWeight={500}
                    textAlign="center"
                    color={PRIMARY_COLOR}
                  >
                    No days selected
                  </Tex>
                  <Link
                    onClick={resetSchedule}
                    sx={{ mt: 2, cursor: 'pointer' }}
                  >
                    Go back to schedule date
                  </Link>
                </Stack>
              )}
            </Stack>
          </Stack>
          <Stack alignItems="center" width="80%">
            <Link onClick={resetSchedule} sx={{ cursor: 'pointer' }}>
              {CLEAR_ALL}
            </Link>
            <SaveButton
              variant="contained"
              loading={isSaving}
              onClick={saveSchedule}
              disabled={
                isSaving || !activity || !time || !peopleNumber || draft
              }
            >
              {SAVE}
            </SaveButton>
          </Stack>
          <SchedulePlaceDialog
            isOpen={isModalOpen && !!modalType}
            onClose={() => setIsModalOpen(false)}
          >
            {modalType === ModalTypes.activity ? (
              <>
                {activityList.map((item) => (
                  <DialogListItem
                    key={item}
                    onClick={() => handleSelectActivity(item)}
                    secondaryAction={
                      item === activity ? (
                        <CheckIcon fontSize="small" color="primary" />
                      ) : null
                    }
                  >
                    <ListItemText primary={item} />
                  </DialogListItem>
                ))}
              </>
            ) : modalType === ModalTypes.time ? (
              <>
                {timeList.map((item) => (
                  <DialogListItem
                    key={item}
                    onClick={() => handleSelectTime(item)}
                    secondaryAction={
                      item === time ? (
                        <CheckIcon fontSize="small" color="primary" />
                      ) : null
                    }
                  >
                    <ListItemText primary={item} />
                  </DialogListItem>
                ))}
              </>
            ) : (
              <>
                {peopleList.map((item) => (
                  <DialogListItem
                    key={item}
                    onClick={() => handleSelectPeopleNumber(item)}
                    secondaryAction={
                      item === peopleNumber && (
                        <CheckIcon fontSize="small" color="primary" />
                      )
                    }
                  >
                    <ListItemText primary={item} />
                  </DialogListItem>
                ))}
              </>
            )}
          </SchedulePlaceDialog>
        </Container>
      </Stack>
      <Footer />
    </>
  ) : (
    <NotFoundPage />
  );
}

const Container = styled(Stack)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  margin-bottom: 16px;
  gap: 24vh;

  ${device.laptopN} {
    gap: 8vh;
  }
`;

const DayListItem = styled(ListItem)`
  height: 52px;
  width: 340px;
  background-color: ${common.white};
  border-bottom: 1px solid #d6d6d6;
`;

const AddDayIcon = styled(IconButton)<{ is_active: number }>`
  margin: 0;
  background-color: ${({ is_active }) =>
    is_active ? PRIMARY_COLOR : BACKGROUND_COLOR};
  color: #fff;

  &:hover {
    background-color: ${({ is_active }) =>
      is_active ? PRIMARY_COLOR : BACKGROUND_COLOR};
  }
`;

const DayItemPrimaryText = styled(Tex)`
  font-size: 16px;
  line-height: 20px;
  font-family: 'CircularStd-Book', sans-serif;
  font-weight: 700;
`;

const DayItemSecondaryText = styled(Tex)`
  color: #a19e9e;
  font-size: 16px;
  line-height: 20px;
  font-family: 'CircularStd-Book', sans-serif;
  font-weight: 500;
`;

const DialogListItem = styled(ListItem)`
  height: 52px;
  width: 100%;
  background-color: ${common.white};
  border-bottom: 1px solid #d6d6d6;
  cursor: pointer;

  &:hover {
    background: #eee;
  }
`;

const ErrorBlock = styled(Stack)`
  background-color: #d6472a;
  color: #fff;
  padding: 8px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const TitleText = styled(Tex)`
  text-align:center;
  color: ${PRIMARY_COLOR}
  font-family: "canela-bold", sans-serif;
  font-size: 24px;
  font-weight: 700;
`;

const DateBlock = styled(Stack)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 24px;
  width: 44px;
`;

const ActionsBlock = styled(Stack)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 8px;
`;

const SaveButton = styled(LoadingButton)<{ disabled: boolean }>`
  padding: 8px 16px;
  margin: 16px 0 8px;
  border-radius: 9999px;
  background-color: ${({ disabled }) => (disabled ? '#000' : '#d6472a')};
  height: 40px;
  width: 80%;
  max-width: 260px;
`;
