import { useState } from 'react';
import styled from '@emotion/styled';
import { LogoHeader } from '../components/LogoHeader';
import { Tex } from '../components/shared/Tex';
import { Link, Stack } from '@mui/material';
import { Footer } from '../components/Footer';
import { NotFoundPage } from './NotFoundPage';
import { useNavigate, useParams } from 'react-router-dom';
import { PRIMARY_COLOR } from '../settings/jaunt-theme';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormTextField } from 'src/components/shared/FormTextField';
import { FormSelect } from 'src/components/shared/FormSelect';
import { activityTags } from 'src/constants';
import { PlaceData } from '@jaunt/api';
import { common } from '@mui/material/colors';
import { useTripPath } from 'src/hooks/use-trip';
import { useCityNeighborhoods } from 'src/hooks/use-city-neighborhoods';
import { useTags } from 'src/hooks/use-tags';
import { useQuery } from 'src/hooks/use-query';
import { useCreateTripPlace } from 'src/hooks/use-trip-places';
import { useCreatePlace } from 'src/hooks/use-place';
import { useReplacePlaceTags } from 'src/hooks/use-place-tags';

const CANCEL = 'CANCEL';
const SAVE = 'SAVE THIS ACTIVITY';

const activityTagOptions = activityTags.map((tag) => ({
  value: tag.charAt(0).toUpperCase() + tag.slice(1),
  label: tag.charAt(0).toUpperCase() + tag.slice(1),
}));

const resolver = yupResolver(
  yup.object({
    activity: yup.string().required('Required'),
    neighborhoodId: yup.string().required('Required'),
    tag: yup.string().required('Required'),
  }),
);

interface FormData {
  activity: string;
  neighborhoodId: string;
  tag: string;
}

export function AddTripPlace(): JSX.Element {
  const params = useParams();
  const query = useQuery();
  const navigate = useNavigate();
  const tripPath = params.tripPath || null;
  const cityId = query.get('cityId');
  const { data: trip } = useTripPath(tripPath);
  const { data: tags } = useTags();
  const [error, setError] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const { register, handleSubmit, formState } = useForm<FormData>({
    resolver,
  });
  const { data: neighborhoods } = useCityNeighborhoods(cityId);
  const { createTripPlace } = useCreateTripPlace();
  const { createPlace } = useCreatePlace();
  const { replacePlaceTags } = useReplacePlaceTags();

  async function onSubmit(formData: FormData): Promise<void> {
    setIsSaving(true);
    try {
      if (trip?.tripId && tags) {
        const newPlaceData: PlaceData = {
          cityId: cityId ?? undefined,
          neighborhoodId: formData.neighborhoodId,
          name: formData.activity,
          description: '',
          tip: '',
          website: '',
          book: '',
          instagram: '',
          isCustom: true,
          placePath: uuid(),
        };
        const createdPlace = await createPlace(newPlaceData);

        const activityTagIds = tags
          .filter(
            (tag) => tag.name.toLowerCase() === formData.tag.toLowerCase(),
          )
          .map((tag) => tag.tagId);

        await replacePlaceTags({
          placeId: createdPlace.placeId,
          data: activityTagIds,
        });

        const newTripPlace = {
          tripId: trip?.tripId,
          placeId: createdPlace.placeId,
          day: null,
          order: null,
          activity: formData.tag,
          time: null,
          people: null,
        };

        await createTripPlace(newTripPlace);
      }

      setIsSaving(false);

      navigate(-1);
    } catch (_) {
      setIsSaving(false);
      setError('Create place error was executed');
    }
  }

  const onCancel = async () => {
    navigate(-1);
  };

  return trip?.tripId && cityId ? (
    <>
      <LogoHeader />
      <Container className="main">
        {!!error && (
          <ErrorBlock>
            <Tex fontSize={16} fontWeight={500} textAlign="center">
              {error}
            </Tex>
          </ErrorBlock>
        )}
        <Stack
          onSubmit={handleSubmit(onSubmit)}
          component="form"
          display="flex"
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          gap="260px"
          sx={{ width: '100%' }}
        >
          <Stack width="80%" sx={{ alignItems: 'center' }}>
            <Stack direction="column" alignItems="center" sx={{ mt: 5, mb: 7 }}>
              <TitleText fontFamily="canela-bold" color="primary">
                Add An Activity
              </TitleText>
              {trip?.name ? (
                <Tex fontSize={18} lineHeight="25px">
                  to {trip?.name}
                </Tex>
              ) : null}
            </Stack>
            <Stack
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Stack sx={{ my: 1 }}>
                <FormTextField
                  defaultValue=""
                  register={register}
                  formState={formState}
                  fieldName="activity"
                  label="Activity Name"
                  placeholder="Enter activity name"
                  required
                  sx={{
                    width: '340px',
                    fontWeight: 500,
                    borderRadius: '4px',
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: common.white,
                    },
                  }}
                />
              </Stack>
              {neighborhoods?.length ? (
                <Stack sx={{ my: 1 }}>
                  <FormSelect
                    register={register}
                    formState={formState}
                    fieldName="neighborhoodId"
                    label="Neighborhood"
                    placeholder="Enter neighborhood"
                    options={neighborhoods.map(({ name, neighborhoodId }) => ({
                      value: neighborhoodId,
                      label: name,
                    }))}
                    defaultValue={neighborhoods[0].neighborhoodId}
                    required
                    sx={{ width: '340px', backgroundColor: common.white }}
                  />
                </Stack>
              ) : null}
              <Stack sx={{ my: 1 }}>
                <FormSelect
                  register={register}
                  formState={formState}
                  fieldName="tag"
                  label="Type of Activity"
                  options={activityTagOptions}
                  defaultValue={activityTagOptions[0].value}
                  required
                  sx={{
                    width: '340px',
                    backgroundColor: common.white,
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack alignItems="center" width="80%">
            <SaveButton
              variant="contained"
              type="submit"
              loading={isSaving}
              disabled={isSaving}
            >
              {SAVE}
            </SaveButton>
            <Link
              onClick={onCancel}
              sx={{ cursor: 'pointer', fontSize: '12px' }}
            >
              {CANCEL}
            </Link>
          </Stack>
        </Stack>
      </Container>
      <Footer />
    </>
  ) : (
    <NotFoundPage />
  );
}

const Container = styled(Stack)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin-bottom: 16px;
`;

const ErrorBlock = styled(Stack)`
  background-color: #d6472a;
  color: #fff;
  padding: 8px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const TitleText = styled(Tex)`
  text-align:center;
  color: ${PRIMARY_COLOR}
  font-family: "canela-bold", sans-serif;
  font-size: 24px;
  font-weight: 700;
`;

const SaveButton = styled(LoadingButton)<{ disabled: boolean }>`
  padding: 8px 16px;
  margin: 16px 0 8px;
  border-radius: 9999px;
  background-color: ${({ disabled }) => (disabled ? '#000' : PRIMARY_COLOR)};
  height: 40px;
  width: 80%;
  max-width: 260px;
`;
