import { Tex } from '../shared/Tex';
import {
  device,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
} from '../../settings/jaunt-theme';
import { Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { common } from '@mui/material/colors';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toQueryParams } from '../../services/base.util';
import styled from '@emotion/styled';

const rootActivities = [
  {
    title: 'Stay',
    description:
      'If you want to cut the search and just stay somewhere awesome, we got you.',
    imageUrl: '/activity_stay.jpeg',
  },
  {
    title: 'Eat',
    description:
      "Breakfast, lunch, dinner, dessert - you won't go hungry, we promise.",
    imageUrl: '/activity_eat.jpeg',
  },
  {
    title: 'Drink',
    description:
      "From dive bars to bougie rooftops, we're giving you the places we'd go ourselves.",
    imageUrl: '/activity_drink.jpeg',
  },
  {
    title: 'Do',
    description:
      "Museum? Castle? Hot air balloon ride?  We'll tell you what you can't NOT do.",
    imageUrl: '/activity_do.jpg',
  },
];

type CityDetailActivitiesProps = {
  cityPath: string | null;
};

export const CityDetailActivities = ({
  cityPath,
}: CityDetailActivitiesProps): JSX.Element | null => {
  function getActivityUrl(activityTitle: string): string {
    if (cityPath) {
      return `/places?${toQueryParams({
        city: cityPath,
        tagName: activityTitle,
      })}`;
    }
    return '/places';
  }

  return (
    <Container spacing={1}>
      <Title
        align="center"
        lineHeight="32px"
        fontFamily="Canela, serif"
        fontWeight={700}
        color={PRIMARY_COLOR}
      >
        Curated Recommendations
      </Title>
      <ActivitiesWrapper spacing={2}>
        {rootActivities.map((activity) => (
          <ActivityLink
            key={activity.title}
            to={getActivityUrl(activity.title)}
          >
            <LazyLoadImage
              key={activity.title}
              src={activity.imageUrl}
              alt={activity.title}
              width="100%"
              height="100%"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
              placeholder={
                <Stack
                  sx={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: SECONDARY_COLOR,
                  }}
                />
              }
            />
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: '100%',
                p: 1,
                backgroundColor: 'rgba(0, 0, 0, 0.68)',
                color: common.white,
                position: 'absolute',
                left: 0,
                bottom: 0,
              }}
            >
              <Tex fontSize={16} fontWeight={500}>
                {activity.title}
              </Tex>
            </Stack>
          </ActivityLink>
        ))}
      </ActivitiesWrapper>
      <ActivitiesLink
        to={cityPath ? `/places?${toQueryParams({ city: cityPath })}` : '/'}
      >
        <Tex
          fontFamily="CircularStd-Light"
          fontWeight={300}
          fontSize={16}
          lineHeight="24px"
        >
          View All Activities
        </Tex>
      </ActivitiesLink>
    </Container>
  );
};

const Container = styled(Stack)`
  padding: 0 16px;
  margin-top: 20px !important;

  ${device.tablet} {
    padding: 0 32px;
  }

  ${device.laptopN} {
    padding: 0 70px;
  }
`;

const Title = styled(Tex)`
  font-size: 27px;
  margin: 24px 16px 16px !important;

  ${device.laptopN} {
    font-size: 34px;
    margin-bottom: 28px !important;
  }
`;

const ActivitiesWrapper = styled(Stack)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;

  ${device.laptopN} {
    gap: 12px;
    justify-content: flex-start;
  }

  ${device.desktop} {
    gap: 12px;
    justify-content: space-between;
  }
`;

const ActivityLink = styled(RouterLink)`
  width: calc(50% - 10px);
  min-width: 170px;
  max-width: 400px;
  height: 170px;
  position: relative;
  margin: 0 !important;
  color: ${common.black};

  ${device.laptopN} {
    width: 210px;
    height: 230px;
  }

  ${device.desktop} {
    width: 310px;
    height: 310px;
  }
`;

const ActivitiesLink = styled(RouterLink)`
  margin: 8px 0 20px 0 !important;
  color: ${PRIMARY_COLOR};
`;
